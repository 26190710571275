import {
    STAFF_MEMBERS_LOADED,
    STUDENTS_LOADED,
    CONTACTS_LOADED,
    ADMIN_ERROR,
    LOADING_STUDENT,
    GET_STUDENT, // for new and updated students
    CLEAR_STUDENT,
    LOADING_STUDENT_PIC,
    UPDATE_STUDENT_PIC,
    DONE_LOADING_STUDENT_PIC,
    LOADING_CONTACT,
    GET_CONTACT,
    CLEAR_CONTACT,
    LOADING_CONTACT_PIC,
    DONE_LOADING_CONTACT_PIC,
    UPDATE_CONTACT_PIC,
    LOADING_TERM,
    TERMS_LOADED,
    GET_TERM,
    CLEAR_TERM,
    LOADING_SCHOOL,
    GET_SCHOOL,
    SCHOOLS_FOR_USER_LOADED,
    DONE_LOADING_SCHOOL_PIC,
    LOADING_SCHOOL_PIC,
    UPDATE_SCHOOL_PIC,
    GET_SCHOOL_ATTENDANCE,
    LOADING_SCHOOL_ATTENDANCE,
    SCHOOL_ATTENDANCE_LOADED,
    LOGOUT,
    ACCOUNT_DELETED,
    LOADING_STAFF,
    SUPERADMIN_USER_SWITCHED,
    GRADE_LEVELS_LOADED,
    LOADING_GRADE_LEVEL,
    GET_GRADE_LEVEL,
    CLEAR_GRADE_LEVEL,
    DELETE_GRADE_LEVEL,
    LOADING_FEE,
    FEES_LOADED,
    GET_FEE,
    CLEAR_FEE,
    CLEAR_FEES,
    DELETE_FEE,
} from '../actions/types';

const initialState = {
    staffmembers: null,
    loading_staff: false,

    students: null,
    student: null, // currently viewed student
    loading_student: false,
    uploading_student_pic: false,

    contacts: null,
    contact: null,
    loading_contact: false,
    uploading_contact_pic: false,

    terms: null,
    term: null,
    loading_term: false,

    schools: null,
    school: null,
    loading_school: false,

    schoolattendancefordate: null,
    loading_school_attendance: false,

    loading_grade_level: false,
    grade_levels: null,
    grade_level: null,

    loading_fee: false,
    fees: null,
    fee: null,

    // error: {}, // for request errors
};

// reducers take a state and an action (the action is dispatched from a file)
// actions have a type and a payload
const adminReducer = (state = initialState, action) => {
    const { type, payload } = action; // pulled out to shorthand the code

    switch (type) {
        case LOADING_STUDENT:
            return {
                ...state,
                loading_student: true,
            };

        case LOADING_STAFF:
            return {
                ...state,
                loading_staff: true,
            };

        case STAFF_MEMBERS_LOADED:
            return {
                ...state,
                staffmembers: payload,
                loading_staff: false,
            };

        case LOADING_SCHOOL_ATTENDANCE:
            return {
                ...state,
                loading_school_attendance: true,
            };

        case GET_SCHOOL_ATTENDANCE:
            return {
                ...state,
                schoolattendancefordate: payload,
                loading_school_attendance: false,
            };

        case SCHOOL_ATTENDANCE_LOADED: {
            // used when updating attendance. when updating, also
            // need to update all attendance entries to reflect the change to this attendance
            let use = state.schoolattendancefordate
                ? [...state.schoolattendancefordate]
                : [];
            let newdata = use.find((a) => a._id === payload._id)
                ? use.map(
                      // update entry
                      (a) => (a._id === payload._id ? payload : a)
                  )
                : [...use, payload]; // new entry

            return {
                ...state,
                schoolattendancefordate: newdata,
                loading_school_attendance: false,
            };
        }

        case SCHOOLS_FOR_USER_LOADED:
            return {
                ...state,
                schools: payload,
                loading_school: false,
            };

        case STUDENTS_LOADED:
            return {
                ...state,
                students: payload,
                loading_student: false,
            };

        case LOADING_CONTACT:
            return {
                ...state,
                loading_contact: true,
            };

        case CONTACTS_LOADED: {
            return {
                ...state,
                contacts: payload,
                loading_contact: false,
            };
        }

        case GET_CONTACT: {
            // used when updating a contact. when updating, also
            // need to update all contacts to reflect the change to this contact

            let use = state.contacts ? [...state.contacts] : null;

            let newcontacts = use?.find((a) => a._id === payload.contact._id)
                ? use.map(
                      // update entry
                      (a) =>
                          a._id === payload.contact._id ? payload.contact : a
                  )
                : use
                ? [...use, payload.contact]
                : null; // new entry

            return {
                ...state,
                contact: payload.contact,
                contacts: newcontacts,
                loading_contact: false,
            };
        }

        case LOADING_GRADE_LEVEL:
            return {
                ...state,
                loading_grade_level: true,
            };

        case GRADE_LEVELS_LOADED: {
            return {
                ...state,
                grade_levels: payload,
                loading_grade_level: false,
            };
        }

        case GET_GRADE_LEVEL: {
            // used when updating a level. when updating, also
            // need to update all levels to reflect the change to this one
            let use = state.grade_levels ? [...state.grade_levels] : null;

            let newlevels = use?.find((a) => a._id === payload._id)
                ? use.map(
                      // update entry
                      (a) => {
                          return a._id === payload._id ? payload : a;
                      }
                  )
                : use
                ? [...use, payload]
                : null; // new entry

            return {
                ...state,
                grade_level: payload,
                grade_levels: newlevels,
                loading_grade_level: false,
            };
        }

        case LOADING_FEE:
            return {
                ...state,
                loading_fee: true,
            };

        case FEES_LOADED: {
            return {
                ...state,
                fees: payload,
                loading_fee: false,
            };
        }

        case GET_FEE: {
            // used when updating a fee. when updating, also
            // need to update all fees to reflect the change to this one
            let use = state.fees ? [...state.fees] : null;

            let fee = payload.fee;
            let newfees = use?.find((a) => a._id === fee._id)
                ? use.map(
                      // update entry
                      (a) => {
                          return a._id === fee._id ? fee : a;
                      }
                  )
                : use
                ? [...use, fee]
                : null; // new entry

            return {
                ...state,
                fee,
                fees: newfees,
                loading_fee: false,
            };
        }

        case DELETE_FEE:
            // payload is the deleted object
            return {
                ...state,
                fees: state.fees.filter((fee) => fee._id !== payload._id),
            };

        case LOADING_CONTACT_PIC:
            return {
                ...state,
                uploading_contact_pic: true,
            };

        case DONE_LOADING_CONTACT_PIC:
            return {
                ...state,
                uploading_contact_pic: false,
            };

        case UPDATE_CONTACT_PIC: {
            let contacts = [...state.contacts].map(
                // update entry
                (a) => (a._id === payload._id ? payload : a)
            );

            return {
                ...state,
                contact: { ...state.contact, photo: payload.photo }, // payload is contact obj
                contacts,
                uploading_contact_pic: false,
            };
        }

        case GET_STUDENT: {
            // used when updating or fetching a student. when updating, also
            // need to update all students to reflect the change to this student
            // important: if state.students is null, leave it as is. only add or update it
            // if already set; otherwise, if I create empty array to add this student to,
            // the students page will show only that student and not fetch the others
            // because the array is set and no longer null.
            let use = state.students ? [...state.students] : null;

            let newstudents = use?.find(
                // todo optimize so I'm not finding and mapping. expensive
                (a) => a._id === payload._id
            )
                ? use.map(
                      // update entry
                      (a) => (a._id === payload._id ? payload : a)
                  )
                : use
                ? [...use, payload]
                : null; // new entry

            return {
                ...state,
                student: payload,
                students: newstudents,
                loading_student: false,
            };
        }

        case LOADING_STUDENT_PIC:
            return {
                ...state,
                uploading_student_pic: true,
            };

        case DONE_LOADING_STUDENT_PIC:
            return {
                ...state,
                uploading_student_pic: false,
            };

        case UPDATE_STUDENT_PIC: {
            // payload is student obj
            let use = state.students ? [...state.students] : [];
            let students = [...use].map((a) =>
                a._id === payload._id ? payload : a
            );

            return {
                ...state,
                student: { ...state.student, photo: payload.photo }, // payload is student obj
                students,
                uploading_student_pic: false,
            };
        }

        case LOADING_SCHOOL_PIC:
            return {
                ...state,
                uploading_school_pic: true,
            };

        case DONE_LOADING_SCHOOL_PIC:
            return {
                ...state,
                uploading_school_pic: false,
            };

        case UPDATE_SCHOOL_PIC: {
            // payload is school obj
            let use = state.schools ? [...state.schools] : [];

            let schools = [...use].map((a) =>
                a._id === payload._id ? payload : a
            );

            return {
                ...state,
                school: { ...state.school, logo: payload.logo },
                schools,
                uploading_school_pic: false,
            };
        }

        case LOADING_SCHOOL:
            return {
                ...state,
                loading_school: true,
            };

        case GET_SCHOOL: {
            // used when updating or fetching a school
            let use = state.schools ? [...state.schools] : null;
            let newdata = use?.find((a) => a._id === payload._id)
                ? use.map(
                      // update entry
                      (a) => (a._id === payload._id ? payload : a)
                  )
                : use
                ? [...use, payload]
                : null; // new entry

            return {
                ...state,
                school: payload,
                schools: newdata,
                loading_school: false,
            };
        }

        case LOADING_TERM:
            return {
                ...state,
                loading_term: true,
            };

        case TERMS_LOADED: {
            return {
                ...state,
                terms: payload,
                loading_term: false,
            };
        }

        case GET_TERM: {
            // payload is obj w/success bool, msg string, and term obj
            // used when updating a term. when updating, also
            // need to update all terms to reflect the change to this term

            let use = state.terms ? [...state.terms] : null;
            let newdata = use?.find((a) => a._id === payload.term._id)
                ? use.map(
                      // update entry
                      (a) => (a._id === payload.term._id ? payload.term : a)
                  )
                : use
                ? [...use, payload.term]
                : null; // new entry

            return {
                ...state,
                term: payload.term,
                terms: newdata,
                loading_term: false,
            };
        }

        case CLEAR_GRADE_LEVEL: {
            return {
                ...state,
                grade_level: initialState.grade_level,
            };
        }

        case DELETE_GRADE_LEVEL:
            // payload is the deleted object
            return {
                ...state,
                grade_levels: state.grade_levels.filter(
                    (l) => l._id !== payload._id
                ),
            };

        case CLEAR_TERM: {
            return {
                ...state,
                term: initialState.term,
            };
        }

        case CLEAR_FEE: {
            return {
                ...state,
                fee: initialState.fee,
            };
        }

        case CLEAR_FEES: {
            return {
                ...state,
                fees: initialState.fees,
            };
        }

        case CLEAR_STUDENT: {
            return {
                ...state,
                student: initialState.student,
            };
        }

        case CLEAR_CONTACT: {
            return {
                ...state,
                contact: initialState.contact,
            };
        }

        // used to reset admin state when superadmin changes user to preview. impt!
        case SUPERADMIN_USER_SWITCHED: {
            return {
                ...initialState,
            };
        }

        case LOGOUT:
        case ACCOUNT_DELETED:
            return {
                ...initialState,
            };

        case ADMIN_ERROR:
            return {
                ...state, // keep what's currently in the state
                loading_student: false,
                loading_contact: false,
                loading_school: false,
                loading_school_attendance: false,
                loading_staff: false,
                loading_term: false,
                loading_fee: false,
                loading_grade_level: false,
            };

        default:
            return state;
    }
};

export default adminReducer;
