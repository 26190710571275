// PRIVATE ROUTE: logged in user (/account)
import React, { Fragment, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../layout/Spinner';
import {
    getStudentById,
    clearStudent,
} from '../../../../storedata/actions/admin';
import BaseFrame from '../../BaseFrame';
import SchoolHeader from '../../SchoolHeader';
import BackButton from '../../../layout/inputs/BackButton';
import AdminStudentNav from '../AdminStudentNav';
import StudentDetails from './StudentDetails';
import StudentTerms from './StudentTerms';
import { getPrettyName } from '../../../../utils/helpers';

const Student = ({
    studentID,
    getStudentById,
    clearStudent,
    auth: { loading, viewingAs, searchParams },
    admin: { student, loading_student },
}) => {
    let params = useParams();
    let location = useLocation();
    studentID = params.studentid || studentID;

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    useEffect(() => {
        if (studentID) {
            getStudentById(studentID);
        }

        return () => {
            // behaves like componentWillUnmount
            clearStudent();
        };
    }, [getStudentById, studentID, clearStudent]); // @todo8 student not found,

    return (
        <Fragment>
            <BaseFrame>
                <SchoolHeader school={viewingAs?.school} />
                <div className='split-2-header mb-2'>
                    <div className='gridcol2packed'>
                        <BackButton linkTo={`/admin/${params.id}/students/`} />
                        {student ? (
                            <h1>Student: {getPrettyName(student.nameInfo)}</h1>
                        ) : (
                            <Spinner size={0} />
                        )}
                    </div>

                    <Link
                        to={`/admin/${params.id}/editstudent/${studentID}`}
                        className='btn btn-primary'
                    >
                        Edit
                    </Link>
                </div>
                <AdminStudentNav
                    schoolSlug={params.id}
                    studentID={studentID}
                    params={searchParams}
                />
                {loading || loading_student || !student ? (
                    <div className='containerinnersolid'>
                        <Spinner size={0} />
                    </div>
                ) : location.pathname ===
                      `/admin/${viewingAs.school?.urlslug}/student/${studentID}` ||
                  location.pathname ===
                      `/admin/${viewingAs.school?.urlslug}/student/${studentID}/summary` ? (
                    <StudentDetails student={student} />
                ) : location.pathname ===
                  `/admin/${viewingAs.school?.urlslug}/student/${studentID}/terms` ? (
                    <StudentTerms student={student} />
                ) : (
                    <StudentDetails student={student} />
                )}
            </BaseFrame>
        </Fragment>
    );
};

Student.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    getStudentById: PropTypes.func.isRequired,
    clearStudent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
});

export default connect(mapStateToProps, {
    getStudentById,
    clearStudent,
})(Student);
