import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

// closeButtonCallBack: whether to show x button to close modal, and if so, it will be a callback to handle event in caller. @todo prompt to confirm close first
const ModalHeader = ({
    title,
    hasCloseButton = false,
    closeButtonCallBack = null,
    previousLocation = null,
}) => {
    let history = useNavigate();
    let location = useLocation();
    previousLocation = previousLocation || location.state?.previousLocation;

    let navback = (e) => {
        // use passed in close button handler
        if (closeButtonCallBack) {
            closeButtonCallBack(e);
        } else {
            // otherwise, just go to previous page, or route home if fresh pg load
            if (previousLocation) {
                history(previousLocation, { replace: true });
            } else if (window.history.state?.idx > 0) {
                history(-1, { replace: true }); // if history exists, go back a page
            } else {
                history('/', { replace: true }); // otherwise go home if user freshly loaded this page
            }
        }
    };

    return (
        <section className={'modalheader'}>
            <div></div>
            <div>{title}</div>
            <div>
                {hasCloseButton && (
                    <i
                        onClick={(e) => navback(e)}
                        className={'fa fa-close iconlink'}
                    />
                )}
            </div>
        </section>
    );
};

ModalHeader.propTypes = {
    title: PropTypes.string.isRequired,
    closeButtonCallBack: PropTypes.func,
    hasCloseButton: PropTypes.bool,
};

export default ModalHeader;
