// modal overlay routing logic found here:
// https://blog.logrocket.com/building-react-modal-module-with-react-router/#creating-modal-component

import React, {
    useState,
    useRef,
    createRef,
    useLayoutEffect,
    useEffect,
    Fragment,
} from 'react';
import { useEffectOnce } from '../../utils/useEffectOnce';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModalHeader from '../layout/ModalHeader';
import ModalFooter from '../layout/ModalFooter';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { addModal, removeModal } from '../../storedata/actions/modal';

// const INITIAL_MAX_HEIGHT = 10000;

// stepNumber is the step to start on first
const Modal = ({
    addModal,
    removeModal,
    modal, //state
    steps,
    onChangeStep,
    modalTitle,
    startingStepNumber = 1,
    hasCloseButton = false,
    closeButtonCallBack,
    showAsOverlay = false,
    size = 0,
    previousLocation = null,
}) => {
    const [stepNumber, changeStepNumber] = useState(startingStepNumber);
    const modalRef = useRef();
    const wrapperRef = createRef();
    let windowOffset = useRef();
    let modalStateID = useRef(null);
    const [initialized, setInitialized] = useState(null);

    // const [dimensions, setDimensions] = useState(INITIAL_MAX_HEIGHT);
    // useLayoutEffect(() => {
    //     if (wrapperRef.current) {
    //         const boundingRect = wrapperRef.current.getBoundingClientRect();

    //         const { height } = boundingRect;
    //         let h = Math.round(height) - 51;
    //         setDimensions(h);
    //     }
    // }, [wrapperRef]);

    // prevent page window from scrolling to top on modal mount:
    useLayoutEffect(() => {
        if (showAsOverlay) {
            if (!windowOffset.current) {
                windowOffset.current = window.scrollY;
            }
            document.body.setAttribute(
                'style',
                `position:fixed; top: -${windowOffset.current}px; left: 0; right: 0;`
            );

            return () => {
                document.body.setAttribute('style', '');
                window.scrollTo(0, windowOffset.current);
            };
        } else {
            setInitialized(true);
        }
    }, [showAsOverlay]);

    useEffectOnce(() => {
        if (showAsOverlay && initialized) {
            const observerRefValue = modalRef.current;
            disableBodyScroll(observerRefValue);

            return () => {
                if (observerRefValue) {
                    enableBodyScroll(observerRefValue);
                }
            };
        }
    }, [showAsOverlay, initialized]);

    useEffectOnce(() => {
        if (showAsOverlay) {
            if (!modalStateID.current) {
                modalStateID.current = addModal(modalRef);
            }

            return () => {
                if (modalStateID.current) removeModal(modalStateID.current);
            };
        }
    }, [showAsOverlay, addModal, removeModal]);

    useEffect(() => {
        if (modalStateID.current) setInitialized(modalStateID.current);
    }, [showAsOverlay]);

    const numSteps = steps.length;

    const getModalWidth = () => {
        if (size === 0) {
            return 'width500';
        } else if (size === 1) {
            return 'width700';
        } else if (size === 2) {
            return 'width70percent';
        } else {
            return 'width90percent';
        }
    };

    const nextStepButtonTitle = () => {
        return steps[stepNumber - 1].nextStepButtonTitle || 'Next';
    };

    const nextStepButtonIcon = () => {
        return steps[stepNumber - 1].finalStepButtonIcon || 'fa fa-arrow-right';
    };

    const goToPrevStep = () => {
        let prevStep = stepNumber - 1;
        if (prevStep < 1) return; // not a valid step
        changeStepNumber(prevStep);
        onChangeStep(prevStep);
    };

    const goToNextStep = (e) => {
        let stepFunc = steps[stepNumber - 1].nextStepOnClick;
        if (stepFunc) {
            if (stepFunc(e) === false) return;
        }

        let nextStep = stepNumber + 1;
        if (nextStep > numSteps) return; // not a valid step

        changeStepNumber(nextStep);
        onChangeStep(nextStep);
    };

    const body = (
        <div ref={wrapperRef} className={`modalwrapper ${getModalWidth()}`}>
            <ModalHeader
                hasCloseButton={hasCloseButton}
                closeButtonCallBack={closeButtonCallBack}
                title={modalTitle}
                previousLocation={previousLocation}
            />
            <div
                className={`modalbody${
                    showAsOverlay ? ' modalbodyforoverlay' : ''
                }`}
                // umcomment the following for animations but a bit buggy:
                // style={
                //     showAsOverlay
                //         ? {
                //               maxHeight: `min(${dimensions}px, 100vh - 203px)`,
                //               transition: 'all 0.2s linear',
                //               overflowY: 'scroll',
                //           }
                //         : {}
                // }
            >
                <div className='form bigwarmhug'>
                    {steps[stepNumber - 1].body}
                </div>
            </div>
            {
                // only show footer if this modal is the last item in modal state array:

                (!showAsOverlay ||
                    (showAsOverlay &&
                        modal.length > 0 &&
                        modal[modal.length - 1].id === initialized)) && (
                    <Fragment>
                        <ModalFooter
                            hasPrevStep={stepNumber > 1}
                            previouscallback={() => {
                                goToPrevStep();
                            }}
                            nextcallback={(e) => {
                                goToNextStep(e);
                            }}
                            nexttitle={nextStepButtonTitle()}
                            nexticon={nextStepButtonIcon()}
                        />
                    </Fragment>
                )
            }
        </div>
    );
    return showAsOverlay ? (
        initialized ? (
            <div
                ref={modalRef}
                className={
                    modal.length > 0 && modal[0].id === initialized
                        ? 'modal-overlay-bg' /* only show bg for first modal */
                        : ''
                }
            >
                <div id={initialized} className='modal-overlay-main'>
                    {body}
                </div>
            </div>
        ) : null
    ) : (
        body
    );
};

Modal.propTypes = {
    steps: PropTypes.array.isRequired,
    size: PropTypes.number, // modal size: 0 = small, 1 = medium, 2, = large
    startingStepNumber: PropTypes.number,
    closeButtonCallBack: PropTypes.func, // should only be set if hasCloseButton is true
    hasCloseButton: PropTypes.bool,
    showAsOverlay: PropTypes.bool, // whether to show as overlay on top of current pg
    onChangeStep: PropTypes.func, // handler for step change in case that data is needed in caller
    modalTitle: PropTypes.string.isRequired, // for modal header title
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    modal: state.modal,
});

export default connect(mapStateToProps, { addModal, removeModal })(Modal);
