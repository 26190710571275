import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import LeftNavLink from '../../layout/LeftNavLink';
import LeftNavFooter from '../LeftNavFooter';
import LeftNavHomeSection from '../LeftNavHomeSection';

const baseurl = '/superadmin';

const SuperadminLeftNav = () => {
    let params = useParams();
    let school = params.id;

    return (
        <Fragment>
            <LeftNavHomeSection baseurl={baseurl} />

            <ul>
                {LeftNavLink(baseurl + '/schools', 'fas fa-school', 'Schools', [
                    { link: baseurl + '/' + school, exact: false },
                ])}
                {LeftNavLink(
                    baseurl + '/users',
                    'fas fa-address-book',
                    'Users'
                )}
                {/* {LeftNavLink(baseurl + '/plans', 'fas fa-box', 'Plans')} */}
                {/* {LeftNavLink(
                    baseurl + '/messages',
                    'far fa-envelope',
                    'Messages'
                )} */}
                {/* {LeftNavLink(
                    baseurl + '/billing',
                    'fas fa-file-invoice-dollar',
                    'Billing'
                )} */}
                {/* {LeftNavLink(baseurl + '/settings', 'fas fa-gear', 'Settings')} */}
            </ul>

            <LeftNavFooter />
        </Fragment>
    );
};

export default SuperadminLeftNav;
