import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | About School Trail</title>
            </Helmet>

            <div className='containerinnersolid centervertically'>
                <h3 className='normalweight mb-1'>
                    The {APP_NAME} bio is coming soon!
                </h3>
                <h3 className='normalweight'>
                    We are always looking to improve our website, and since we
                    launched recently, we'd love to hear any feedback you may
                    have. Reach out to us on Instagram (
                    <a
                        href='https://www.instagram.com/school.trail/'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        @school.trail
                    </a>
                    ) with your thoughts!
                </h3>
            </div>
        </Fragment>
    );
};

export default About;
