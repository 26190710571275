// PRIVATE PAGE: View All Users
import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import { getAllUsers } from '../../../../storedata/actions/superadmin';
import BaseFrame from '../../BaseFrame';
import { getUserPic } from '../../../../utils/helpers'; // !! reformat dates across app
import Spinner from '../../../layout/Spinner';

const Users = ({ getAllUsers, superadmin }) => {
    useEffect(() => {
        if (superadmin.users?.length === 0) getAllUsers();
    }, [getAllUsers, superadmin.users]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Users</title>
            </Helmet>
            <BaseFrame>
                {superadmin.loading ? (
                    <div className='containerinnersolid'>
                        <Spinner size={0} />
                    </div>
                ) : (
                    <Fragment>
                        <h1 className='mb-2'>
                            {superadmin.users?.length} Registered User
                            {superadmin.users?.length !== 1 && 's'}
                        </h1>
                        <div className='containerinnersolid'>
                            <div className='superadmin-user-boxes'>
                                {superadmin.users?.map((user, idx) => {
                                    return (
                                        <div
                                            className='superadmin-user-box'
                                            key={idx}
                                        >
                                            <img
                                                className='round-img'
                                                src={getUserPic(user.photo)}
                                                alt='reviewer'
                                            />

                                            {user.isSuperadmin && (
                                                <p>
                                                    <small>
                                                        <i className='fas fa-sun' />{' '}
                                                        Superadmin
                                                    </small>
                                                </p>
                                            )}

                                            <p style={{ hyphens: 'auto' }}>
                                                <small>
                                                    {user.emailverified ? (
                                                        <i className='fas fa-envelope-circle-check' />
                                                    ) : (
                                                        <i className='fas fa-envelope' />
                                                    )}{' '}
                                                    {user.email}
                                                </small>
                                            </p>
                                            <p>
                                                <small>
                                                    Registered on{' '}
                                                    {user.createdAt}
                                                </small>
                                            </p>
                                            <p>
                                                <small>
                                                    Last logged in{' '}
                                                    {user.lastLogin ? (
                                                        <span>
                                                            {user.lastLogin}
                                                        </span>
                                                    ) : (
                                                        <span>N/A</span>
                                                    )}
                                                </small>
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Fragment>
                )}
            </BaseFrame>
        </Fragment>
    );
};

Users.propTypes = {
    getAllUsers: PropTypes.func.isRequired,
    superadmin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    superadmin: state.superadmin,
    auth: state.auth,
});

export default connect(mapStateToProps, { getAllUsers })(Users);
