import {
    getStrongestRole,
    setViewingAsSchoolIDheader,
    setViewingAsUserIDheader,
} from '../../utils/helpers';
import {
    REGISTER_SUCCESS,
    USER_LOADED,
    FRESH_USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    ACCOUNT_DELETED,
    LOADING_PROFILE_PIC,
    UPDATE_PROFILE_PIC,
    DONE_LOADING_PROFILE_PIC,
    VERIFYING_EMAIL,
    DONE_VERIFYING_EMAIL,
    RESETTING_PWD,
    DONE_RESETTING_PWD,
    UPDATE_PROFILE,
    SUPERADMIN_SWITCH_USER,
    USER_SWITCH_VIEW,
    UPDATE_SEARCH_PARAMS,
} from '../actions/types';

const initialState = {
    token: localStorage.getItem('token'), // store token in localStorage
    isAuthenticated: false,
    isSuperadmin: false,
    roles: [],
    viewingAs: null, // {role: roleType, school: id}
    user: null,
    // roles: 0: superadmin, 1: school admin, 2: school staff, 3: parent, 4: student
    loading: true, // for initial loading when the app first runs or if user refreshes the browser
    uploading_profile_pic: false,
    verifying_email: false,
    resetting_pwd: false,
    searchParams: null,
};

/* 
viewing as:
user: object
role: superadmin, school admin, staff, parent, student
institution: object
*/

// reducers take a state and an action (the action is dispatched from a file)
// actions have a type and a payload
const authReducer = (state = initialState, action) => {
    const { type, payload } = action; // pulled out to shorthand the code

    switch (type) {
        // for page refreshes
        case USER_LOADED: {
            // payload is user

            let viewingAs =
                payload.isSuperadmin && localStorage.getItem('viewingAs')
                    ? JSON.parse(localStorage.getItem('viewingAs'))
                    : {
                          user: payload,
                          role: getStrongestRole(payload.roles).type, // select first (and strongest) role as one to display,
                          school: getStrongestRole(payload.roles).institution,
                      };

            setViewingAsSchoolIDheader(viewingAs.school?._id);
            setViewingAsUserIDheader(viewingAs.user._id);

            return {
                ...state,
                isAuthenticated: true,
                isSuperadmin: payload.isSuperadmin,
                roles: payload.roles,
                user: payload,
                viewingAs,
                loading: false,
            };
        }

        case FRESH_USER_LOADED: {
            // for registration and logins
            let strongestrole = getStrongestRole(payload.roles); // payload is user
            let viewingAs = {
                user: payload,
                role: strongestrole.type, // select first (and strongest) role as one to display,
                school: strongestrole.institution,
            };

            setViewingAsSchoolIDheader(strongestrole.institution?._id);
            setViewingAsUserIDheader(payload._id);
            if (payload.isSuperadmin)
                localStorage.setItem('viewingAs', JSON.stringify(viewingAs));

            return {
                ...state,
                isAuthenticated: true,
                isSuperadmin: payload.isSuperadmin,
                roles: payload.roles,
                user: payload,
                viewingAs,
                loading: false,
            };
        }

        case SUPERADMIN_SWITCH_USER: {
            // payload is user obj
            let strongestrole = getStrongestRole(payload.roles);

            let viewingAs = {
                role: strongestrole.type, // select first (and strongest) role as one to display,
                school: strongestrole.institution,
                user: payload,
            };

            localStorage.setItem('viewingAs', JSON.stringify(viewingAs));
            localStorage.removeItem('parentMode'); // clear parent mode when switching user

            // console.log('SWITCH USER', viewingAs);
            setViewingAsSchoolIDheader(strongestrole.institution?._id);
            setViewingAsUserIDheader(payload._id);

            return {
                ...state,
                viewingAs, // must also be updated because role/school may changed based on new user
            };
        }

        case USER_SWITCH_VIEW: {
            // payload: { role, school }, so just need to add current user:
            payload.user = state.viewingAs.user;
            // console.log('SWITCH SCHOOL/ROLE', payload);

            if (state.viewingAs.user.isSuperadmin)
                localStorage.setItem('viewingAs', JSON.stringify(payload));
            localStorage.removeItem('parentMode'); // clear parent mode when switching

            return {
                ...state,
                viewingAs: payload,
            };
        }

        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            // payload is just an object with the token
            return {
                ...state, // keep what's currently in the state
                ...payload, // updates the token since payload just has the token:
                // { token } which  is shorthand for {token: token}
                isAuthenticated: true,
                loading: true, // not false until user is logged in
            };

        case VERIFYING_EMAIL:
            return {
                ...state,
                verifying_email: true,
            };

        case DONE_VERIFYING_EMAIL: {
            let viewingAsUser = { ...state.viewingAs.user };
            let user = { ...state.user };

            // if viewing a user or logged in as the user who I'm updating,
            // then update its emailVerified state:
            if (
                state.viewingAs.user._id &&
                state.viewingAs.user._id === payload.userid
            ) {
                viewingAsUser = state.isAuthenticated
                    ? {
                          ...state.viewingAs.user,
                          emailVerified: payload.success,
                      }
                    : null;
            }
            if (state.user._id && state.user._id === payload.userid) {
                user = state.isAuthenticated
                    ? { ...state.user, emailVerified: payload.success }
                    : null;
            }

            return {
                ...state,
                viewingAs: {
                    ...state.viewingAs,
                    user: viewingAsUser,
                },
                user,
                verifying_email: false,
            };
        }

        // used for getting reset email link and for the form that submits new pwd
        case RESETTING_PWD:
            return {
                ...state,
                resetting_pwd: true,
            };

        case DONE_RESETTING_PWD:
            return {
                ...state,
                resetting_pwd: false,
            };

        case LOADING_PROFILE_PIC:
            return {
                ...state,
                uploading_profile_pic: true,
            };

        case DONE_LOADING_PROFILE_PIC:
            return {
                ...state,
                uploading_profile_pic: false,
            };

        case UPDATE_PROFILE_PIC: {
            // pic is uploaded within the context of the currently viewed user,
            // so see if I need to update the user state AND viewingAs.user state:
            let viewingAsUser = { ...state.viewingAs.user, photo: payload }; // payload is user pic
            let user = { ...state.user };

            if (
                state.viewingAs.user &&
                state.viewingAs.user._id === state.user._id
            ) {
                user = { ...state.user, photo: payload };
            }

            return {
                ...state,
                uploading_profile_pic: false,
                viewingAs: {
                    ...state.viewingAs,
                    user: viewingAsUser,
                },
                user,
            };
        }

        case UPDATE_PROFILE: {
            // payload is user

            let viewingAsUser = { ...state.viewingAs.user };
            let user = { ...state.user };

            if (
                state.viewingAs.user._id &&
                state.viewingAs.user._id === payload._id
            ) {
                viewingAsUser = payload;
            }
            if (state.user._id && state.user._id === payload._id) {
                user = payload;
            }

            return {
                ...state,
                viewingAs: {
                    ...state.viewingAs,
                    user: viewingAsUser,
                },
                user,
            };
        }

        case UPDATE_SEARCH_PARAMS: {
            // console.log('UPDATE_SEARCH_PARAMS', payload);
            let sp = state.searchParams ? { ...state.searchParams } : {};
            return { ...state, searchParams: { ...sp, ...payload } };
        }

        case AUTH_ERROR:
        case LOGOUT:
        case ACCOUNT_DELETED:
            return {
                ...state,
                token: null,
                roles: [],
                isSuperadmin: false,
                isAuthenticated: false,
                loading: false,
                user: null,
                viewingAs: undefined,
                searchParams: null,
            };

        default:
            return state;
    }
};

export default authReducer;
