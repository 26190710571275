import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import LogoTitle from '../layout/LogoTitle';
import LeftNavLink from '../layout/LeftNavLink';
import { logout } from '../../storedata/actions/auth';
import UserWidget from './UserWidget';

const LeftNavHomeSection = ({ auth, logout, baseurl }) => {
    let history = useNavigate();

    return (
        <Fragment>
            <div className='centerbox'>
                {!auth.isSuperadmin && <LogoTitle />}
            </div>
            <div className={`${auth.isSuperadmin ? '' : 'mt-2'}`}>
                <UserWidget />
            </div>

            <div className='navsectiontitle mt-2'>Home</div>
            <ul>
                {LeftNavLink(baseurl, 'fas fa-home', 'Dashboard', [
                    { link: baseurl + '/dashboard', exact: false },
                ])}
                {LeftNavLink(baseurl + '/account', 'fas fa-user', 'My Account')}
                <li>
                    <a onClick={() => logout(auth.user, history)} href='#!'>
                        <i className='fas fa-right-from-bracket'></i>{' '}
                        {/* <span className='hide-on-mobile'>Sign Out</span> */}
                        <span>Sign Out</span>
                    </a>
                </li>
            </ul>
            <hr />
        </Fragment>
    );
};

LeftNavHomeSection.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    baseurl: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { logout })(LeftNavHomeSection);
