import React from 'react';
import NavbarLink from '../layout/NavbarLink';

const baseurl = '/admin';

const AdminTermNav = ({ schoolSlug, termid, params }) => {
    let urlSlug = baseurl + '/' + schoolSlug;
    let base = urlSlug + `/terms/${termid}`;
    params = params || {};

    let attendancepath = base + '/attendance';
    if (attendancepath in params) {
        let attparams = params[attendancepath];
        attendancepath += attparams;
    }

    return (
        <nav className='tabbar'>
            <ul>
                <li>
                    <NavbarLink path={base}>Calendar</NavbarLink>
                </li>

                <li>
                    <NavbarLink path={`${base}/registration`}>
                        Registration
                    </NavbarLink>
                </li>

                <li>
                    <NavbarLink
                        path={attendancepath}
                        // relatedpaths={[
                        //     {
                        //         link: attendancepath,
                        //         exact: false,
                        //     },
                        // ]}
                    >
                        Attendance
                    </NavbarLink>
                </li>

                <li>
                    <NavbarLink path={`${base}/courses`}>
                        Courses &amp; Activities
                    </NavbarLink>
                </li>
            </ul>
        </nav>
    );
};

AdminTermNav.defaultProps = {
    params: '',
};

export default AdminTermNav;
