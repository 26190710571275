// PRIVATE ROUTE: ADMIN LEVEL
import React, {
    Fragment,
    useEffect,
    useState,
    useCallback,
    useRef,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import Spinner from '../../../layout/Spinner';
import BaseFrame from '../../BaseFrame';
import { getAllStudents } from '../../../../storedata/actions/admin';
import {
    getPrettyName,
    getStudentPic,
    URLhelpers,
} from '../../../../utils/helpers';
import { Link } from 'react-router-dom';
import Searchbar from '../../../layout/Searchbar';
import SegmentedButton from '../../../layout/inputs/SegmentedButton';
import genericstudentpic from '../../../../img/generic_students.png';
import SchoolHeader from '../../SchoolHeader';

const SchoolAdminStudents = ({ auth, admin, getAllStudents }) => {
    let location = useLocation();
    const [searchParams] = useSearchParams();
    let ref0 = useRef();
    let ref1 = useRef();
    let ref2 = useRef();
    let ref3 = useRef();
    let ref4 = useRef();

    const [filteredData, setFilteredData] = useState(null);

    // checks each set search param in URL and updates students accordingly
    // preassumptions: admin.students is an array (not null, undefined, or other)
    const filterData = useCallback(() => {
        let studentsToReturn = admin.students ? [...admin.students] : [];
        let entries = searchParams.entries();
        if (!entries) return studentsToReturn;

        for (const entry of entries) {
            const [param, value] = entry;

            if (param === 'ss') {
                studentsToReturn = [...studentsToReturn]?.filter((s) => {
                    let nameInfo = s.nameInfo;
                    let ssl = value.toLowerCase();

                    let namestr = getPrettyName(nameInfo);
                    let namestr2 = getPrettyName(nameInfo, false);

                    return (
                        namestr.toLowerCase().includes(ssl) ||
                        namestr2.toLowerCase().includes(ssl)
                    );
                });
            } else if (param === 'st') {
                studentsToReturn = [...studentsToReturn]?.filter((s) => {
                    let status = s.registrationStatus;
                    return status.toString() === value;
                });
            }
        }

        return studentsToReturn;
    }, [admin.students, searchParams]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    useEffect(() => {
        if (!auth.loading && !admin.students) {
            getAllStudents();
        }
    }, [auth.loading, getAllStudents, admin.students]);

    useEffect(() => {
        if (!admin.loading_student && admin.students) {
            setFilteredData(filterData());
        }
    }, [admin.loading_student, admin.students, filterData, location.search]);

    const switchSegment = (value) => {
        value = value ? parseInt(value) : 0;
        setFilteredData(filterData());
    };

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Students</title>
            </Helmet>
            <BaseFrame>
                <SchoolHeader school={auth.viewingAs?.school} />
                <Fragment>
                    <div className='split-2-header mb-2'>
                        <h1>
                            {auth.loading ||
                            admin.loading_school ||
                            filteredData?.length === 0
                                ? ''
                                : filteredData?.length}{' '}
                            Student
                            {filteredData?.length !== 1 && 's'}
                        </h1>
                        <Link
                            to={`/admin/${auth.viewingAs.school?.urlslug}/addstudent`}
                            className='btn btn-primary'
                        >
                            Add Student
                        </Link>
                    </div>

                    <div className='searchbar_and_filters'>
                        <Searchbar
                            autoSearch={true}
                            searchParam='ss'
                            searchName='students'
                        />

                        <SegmentedButton
                            name='type'
                            callback={(val) => switchSegment(val)}
                            defaultIndex={0}
                            controlRef={ref3}
                            searchParam={'st'}
                            smallSize={true}
                            // 0: absent, 2: school staff, 3: parent, 4: student
                            segments={[
                                {
                                    label: 'All',
                                    value: '',
                                    ref: ref0,
                                },
                                {
                                    label: 'Active',
                                    value: '2',
                                    ref: ref1,
                                },
                                {
                                    label: 'Pending',
                                    value: '1',
                                    ref: ref2,
                                },
                                {
                                    label: 'Inactive',
                                    value: '0',
                                    ref: ref4,
                                },
                            ]}
                        />
                    </div>

                    <Fragment>
                        {auth.loading ||
                        admin.loading_school ||
                        admin.loading_student ||
                        !admin.students ||
                        !filteredData ? (
                            <div className='containerinnersolid'>
                                <Spinner size={0} />
                            </div>
                        ) : admin.students.length === 0 ? (
                            <div className='containerinnersolid'>
                                <div
                                    style={{
                                        display: 'grid',
                                        justifyItems: 'center',
                                        gap: '15px',
                                        gridTemplateRows:
                                            '150px auto auto auto',
                                    }}
                                >
                                    <img
                                        src={genericstudentpic}
                                        alt='No students yet'
                                        style={{
                                            height: '150px',
                                            width: 'auto',
                                        }}
                                    />
                                    <p className='impactText m-0 pt-1'>
                                        No students yet
                                    </p>
                                </div>
                            </div>
                        ) : filteredData.length === 0 &&
                          (URLhelpers.getURLParam('ss', location.search) ||
                              URLhelpers.getURLParam('st', location.search)) ? (
                            <div className='containerinnersolid'>
                                <div
                                    style={{
                                        display: 'grid',
                                        justifyItems: 'center',
                                        gap: '15px',
                                        gridTemplateRows:
                                            '150px auto auto auto',
                                    }}
                                >
                                    <img
                                        src={genericstudentpic}
                                        alt='No matching students found'
                                        style={{
                                            height: '150px',
                                            width: 'auto',
                                        }}
                                    />
                                    <p className='impactText m-0 pt-1'>
                                        No matching students found
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className='student-tile-wrapper'>
                                {filteredData.map((student, idx) => {
                                    return (
                                        <div className='student-tile' key={idx}>
                                            <div className='student-tile-header'>
                                                <h2>
                                                    {getPrettyName(
                                                        student.nameInfo,
                                                        false
                                                    )}
                                                </h2>
                                                <div className='gridcol'>
                                                    <Link
                                                        to={`/admin/${auth.viewingAs.school?.urlslug}/student/${student._id}`}
                                                        className='btn'
                                                    >
                                                        View
                                                    </Link>
                                                    <Link
                                                        to={`/admin/${auth.viewingAs.school?.urlslug}/editstudent/${student._id}`}
                                                        className='btn'
                                                    >
                                                        Edit
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className='student-tile-body'>
                                                <div>
                                                    <img
                                                        className='round-img border-radius-5'
                                                        src={getStudentPic(
                                                            student.photo
                                                        )}
                                                        alt={
                                                            student.nameInfo
                                                                .firstName
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <p>
                                                        <small>Address</small>
                                                    </p>
                                                    {student.address.line1 && (
                                                        <p>
                                                            {
                                                                student.address
                                                                    .line1
                                                            }
                                                        </p>
                                                    )}
                                                    {student.address.line2 && (
                                                        <p>
                                                            {
                                                                student.address
                                                                    .line2
                                                            }
                                                        </p>
                                                    )}
                                                    {student.address.city && (
                                                        <p>
                                                            {
                                                                student.address
                                                                    .city
                                                            }
                                                            {student.address
                                                                .region && (
                                                                <span>
                                                                    ,{' '}
                                                                    {
                                                                        student
                                                                            .address
                                                                            .region
                                                                    }
                                                                </span>
                                                            )}
                                                        </p>
                                                    )}

                                                    {student.address
                                                        .postalCode && (
                                                        <p>
                                                            {
                                                                student.address
                                                                    .postalCode
                                                            }
                                                        </p>
                                                    )}
                                                    {student.address
                                                        .country && (
                                                        <p>
                                                            {
                                                                student.address
                                                                    .country
                                                            }
                                                        </p>
                                                    )}
                                                </div>
                                                <div>
                                                    <p className='pb'>
                                                        <small>Status</small>
                                                    </p>
                                                    <div>
                                                        {student.registrationStatus ===
                                                        0 ? (
                                                            <div className='badge errorbadge mb-1 mr-1'>
                                                                <i className='far fa-circle-xmark'></i>{' '}
                                                                Inactive
                                                            </div>
                                                        ) : student.registrationStatus ===
                                                          1 ? (
                                                            <div className='badge warningbadge mb-1 mr-1'>
                                                                <i className='far fa-clock'></i>{' '}
                                                                Pending
                                                            </div>
                                                        ) : (
                                                            <div className='badge successbadge mb-1 mr-1'>
                                                                <i className='fas fa-check'></i>{' '}
                                                                Active
                                                            </div>
                                                        )}
                                                        {student.hasGraduated && (
                                                            <div className='badge neutralbadge mb-1 mr-1'>
                                                                <i className='fas fa-graduation-cap'></i>{' '}
                                                                Graduated
                                                            </div>
                                                        )}
                                                    </div>

                                                    <p>
                                                        <small>Birthday</small>
                                                    </p>
                                                    {student.birthday && (
                                                        <p>
                                                            {new Date(
                                                                student.birthday
                                                            ).getMonth() + 1}
                                                            /
                                                            {new Date(
                                                                student.birthday
                                                            ).getDate()}
                                                            /
                                                            {new Date(
                                                                student.birthday
                                                            ).getFullYear()}
                                                        </p>
                                                    )}
                                                    <p className='mt'>
                                                        <small>Sex</small>
                                                    </p>
                                                    {student.sex !== null && (
                                                        <p>
                                                            {student.sex === 1
                                                                ? 'Female'
                                                                : 'Male'}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </Fragment>
                </Fragment>
            </BaseFrame>
        </Fragment>
    );
};

SchoolAdminStudents.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    getAllStudents: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
});

export default connect(mapStateToProps, { getAllStudents })(
    SchoolAdminStudents
);
