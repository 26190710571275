import React, {
    Fragment,
    useState,
    useEffect,
    useCallback,
    useRef,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../../storedata/actions/alert';
import Modal from '../../layout/Modal';
import { updateTerm } from '../../../storedata/actions/admin';
import DatePicker from '../../layout/inputs/DatePicker';
import {
    getPrettyDate,
    getDatesInRange,
    getDayTypeCountInRange,
    getNumDayTypeSelected,
} from '../../../utils/helpers';
import Checkbox, { CHECKBOX_STATES } from '../../layout/inputs/Checkbox';
import Spinner from '../../layout/Spinner';

const initialState = {
    initialized: false,
    name: '',
    schoolYear: '',
    dates: [],
    isActive: true,
};

const daysDict = [
    { name: 'Sun', toggled: CHECKBOX_STATES.Checked, selected: [] },
    { name: 'Mon', toggled: CHECKBOX_STATES.Empty, selected: [] },
    { name: 'Tue', toggled: CHECKBOX_STATES.Empty, selected: [] },
    { name: 'Wed', toggled: CHECKBOX_STATES.Empty, selected: [] },
    { name: 'Thu', toggled: CHECKBOX_STATES.Empty, selected: [] },
    { name: 'Fri', toggled: CHECKBOX_STATES.Empty, selected: [] },
    { name: 'Sat', toggled: CHECKBOX_STATES.Checked, selected: [] },
];

// Previous location is the page that this modal sits on top of
const AddTermModal = ({
    auth: { loading, viewingAs },
    // admin: { loading_term },
    updateTerm,
    previousLocation, // set when modal opens via app route (has unique URL),
    setAlert,
    submitCallback,
    closeCallback,
    term,
}) => {
    let history = useNavigate();
    let location = useLocation();
    let dateDivRef = useRef();
    let dateDivRef2 = useRef();
    let bodyRef = useRef();

    const [formData, setFormData] = useState(initialState);
    const [showSpinner, setShowSpinner] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showDatePicker2, setShowDatePicker2] = useState(false);
    const [weekdays, setWeekdays] = useState(['Sat', 'Sun']);
    const [weekdaysDict, setWeekdaysDict] = useState(daysDict);
    const [startDate, setStartDate] = useState([]);
    const [endDate, setEndDate] = useState([]);

    // based on the latest selectedDates, it sets the weekdays diction state to be
    // the right toggle state, and it updates the list of excluded days as well
    const updateDataForDay = useCallback(
        (dayNum, selectedDates, startDate, endDate, newDaysDict, weekdays) => {
            weekdays = [...weekdays];
            // empty arrays
            for (var i = 0; i < newDaysDict.length; i++) {
                newDaysDict[i].selected = [];
            }

            for (var j = 0; j < selectedDates.length; j++) {
                let date = selectedDates[j];
                let dayNum = date.getDay();
                newDaysDict[dayNum].selected.push(date);
            }

            let ds = getNumDayTypeSelected(selectedDates, dayNum);
            let dc = getDayTypeCountInRange(startDate, endDate, dayNum);

            if (ds < 1) {
                // none of that day in range are selected, so excluded
                newDaysDict[dayNum].toggled = CHECKBOX_STATES.Checked;
                weekdays = [...weekdays, newDaysDict[dayNum].name];
                // setWeekdays(weekdays);
            } else if (ds < dc) {
                newDaysDict[dayNum].toggled = CHECKBOX_STATES.Indeterminate;
            } else if (ds === dc) {
                newDaysDict[dayNum].toggled = CHECKBOX_STATES.Empty;
                // remove unchecked day
                weekdays = [...weekdays].filter(
                    (d) => d !== newDaysDict[dayNum].name
                );
                // setWeekdays(weekdays);
            }
            setWeekdaysDict(newDaysDict);
            return weekdays;
        },
        []
    );

    // useEffect(() => {
    //     return () => {
    //         // behaves like componentWillUnmount

    //     };
    // }, []);

    useEffect(() => {
        if (!term) {
            // adding term
            const formData = {
                ...initialState,
                initialized: true,
            };
            setFormData(formData);
        } else {
            // update form fields with current term

            // convert date strings into date objects
            let dates = term.dates.map((d) => new Date(d));
            let start = dates[0];
            let end = dates[dates.length - 1];
            setStartDate([start]);
            setEndDate([end]);

            // update toggle states and exclusions for each day of week
            // based on term's selected dates

            let newDaysDict = JSON.parse(JSON.stringify(daysDict));
            let w = [];
            // newDaysDict and w passed by reference so they are updated for each week day :)
            for (var i = 0; i < 7; i++) {
                let newweekdays = updateDataForDay(
                    i,
                    dates,
                    start,
                    end,
                    newDaysDict,
                    w
                );
                w = newweekdays;
                setWeekdays(newweekdays);
            }

            setFormData({
                name: term.name,
                schoolYear: term.schoolYear,
                dates,
                initialized: true,
                isActive: term.isActive,
            });
        }
    }, [viewingAs?.user, term, viewingAs, updateDataForDay]);

    let { name, schoolYear, dates, isActive, initialized } = formData;

    const updateCheckbox = (weekdaysDict, dayobj, idx) => {
        let updatedChecked;

        if (
            dayobj.toggled === CHECKBOX_STATES.Checked ||
            dayobj.toggled === CHECKBOX_STATES.Indeterminate
        ) {
            updatedChecked = CHECKBOX_STATES.Empty;
        } else {
            updatedChecked = CHECKBOX_STATES.Checked;
        }

        let newDaysDict = [];
        for (var i = 0; i < weekdaysDict.length; i++) {
            let x = { ...weekdaysDict[i] };

            if (i === idx) {
                x.toggled = updatedChecked;
            }

            newDaysDict.push(x);
        }

        setWeekdaysDict([...newDaysDict]);

        let origdays = [...weekdays];
        let days = [...newDaysDict]
            .filter((dayentry) => {
                return dayentry.toggled === CHECKBOX_STATES.Checked;
            })
            .map((dayentry) => {
                return dayentry.name;
            });

        setWeekdays(days);

        setFormData({
            ...formData,
            dates: getDatesInRange(
                dates,
                startDate.length > 0 ? startDate[0] : null,
                endDate.length > 0 ? endDate[0] : null,
                days,
                origdays
            ),
        });
    };

    const getCheckboxes = (weekdaysDict) => {
        let x = weekdaysDict.map((dayobj, idx) => {
            return (
                <div className='ms-unchecked' key={idx}>
                    <Checkbox
                        value={dayobj.toggled}
                        label={dayobj.name}
                        onChange={() => {
                            updateCheckbox(weekdaysDict, dayobj, idx);
                        }}
                    />
                </div>
            );
        });

        return <Fragment>{x}</Fragment>;
    };

    // select start or end date and get selected dates from that range (with exclusions applied)
    const updateDates = (startDate, endDate) => {
        if (startDate.length > 0 && endDate.length > 0) {
            let start = startDate[0];
            let end = endDate[0];

            if (start >= end) {
                setAlert(
                    'Please enter a start date that comes before the end date.',
                    'danger'
                );
                return;
            }

            setFormData({
                ...formData,
                dates: getDatesInRange(dates, start, end, weekdays, weekdays),
            });
        }
    };

    const onChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setFormData({ ...formData, [name]: value });
    };

    const onChangeCheckbox = (name, checked) => {
        setFormData({ ...formData, [name]: checked });
    };

    const onSubmit = async (e) => {
        e.preventDefault(); // don't let browser do its default behavior

        if (
            startDate.length > 0 &&
            endDate.length > 0 &&
            startDate[0] >= endDate[0]
        ) {
            setAlert(
                'Please enter a start date that comes before the end date.',
                'danger'
            );
            return;
        } else if (dates.length === 0) {
            setAlert('Please select school dates for the term', 'danger');
            return;
        }

        setShowSpinner(true);

        updateTerm(
            formData,
            history,
            location,
            false, // relocate
            previousLocation, // relocateTo
            term?._id || undefined
        )
            .then(function (result) {
                if (result.success) {
                    submitCallback && submitCallback(e, formData);
                }
            })
            .catch(function (err) {
                setShowSpinner(false);
            });
    };

    let body = (
        <div ref={bodyRef}>
            {!initialized || loading || showSpinner ? (
                <Spinner size={0} />
            ) : (
                <Fragment>
                    <div className='row-split-evenly even2split'>
                        <div>
                            <div className='form-header'>Name*</div>
                            <input
                                type='text'
                                name='name'
                                placeholder='e.g. Autumn Semester'
                                value={name || initialState.name}
                                onChange={onChange}
                                required
                            />
                        </div>

                        <div>
                            <div className='form-header'>Academic Year</div>
                            <input
                                type='text'
                                name='schoolYear'
                                placeholder='e.g. 2023-2024'
                                value={schoolYear || initialState.schoolYear}
                                onChange={onChange}
                            />
                        </div>
                    </div>

                    <div className='labelwithcheckbox'>
                        <Checkbox
                            value={
                                isActive
                                    ? CHECKBOX_STATES.Checked
                                    : CHECKBOX_STATES.Empty
                            }
                            label={'Term is active'}
                            onChange={(checked, indeterminate) =>
                                onChangeCheckbox(
                                    'isActive',
                                    checked,
                                    indeterminate
                                )
                            }
                        />
                    </div>
                    <hr className='mt-1' />

                    <div className='row-split-evenly auto2split'>
                        <div>
                            <div className='form-header'>Start Date</div>
                            <span
                                ref={dateDivRef}
                                id='startdatecontrol'
                                className={
                                    'datePickerOpener' +
                                    (showDatePicker ? ' whitebg' : '')
                                }
                                onClick={() => {
                                    let prevState = showDatePicker;
                                    // if other picker is open, close it to prevent UI sizing glitches
                                    if (!prevState) {
                                        // if (showDatePicker2) {
                                        //     setShowDatePicker2(false);
                                        // }
                                        setShowDatePicker(true);
                                    }

                                    // don't trigger a close w/a click; that happens in datepicker
                                }}
                            >
                                <i className='far fa-calendar'></i>{' '}
                                {startDate.length > 0 ? (
                                    getPrettyDate(startDate[0])
                                ) : (
                                    <span>Select date...</span>
                                )}
                            </span>

                            {showDatePicker && (
                                <DatePicker
                                    cssID='dp-startdate'
                                    allowSelectNone={false}
                                    onChangeHandler={(
                                        e,
                                        selectedDates,
                                        lastClicked,
                                        closepopover /* true if blurred in popover mode */
                                    ) => {
                                        if (closepopover && showDatePicker) {
                                            setShowDatePicker(false);
                                            if (!selectedDates) return; // closed w/o selecting dates
                                        }
                                        setStartDate(selectedDates);
                                        updateDates(selectedDates, endDate);
                                        // also close once selected
                                        setShowDatePicker(false);
                                    }}
                                    selectedDateObjects={startDate}
                                    selectOneMax={true}
                                    size={0}
                                    controlRef={dateDivRef}
                                    wrapperRef={bodyRef}
                                    // endDate={
                                    //     endDate.length > 0 && startDate.length === 0
                                    //         ? getPrevDate(endDate[0])
                                    //         : null
                                    // }
                                />
                            )}
                        </div>
                        <div>
                            <div className='form-header'>End Date</div>
                            <span
                                ref={dateDivRef2}
                                id='enddatecontrol'
                                className={
                                    'datePickerOpener' +
                                    (showDatePicker2 ? ' whitebg' : '')
                                }
                                onClick={() => {
                                    let prevState = showDatePicker2;
                                    // if other picker is open, close it to prevent UI sizing glitches

                                    if (!prevState) {
                                        // if (showDatePicker) {
                                        //     setShowDatePicker(false);
                                        // }
                                        setShowDatePicker2(true);
                                    }
                                    // don't trigger a close w/a click; that happens in datepicker
                                }}
                            >
                                <i className='far fa-calendar'></i>{' '}
                                {endDate.length > 0 ? (
                                    getPrettyDate(endDate[0])
                                ) : (
                                    <span>Select date...</span>
                                )}
                            </span>

                            {showDatePicker2 && (
                                <DatePicker
                                    cssID='dp-enddate'
                                    allowSelectNone={false}
                                    onChangeHandler={(
                                        e,
                                        selectedDates,
                                        lastClicked,
                                        closepopover /* true if blurred in popover mode */
                                    ) => {
                                        if (closepopover && showDatePicker2) {
                                            setShowDatePicker2(false);
                                            if (!selectedDates) return; // closed w/o selecting dates
                                        }
                                        setEndDate(selectedDates);

                                        updateDates(startDate, selectedDates);
                                        // also close once selected
                                        setShowDatePicker2(false);
                                    }}
                                    selectedDateObjects={endDate}
                                    selectOneMax={true}
                                    size={0}
                                    controlRef={dateDivRef2}
                                    wrapperRef={bodyRef}
                                    // startDate={
                                    //     startDate.length > 0 && endDate.length === 0
                                    //         ? getNextDate(startDate[0])
                                    //         : null
                                    // }
                                />
                            )}
                        </div>
                    </div>

                    {startDate.length > 0 &&
                    endDate.length > 0 &&
                    startDate[0] < endDate[0] ? (
                        <div className=' mt-2'>
                            <div className='form-header'>
                                Exclude these days:
                            </div>

                            <div className='multiselect-group solid mb-2'>
                                {getCheckboxes(weekdaysDict)}
                            </div>

                            <div
                                className={`inline-notification notification-warm mt-2 mb-2`}
                            >
                                <span>
                                    Unselect dates below for when your school is
                                    closed (e.g. public holidays, weekends) so
                                    that student attendance can be properly
                                    recorded.
                                </span>
                            </div>

                            <DatePicker
                                cssID='dp1'
                                onChangeHandler={(
                                    e,
                                    selectedDates,
                                    lastClicked
                                ) => {
                                    selectedDates = [...selectedDates];

                                    let dayNum = lastClicked.date.getDay();
                                    let newDaysDict = JSON.parse(
                                        JSON.stringify(weekdaysDict)
                                    );

                                    let newweekdays = updateDataForDay(
                                        dayNum,
                                        selectedDates,
                                        startDate[0],
                                        endDate[0],
                                        newDaysDict,
                                        weekdays
                                    );
                                    setWeekdays(newweekdays);

                                    setFormData({
                                        ...formData,
                                        dates: selectedDates,
                                    });
                                }}
                                startDate={
                                    startDate.length > 0 ? startDate[0] : null
                                }
                                endDate={endDate.length > 0 ? endDate[0] : null}
                                selectedDateObjects={dates}
                                dayToShow={
                                    startDate.length > 0
                                        ? startDate[0]
                                        : new Date()
                                }
                                size={2}
                            />
                        </div>
                    ) : (
                        <div></div>
                    )}
                </Fragment>
            )}
        </div>
    );

    let steps = [
        {
            body,
            nextStepOnClick: (e) => {
                onSubmit(e);
            },
            nextStepButtonTitle: 'Save',
            finalStepButtonIcon: 'fa fa-save',
        },
    ];

    return (
        <Modal
            steps={steps}
            hasCloseButton={true}
            closeButtonCallBack={closeCallback}
            showAsOverlay={true}
            modalTitle={(term ? 'Edit' : 'Add A') + ' Term'}
            size={1}
        />
    );
};

AddTermModal.propTypes = {
    setAlert: PropTypes.func.isRequired,
    updateTerm: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    setAlert,
    updateTerm,
})(AddTermModal);
