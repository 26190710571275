// PRIVATE ROUTE: ADMIN LEVEL
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import Spinner from '../../../layout/Spinner';
import { getTermById } from '../../../../storedata/actions/admin';
import DatePicker from '../../../layout/inputs/DatePicker';
import { getPrettyDate, dateIsInTerm } from '../../../../utils/helpers';

const initialState = {
    selectedDate: null,
};

const SchoolAdminSchoolTermCalendar = ({ admin: { term }, getTermById }) => {
    let params = useParams();
    let termid = params.termid;

    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    useEffect(() => {
        if (!term && termid) {
            getTermById(termid);
        }

        if (term) {
            setFormData((fd) => {
                return {
                    ...fd,
                    selectedDate:
                        term.dates.length > 0
                            ? new Date(term.dates[0])
                            : new Date(),
                };
            });
        }
    }, [getTermById, termid, term]); // @todo8 term not found

    let { selectedDate } = formData;

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | School Term Calendar</title>
            </Helmet>

            {!term ? (
                <div className='containerinnersolid'>
                    <Spinner size={0} />
                </div>
            ) : (
                <div className='calendar-split-view'>
                    <div className='containerinnersolid'>
                        <div>
                            <strong>
                                {getPrettyDate(term.dates[0])} -{' '}
                                {getPrettyDate(
                                    term.dates[term.dates.length - 1]
                                )}
                            </strong>
                            <span className='badge neutralbadge mb-1 mr-1 ml-1'>
                                {term.dates.length} days in term
                            </span>
                            <span
                                className={`badge ${
                                    term.isActive ? 'success' : 'warning'
                                }badge mb-1 mr-1`}
                            >
                                {term.isActive ? 'Active' : 'Inactive'}
                            </span>
                            {term.schoolYear && (
                                <span>School year: {term.schoolYear}</span>
                            )}
                        </div>
                        <DatePicker
                            cssID='dp1'
                            onChangeHandler={(
                                e,
                                selectedDates,
                                lastClicked
                            ) => {
                                setFormData({
                                    ...formData,
                                    selectedDate:
                                        selectedDates.length > 0
                                            ? selectedDates[0]
                                            : null,
                                });
                            }}
                            selectOneMax={true}
                            allowSelectNone={false}
                            startDate={
                                term.dates.length > 0
                                    ? new Date(term.dates[0])
                                    : null
                            }
                            endDate={
                                term.dates.length > 0
                                    ? new Date(
                                          term.dates[term.dates.length - 1]
                                      )
                                    : null
                            }
                            selectedDateObjects={
                                selectedDate ? [selectedDate] : []
                            }
                            focusedDateObjects={term.dates.map(
                                (d) => new Date(d)
                            )}
                            dayToShow={new Date(term.dates[0])}
                            size={3}
                        />
                    </div>

                    <div className='containerinnersolid'>
                        <p className='impactText mb-1'>
                            {getPrettyDate(selectedDate)}
                        </p>{' '}
                        {dateIsInTerm(selectedDate, term) ? (
                            <p>School is open.</p>
                        ) : (
                            <p>
                                School is closed. Edit the term to add this day
                                to the schedule.
                            </p>
                        )}
                    </div>
                </div>
            )}
        </Fragment>
    );
};

SchoolAdminSchoolTermCalendar.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    getTermById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
});

export default connect(mapStateToProps, {
    getTermById,
})(SchoolAdminSchoolTermCalendar);
