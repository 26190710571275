import PropTypes from 'prop-types';

const LabelWrapper = ({ title, children }) => {
    return (
        <div className='input-wrapper'>
            <div className='lwfloat'>{title}</div>
            {children}
        </div>
    );
};

LabelWrapper.propTypes = {
    title: PropTypes.string.isRequired,
};

export default LabelWrapper;
