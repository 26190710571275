import React, { useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPrettyName, getStrongestRole } from '../../../utils/helpers';
import { getAllUsers, switchUser } from '../../../storedata/actions/superadmin';
import Spinner from '../../layout/Spinner';
import LabelWrapper from '../../layout/inputs/LabelWrapper';

const SuperadminUserWidget = ({
    auth,
    superadmin,
    switchUser,
    getAllUsers,
}) => {
    useEffect(() => {
        if (!superadmin.users) getAllUsers();
    }, [getAllUsers, superadmin.users]);

    let history = useNavigate();

    const onChange = (e) => {
        let userid = e.target.value;
        let user = superadmin.users?.find((user) => {
            return user._id === userid;
        });

        switchUser(user);

        history(getStrongestRole(user.roles).path);
    };

    return (
        <div className='superadminuserwidget'>
            {!auth.loading && auth.user && !superadmin.loading ? (
                <LabelWrapper title='View As'>
                    <select
                        name='role'
                        value={auth.viewingAs?.user?._id}
                        onChange={onChange}
                        onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault();
                        }}
                    >
                        <Fragment>
                            <option value={auth.user._id}>
                                {auth.user.nameInfo.firstName + ' (me)'}
                            </option>
                            <option disabled>─────</option>
                            {superadmin.users
                                ?.filter((user) => {
                                    return (
                                        user._id !== auth.user._id && // don't show myself again :)
                                        user.roles.length > 0
                                    );
                                })
                                .map((user, i) => (
                                    <option value={user._id} key={i}>
                                        {getPrettyName(user.nameInfo, false)}
                                    </option>
                                ))}
                        </Fragment>
                    </select>
                </LabelWrapper>
            ) : (
                <Spinner size={0} />
            )}
        </div>
    );
};

SuperadminUserWidget.propTypes = {
    auth: PropTypes.object.isRequired,
    superadmin: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    superadmin: state.superadmin,
    switchUser: PropTypes.func.isRequired,
});

// to use an action, pass it into connect below to allow us to access props.login so we can use it in this component.
// 1st param is state to connect. 2nd param is any actions I want to use
export default connect(mapStateToProps, { switchUser, getAllUsers })(
    SuperadminUserWidget
);
