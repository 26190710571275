import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Tag from '../Tag';

// suggestions prop is an array of objects that have a name and an ID. this works for products but is generalized to anything with the same object structure (name, _id fields). Note: data passed in must have name and id fields for this to work, OR be a string. the code will check.
const Autocomplete = ({
    suggestions,
    inputName,
    placeholder,
    onChangeHandler,
    allowNotFoundSuggestions, // whether to let user select tags that don't match the suggestions
    showAllOptions,
    selectedData,
    selectOneMax, // whether only one max can be entered
    cssID,
    addOptionHandler,
    required,
}) => {
    const initialState = {
        // The active selection's index
        activeSuggestion: 0,
        // The suggestions that match the user's input
        filteredSuggestions: [],
        // Whether or not the suggestion list is shown
        showSuggestions: false,
        // What the user has entered
        userInput: '',
        selectedTagObjects: [], // used to keep track of tags user confirmed. used as value passed up to caller, kept track of with an input that stores the tags and is hidden
    };

    const [completionData, setCompletionData] = useState(initialState);

    let selectRef = useRef();

    // used to send a change in input data to caller
    const bubbleUpInputChange = useCallback(
        (data) => {
            // trigger onChange in hidden input which stores value to save in caller's state
            // to understand below, see https://hustle.bizongo.in/simulate-react-on-change-on-controlled-components-baa336920e04

            var input = //selectRef.current;
                document.querySelector(
                    '#autocomplete-' + cssID + '-' + inputName
                );
            var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                'value'
            ).set;
            nativeInputValueSetter.call(input, JSON.stringify(data));

            var inputEvent = new Event('input', { bubbles: true });
            input.dispatchEvent(inputEvent);
        },
        [inputName, cssID]
    );

    useEffect(() => {
        // remove invalid entries
        for (let i = selectedData.length - 1; i >= 0; i--) {
            let o = selectedData[i];
            if (o.name) {
                if (!o?.name?.length) {
                    selectedData.splice(i, 1); // remove invalid entry
                }
            } else if (!o?.length) {
                selectedData.splice(i, 1); // remove invalid entry
            }
        }

        setCompletionData((completionData) => {
            return {
                ...completionData,
                selectedTagObjects: selectedData,
            };
        });
    }, [selectedData]);

    const {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
        selectedTagObjects,
    } = completionData;

    const getFilteredSuggestions = (userInput) => {
        // Filter out suggestions: keep the ones that contain the user's input (either in name or synonyms, if synonyms string array exists) and also
        // aren't already tagged
        let filteredSuggestions = [];
        if (suggestions && suggestions.length)
            filteredSuggestions = [...suggestions].filter((suggestionobj) => {
                /*
                let suggestion = suggestionobj;

                if (nameField) {
                    for (var i = 0; i < nameField.length; i++) {
                        let into = nameField[i].toString();
                        suggestion = suggestion[into];
                    }
                } else {
                    suggestion = suggestionobj.name || suggestionobj; // itself (string)
                }
                */
                let suggestion = suggestionobj.name || suggestionobj;
                // obj may have synonyms array
                let synonyms = suggestionobj.synonyms;

                let hasInputMatches =
                    suggestion?.toLowerCase().indexOf(userInput.toLowerCase()) >
                    -1;
                let isNotAlreadySelected = ![...selectedTagObjects].filter(
                    (obj) => (obj.name || obj) === suggestion
                ).length;

                let matchesSynonym = synonyms
                    ? synonyms.some((synonym) =>
                          synonym
                              .toLowerCase()
                              .includes(userInput.toLowerCase())
                      )
                    : false;

                return (
                    (hasInputMatches || matchesSynonym) && isNotAlreadySelected
                );
            });

        return filteredSuggestions;
    };

    const blurInput = () => {
        setTimeout(() => selectRef.current.blur(), 1);
    };

    const focusInput = () => {
        setTimeout(() => selectRef.current.focus(), 1);
    };

    // Event fired when the input value is changed
    const onChange = (e) => {
        const userInput = e.currentTarget.value;
        const filteredSuggestions = getFilteredSuggestions(userInput);
        // Update the user input and filtered suggestions, reset the active
        // suggestion and make sure the suggestions are shown
        // @todo7 if user arrows down past visible index, it should scroll

        setCompletionData({
            ...completionData,
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: userInput?.length > 0, // !!! test this
            userInput: userInput,
        });

        focusInput();
    };

    // Event fired when the user clicks on a suggestion, which means it is an object since it is in
    // suggestions list. Note that we get the index from the li element that was clicked and use
    // that value to get correct selection. The active suggestion is not accurate for mouse selections!
    const onClick = (e, index) => {
        // Update the user input and reset the rest of the state.
        let selected = filteredSuggestions[index];

        let updatedTags = selectOneMax
            ? [selected] // select just this one
            : [...selectedTagObjects, selected]; // add this one to rest

        // get filtered list in case showAllOptions is true
        let filtered = getFilteredSuggestions('').filter(
            (_sug, idx) => idx !== index
        );

        // see if I need to swap the selected item with the one previously selected
        if (showAllOptions && selectOneMax) {
            filtered = [...filtered, ...selectedTagObjects].sort((a, b) =>
                a.name ? a.name.localeCompare(b.name) : a.localeCompare(b)
            );
        }

        setCompletionData({
            ...completionData, // impt! need this here so I don't get (un)controlled component err
            activeSuggestion: 0,
            // if showAllOptions is true, then remove selected item from filtered list and show the rest.
            // otherwise, clear suggestions list. note: I explicitly remove the selected item from list
            // here because the selectedTabObjects that is used in the getFilteredSuggestions function
            // won't yet be updated at the time of that function call so this is a hack :/
            filteredSuggestions: showAllOptions
                ? filtered
                : filteredSuggestions,
            showSuggestions: selectOneMax,
            userInput: '', // clear field
            selectedTagObjects: updatedTags,
        });

        if (selectOneMax) blurInput();
        bubbleUpInputChange(updatedTags);
    };

    // Event fired when the user presses a key down
    const onKeyDown = (e) => {
        // User pressed the enter key AND a match is found (if allowNotFoundSuggestions is true),
        // update the input and close the suggestions
        if (e.keyCode === 13) {
            if (
                // if there is no exact match and user can't select non-matching tags, do nothing
                filteredSuggestions.length === 0 &&
                !allowNotFoundSuggestions
            ) {
                return;
            }

            let updatedTags;
            if (filteredSuggestions.length) {
                // match found so add object
                let tagToSelect = filteredSuggestions[activeSuggestion];

                updatedTags = selectOneMax
                    ? [tagToSelect] // select just this one
                    : [...selectedTagObjects, tagToSelect]; // add this one to rest

                // get filtered list in case showAllOptions is true
                let filtered = getFilteredSuggestions('').filter(
                    (_sug, idx) => idx !== activeSuggestion
                );

                // see if I need to swap the selected item with the one previously selected
                if (showAllOptions && selectOneMax)
                    filtered = [...filtered, ...selectedTagObjects].sort(
                        (a, b) =>
                            a.name
                                ? a.name.localeCompare(b.name)
                                : a.localeCompare(b)
                    );

                setCompletionData({
                    ...completionData,
                    activeSuggestion: 0,
                    filteredSuggestions: showAllOptions
                        ? filtered
                        : filteredSuggestions,
                    showSuggestions: selectOneMax,
                    userInput: '', // clear field
                    selectedTagObjects: updatedTags,
                });

                // call the passed in change handler
                bubbleUpInputChange(updatedTags);
            } else {
                // match not found so add string
                let tagToSelect = userInput.trim();
                if (tagToSelect.length === 0) return; // don't add empty strings

                // !!! this assumes an array of strings and NOT objects...
                // need to check and add name, _id fields
                updatedTags = selectOneMax
                    ? [tagToSelect] // select just this one
                    : [...selectedTagObjects, tagToSelect]; // add this one to rest

                setCompletionData({
                    ...completionData,
                    activeSuggestion: 0,
                    showSuggestions: selectOneMax,
                    userInput: '', // clear field
                    selectedTagObjects: updatedTags,
                });

                // call the passed in change handler
                bubbleUpInputChange(updatedTags);
            }

            if (selectOneMax) blurInput();
        }
        // User pressed the up arrow, decrement the index
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            setCompletionData({
                ...completionData,
                activeSuggestion: activeSuggestion - 1,
            });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {
            if (activeSuggestion === filteredSuggestions.length - 1) {
                return;
            }

            setCompletionData({
                ...completionData,
                activeSuggestion: activeSuggestion + 1,
            });
        }
    };

    const deleteObjectTag = (tagobj) => {
        let updatedTags = selectedTagObjects.filter((tag) =>
            tagobj.name ? tag.name !== tagobj.name : tag !== tagobj
        );

        setCompletionData({
            ...completionData, // keep current state
            selectedTagObjects: updatedTags,
        });

        // call the passed in change handler
        bubbleUpInputChange(updatedTags);
    };

    let suggestionsListComponent;

    // handle on blur (if user moves focus away from autocomplete input)
    const blu = (e) => {
        // if user left field, auto-select what they entered if custom strings are supported:
        // if (userInput.length > 0 && allowNotFoundSuggestions) {
        //     const event = new KeyboardEvent('keydown', { key: 'Enter' });
        //     e.currentTarget.dispatchEvent(event);
        // }

        setCompletionData({
            ...completionData,
            showSuggestions: false,
        });
    };

    const foc = (e) => {
        if (e.target.autocomplete) {
            e.target.autocomplete = 'whatever'; // required to remove chrome autocomplete
        }

        if (showAllOptions) {
            setCompletionData({
                ...completionData,
                // activeSuggestion: 0,
                filteredSuggestions: getFilteredSuggestions(userInput),
                showSuggestions: true,
            });
        } else if (userInput) {
            setCompletionData({
                ...completionData,
                showSuggestions: true,
            });
        }
    };

    if (showSuggestions) {
        if (filteredSuggestions.length) {
            suggestionsListComponent = (
                <ul className='suggestions'>
                    {filteredSuggestions.map((suggestion, index) => {
                        let className = '';

                        // Flag the active suggestion with a class
                        if (index === activeSuggestion) {
                            className = 'suggestion-active';
                        }

                        let matchedSynonym;
                        let synonyms = suggestion.synonyms;
                        if (synonyms) {
                            // get first match, if any
                            matchedSynonym = synonyms.find((synonym) =>
                                synonym
                                    .toLowerCase()
                                    .includes(userInput.toLowerCase())
                            );
                        }

                        return (
                            <li
                                className={className}
                                key={suggestion._id || index}
                                onMouseDown={(e) => {
                                    e.preventDefault(); // to not interfere with onBlur of main input field
                                }}
                                onClick={(e) => {
                                    onClick(e, index);
                                }}
                            >
                                <span>{suggestion.name || suggestion}</span>
                                {matchedSynonym && (
                                    <span className='subtext'>
                                        {matchedSynonym}
                                    </span>
                                )}
                            </li>
                        );
                    })}
                </ul>
            );
        } else if (userInput.length > 0) {
            suggestionsListComponent = allowNotFoundSuggestions ? (
                <div className='no-suggestions mb-2'>
                    Press 'enter' to create a new label.
                </div>
            ) : (
                <div className='no-suggestions mb-2'>
                    <span>No options available.</span>
                    {addOptionHandler ? (
                        <button
                            className='btn btn-small inlinegrid'
                            onClick={(e) => addOptionHandler(e)}
                            onMouseDown={(e) => e.preventDefault()}
                        >
                            <i className='fas fa-plus' /> Add...
                        </button>
                    ) : (
                        <span></span>
                    )}
                </div>
            );
        }
    }

    // note that the hidden input below as well as the 'offISay!' value for autocomplete is a hack
    // to prevent Chrome from showing autocomplete dropdowns.
    // src: https://medium.com/paul-jaworski/turning-off-autocomplete-in-chrome-ee3ff8ef0908
    return (
        <div className='rel mb-1'>
            <input
                type='text'
                autoComplete={'off'}
                className='autocomplete'
                onBlur={(e) => blu(e)}
                onFocus={(e) => {
                    foc(e);
                }}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                }}
                value={userInput}
                placeholder={placeholder}
                ref={selectRef}
                required={required ? !selectedData.length : false}
            />

            <input
                type='text'
                style={{
                    display: 'none',
                }}
                id={'autocomplete-' + cssID + '-' + inputName}
                onChange={(e) => {
                    onChangeHandler(e);
                }}
                value={selectedTagObjects}
                name={inputName}
            />

            {suggestionsListComponent}
            <div className='mb-1'>
                {selectedTagObjects &&
                    selectedTagObjects.map((tagobj, idx) => {
                        let selectedTitle = tagobj.name || tagobj;

                        return (
                            <Tag
                                name={selectedTitle}
                                deleteHandler={() => deleteObjectTag(tagobj)}
                                key={selectedTitle + '-obj-' + idx}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

Autocomplete.propTypes = {
    suggestions: PropTypes.array.isRequired, // list of possible values to choose from
    inputName: PropTypes.string.isRequired, // name identifier of field that stores object array
    placeholder: PropTypes.string, // input ghost text
    onChangeHandler: PropTypes.func.isRequired, // passed in: what to call when change happens
    allowNotFoundSuggestions: PropTypes.bool, // allow user to enter data that isn't part of suggestions
    showAllOptions: PropTypes.bool, // whether to show suggestions up front
    selectedData: PropTypes.array.isRequired, // used to reflect changes and store array of selected data
    selectOneMax: PropTypes.bool, // only pick one item max, or multiple
    cssID: PropTypes.string.isRequired, // used to get unique ID for input
    required: PropTypes.bool, // whether a value is required for the field
    addOptionHandler: PropTypes.func, // if present, CTA to let user create a new option
};

Autocomplete.defaultProps = {
    suggestions: [],
    selectedTagObjects: [],
    allowNotFoundSuggestions: false,
    showAllOptions: false,
    selectOneMax: false,
    placeholder: '',
    required: false,
};

export default Autocomplete;
