import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useEffectOnce } from '../../../utils/useEffectOnce';
import PropTypes from 'prop-types';
import { getMonthName } from '../../../utils/helpers';

const initialState = {
    dayOfNote: null, // set to null so I can detect it being set and then load data appropriately
    lastSelected: null,
};

// Component that supports multi-select checkbox-like toggles.
function DatePicker({
    cssID,
    onChangeHandler,
    selectOneMax,
    allowSelectNone,
    selectedDateObjects,
    focusedDateObjects,
    size,
    endDate,
    startDate,
    controlRef,
    wrapperRef,
    dayToShow,
}) {
    const [completionData, setCompletionData] = useState(initialState);
    const { dayOfNote, lastSelected } = completionData;
    let datePickerRef = useRef();
    const isMounted = useRef(false);

    // const resetHeight = () => {
    //     wrapperRef &&
    //         wrapperRef.current?.style.setProperty('min-height', `auto`);
    // };

    useEffectOnce(() => {
        // console.log('creating', Date.now());
        // in case dates aren't objects yet:
        // selectedDateObjects = selectedDateObjects.map((d) => new Date(d));

        let d =
            dayToShow ||
            (selectedDateObjects.length > 0
                ? selectedDateObjects[0]
                : new Date());

        setCompletionData({
            ...completionData,
            dayOfNote: d,
        });

        isMounted.current = true;

        if (controlRef) {
            window.addEventListener('resize', updatePosition);
            window.addEventListener('click', popHandler);
        }
        return () => {
            // console.log('destroying', Date.now());

            isMounted.current = false;

            // resetHeight(); todo fix glitch where it shrinks back height when opening a new picker

            if (controlRef) {
                window.removeEventListener('resize', updatePosition);
                window.removeEventListener('click', popHandler);
            }
        };
    });

    const updatePosition = useCallback(() => {
        if (!dayOfNote) return;
        // get properties of datePicker popover
        const {
            style,
            offsetWidth: popoverWidth,
            offsetHeight: popoverHeight,
        } = datePickerRef.current;

        // get properties of element where this popover should be anchored
        const { offsetWidth, offsetHeight, offsetLeft, offsetTop } =
            controlRef.current;

        const wrapperheight = wrapperRef
            ? Math.round(wrapperRef.current.clientHeight)
            : window.innerHeight;

        let newLeft = offsetLeft + offsetWidth - popoverWidth;
        if (newLeft < 0) newLeft = offsetLeft; // in case there's not enough room

        let newTop = offsetTop - popoverHeight - 3; // show above CTA

        // if not enough room to show popover at top, show at bottom:
        if (newTop < 0) {
            newTop = offsetTop + offsetHeight + 3; // show below CTA
        }

        // see if I need to increase height to make full popover visible (only for popovers)
        if (wrapperRef && newTop + popoverHeight > wrapperheight + 50) {
            wrapperRef.current.style.setProperty(
                'min-height',
                `${wrapperheight + popoverHeight}px`
            );
        }

        style.setProperty('left', `${newLeft}px`);
        style.setProperty('top', `${newTop}px`);

        // let x = datePickerRef.current.animate(
        //     [{ opacity: 0 }, { opacity: 1 }],
        //     {
        //         duration: 500,
        //         fill: 'forwards',
        //     }
        // );

        // x.onfinish = () =>
    }, [controlRef, wrapperRef, dayOfNote]);

    useEffect(() => {
        if (controlRef) {
            updatePosition();
        }
    }, [updatePosition, dayOfNote, controlRef]); // dateOfNote is there since it may change size if month changes, so need to reposition container

    const popHandler = (e) => {
        if (
            isMounted.current &&
            datePickerRef?.current &&
            !datePickerRef.current.contains(e.target) //&&
            // controlRef?.current &&
            // !controlRef.current.contains(e.target)
        ) {
            // clicked outside the popover
            let anim = datePickerRef?.current?.animate(
                [{ opacity: 1 }, { opacity: 0 }],
                {
                    duration: 200,
                    fill: 'forwards',
                }
            );
            if (anim)
                anim.onfinish = () => {
                    onChangeHandler(
                        e,
                        null /* selected dates */,
                        lastSelected,
                        true /* close */
                    );
                };
        }
    };

    // Returns the number of days in the given month.
    // Date format: year, month [, day [, hours [, minutes [, seconds [, milliseconds]]]]]
    // values in Javascript dates wrap around, so go to next month
    // and then use 0 as the day which will "rewind" a day and return
    // the number of days in the current month
    // d: the date object
    const getNumDaysInMonth = (d) => {
        return new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
    };

    // 0-indexed day of the week that the 1st of the given month falls on (0 is Sunday)
    // d: the date object
    const getFirstDayOfMonth = (d) => {
        var firstOfMonth = new Date(d.getFullYear(), d.getMonth(), 1);
        return firstOfMonth.getDay();
    };

    // returns the month title and month picker HTML section
    // d: the date object
    const getDateHTMLtableMenu = (d) => {
        return (
            <div className='datepicker-month'>
                <div className='datepicker-month-title'>
                    {getMonthName(d)} {d.getFullYear()}
                </div>

                <span
                    className='datepicker-month-prev'
                    onClick={() =>
                        setCompletionData({
                            ...completionData,
                            dayOfNote: new Date(
                                d.getFullYear(),
                                d.getMonth() - 1,
                                1
                            ),
                        })
                    }
                >
                    <i className='fas fa-arrow-left' />
                </span>

                <span
                    className='datepicker-month-next'
                    onClick={() =>
                        setCompletionData({
                            ...completionData,
                            dayOfNote: new Date(
                                d.getFullYear(),
                                d.getMonth() + 1,
                                1
                            ),
                        })
                    }
                >
                    <i className='fas fa-arrow-right' />
                </span>
            </div>
        );
    };

    // returns true if the given date is today's date; false otherwise
    // d: the date object
    const isToday = (d) => {
        var today = new Date();
        var isToday =
            d.getFullYear() === today.getFullYear() &&
            d.getMonth() === today.getMonth() &&
            d.getDate() === today.getDate();

        return isToday;
    };

    // listen for click events in calendar
    const clickHandler = (event, d) => {
        if (event.target.matches('#' + cssID + ' div.active-day')) {
            var day = parseInt(event.target.textContent);
            var clickedDate = new Date(d.getFullYear(), d.getMonth(), day);

            let isSelected = true;

            let newdata;
            if (isSelectedDay(clickedDate)) {
                if (!allowSelectNone) return;
                isSelected = false;

                // unselect it
                let copy = [...selectedDateObjects];
                newdata = copy.filter(
                    (d) => d.toDateString() !== clickedDate.toDateString()
                );
            } else {
                // select it
                newdata = selectOneMax
                    ? [clickedDate]
                    : [...selectedDateObjects, clickedDate];
            }

            // order ascending
            newdata.sort((a, b) => {
                return a - b;
            });

            let lastSelected = { date: clickedDate, isSelected };
            setCompletionData((completionData) => {
                return {
                    ...completionData,
                    lastSelected,
                };
            });

            if (controlRef) {
                // fade out popovers
                let anim = datePickerRef?.current?.animate(
                    [{ opacity: 1 }, { opacity: 0 }],
                    {
                        duration: 200,
                        fill: 'forwards',
                    }
                );
                anim.onfinish = () => {
                    onChangeHandler(event, newdata, lastSelected, selectOneMax);
                };
            } else {
                onChangeHandler(event, newdata, lastSelected);
            }
        }
    };

    // returns true if the given date is the calendar's selected day; false otherwise
    // d: the date object
    const isSelectedDay = (d) => {
        let filtered =
            selectedDateObjects.length > 0
                ? [...selectedDateObjects].filter((selected) => {
                      return (
                          d.getFullYear() === selected.getFullYear() &&
                          d.getMonth() === selected.getMonth() &&
                          d.getDate() === selected.getDate()
                      );
                  })
                : [];

        return filtered.length > 0;
    };

    // returns true if the given date is the calendar's selected day; false otherwise
    // d: the date object
    const isHighlightedDay = (d) => {
        let filtered =
            focusedDateObjects.length > 0
                ? [...focusedDateObjects].filter((highlighted) => {
                      return (
                          d.getFullYear() === highlighted.getFullYear() &&
                          d.getMonth() === highlighted.getMonth() &&
                          d.getDate() === highlighted.getDate()
                      );
                  })
                : [];

        return filtered.length > 0;
    };

    const generateRows = (d) => {
        var index = 0;
        var firstDay = getFirstDayOfMonth(d);
        var numDaysInPrevMonth = getNumDaysInMonth(
            new Date(d.getFullYear(), d.getMonth() - 1, 1)
        );
        var dayOfPrevMonth = numDaysInPrevMonth - firstDay + 1;
        var numDaysInMonth = getNumDaysInMonth(d);
        var dayOfNextMonth = 1;
        var numWeeksNeeded = Math.ceil((numDaysInMonth + firstDay) / 7);

        let nums = Array.from(
            { length: numWeeksNeeded },
            (value, index) => index
        );

        const data = nums.map((entry) => {
            let data = (
                <div className='week' key={entry}>
                    {[0, 1, 2, 3, 4, 5, 6].map((day) => {
                        let toreturn;
                        var date;

                        let dmonth = d.getMonth();
                        let dyear = d.getFullYear();

                        // show previous month's dates if current month doesn't start on Sunday:
                        if (index < firstDay) {
                            date = dayOfPrevMonth++;

                            var prevDate = new Date(dyear, dmonth - 1, date);

                            let isActive = isSelectedDay(prevDate);

                            let dclass =
                                'inactive-day' + (isActive ? ' selected' : '');
                            dclass += isHighlightedDay(prevDate)
                                ? ' highlighted-date'
                                : '';

                            toreturn = (
                                <div key={date} className={dclass}>
                                    {date}
                                </div>
                            );
                        }

                        // if this is current month's day:
                        else if (
                            index >= firstDay &&
                            index < numDaysInMonth + firstDay
                        ) {
                            date = index - firstDay + 1;
                            var thisDate = new Date(dyear, dmonth, date);
                            var todayClass = isToday(thisDate) ? 'today' : '';
                            todayClass = isSelectedDay(thisDate)
                                ? 'selected-date'
                                : todayClass;

                            todayClass += isHighlightedDay(thisDate)
                                ? ' highlighted-date'
                                : '';

                            let startdatemonth = startDate?.getMonth();
                            let startdateyear = startDate?.getFullYear();
                            let startDateIsValid = startDate
                                ? (dmonth > startdatemonth &&
                                      dyear >= startdateyear) ||
                                  (dmonth === startdatemonth &&
                                      dyear === startdateyear &&
                                      date >= startDate.getDate())
                                : true;

                            let enddatemonth = endDate?.getMonth();
                            let enddateyear = endDate?.getFullYear();
                            let endDateIsValid = endDate
                                ? (dmonth < enddatemonth &&
                                      dyear <= enddateyear) ||
                                  (dmonth === enddatemonth &&
                                      dyear === enddateyear &&
                                      date <= endDate.getDate())
                                : true;

                            let isActive = startDateIsValid && endDateIsValid;

                            toreturn = (
                                <div
                                    key={date}
                                    className={`${
                                        isActive ? '' : 'in'
                                    }active-day ${todayClass}`}
                                >
                                    {' '}
                                    {date}
                                </div>
                            );
                        }

                        // otherwise, show days of next month
                        else {
                            date = dayOfNextMonth++;

                            var nextDate = new Date(dyear, dmonth + 1, date);

                            let isActive = isSelectedDay(nextDate);
                            let dclass =
                                'inactive-day' + (isActive ? ' selected' : '');
                            dclass += isHighlightedDay(nextDate)
                                ? ' highlighted-date'
                                : '';

                            toreturn = (
                                <div key={date} className={dclass}>
                                    {date}
                                </div>
                            );
                        }

                        index++;
                        return (
                            <div className='td' key={day}>
                                {toreturn}
                            </div>
                        );
                    })}
                </div>
            );

            return data;
        });

        return data;
    };

    const getSize = () => {
        if (size === 0) return 'small';
        else if (size === 1) return 'medium';
        else if (size === 2) return 'full';
        else if (size === 3) return 'tall';
    };

    const getHTML = (d) => {
        return (
            <div
                className={`cal-wrapper ${
                    controlRef ? 'datepicker-popover' : ''
                }`}
                ref={datePickerRef}
            >
                <div
                    id={cssID}
                    className={`datepicker datepicker-size-${getSize()}`}
                    onClick={(e) => {
                        clickHandler(e, d);
                    }}
                >
                    {getDateHTMLtableMenu(d)}
                    <div className='datepicker-calendar'>
                        <section className='th'>
                            {[
                                'Sun',
                                'Mon',
                                'Tue',
                                'Wed',
                                'Thu',
                                'Fri',
                                'Sat',
                            ].map((day) => (
                                <span key={day}>{day}</span>
                            ))}
                        </section>

                        {generateRows(d)}
                    </div>
                </div>
            </div>
        );
    };

    return dayOfNote && getHTML(dayOfNote);
}

DatePicker.propTypes = {
    cssID: PropTypes.string.isRequired, // unique ID for component to create the DatePicker in
    onChangeHandler: PropTypes.func.isRequired, // function used to update state in caller when a date is clicked. It is sent the array of selected dates
    selectOneMax: PropTypes.bool, // whether only one option can be selected
    selectedDateObjects: PropTypes.array.isRequired, // array of selected dates
    focusedDateObjects: PropTypes.array, // array of dates to make stand out ("hyperactive")
    size: PropTypes.number,
    startDate: PropTypes.object, // the first active date to show
    endDate: PropTypes.object, // the last active date to show
    dayToShow: PropTypes.object, // the date to initially show
    controlRef: PropTypes.object, // if using datepicker as popover
    wrapperRef: PropTypes.object, // used for popover to determine parent element to measure placement boundaries. this is only needed if parent isn't the actual full window size (visible size as in height of window, not full height of scrollable area). e.g. if wrapper is a modal
    allowSelectNone: PropTypes.bool, // whether to allow user to deselect a date (used w/selectOneMax = true)
};

DatePicker.defaultProps = {
    selectOneMax: false,
    selectedDateObjects: [],
    focusedDateObjects: [],
    size: 0,
    allowSelectNone: true,
};

export default DatePicker;
