// constants
// ALERT TYPES (handled only by react)
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REMOVE_ALL_ALERTS = 'REMOVE_ALL_ALERTS';
export const REMOVE_ALL_NONSTICKY_ALERTS = 'REMOVE_ALL_NONSTICKY_ALERTS';

// MODAL TYPES (handled only by react)
export const ADD_MODAL = 'ADD_MODAL';
export const REMOVE_MODAL = 'REMOVE_MODAL';

// AUTH TYPES
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const FRESH_USER_LOADED = 'FRESH_USER_LOADED';
export const USER_SWITCH_VIEW = 'USER_SWITCH_VIEW';
export const VERIFYING_EMAIL = 'VERIFYING_EMAIL';
export const DONE_VERIFYING_EMAIL = 'DONE_VERIFYING_EMAIL';
export const RESETTING_PWD = 'RESETTING_PWD';
export const DONE_RESETTING_PWD = 'DONE_RESETTING_PWD';
export const AUTH_ERROR = 'AUTH_ERROR'; // for if something goes wrong
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const UPDATE_SEARCH_PARAMS = 'UPDATE_SEARCH_PARAMS';

export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const LOADING_PROFILE = 'LOADING_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE'; // clear profile for future user who may not have one
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const UPDATE_PROFILE_PIC = 'UPDATE_PROFILE_PIC';
export const LOADING_PROFILE_PIC = 'LOADING_PROFILE_PIC';
export const DONE_LOADING_PROFILE_PIC = 'DONE_LOADING_PROFILE_PIC';

// SUPERADMIN TYPES
export const USERS_LOADED = 'USERS_LOADED';
export const INSTITUTIONS_LOADED = 'INSTITUTIONS_LOADED';
export const ADMIN_ERROR = 'ADMIN_ERROR';
export const LOADING_SUPERADMIN_DATA = 'LOADING_SUPERADMIN_DATA';
export const SUPERADMIN_SWITCH_USER = 'SUPERADMIN_SWITCH_USER';
export const SUPERADMIN_USER_SWITCHED = 'SUPERADMIN_USER_SWITCHED';

// SCHOOL ADMIN TYPES
export const STAFF_MEMBERS_LOADED = 'STAFF_MEMBERS_LOADED';
export const LOADING_STAFF = 'LOADING_STAFF';

export const LOADING_CONTACT = 'LOADING_CONTACT';
export const CONTACTS_LOADED = 'CONTACTS_LOADED';
export const GET_CONTACT = 'GET_CONTACT';
export const LOADING_CONTACT_PIC = 'LOADING_CONTACT_PIC';
export const DONE_LOADING_CONTACT_PIC = 'DONE_LOADING_CONTACT_PIC';
export const UPDATE_CONTACT_PIC = 'UPDATE_CONTACT_PIC';
export const CLEAR_CONTACT = 'CLEAR_CONTACT';

export const LOADING_STUDENT = 'LOADING_STUDENT';
export const STUDENTS_LOADED = 'STUDENTS_LOADED';
export const GET_STUDENT = 'GET_STUDENT';
export const UPDATE_STUDENT_PIC = 'UPDATE_STUDENT_PIC';
export const LOADING_STUDENT_PIC = 'LOADING_STUDENT_PIC';
export const DONE_LOADING_STUDENT_PIC = 'DONE_LOADING_STUDENT_PIC';
export const CLEAR_STUDENT = 'CLEAR_STUDENT';

export const LOADING_TERM = 'LOADING_TERM';
export const TERMS_LOADED = 'TERMS_LOADED';
export const GET_TERM = 'GET_TERM';
export const CLEAR_TERM = 'CLEAR_TERM';

export const LOADING_SCHOOL = 'LOADING_SCHOOL';
export const GET_SCHOOL = 'GET_SCHOOL';
export const UPDATE_SCHOOL_PIC = 'UPDATE_SCHOOL_PIC';
export const LOADING_SCHOOL_PIC = 'LOADING_SCHOOL_PIC';
export const DONE_LOADING_SCHOOL_PIC = 'DONE_LOADING_SCHOOL_PIC';
export const SCHOOLS_FOR_USER_LOADED = 'SCHOOLS_FOR_USER_LOADED';

export const GET_SCHOOL_ATTENDANCE = 'GET_SCHOOL_ATTENDANCE';
export const LOADING_SCHOOL_ATTENDANCE = 'LOADING_SCHOOL_ATTENDANCE';
export const SCHOOL_ATTENDANCE_LOADED = 'SCHOOL_ATTENDANCE_LOADED';

export const GRADE_LEVELS_LOADED = 'GRADE_LEVELS_LOADED';
export const LOADING_GRADE_LEVEL = 'LOADING_GRADE_LEVEL';
export const GET_GRADE_LEVEL = 'GET_GRADE_LEVEL';
export const CLEAR_GRADE_LEVEL = 'CLEAR_GRADE_LEVEL';
export const DELETE_GRADE_LEVEL = 'DELETE_GRADE_LEVEL';

export const LOADING_FEE = 'LOADING_FEE';
export const FEES_LOADED = 'FEES_LOADED';
export const GET_FEE = 'GET_FEE';
export const CLEAR_FEE = 'CLEAR_FEE';
export const CLEAR_FEES = 'CLEAR_FEES';
export const DELETE_FEE = 'DELETE_FEE';
