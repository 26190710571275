// import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const BackButton = ({ linkTo = -1 /* go to prev pg */ }) => {
    let history = useNavigate();

    return (
        <button
            className='btn'
            onClick={(e) => {
                e.preventDefault();
                history(linkTo);
            }}
        >
            <i className='fas fa-arrow-left'></i>
        </button>
    );
};

export default BackButton;
