import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../layout/Modal';
import {
    updateGradeLevel,
    getGradeLevelById,
    clearGradeLevel,
} from '../../../storedata/actions/admin';
import Spinner from '../../layout/Spinner';

const initialState = {
    initialized: false,
    name: '',
    description: '',
};

// Previous location is the page that this modal sits on top of
const AddGradeLevelModal = ({
    levelID,
    admin: { loading_grade_level, grade_level },
    auth: { loading, viewingAs },
    updateGradeLevel,
    getGradeLevelById,
    clearGradeLevel,
    previousLocation, // set when modal opens via app route (has unique URL)
    submitCallback,
    closeCallback,
}) => {
    let params = useParams();
    let history = useNavigate();
    let location = useLocation();
    let mounted = useRef(false); // used to ensure that submitting form that has error won't reset form

    const [formData, setFormData] = useState(initialState);
    const [savingLevel, setSavingLevel] = useState(false);

    let { name, description } = formData;
    let levelid = params.gradelevelid || levelID;

    useEffect(() => {
        return () => {
            // behaves like componentWillUnmount
            clearGradeLevel();
        };
    }, [clearGradeLevel]);

    useEffect(() => {
        if (!mounted.current && !loading /* wait for user state */ && levelid) {
            getGradeLevelById(levelid);
        }
    }, [loading, getGradeLevelById, levelid]); // @todo8 level not found,

    useEffect(() => {
        if (!mounted.current) {
            if (!levelid) {
                const formData = {
                    ...initialState,
                    initialized: true,
                };
                setFormData(formData);
                mounted.current = true;
            } else if (!loading_grade_level && grade_level && !loading) {
                // update form fields with current level
                setFormData((formData) => {
                    const formData2 = {
                        ...formData,
                    };

                    for (const key in grade_level) {
                        if (key in formData) formData2[key] = grade_level[key];
                    }

                    formData2.initialized = true;
                    mounted.current = true;
                    return { ...formData2 };
                });
            }
        }
    }, [
        loading,
        loading_grade_level,
        viewingAs,
        params.gradelevelid,
        grade_level,
        levelid,
    ]);

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = async (e) => {
        e.preventDefault(); // don't let browser do its default behavior

        setSavingLevel(true);
        updateGradeLevel(
            formData,
            history,
            location,
            false, // relocate
            previousLocation, // relocateTo
            levelid
        )
            .then(function (result) {
                /*res.json({
                    msg: 'Grade level successfully created.',
                    success: true,
                    level,
                }); */
                if (result.success) {
                    submitCallback && submitCallback(e, formData);
                }
                setSavingLevel(false);
            })
            .catch(function (err) {
                setSavingLevel(false);
            });
    };

    let body =
        loading ||
        savingLevel ||
        !mounted.current ||
        (params.gradelevelid && loading_grade_level) ? (
            <Spinner size={0} />
        ) : (
            <Fragment>
                <div>
                    <div className='form-header'>Name*</div>
                    <input
                        type='text'
                        name='name'
                        value={name || initialState.name}
                        onChange={onChange}
                        required
                    />
                </div>

                <div>
                    <div className='form-header'>Description</div>
                    <textarea
                        name='description'
                        value={description || initialState.description}
                        rows={2}
                        onChange={onChange}
                    />
                </div>
            </Fragment>
        );

    let steps = [
        {
            body,
            nextStepOnClick: (e) => {
                onSubmit(e);
            },
            nextStepButtonTitle: 'Save',
            finalStepButtonIcon: 'fa fa-save',
        },
    ];

    return (
        <Modal
            steps={steps}
            hasCloseButton={true}
            closeButtonCallBack={closeCallback}
            showAsOverlay={true}
            modalTitle={(levelid ? 'Edit' : 'Add A') + ' Grade Level'}
            size={1}
            previousLocation={previousLocation}
        />
    );
};

AddGradeLevelModal.propTypes = {
    updateGradeLevel: PropTypes.func.isRequired,
    getGradeLevelById: PropTypes.func.isRequired,
    clearGradeLevel: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    updateGradeLevel,
    getGradeLevelById,
    clearGradeLevel,
})(AddGradeLevelModal);
