import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    validateCaptcha,
} from 'react-simple-captcha';
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux'; // connects component to redux
import { Helmet } from 'react-helmet-async';

import { APP_NAME } from '../../utils/constants';
import { register } from '../../storedata/actions/auth';
import { setAlert } from '../../storedata/actions/alert';
import PropTypes from 'prop-types';
import LandingHeader from '../layout/LandingHeader';
import Footer from '../layout/Footer';
import Modal from '../layout/Modal';
import PersonalInfo from './PersonalInfo';
import Autocomplete from '../layout/inputs/Autocomplete';
import SegmentedButton from '../layout/inputs/SegmentedButton';
import { findCurrentCountryObj, country_list } from '../../utils/helpers';

const initialState = {
    // default values for the formData state
    nameInfo: {
        firstName: '',
        middleName: '',
        lastName: '',
        namePrefix: 1,
        nameSuffix: '',
    },
    address: {
        postalCode: '',
        line1: '',
        line2: '',
        country: '',
        city: '',
        region: '',
    },
    email: '',
    password: '',
    password2: '',
    phone: '',
    accessCode: '',
    school_country: '',
    school_name: '',
    school_phone: '',
    school_addr1: '',
    school_addr2: '',
    school_city: '',
    school_region: '',
    school_zip: '',
    type: 3, // parent
};

// newschool is whether I'm doing the school sign up flow in addition to user registration
const Register = ({ register, auth, newschool = false, setAlert }) => {
    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    let ref1 = useRef();
    let ref2 = useRef();
    let ref3 = useRef();

    // format: [state, function to update state]
    const [formData, setFormData] = useState(initialState);
    const [stepNumber, setStepNumber] = useState(1);

    // destructure so I don't have to do formData.name, etc...
    // The state will be available everywhere in this file :)
    /* eslint-disable no-unused-vars */
    const {
        nameInfo, // actually used, so don't remove
        address,
        school_country,
        school_name,
        school_phone,
        school_addr1,
        school_addr2,
        school_city,
        school_region,
        school_zip,
        type,
        accessCode,
    } = formData;
    /* eslint-enable no-unused-vars */

    const onChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'school_country') {
            // value is stringified array; parse into array
            const parsed = JSON.parse(value);
            value = parsed.length > 0 ? parsed[0] : '';
            if (value.name) value = value.name; // in case it is a string vs obj

            setFormData({
                ...formData,
                [name]: value,
                school_region: '', // in case it was set from another country and is no longer valid
            });
        } else if (name === 'school_region') {
            // value is stringified array; parse into array
            const parsed = JSON.parse(value);
            value = parsed.length > 0 ? parsed[0] : '';
            if (value.name) value = value.name; // in case it is a string vs obj
            setFormData({
                ...formData,
                [name]: value,
            });
        } else setFormData({ ...formData, [name]: value });
    };

    const switchSegment = (value) => {
        setFormData({
            ...formData,
            type: parseInt(value),
        });
    };

    const updatePersonalInfo = (dataobj) => {
        let nameitems = [
            'namePrefix',
            'nameSuffix',
            'firstName',
            'middleName',
            'lastName',
            'nickname',
        ];

        let addritems = [
            'line1',
            'line2',
            'country',
            'city',
            'region',
            'postalCode',
        ];

        let value = dataobj.value;
        const name = dataobj.name;

        if (nameitems.includes(name)) {
            setFormData({
                ...formData,
                nameInfo: {
                    ...formData.nameInfo,
                    [name]: value,
                },
            });
        } else if (addritems.includes(dataobj.name)) {
            if (name === 'country') {
                // value is stringified array; parse into array
                //const parsed = JSON.parse(value);
                // value = parsed.length > 0 ? parsed[0] : '';
                if (value.name) value = value.name; // in case it is a string vs obj

                setFormData({
                    ...formData,
                    address: {
                        ...formData.address,
                        [name]: value,
                        region: '', // in case it was set from another country and is no longer valid
                    },
                });
            } else {
                setFormData({
                    ...formData,
                    address: {
                        ...formData.address,
                        [name]: value,
                    },
                });
            }
        } else setFormData({ ...formData, [name]: value });
    };

    const isSchoolStepValid = () => {
        if (newschool) {
            if (
                !school_name ||
                !school_name.trim().length ||
                !school_phone ||
                !school_phone.trim().length ||
                !school_country ||
                !school_country.trim().length ||
                !school_addr1 ||
                !school_addr1.trim().length ||
                !school_city ||
                !school_city.trim().length ||
                !school_region ||
                !school_region.trim().length ||
                (school_country === country_list[1] &&
                    (!school_zip || !school_zip.trim().length))
            ) {
                return false;
            } else return true;
        } else return false;
    };

    const isUserAddressValid = () => {
        if (
            !address.country ||
            !address.line1 ||
            !address.city ||
            !address.region ||
            (address.country === 'United States of America' &&
                !address.postalCode)
        ) {
            return false;
        }

        return true;
    };

    let schoolbody = (
        <Fragment>
            <small className='mb-2 center'>
                Already have an account? <Link to='/login'>Sign In</Link>
            </small>
            <h2 className='mb-1'>School Details</h2>

            <div className='form-header'>School Name*</div>
            <input
                type='text'
                name='school_name'
                value={school_name}
                onChange={onChange}
                required
            />

            <div className='form-header'>Phone Number*</div>
            <input
                type='text'
                name='school_phone'
                value={school_phone}
                onChange={onChange}
                required
            />

            <div className='form-header'>Country*</div>

            <Autocomplete
                cssID={'ctry'} // index 0 since there is only 1 possible line field.
                inputName='school_country'
                selectedData={
                    findCurrentCountryObj(school_country)
                        ? [findCurrentCountryObj(school_country)]
                        : []
                }
                placeholder='Select a country...'
                onChangeHandler={onChange}
                showAllOptions={true}
                suggestions={country_list}
                selectOneMax={true}
            />

            {school_country && (
                <div>
                    <div className='form-header'>Address Line 1*</div>
                    <input
                        type='text'
                        name='school_addr1'
                        value={school_addr1}
                        onChange={onChange}
                        required
                    />

                    <div className='form-header'>Address Line 2</div>
                    <input
                        type='text'
                        name='school_addr2'
                        value={school_addr2}
                        onChange={onChange}
                    />

                    <div className='row-split-evenly even2split grid-gap-20'>
                        <div>
                            <div className='form-header'>City*</div>
                            <input
                                type='text'
                                name='school_city'
                                value={school_city}
                                onChange={onChange}
                            />
                        </div>
                        <div>
                            <div className='form-header'>
                                {school_country === 'Jamaica'
                                    ? 'Parish*'
                                    : 'State*'}
                            </div>

                            <Autocomplete
                                cssID={'ctry2'} // index 0 since there is only 1 possible line field.
                                inputName='school_region'
                                selectedData={
                                    school_region ? [school_region] : []
                                }
                                placeholder={
                                    'Select a ' +
                                    (school_country === 'Jamaica'
                                        ? 'parish'
                                        : 'state') +
                                    '...'
                                }
                                onChangeHandler={onChange}
                                showAllOptions={true}
                                suggestions={
                                    findCurrentCountryObj(school_country)
                                        ? findCurrentCountryObj(school_country)
                                              .regions
                                        : []
                                }
                                selectOneMax={true}
                            />
                        </div>
                    </div>
                    {school_country !== 'Jamaica' && (
                        <div>
                            <div className='form-header'>Zip Code*</div>
                            <input
                                type='text'
                                name='school_zip'
                                value={school_zip}
                                onChange={onChange}
                            />
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    );

    let userbody = (
        <Fragment>
            <small className='mb-2 centertext'>
                Already have an account? <Link to='/login'>Sign In</Link>.
                <br />
                Or if you are a school administrator,{' '}
                <Link to='/register/school'>register your school</Link>.
            </small>
            <h2 className='mb-1'>
                {newschool === true
                    ? 'Administrator details'
                    : 'Enter your details'}
            </h2>

            {!newschool && (
                <div className='form-header'>Choose Account Type</div>
            )}

            {!newschool && (
                <SegmentedButton
                    name='type'
                    classes='mt-1 mb-2'
                    callback={(val) => switchSegment(val)}
                    defaultIndex={type === 3 ? 0 : 1}
                    controlRef={ref1}
                    // 1: school admin, 2: school staff, 3: parent, 4: student
                    segments={[
                        {
                            label: 'Parent',
                            value: '3',
                            ref: ref3,
                        },
                        {
                            label: 'School Staff',
                            value: '2',
                            ref: ref2,
                        },
                    ]}
                />
            )}

            {!newschool && (
                <Fragment>
                    <div className='form-header'>School Access Code</div>
                    <small className='form-text mb-1'>
                        Enter the access code provided by your school. If you do
                        not have one, please reach out to your school.
                    </small>
                    <input
                        type='text'
                        name='accessCode'
                        value={accessCode}
                        onChange={onChange}
                        required
                    />
                </Fragment>
            )}

            <PersonalInfo
                onChangeHandler={(e) => updatePersonalInfo(e)}
                data={formData}
            />

            <div className='centerbox centertext mt-1'>
                <LoadCanvasTemplate reloadColor='#126262' />
            </div>
            <input
                type='text'
                autoComplete='off'
                placeholder='Enter captcha'
                className='mt'
                id='user_captcha_input'
                required
            />

            <small className='center'>
                By creating an account, you agree to our{' '}
                <a href='/terms' target='_blank' rel='noopener noreferrer'>
                    Terms &amp; Conditions
                </a>
            </small>
        </Fragment>
    );

    const checkSchoolStep = () => {
        if (!isSchoolStepValid()) {
            setAlert(
                'Please enter a valid school name, phone number, and address.',
                'danger'
            );
            return false;
        }
        return true;
    };

    let steps = newschool
        ? [
              {
                  body: schoolbody,
                  nextStepOnClick: () => {
                      return checkSchoolStep();
                  },
              },
              {
                  body: userbody,
                  nextStepOnClick: () => {
                      onSubmit();
                  },
                  nextStepButtonTitle: 'Finish',
                  finalStepButtonIcon: 'fa fa-check',
              },
          ]
        : [
              {
                  body: userbody,
                  nextStepOnClick: () => {
                      onSubmit();
                  },
                  nextStepButtonTitle: 'Finish',
                  finalStepButtonIcon: 'fa fa-check',
              },
          ];

    // when the state updates, useEffect will keep running in a constant loop, unless
    // you add the [] specified at the end (2nd arg) so that it runs only once when app is mounted
    // This behaves like componentDidMount
    useEffect(() => {
        if (!newschool || (newschool && stepNumber === 2))
            loadCaptchaEnginge(5, 'black', 'white', 'numbers');
    }, [newschool, stepNumber]);

    const onSubmit = async () => {
        let user_captcha_value =
            document.getElementById('user_captcha_input').value;

        if (newschool && !isSchoolStepValid()) {
            setAlert('Please enter a valid school address.', 'danger');
            return;
        }

        if (!isUserAddressValid) {
            setAlert('Please enter a valid address.', 'danger');
            return;
        }

        if (validateCaptcha(user_captcha_value, false)) {
            // pass newschool to register API
            register({ ...formData, newschool });
        } else {
            setAlert(
                'Captcha incorrect; please enter the numbers in the order shown.',
                'danger'
            );
        }
    };

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Register</title>
            </Helmet>
            {auth.isAuthenticated && <Navigate to='/base' />}
            <div className='landingbg'>
                <LandingHeader hasButtons={false} />
                <div className='margin-sides-wrapper centerbox'>
                    <Modal
                        steps={steps}
                        startingStepNumber={stepNumber}
                        onChangeStep={(num) => setStepNumber(num)}
                        hasCloseButton={true}
                        modalTitle='Create Your Account'
                    />
                </div>
            </div>
            <Footer hastopline />
        </Fragment>
    );
};

Register.propTypes = {
    register: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

// to use an action, pass it into connect below to allow us to access props.login so we can use it in this component.
// 1st param is state to connect. 2nd param is any actions I want to use
export default connect(mapStateToProps, { register, setAlert })(Register);
