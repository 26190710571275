// since this is a form,  each input should be a piece of state, so we need useState hook
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../layout/Spinner';
import FileUpload from '../../../layout/FileUpload';
import { getSchoolPic, ImgTypes } from '../../../../utils/helpers';

const UploadSchoolPic = ({
    // prop types
    admin: { school, uploading_school_pic },
}) => {
    const [getPhotoData, setPhotoData] = useState(null);

    useEffect(() => {
        if (school) {
            setPhotoData(getSchoolPic(school.logo));
        }
    }, [school]);
    return !school ? (
        <Spinner />
    ) : (
        <div className='upload-box'>
            <p className='form-header'>Change School Logo</p>
            {getPhotoData && (
                <img
                    className='round-img-avg'
                    src={getPhotoData}
                    alt={school.name}
                />
            )}
            <small className='form-text mb'>A square photo works best.</small>
            {uploading_school_pic ? (
                <Spinner size={0} />
            ) : (
                <FileUpload
                    formID='schoolpic'
                    uploadType={ImgTypes().SCHOOL}
                    id={school._id}
                />
            )}
        </div>
    );
};

UploadSchoolPic.propTypes = {
    admin: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
});

export default connect(mapStateToProps)(UploadSchoolPic);
