import api from '../../utils/api';
import {
    USERS_LOADED,
    ADMIN_ERROR,
    LOADING_SUPERADMIN_DATA,
    SUPERADMIN_SWITCH_USER,
    SUPERADMIN_USER_SWITCHED,
    INSTITUTIONS_LOADED,
} from './types';
import actionErrorHandler from './helpers/actionErrorHandler';

export const switchUser = (user) => (dispatch) => {
    dispatch({
        type: SUPERADMIN_SWITCH_USER,
        payload: user,
    });

    dispatch({
        type: SUPERADMIN_USER_SWITCHED, // to reset admin state when switching user
    });
};

// Load Users
export const getAllUsers = () => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_SUPERADMIN_DATA,
        });
        const res = await api.get('/superadmin/allusers');

        let users = res.data; // users with all fields except password

        dispatch({
            type: USERS_LOADED,
            payload: users,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};

export const getAllInstitutions = () => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_SUPERADMIN_DATA,
        });
        const res = await api.get('/superadmin/allinstitutions');

        let institutions = res.data;

        dispatch({
            type: INSTITUTIONS_LOADED,
            payload: institutions,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};
