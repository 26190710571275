// PRIVATE ROUTE: ADMIN LEVEL
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import Spinner from '../../../layout/Spinner';
import BaseFrame from '../../BaseFrame';
import { getAllTerms } from '../../../../storedata/actions/admin';
import SchoolHeader from '../../SchoolHeader';
import AddTermModal from '../AddTermModal';

const SchoolAdminSchoolTerms = ({ auth, admin, getAllTerms }) => {
    let location = useLocation();
    const [showTermModal, setShowTermModal] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    useEffect(() => {
        if (auth.viewingAs.school) {
            if (!admin.terms) getAllTerms();
        }
    }, [admin.terms, getAllTerms, auth.viewingAs.school]);

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | School Terms</title>
            </Helmet>
            <BaseFrame>
                <SchoolHeader school={auth.viewingAs?.school} />
                {!admin.terms ? (
                    <div className='containerinnersolid'>
                        <Spinner size={0} />
                    </div>
                ) : (
                    <Fragment>
                        <div className='split-2-header mb-2'>
                            <h1>
                                {admin.terms?.length} Term
                                {admin.terms?.length !== 1 && 's'}
                            </h1>
                            <Link
                                className='btn btn-primary'
                                to={`/admin/${auth.viewingAs.school?.urlslug}/terms/addterm`}
                                state={{
                                    previousLocation: location,
                                }}
                            >
                                Add Term
                            </Link>
                        </div>

                        <div className='containerinnersolid'>
                            <div
                                className={`term-boxes ${
                                    admin.terms?.length > 0 ? 'mt-1' : ''
                                }`}
                            >
                                {admin.terms?.map((term, idx) => {
                                    return (
                                        <div key={idx}>
                                            <div className='split-2-header'>
                                                <div className='gridcol2packed'>
                                                    <h2 className='darkercolor'>
                                                        {term.name}
                                                    </h2>
                                                    <span
                                                        className={`badge ${
                                                            term.isActive
                                                                ? 'success'
                                                                : 'warning'
                                                        }badge mr-1`}
                                                    >
                                                        {term.isActive
                                                            ? 'Active'
                                                            : 'Inactive'}
                                                    </span>
                                                </div>
                                                <div className='gridcol'>
                                                    <Link
                                                        className='btn'
                                                        to={`/admin/${auth.viewingAs.school?.urlslug}/terms/${term._id}`}
                                                    >
                                                        View
                                                    </Link>
                                                    <button
                                                        className='btn'
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setShowTermModal(
                                                                term
                                                            );
                                                        }}
                                                    >
                                                        Edit
                                                    </button>
                                                    {/* <Link
                                                        className='btn'
                                                        to={`/admin/${auth.viewingAs.school?.urlslug}/terms/editterm/${term._id}`}
                                                        state={{
                                                            previousLocation:
                                                                location,
                                                        }}
                                                    >
                                                        Edit
                                                    </Link> */}
                                                </div>
                                            </div>

                                            <p>
                                                {term.dates?.length} days in
                                                term
                                            </p>
                                            {term.schoolYear && (
                                                <span>
                                                    School year:{' '}
                                                    {term.schoolYear}
                                                </span>
                                            )}
                                            {showTermModal !== null && (
                                                <AddTermModal
                                                    previousLocation={
                                                        location.pathname
                                                    }
                                                    submitCallback={(
                                                        e,
                                                        formData
                                                    ) => {
                                                        e.preventDefault();
                                                        setShowTermModal(null);
                                                    }}
                                                    closeCallback={(e) => {
                                                        e.preventDefault();
                                                        setShowTermModal(null);
                                                    }}
                                                    term={showTermModal}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Fragment>
                )}
            </BaseFrame>
        </Fragment>
    );
};

SchoolAdminSchoolTerms.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    getAllTerms: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
});

export default connect(mapStateToProps, {
    getAllTerms,
})(SchoolAdminSchoolTerms);
