import React, { useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; // connects component to redux
import {
    getUserPic,
    getRoleTypes,
    getRoleBasePath,
    getRoleFromPath,
    getUserName,
    findSchoolFromRoles,
} from '../../utils/helpers';
import { switchView } from '../../storedata/actions/auth';
import Spinner from '../layout/Spinner';

const UserWidget = ({ auth, switchView }) => {
    let history = useNavigate();
    let location = useLocation();
    let params = useParams();

    useEffect(() => {
        if (!auth.loading && !auth.viewingAs) {
            let role = getRoleFromPath(location.pathname);
            let type = getRoleTypes(auth.viewingAs.user.roles).find((r) => {
                return parseInt(r.type) === parseInt(role);
            });

            let school = params.id
                ? findSchoolFromRoles(params.id, auth.viewingAs.user.roles) // use url to set school
                : type?.institution || null;

            // console.log('switch user role');
            switchView(role, school);
        }
    }, [
        auth.loading,
        auth.viewingAs,
        params.id,
        location.pathname,
        switchView,
    ]);

    const onChange = (e) => {
        let value = JSON.parse(e.target.value);

        switchView(value.role, value.school);

        history(getRoleBasePath(value.role));
    };

    return (
        <div className='userwidget light'>
            <div className='userpic'>
                <img
                    className='round-img-sml'
                    src={getUserPic(auth.viewingAs.user.photo)}
                    alt='reviewer'
                />
            </div>
            <div className='widgettext'>
                <div className='username'>
                    {getUserName(auth.viewingAs.user)}
                </div>
                <div>
                    {auth.loading ? (
                        <Spinner size={1} />
                    ) : (
                        <select
                            name='role'
                            value={JSON.stringify(auth.viewingAs)}
                            onChange={onChange}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        >
                            {getRoleTypes(auth.viewingAs.user.roles).map(
                                (role, i) => (
                                    <option
                                        value={JSON.stringify({
                                            role: role.type,
                                            school: role.institution,
                                        })}
                                        key={i}
                                    >
                                        {role.name}
                                    </option>
                                )
                            )}
                        </select>
                    )}
                </div>
            </div>
        </div>
    );
};

UserWidget.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    switchView: PropTypes.func.isRequired,
});

// to use an action, pass it into connect below to allow us to access props.login so we can use it in this component.
// 1st param is state to connect. 2nd param is any actions I want to use
export default connect(mapStateToProps, { switchView })(UserWidget);
