import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

const removeTrailingSlash = (str = '') => {
    return str.replace(/\/+$/, '');
};

// remove everything that includes and comes after '?'.
// do this since params will mess up exact path matches
// and aren't needed when validating active state of a URL
const removeParams = (str = '') => {
    let r = str.indexOf('?') >= 0 ? str.substring(0, str.indexOf('?')) : str;
    return r;
};

// accepts a prop 'relatedpaths' that is an array of paths that match the prop 'path'
// when selecting nav item in nav bar (making item bold)
// 'children' is a prop that will take the inner contents provided to the component and use it where specified
const NavbarLink = ({ path, relatedpaths, children }) => {
    let origPath = removeTrailingSlash(path);
    let locpath = removeTrailingSlash(useLocation().pathname);
    path = removeParams(path);

    const hasMatchingPath = () => {
        if (path === locpath) {
            return true;
        }

        for (let p = 0; p < relatedpaths.length; p++) {
            let path = relatedpaths[p];
            path.link = removeTrailingSlash(path.link);

            let matches =
                (path.link?.length > 0 && path.link === locpath) ||
                (locpath.indexOf(path.link) === 0 && !path.exact); // url starts with a valid path but is longer than path to match so it doesn't greedy match
            if (matches) {
                return true;
            }
        }

        return false;
    };

    return (
        <Link to={origPath} className={hasMatchingPath() ? 'selected' : ''}>
            {children}
        </Link>
    );
};

NavbarLink.propTypes = {
    path: PropTypes.string.isRequired,
    relatedpaths: PropTypes.array.isRequired,
};

NavbarLink.defaultProps = {
    relatedpaths: [],
};

export default NavbarLink;
