import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../layout/Modal';
import PersonalInfo from '../PersonalInfo';
import {
    updateContact,
    getContactById,
    clearContact,
} from '../../../storedata/actions/admin';
import Spinner from '../../layout/Spinner';
import UploadContactPic from './pages/UploadContactPic';

const initialState = {
    initialized: false,
    nameInfo: {
        firstName: '',
        middleName: '',
        lastName: '',
        namePrefix: 1,
        nameSuffix: '',
    },
    address: {
        postalCode: '',
        line1: '',
        line2: '',
        country: '',
        city: '',
        region: '',
    },
    email: '',
    phone: '',
};

// Previous location is the page that this modal sits on top of
const AddContactModal = ({
    admin: { loading_contact, contact },
    auth: { loading, viewingAs },
    updateContact,
    getContactById,
    clearContact,
    previousLocation, // set when modal opens via app route (has unique URL)
    submitCallback,
    closeCallback,
}) => {
    let params = useParams();
    let history = useNavigate();
    let location = useLocation();
    let mounted = useRef(false); // used to ensure that submitting form that has error won't reset form

    const [formData, setFormData] = useState(initialState);
    const [savingContact, setSavingContact] = useState(false);

    useEffect(() => {
        return () => {
            // behaves like componentWillUnmount
            clearContact();
        };
    }, [clearContact]);

    useEffect(() => {
        if (
            !mounted.current &&
            !loading /* wait for user state */ &&
            params.contactid
        ) {
            getContactById(params.contactid);
        }
    }, [loading, getContactById, params.contactid]); // @todo8 contact not found,

    useEffect(() => {
        if (!mounted.current) {
            if (!params.contactid) {
                const formData = {
                    ...initialState,
                    initialized: true,
                };
                setFormData(formData);
                mounted.current = true;
            } else if (!loading_contact && contact && !loading) {
                // update form fields with current contact
                setFormData((formData) => {
                    const formData2 = {
                        ...formData,
                    };

                    for (const key in contact) {
                        if (key in formData) formData2[key] = contact[key];
                    }

                    formData2.initialized = true;
                    mounted.current = true;
                    return { ...formData2 };
                });
            }
        }
    }, [loading, loading_contact, viewingAs, params.contactid, contact]);

    // for PersonalInfo form fields
    const onChange = (dataobj) => {
        let nameitems = [
            'nameSuffix',
            'namePrefix',
            'firstName',
            'middleName',
            'lastName',
            'nickname',
        ];

        let addritems = [
            'line1',
            'line2',
            'country',
            'city',
            'region',
            'postalCode',
        ];

        if (nameitems.includes(dataobj.name)) {
            setFormData({
                ...formData,
                nameInfo: {
                    ...formData.nameInfo,
                    [dataobj.name]: dataobj.value,
                },
            });
        } else if (addritems.includes(dataobj.name)) {
            setFormData({
                ...formData,
                address: {
                    ...formData.address,
                    [dataobj.name]: dataobj.value,
                },
            });
        } else {
            setFormData({ ...formData, [dataobj.name]: dataobj.value });
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault(); // don't let browser do its default behavior

        setSavingContact(true);
        updateContact(
            formData,
            history,
            location,
            false, // relocate
            previousLocation, // relocateTo
            params.contactid || undefined
        )
            .then(function (result) {
                /*res.json({
                    msg: 'Contact successfully created.',
                    success: true,
                    contact,
                }); */
                if (result.success) {
                    submitCallback && submitCallback(e, formData);
                }
                setSavingContact(false);
            })
            .catch(function (err) {
                setSavingContact(false);
            });
    };

    let body =
        loading /*|| !viewingAs?.user */ ||
        savingContact ||
        !mounted.current ||
        (params.contactid && loading_contact) ? (
            <div>
                <br />
                <br />
                <br />
                <Spinner size={0} />
                <br />
                <br />
                <br />
            </div>
        ) : (
            <Fragment>
                {params.contactid && formData.initialized && (
                    <UploadContactPic />
                )}
                <PersonalInfo
                    onChangeHandler={(data) => onChange(data)}
                    data={formData}
                    showMiddleName={true}
                    showNameSuffix={true}
                    showNamePrefix={true}
                    emailRequired={false}
                    phoneRequired={true}
                    showPassword={false}
                />
            </Fragment>
        );

    let steps = [
        {
            body,
            nextStepOnClick: (e) => {
                onSubmit(e);
            },
            nextStepButtonTitle: 'Save',
            finalStepButtonIcon: 'fa fa-save',
        },
    ];

    return (
        <Modal
            steps={steps}
            hasCloseButton={true}
            closeButtonCallBack={closeCallback}
            showAsOverlay={true}
            modalTitle={(params.contactid ? 'Edit' : 'Add A') + ' Contact'}
            size={1}
            previousLocation={previousLocation}
        />
    );
};

AddContactModal.propTypes = {
    updateContact: PropTypes.func.isRequired,
    getContactById: PropTypes.func.isRequired,
    clearContact: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    updateContact,
    getContactById,
    clearContact,
})(AddContactModal);
