// PRIVATE ROUTE: ADMIN LEVEL
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import Spinner from '../../../layout/Spinner';
import BaseFrame from '../../BaseFrame';
import { getAllStaff } from '../../../../storedata/actions/admin';
import SchoolHeader from '../../SchoolHeader';
import { getPrettyName } from '../../../../utils/helpers';

const SchoolAdminStaff = ({ auth, admin, getAllStaff }) => {
    useEffect(() => {
        if (!admin.staffmembers) {
            if (auth.viewingAs.school) getAllStaff();
        }
    }, [admin.staffmembers, getAllStaff, auth.viewingAs.school]);

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Staff Members</title>
            </Helmet>
            <BaseFrame>
                <SchoolHeader school={auth.viewingAs?.school} />
                {admin.loading_staff || !admin.staffmembers ? (
                    <div className='containerinnersolid'>
                        <Spinner size={0} />
                    </div>
                ) : (
                    <Fragment>
                        <h1 className='mb-2'>Staff Members</h1>
                        <div className='containerinnersolid'>
                            <div className='inline-notification notification-warm mb-2'>
                                <span>Staff management coming soon!</span>
                            </div>
                            <h2>
                                {admin.staffmembers.length} Staff Member
                                {admin.staffmembers.length !== 1 && 's'}
                            </h2>
                            <div className='superadmin-user-boxes'>
                                {admin.staffmembers.map((member, idx) => {
                                    return (
                                        <div
                                            className='superadmin-user-box'
                                            key={idx}
                                        >
                                            <p>
                                                {getPrettyName(
                                                    member.nameInfo,
                                                    false
                                                )}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Fragment>
                )}
            </BaseFrame>
        </Fragment>
    );
};

SchoolAdminStaff.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    getAllStaff: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
});

export default connect(mapStateToProps, { getAllStaff })(SchoolAdminStaff);
