import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Tag = ({ name, deleteHandler, slug, status }) => {
    let iconClass = 'fas fa-times' + (deleteHandler ? ' deletable' : '');
    let statusClass = () => {
        switch (status) {
            case 'SUCCESS':
                return ' tag-success';

            case 'WARNING':
                return ' tag-warning';

            case 'ERROR':
                return ' tag-error';

            case 'DEFAULT':
            default:
                return ' tag-default';
        }
    };

    return (
        <span
            className={
                'item-tag' + (slug ? ' tag-clickable' : '') + statusClass()
            }
            key={name}
        >
            {slug ? <Link to={slug}>{name}</Link> : name}
            {deleteHandler ? (
                <i className={iconClass} onClick={deleteHandler} />
            ) : (
                ''
            )}
        </span>
    );
};

Tag.propTypes = {
    name: PropTypes.string.isRequired,
    deleteHandler: PropTypes.func, // function used to handle removal of tag
    slug: PropTypes.string, // a string used to click to a link
    status: PropTypes.oneOf(['DEFAULT', 'WARNING', 'ERROR', 'SUCCESS']),
};

Tag.defaultProps = {
    deleteHandler: null,
    slug: null,
    status: 'DEFAULT',
};

export default Tag;
