import { setAlert } from '../alert';

/* Used to handle errors in action files
   There are two types of errors that can be sent from API:
   1. my custom error object, which has the form:
    {
        errors: [{ msg: 'sample err msg' }],
    }
   2. AxiosError type, which have the form:
    {
        "message": "Request failed with status code 400",
        "name": "AxiosError",
        "stack": {omitted},
        "config": {omitted},
        "code": "ERR_BAD_REQUEST",
        "request": {omitted}
        "response": {
            config: {omitted}
            data: {errors: [{msg: 'sample err msg'}]}
            headers: {omitted}
            request: {omitted}
            status: 400
            statusText: "Bad Request"
        }
    }
*/
// returns error message
const actionErrorHandler = (dispatch, err, type, showalert = true) => {
    const errors =
        err?.response?.errors || // for my custom errors
        err?.response?.data?.errors || // for AxiosError types
        err?.response?.data; // for AxiosError types
    console.log(err);
    let msg =
        errors && errors[0].msg?.length > 0
            ? errors[0].msg
            : 'API Error' +
              (err ? ': ' + err.toString().slice(0, 50) + '...' : '');

    if (showalert) dispatch(setAlert(msg, 'danger')); // show first error

    dispatch({
        type,
        payload: {
            msg,
            status: err?.response?.status,
        },
    });

    return msg;
};

export default actionErrorHandler;
