import React, { Fragment, useEffect, useState } from 'react';
import { toggleFullScreen } from '../../utils/helpers';

const LeftNavFooter = () => {
    const [isFullscreen, setIsFullscreen] = useState(false);

    const onFullscreen = () => {
        setIsFullscreen(Boolean(document.fullscreenElement));
    };

    useEffect(() => {
        window.addEventListener('fullscreenchange', () => onFullscreen());
        return () => {
            // behaves like componentWillUnmount
            window.removeEventListener('fullscreenchange', onFullscreen());
        };
    }, []);

    return (
        <Fragment>
            <hr />
            <div
                onClick={() => {
                    if (isFullscreen) {
                        setIsFullscreen(false);
                        document.exitFullscreen();
                    } else {
                        setIsFullscreen(true);
                        toggleFullScreen();
                    }
                }}
            >
                <div id='full-screen-button'>
                    {isFullscreen ? (
                        <span>
                            <i className='fas fa-minimize'></i> Close
                        </span>
                    ) : (
                        <span>
                            <i className='fas fa-maximize'></i>
                        </span>
                    )}{' '}
                    Full Screen
                </div>
            </div>
        </Fragment>
    );
};

export default LeftNavFooter;
