import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import Logo from '../../img/logo.svg';
import { APP_NAME, CLIENT_VERSION } from '../../utils/constants';

const Footer = ({ hastopline = false }) => {
    return (
        <section className={`footer${hastopline ? ' footertopline' : ''}`}>
            <div className='footerleft'>
                <div className='logolink'>
                    <img src={Logo} alt='logo' id='logo-footer' />
                </div>
                <span>
                    Copyright &copy; {new Date().getFullYear()} {APP_NAME}
                    <sup>SM</sup> v{CLIENT_VERSION}
                </span>
                {/* <Link to='/about'>About Us</Link> |{' '}
                <Link to='/terms'>Terms &amp; Conditions</Link> */}
            </div>
            <div>
                <a
                    href='https://www.instagram.com/school.trail'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <i className='fab fa-instagram fa-2x' />
                </a>
            </div>
        </section>
    );
};

Footer.propTypes = {
    hastopline: PropTypes.bool,
};

export default Footer;
