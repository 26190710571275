// configure RUM application
import { datadogRum } from '@datadog/browser-rum';
import { CLIENT_VERSION } from './utils/constants';

const ruminit = () => {
    datadogRum.init({
        applicationId: '3abc84e8-512c-4edc-86d3-8ec118de7ba0',
        clientToken: 'pube47ca4146dbd16baf7de552eb946ae2d',
        site: 'datadoghq.com',
        service: 'schooltrail',
        env: process.env.NODE_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: CLIENT_VERSION,
        sampleRate: 100,
        silentMultipleInit: true,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
};

export default ruminit;
