// since this is a form,  each input should be a piece of state, so we need useState hook
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../layout/Spinner';
import FileUpload from '../../../layout/FileUpload';
import { getStudentPic, ImgTypes } from '../../../../utils/helpers';

const UploadStudentPic = ({
    // prop types
    admin: { student, uploading_student_pic },
}) => {
    const [getPhotoData, setPhotoData] = useState(null);

    useEffect(() => {
        if (student) {
            setPhotoData(student.photo);
        }
    }, [student]);
    return !student ? (
        <Spinner size={0} />
    ) : (
        <div className='upload-box'>
            <p className='form-header'>Change Student Picture</p>
            {getPhotoData && (
                <img
                    className='round-img-avg'
                    src={getStudentPic(getPhotoData)}
                    alt={student.nameInfo.firstName}
                />
            )}
            <small className='form-text mb'>A square photo works best.</small>
            {uploading_student_pic ? (
                <Spinner size={0} />
            ) : (
                <FileUpload
                    formID='studentpic'
                    uploadType={ImgTypes().STUDENT}
                    id={student._id}
                />
            )}
        </div>
    );
};

UploadStudentPic.propTypes = {
    admin: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
});

export default connect(mapStateToProps)(UploadStudentPic);
