// PRIVATE ROUTE
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
    isSchoolAdmin,
    isParent,
    storageItemIsTrue,
} from '../../utils/helpers';
import SchoolAdminLeftNav from './admin/SchoolAdminLeftNav';
import SuperadminLeftNav from './superadmin/SuperadminLeftNav';
import ParentLeftNav from './parent/ParentLeftNav';
import Footer from '../layout/Footer';
import Spinner from '../layout/Spinner';
import { getRoleFromPath } from '../../utils/helpers';
import { updateSearchParams } from '../../storedata/actions/auth';

// 'children' is a prop that will take the inner contents
// provided to the component and use it where specified
const BaseFrame = ({ auth, updateSearchParams, children }) => {
    let location = useLocation();
    let history = useNavigate();
    let params = useParams();

    let role = getRoleFromPath(location.pathname);

    const [storage, setStorage] = useState(null);
    const handleStorage = () => {
        setStorage(storageItemIsTrue('parentMode'));
    };

    useEffect(() => {
        setStorage(storageItemIsTrue('parentMode'));
        window.addEventListener('storage', () => handleStorage());

        return () => {
            // behaves like componentWillUnmount
            window.removeEventListener('storage', handleStorage);
        };
    }, []);

    // impt: check if URL already loaded so it doesn't run more than once.
    // let endOfPath = location.pathname.substring(
    //     location.pathname.indexOf('/students'),
    //     location.pathname.length
    // );

    useEffect(() => {
        let paramsString = new URLSearchParams(location.search).toString();

        // if I don't have params in URL right now, or, but have some stored, use stored:
        // use params that were loaded via URL
        if (
            paramsString &&
            (!auth.searchParams || !auth.searchParams[location.pathname])
        ) {
            // 2 routes for same component, so update both
            updateSearchParams(location.pathname, location.search);
        }
        // otherwise, if url doesn't have params but some exist (e.g. if I load a clean URL that
        // accidentally doesn't have search params on it), load from state and update URL:
        else if (!paramsString && auth.searchParams) {
            let studentparamsString = auth.searchParams[location.pathname];
            if (studentparamsString) {
                history(studentparamsString, {
                    replace: true,
                    isActive: true,
                });
            }
        }
    }, [
        history,
        auth.searchParams,
        location.search,
        location.pathname,
        updateSearchParams,
    ]);

    if (
        localStorage.getItem('parentMode') !== null &&
        location.pathname !== localStorage.getItem('parentMode')
    ) {
        return <Navigate to={localStorage.getItem('parentMode')} />;
    } else
        return (
            <div
                className={
                    storage ? 'containerbodyonly' : 'containerforleftnavandbody'
                }
            >
                {!storage && (
                    <div className='leftnav'>
                        {auth.loading || !auth.viewingAs ? (
                            <Spinner />
                        ) : params.id &&
                          params.id !== auth.viewingAs.school?.urlslug ? (
                            // don't let user load foreign school slug in URL
                            <Navigate to='/base' />
                        ) : auth.isSuperadmin && role === 0 ? (
                            <SuperadminLeftNav />
                        ) : auth.isSuperadmin ||
                          (role === 1 &&
                              isSchoolAdmin(auth.viewingAs?.user?.roles)) ? (
                            <SchoolAdminLeftNav
                                school={auth.viewingAs?.school}
                                params={auth.searchParams}
                            />
                        ) : auth.isSuperadmin || // todo add staff role
                          (role === 3 &&
                              isParent(auth.viewingAs.user?.roles)) ? (
                            <ParentLeftNav />
                        ) : (
                            <Navigate to='/base' />
                        )}
                    </div>
                )}
                <div className='rightnav'>
                    <div className='containerinner m-3 mb-0'>
                        {auth.loading || !auth.viewingAs ? (
                            <div className='containerinnersolid'>
                                <Spinner />
                            </div>
                        ) : (
                            <Fragment>{children}</Fragment>
                        )}
                    </div>
                    <Footer />
                </div>
            </div>
        );
};

BaseFrame.propTypes = {
    auth: PropTypes.object.isRequired, // auth state
    updateSearchParams: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { updateSearchParams })(BaseFrame);
