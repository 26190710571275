// since this is a form,  each input should be a piece of state, so we need useState hook
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import FileUpload from '../layout/FileUpload';
import { getUserPic, ImgTypes } from '../../utils/helpers';

const UploadProfilePic = ({
    // prop types
    auth: { loading, uploading_profile_pic, viewingAs },
}) => {
    const [getPhotoData, setPhotoData] = useState(null);

    useEffect(() => {
        if (!loading && viewingAs.user) {
            setPhotoData(viewingAs.user.photo);
        }
    }, [loading, viewingAs.user]);
    return loading || !viewingAs.user ? (
        <Spinner />
    ) : (
        <div className='upload-box'>
            <p className='form-header'>Change Profile Picture</p>
            {getPhotoData && (
                <img
                    className='round-img-avg'
                    src={getUserPic(getPhotoData)}
                    alt={viewingAs.user.nameInfo.firstName}
                />
            )}
            <small className='form-text mb'>A square photo works best.</small>
            {uploading_profile_pic ? (
                <Spinner size={0} />
            ) : (
                <FileUpload
                    formID='profilepic'
                    uploadType={ImgTypes().PROFILE}
                />
            )}
        </div>
    );
};

UploadProfilePic.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(UploadProfilePic);
