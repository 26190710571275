// PRIVATE ROUTE: logged in user (/account)
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { APP_NAME } from '../../../../utils/constants';
import { Helmet } from 'react-helmet-async';
import { getAllTerms } from '../../../../storedata/actions/admin';
import Spinner from '../../../layout/Spinner';
import FeeRow from '../../../layout/FeeRow';

const StudentTerms = ({ student, auth, admin: { terms }, getAllTerms }) => {
    useEffect(() => {
        if (!terms) {
            getAllTerms();
        }
    }, [terms, getAllTerms]);

    const filterRequiredFees = (f, gradeLevel) => {
        if (f.isRequired) {
            if (
                // show fees that contain the set level
                gradeLevel &&
                f.gradeLevels?.find((l) => l && l.name === gradeLevel.name)
            ) {
                return true;
            } else return !f.gradeLevels || f.gradeLevels.length === 0;
        } else return false;
    };

    const getValidTerms = (terms) => {
        if (terms.length === 0) return [];
        // let clonedTerms = structuredClone(terms);

        let validTerms = structuredClone(terms) // avoid mutating terms state
            // show only the terms where this student is registered
            .filter((t) =>
                t.registeredStudents?.find((s) => s.student._id === student._id)
                    ? true
                    : false
            )
            // for the students registered in each term that our student is found in,
            // only include that student
            .map((t) => {
                if (t.registeredStudents) {
                    t.registeredStudents = [...t.registeredStudents].filter(
                        (reg) => reg.student._id === student._id
                    );
                }

                return t;
            });

        return validTerms;
    };

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Student Terms</title>
            </Helmet>

            {!terms ? (
                <div className='containerinnersolid'>
                    <Spinner size={0} />
                </div>
            ) : getValidTerms(terms).length === 0 ? (
                <div className='containerinnersolid'>None yet.</div>
            ) : (
                getValidTerms(terms).map((term, idx) => {
                    return (
                        <div className='fee-tile' key={idx}>
                            <div className='gridcolpacked gap0'>
                                <h2 className='darkercolor mr-1 mb-0'>
                                    {term.name}
                                </h2>
                                <span
                                    className={`badge ${
                                        term.isActive ? 'success' : 'warning'
                                    }badge mr-1`}
                                >
                                    {term.isActive ? 'Active' : 'Inactive'}
                                </span>
                                <Link
                                    className='btn btn-small inlinegrid'
                                    to={`/admin/${auth.viewingAs.school?.urlslug}/terms/${term._id}/registration`}
                                >
                                    View
                                </Link>
                            </div>
                            <hr className='mt-1' />

                            <h3 className='mt-1'>
                                Grade Level
                                <span className='badge neutralbadge mr-1 ml-1'>
                                    {term.registeredStudents &&
                                    term.registeredStudents[0].gradeLevel
                                        ? term.registeredStudents[0].gradeLevel
                                              .name
                                        : 'Unassigned'}
                                </span>
                            </h3>

                            <h3 className='mb-1 mt-2'>Required Fees</h3>
                            {term.fees &&
                            [...term.fees]?.filter((f) => filterRequiredFees(f))
                                .length > 0 ? (
                                <div className='feeWrapper'>
                                    {[...term.fees]
                                        .filter((f) => filterRequiredFees(f))
                                        .map((fee, idx) => (
                                            <FeeRow
                                                term={term}
                                                fee={fee}
                                                key={idx}
                                                disabled
                                                selectable
                                            />
                                        ))}
                                </div>
                            ) : (
                                <p>None</p>
                            )}

                            <h3 className='mb-1 mt-2'>
                                Selected Optional Fees
                            </h3>
                            {term.registeredStudents &&
                            term.registeredStudents[0].optionalFees?.length >
                                0 ? (
                                <div className='feeWrapper'>
                                    {term.registeredStudents[0].optionalFees.map(
                                        (fee, idx) => (
                                            <FeeRow
                                                term={term}
                                                fee={fee}
                                                key={idx}
                                                disabled
                                                selectable
                                            />
                                        )
                                    )}
                                </div>
                            ) : (
                                <p>None</p>
                            )}
                            {/* {showTermModal !== null && (
                                <AddTermModal
                                    previousLocation={location.pathname}
                                    submitCallback={(e, formData) => {
                                        e.preventDefault();
                                        setShowTermModal(null);
                                    }}
                                    closeCallback={(e) => {
                                        e.preventDefault();
                                        setShowTermModal(null);
                                    }}
                                    term={showTermModal}
                                />
                            )} */}
                        </div>
                    );
                })
            )}
        </Fragment>
    );
};

StudentTerms.propTypes = {
    student: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    getAllTerms: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    auth: state.auth,
});

export default connect(mapStateToProps, { getAllTerms })(StudentTerms);
