import React from 'react';
import NavbarLink from '../../layout/NavbarLink';

const AdminStudentNav = ({ schoolSlug, studentID, params }) => {
    let base = `/admin/${schoolSlug}`;
    params = params || {};

    let studentpath = base + '/student/' + studentID;
    if (studentpath in params) {
        let studentparams = params[studentpath];
        studentpath += studentparams;
    }

    return (
        <nav className='tabbar'>
            <ul>
                <li>
                    <NavbarLink
                        path={studentpath}
                        relatedpaths={[
                            {
                                link: `${base}/student/${studentID}/summary`,
                                exact: true,
                            },
                        ]}
                    >
                        Summary
                    </NavbarLink>
                </li>

                <li>
                    <NavbarLink path={`${base}/student/${studentID}/terms`}>
                        Term Registrations
                    </NavbarLink>
                </li>
            </ul>
        </nav>
    );
};

AdminStudentNav.defaultProps = {
    params: '',
};

export default AdminStudentNav;
