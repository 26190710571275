import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { URLhelpers } from '../../../utils/helpers';
import { updateSearchParams } from '../../../storedata/actions/auth';

const SegmentedButton = ({
    name,
    segments,
    callback,
    classes,
    defaultIndex = 0,
    controlRef,
    smallSize = false,
    updateSearchParams,
    searchParam, // param name used to identify this search query.
    // if not set (empty str), then the URL location isn't updated
}) => {
    let history = useNavigate();
    let location = useLocation();
    const [activeIndex, setActiveIndex] = useState(defaultIndex);
    const componentReady = useRef();

    useEffect(() => {
        if (searchParam) {
            // 0: inactive; 1: pending; 2: active; -1 represents 'all'
            let p = URLhelpers.getURLParam(searchParam, location.search);

            let foundAtIndex = 0;
            // if p is undefined, then we can remove query (e.g. for 'All' segment that represents no filter)
            if (p) {
                segments.find((s, idx) => {
                    let found = s.value === p;
                    if (found) foundAtIndex = idx;
                    return found;
                });
            }

            setActiveIndex(foundAtIndex);
        }
    }, [location, searchParam, segments]);

    // Determine when the component is "ready"
    useEffect(() => {
        componentReady.current = true;
    }, []);

    useEffect(() => {
        const activeSegmentRef = segments[activeIndex].ref;
        const { offsetWidth, offsetLeft } = activeSegmentRef.current;
        const { style } = controlRef.current;

        style.setProperty('--highlight-width', `${offsetWidth}px`);
        style.setProperty('--highlight-x-pos', `${offsetLeft}px`);
    }, [activeIndex, callback, controlRef, segments]);

    const onInputChange = (value, index) => {
        setActiveIndex(index);
        if (searchParam) {
            let updatedurl;
            if (!value) {
                updatedurl = URLhelpers.deleteURLparam(
                    history,
                    location,
                    searchParam
                );
            } else {
                var o = { [searchParam]: value };
                updatedurl = URLhelpers.setURLparam(
                    history,
                    location.search,
                    o
                );
            }

            updateSearchParams(location.pathname, updatedurl);
        }
        callback(value, index);
    };

    return (
        <div
            className={'controls-container' + (classes ? ' ' + classes : '')}
            ref={controlRef}
        >
            <div
                className={`controls ${
                    componentReady.current ? 'ready' : 'idle'
                }${smallSize ? ' segsml' : ''}`}
            >
                {segments?.map((item, i) => (
                    <div
                        key={item.value}
                        className={`segment ${
                            i === activeIndex ? 'active' : 'inactive'
                        }`}
                        ref={item.ref}
                    >
                        <input
                            type='radio'
                            value={item.value}
                            id={item.label}
                            name={name}
                            // onFocus={(e) => {
                            //     foc(e);
                            // }}
                            onChange={() => onInputChange(item.value, i)}
                            checked={i === activeIndex}
                        />
                        <label htmlFor={item.label}>{item.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

SegmentedButton.propTypes = {
    segments: PropTypes.array.isRequired, // array w/properties for each segment
    name: PropTypes.string.isRequired, // input name
    callback: PropTypes.func.isRequired,
    searchParam: PropTypes.string, // param to identify this search query use. if set, it updates URL bar.
    classes: PropTypes.string, // styling classes
    defaultIndex: PropTypes.number, // the segment to start on
    controlRef: PropTypes.object.isRequired, // used to locate placement of control for animating segments
    updateSearchParams: PropTypes.func.isRequired,
    smallSize: PropTypes.bool, // whether to make it a smaller height
};

SegmentedButton.defaultProps = {
    defaultIndex: 0,
};

export default connect(null, { updateSearchParams })(SegmentedButton);
