// PRIVATE ROUTE: ADMIN LEVEL
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import Spinner from '../../../layout/Spinner';
import { getTermById } from '../../../../storedata/actions/admin';

// const initialState = {
//     selectedDate: null,
// };

const SchoolAdminSchoolTermAttendance = ({ term }) => {
    // const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    // let { selectedDate } = formData;

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | School Term Courses</title>
            </Helmet>

            {!term ? (
                <div className='containerinnersolid'>
                    <Spinner size={0} />
                </div>
            ) : (
                <div className='containerinnersolid'>
                    <h2>Coming soon!</h2>
                </div>
            )}
        </Fragment>
    );
};

SchoolAdminSchoolTermAttendance.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    getTermById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
});

export default connect(mapStateToProps, {
    getTermById,
})(SchoolAdminSchoolTermAttendance);
