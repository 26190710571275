import { ADD_MODAL, REMOVE_MODAL } from '../actions/types';

const initialState = [];

// reducers take a state and an action (the action is dispatched from a file)
// actions have a type and a payload
const modalReducer = (state = initialState, action) => {
    const { type, payload } = action; // pulled out to shorthand the code

    switch (type) {
        case ADD_MODAL: {
            return [...state, payload];
        }
        case REMOVE_MODAL:
            // remove modal that matches a specific ID (payload is set to be just the ID here)
            return state.filter((modal) => modal.id !== payload);

        default:
            return state;
    }
};

export default modalReducer;
