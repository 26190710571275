import React, { useState, Fragment } from 'react';
import { Navigate, useNavigate, useLocation, Link } from 'react-router-dom';
import { connect } from 'react-redux'; // connects component to redux
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { resetPassword } from '../../storedata/actions/auth';
import Spinner from '../layout/Spinner';
import LandingHeader from '../layout/LandingHeader';
import Footer from '../layout/Footer';

// create function component
const ResetPassword = ({
    resetPassword,
    auth: { isAuthenticated, loading, resetting_pwd },
}) => {
    let history = useNavigate();
    let location = useLocation();

    const [formData, setFormData] = useState({
        message: null,
        success: false,
        email: '',
        newpassword1: '',
        newpassword2: '',
        submitted: false,
    });

    const { message, newpassword1, newpassword2, success, email, submitted } =
        formData;

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault(); // don't let browser do its default behavior

        const params = new URLSearchParams(location.search);
        let hash = params.get('c');
        resetPassword(formData, hash, history)
            .then(function (result) {
                setFormData({
                    ...formData,
                    message: result.msg,
                    success: result.success,
                    submitted: true,
                });
            })
            .catch(function (err) {
                setFormData({
                    ...formData,
                    message: 'Error verifying email address.',
                    success: false,
                    submitted: true,
                });
            });
    };

    if (isAuthenticated) {
        return <Navigate to='/base' />;
    } else
        return (
            <Fragment>
                <Helmet>
                    <title>{APP_NAME} | Reset Password</title>
                </Helmet>
                <div className='landingbg'>
                    <LandingHeader hasButtons={true} />
                    <div className='margin-sides-wrapper centerbox'>
                        <div className='modalwrapper width500 p-3'>
                            <h1 className='mb-2'>Reset Your Password</h1>

                            {!message?.success && (
                                <form className='form' onSubmit={onSubmit}>
                                    <div className='form-header'>
                                        Account Email Address
                                    </div>
                                    <input
                                        type='email'
                                        name='email'
                                        value={email}
                                        onChange={onChange}
                                        required
                                    />
                                    <div className='form-header'>
                                        New Password
                                    </div>
                                    <small className='form-text mb'>
                                        Must be at least 8 characters long
                                    </small>
                                    <input
                                        type='password'
                                        name='newpassword1'
                                        value={newpassword1}
                                        onChange={onChange}
                                        minLength='8'
                                    />

                                    <div className='form-header'>
                                        Confirm New Password
                                    </div>
                                    <input
                                        type='password'
                                        name='newpassword2'
                                        value={newpassword2}
                                        onChange={onChange}
                                        minLength='8' // @todo make 8 a constant and use it in the backend api validator too
                                    />
                                    {loading || resetting_pwd ? (
                                        <Spinner size={1} />
                                    ) : submitted ? (
                                        <div
                                            className={`inline-notification ${
                                                success
                                                    ? ''
                                                    : 'notification-error'
                                            } mb-2 mt`}
                                        >
                                            <i
                                                className={`${
                                                    success
                                                        ? 'fas fa-check'
                                                        : 'far fa-circle-xmark'
                                                }`}
                                            />
                                            <span className='ml-1'>
                                                {message}
                                            </span>
                                        </div>
                                    ) : null}
                                    {!success && (
                                        <input
                                            type='submit'
                                            className='btn btn-primary mr-0'
                                            value='Reset Password'
                                        />
                                    )}
                                </form>
                            )}
                            <p className='link-small center mt-1'>
                                <Link to='/forgotpassword'>
                                    Get another password reset link
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
                <Footer hastopline />
            </Fragment>
        );
};

ResetPassword.propTypes = {
    resetPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    auth: state.auth,
});

// to use an action, pass it into connect below to allow us to access props.register so we can use it in this component.
// 1st param is state to connect (not needed here). 2nd param is any actions I want to use
export default connect(mapStateToProps, { resetPassword })(ResetPassword);
