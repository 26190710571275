// PRIVATE ROUTE: ADMIN LEVEL
import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import Spinner from '../../../layout/Spinner';
import {
    getAllStudents,
    getAllFeesForTerm,
    clearFees,
    getAllGradeLevels,
} from '../../../../storedata/actions/admin';
import AddTermFeeModal from '../AddTermFeeModal';
import AddStudentModal from '../AddStudentModal';
import UpdateRegistrationModal from '../UpdateRegistrationModal';
import AddTermModal from '../AddTermModal';
import AddGradeLevelModal from '../AddGradeLevelModal';
import FeeRow from '../../../layout/FeeRow';
import SchoolAdminSchoolTermRegistrationBox from './SchoolAdminSchoolTermRegistrationBox';

const SchoolAdminSchoolTermRegistration = ({
    admin,
    term,
    getAllStudents,
    getAllFeesForTerm,
    clearFees,
    getAllGradeLevels,
}) => {
    let params = useParams();
    let location = useLocation();
    let termid = params.termid;

    // const [showSpinner, setShowSpinner] = useState(false);
    const [showFeeModal, setShowFeeModal] = useState(false); // false to hide; null to add fee; ID to update fee
    const [showStudentModal, setShowStudentModal] = useState(null);
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);
    const [showTermModal, setShowTermModal] = useState(false);
    const [showGradeLevelModal, setShowGradeLevelModal] = useState(false); // false to hide; null to add; ID to update

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    useEffect(() => {
        if (!admin.students) {
            getAllStudents();
        }
        if (!admin.grade_levels) {
            getAllGradeLevels();
        }
    }, [getAllStudents, admin.students, getAllGradeLevels, admin.grade_levels]);

    // useEffect(() => {
    //     setShowSpinner(admin.loading_term);
    // }, [admin.loading_term, location.pathname]);

    useEffect(() => {
        getAllFeesForTerm(termid);
    }, [getAllFeesForTerm, termid]);

    useEffect(() => {
        return () => {
            // behaves like componentWillUnmount
            clearFees();
        };
    }, [clearFees]);

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | School Term Registration</title>
            </Helmet>

            {!showTermModal /*showSpinner ||*/ &&
            (!admin.grade_levels || !admin.students || !admin.fees) ? (
                <div className='containerinnersolid'>
                    <Spinner size={0} />
                </div>
            ) : (
                <Fragment>
                    <div className='gridrow grid-gap-30'>
                        <div className='containerinnersolid'>
                            <div className='registrationHeader'>
                                <span className='stepNum'>1</span>
                                <h2>Add Grade Levels</h2>

                                <button
                                    className='btn btn-small inlinegrid'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowGradeLevelModal(null);
                                    }}
                                >
                                    <i className='fas fa-plus' /> Add
                                </button>
                            </div>
                            <p className='mb-2'>
                                Create at least one grade level to enroll
                                students in for this term. The grade levels will
                                be available to all terms.
                            </p>
                            <h2>
                                {admin.grade_levels.length} Grade Level
                                {admin.grade_levels.length !== 1 && 's'}{' '}
                            </h2>
                            <div
                                className={`compact-boxes ${
                                    admin.grade_levels?.length > 0 ? 'mt-1' : ''
                                }`}
                            >
                                {admin.grade_levels?.map((level, idx) => {
                                    return (
                                        <div key={idx}>
                                            <div className='split-2-header'>
                                                <div>
                                                    <p className='darkercolor'>
                                                        {level.name}
                                                    </p>
                                                </div>
                                                <div className='gridcol'>
                                                    <button
                                                        className='btn'
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setShowGradeLevelModal(
                                                                level._id
                                                            );
                                                        }}
                                                    >
                                                        <i className='fas fa-pen' />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className='containerinnersolid'>
                            <div className='registrationHeader'>
                                <span className='stepNum'>2</span>
                                <h2>Add Term Fees </h2>
                                <button
                                    className='btn btn-small inlinegrid'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowFeeModal(null);
                                    }}
                                >
                                    <i className='fas fa-plus' /> Add
                                </button>
                            </div>
                            <p className='mb-2'>
                                If applicable, add term fees to track billing
                                for registered students.
                            </p>
                            {admin.fees?.length > 0 ? (
                                <div className='feeWrapper'>
                                    {admin.fees.map((fee, idx) => (
                                        <FeeRow
                                            term={term}
                                            fee={fee}
                                            key={idx}
                                            editable
                                        />
                                    ))}
                                </div>
                            ) : (
                                <Fragment>
                                    <button
                                        className='btn btn-primary'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowFeeModal(null);
                                        }}
                                    >
                                        <i className='fas fa-plus' /> Add Fee
                                    </button>
                                </Fragment>
                            )}
                        </div>
                        <SchoolAdminSchoolTermRegistrationBox term={term} />

                        <div className='containerinnersolid'>
                            <div className='registrationHeader'>
                                <span className='stepNum'>4</span>
                                <h2>Activate Term</h2>
                                <button
                                    className='btn btn-small inlinegrid'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowTermModal(true);
                                    }}
                                >
                                    <i className='fas fa-pen' /> Edit Term
                                </button>
                            </div>

                            <p className='mb-2'>
                                Activate the term when it is ready to go live.
                            </p>

                            <span
                                className={`badge ${
                                    term.isActive ? 'success' : 'error'
                                }badge`}
                            >
                                The term is currently{' '}
                                {term.isActive ? 'active' : 'inactive'}
                            </span>
                        </div>
                    </div>
                </Fragment>
            )}

            {showFeeModal !== false && (
                <AddTermFeeModal
                    term={term}
                    feeID={showFeeModal}
                    previousLocation={location.pathname}
                    submitCallback={(e, formData) => {
                        e.preventDefault();
                        setShowFeeModal(false);
                    }}
                    closeCallback={(e) => {
                        e.preventDefault();
                        setShowFeeModal(false);
                    }}
                />
            )}

            {term && showTermModal && (
                <AddTermModal
                    previousLocation={location.pathname}
                    submitCallback={(e, formData) => {
                        e.preventDefault();
                        setShowTermModal(false);
                    }}
                    closeCallback={(e) => {
                        e.preventDefault();
                        setShowTermModal(false);
                    }}
                    term={term}
                />
            )}

            {showRegistrationModal !== false && (
                <UpdateRegistrationModal
                    term={term}
                    students={showRegistrationModal}
                    previousLocation={location.pathname}
                    submitCallback={(e, formData) => {
                        e.preventDefault();
                        setShowRegistrationModal(false);
                    }}
                    closeCallback={(e) => {
                        e.preventDefault();
                        setShowRegistrationModal(false);
                    }}
                />
            )}

            {showStudentModal && (
                <AddStudentModal
                    previousLocation={location.pathname}
                    submitCallback={(e, formData) => {
                        e.preventDefault();
                        setShowStudentModal(null);
                    }}
                    closeCallback={(e) => {
                        e.preventDefault();
                        setShowStudentModal(null);
                    }}
                    studentID={showStudentModal}
                />
            )}

            {showGradeLevelModal !== false && (
                <AddGradeLevelModal
                    term={term}
                    levelID={showGradeLevelModal}
                    previousLocation={location.pathname}
                    submitCallback={(e, formData) => {
                        e.preventDefault();
                        setShowGradeLevelModal(false);
                    }}
                    closeCallback={(e) => {
                        e.preventDefault();
                        setShowGradeLevelModal(false);
                    }}
                />
            )}
        </Fragment>
    );
};

SchoolAdminSchoolTermRegistration.propTypes = {
    admin: PropTypes.object.isRequired,
    getAllStudents: PropTypes.func.isRequired,
    getAllFeesForTerm: PropTypes.func.isRequired,
    clearFees: PropTypes.func.isRequired,
    getAllGradeLevels: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
});

export default connect(mapStateToProps, {
    getAllStudents,
    getAllFeesForTerm,
    clearFees,
    getAllGradeLevels,
})(SchoolAdminSchoolTermRegistration);
