import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LeftNavLink from '../../layout/LeftNavLink';
import LeftNavHomeSection from '../LeftNavHomeSection';
import SchoolsWidget from '../SchoolsWidget';

const baseurl = '/parent';

const ParentLeftNav = ({ auth }) => {
    const [urlSlug, setUrlSlug] = useState(null);

    useEffect(() => {
        let school = auth.viewingAs.school;
        setUrlSlug(baseurl + (school ? '/' + school.urlslug : ''));
    }, [auth.viewingAs.school, auth.viewingAs.school?.urlslug]);

    return (
        <Fragment>
            <LeftNavHomeSection baseurl={baseurl} />

            <SchoolsWidget baseurl={baseurl} />
            <ul>
                {LeftNavLink(
                    urlSlug + '/students',
                    'far fa-building',
                    'Students',
                    [{ link: urlSlug, exact: true }]
                )}
                {LeftNavLink(
                    urlSlug + '/messages',
                    'far fa-envelope',
                    'Messages'
                )}
                {LeftNavLink(
                    urlSlug + '/billing',
                    'fas fa-file-invoice-dollar',
                    'Billing'
                )}
                {LeftNavLink(urlSlug + '/settings', 'fas fa-gear', 'Settings')}
                {LeftNavLink(
                    urlSlug + '/help',
                    'fas fa-circle-question',
                    'Help'
                )}
            </ul>
        </Fragment>
    );
};

ParentLeftNav.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(ParentLeftNav);
