// PRIVATE ROUTE: ADMIN LEVEL
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getSchoolPic,
    findStrongestRoleFromSchool,
} from '../../../utils/helpers';
import { switchView } from '../../../storedata/actions/auth';

const SchoolTile = ({
    auth,
    switchView,
    school,
    editAs,
    onMainPage = false,
}) => {
    let history = useNavigate();

    return (
        <div className='school-tile'>
            <div className='school-tile-header'>
                {onMainPage ? (
                    <h1 className='warm'>{school.name}</h1>
                ) : (
                    <h2 className='warm'>
                        {/* <Link to={`/${editAs}/${school.urlslug}`}> */}
                        {school.name}
                        {/* </Link> */}
                    </h2>
                )}
                <div className='gridcol'>
                    {!onMainPage && editAs !== 'superadmin' && (
                        <button
                            className='btn'
                            onClick={(e) => {
                                e.preventDefault();
                                let role = findStrongestRoleFromSchool(
                                    school._id,
                                    auth.roles
                                );

                                switchView(role, school);
                                history(`/${editAs}/${school.urlslug}`);
                            }}
                        >
                            View
                        </button>
                    )}
                    {editAs !== 'superadmin' && (
                        <Link
                            to={`/${editAs}/${school.urlslug}/editschool/${school._id}`}
                            className='btn'
                        >
                            Edit
                        </Link>
                    )}
                </div>
            </div>
            <div className='school-tile-body'>
                <div>
                    <img
                        className='round-img-avg'
                        src={getSchoolPic(school.logo)}
                        alt={school.name}
                    />
                </div>
                <div>
                    {school.isApproved ? (
                        <div className='badge successbadge mb-1 mr-1'>
                            <i className='fas fa-check'></i> Approved
                        </div>
                    ) : (
                        <div className='badge errorbadge mb-1 mr-1'>
                            <i className='fas fa-x'></i> Pending Approval
                        </div>
                    )}

                    {school.isActive ? (
                        // <div className='badge successbadge mb-1 mr-1'>
                        //     <i className='fas fa-check'></i> Active
                        // </div>
                        <span></span>
                    ) : (
                        <div className='badge errorbadge mb-1 mr-1'>
                            <i className='fas fa-x'></i> Deactivated
                        </div>
                    )}

                    {/* {school.isPublished ? (
                        <div className='badge successbadge mb-1'>
                            <i className='fas fa-check'></i> Publicly Visible
                        </div>
                    ) : (
                        <div className='badge errorbadge mb-1'>
                            <i className='fas fa-x'></i> Draft
                        </div>
                    )} */}

                    <p>
                        <small>Address</small>
                    </p>
                    <p>
                        {school.address.line1 && (
                            <span>{school.address.line1}, </span>
                        )}
                        {school.address.line2 && (
                            <span> {school.address.line2}, </span>
                        )}
                        {school.address.city && (
                            <span>
                                {school.address.city},{' '}
                                {school.address.region && (
                                    <span>{school.address.region}</span>
                                )}
                            </span>
                        )}

                        {school.address.postalCode && (
                            <span> {school.address.postalCode}</span>
                        )}
                        {school.address.country && (
                            <span>, {school.address.country}</span>
                        )}
                    </p>
                </div>
                <div>
                    <p>
                        <small>Registered On</small>
                    </p>
                    {school.createdAt && (
                        <p>
                            {new Date(school.createdAt).getMonth() + 1}/
                            {new Date(school.createdAt).getDate()}/
                            {new Date(school.createdAt).getFullYear()}
                        </p>
                    )}
                    <p className='mt'>
                        <small>Access Code</small>
                    </p>
                    {school.accessCode && <p>{school.accessCode}</p>}
                </div>
            </div>
        </div>
    );
};
SchoolTile.propTypes = {
    auth: PropTypes.object.isRequired,
    switchView: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { switchView })(SchoolTile);
