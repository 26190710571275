import axios from 'axios';
import store from '../store';
import { LOGOUT } from '../storedata/actions/types';
import { TOKEN_INVALID_STR } from '../utils/constants';

const upload_api = axios.create({
    baseURL: '/api',
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});

upload_api.interceptors.response.use(
    (res) => res,
    (err) => {
        if (err.response.data.msg === TOKEN_INVALID_STR) {
            // Token invalid: log out
            store.dispatch({ type: LOGOUT });
        }
        return Promise.reject(err);
    }
);

export default upload_api;
