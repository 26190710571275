import api from '../../utils/api';
import upload_api from '../../utils/upload_api';
import { setAlert } from './alert';
import {
    STAFF_MEMBERS_LOADED,
    LOADING_STAFF,
    STUDENTS_LOADED,
    ADMIN_ERROR,
    LOADING_STUDENT,
    GET_STUDENT,
    CLEAR_STUDENT,
    LOADING_STUDENT_PIC,
    UPDATE_STUDENT_PIC,
    DONE_LOADING_STUDENT_PIC,
    CONTACTS_LOADED,
    LOADING_CONTACT,
    GET_CONTACT,
    CLEAR_CONTACT,
    LOADING_CONTACT_PIC,
    DONE_LOADING_CONTACT_PIC,
    UPDATE_CONTACT_PIC,
    LOADING_TERM,
    CLEAR_TERM,
    TERMS_LOADED,
    GET_TERM,
    LOADING_SCHOOL,
    GET_SCHOOL,
    UPDATE_SCHOOL_PIC,
    LOADING_SCHOOL_PIC,
    DONE_LOADING_SCHOOL_PIC,
    SCHOOLS_FOR_USER_LOADED,
    GET_SCHOOL_ATTENDANCE,
    LOADING_SCHOOL_ATTENDANCE,
    SCHOOL_ATTENDANCE_LOADED,
    GRADE_LEVELS_LOADED,
    LOADING_GRADE_LEVEL,
    GET_GRADE_LEVEL,
    CLEAR_GRADE_LEVEL,
    DELETE_GRADE_LEVEL,
    LOADING_FEE,
    FEES_LOADED,
    GET_FEE,
    CLEAR_FEE,
    CLEAR_FEES,
    DELETE_FEE,
} from './types';
import actionErrorHandler from './helpers/actionErrorHandler';

export const getAllGradeLevels = () => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_GRADE_LEVEL,
        });

        const res = await api.get(`/admin/allgradelevels`);

        let levels = res.data;

        dispatch({
            type: GRADE_LEVELS_LOADED,
            payload: levels,
        });

        return true;
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
        return false;
    }
};

export const getAllStaff = () => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_STAFF,
        });

        const res = await api.get(`/admin/allstaff`);

        let staff = res.data;

        dispatch({
            type: STAFF_MEMBERS_LOADED,
            payload: staff,
        });

        return true;
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
        return false;
    }
};

export const getAllContacts = () => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_CONTACT,
        });
        const res = await api.get(`/admin/allcontacts`);

        let contacts = res.data;

        dispatch({
            type: CONTACTS_LOADED,
            payload: contacts,
        });

        return true;
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
        return false;
    }
};

export const getAllStudents = () => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_STUDENT,
        });
        const res = await api.get(`/admin/allstudents`);

        let students = res.data;

        dispatch({
            type: STUDENTS_LOADED,
            payload: students,
        });

        return true;
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
        return false;
    }
};

// gets all schools for the currently viewed-as useer
export const getAllSchoolsForUser = () => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_SCHOOL,
        });
        const res = await api.get(`/admin/allschoolsforuser`);

        let schools = res.data;

        dispatch({
            type: SCHOOLS_FOR_USER_LOADED,
            payload: schools,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};

export const getFeeById = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_FEE });
        const res = await api.get(`/admin/fees/${id}`);

        dispatch({
            type: GET_FEE,
            payload: res.data,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};

export const deleteFee = (id) => async (dispatch) => {
    try {
        const res = await api.delete(`/admin/fees/${id}`);

        dispatch({
            type: DELETE_FEE,
            payload: res.data /* deleted fee */,
        });

        dispatch(
            setAlert(
                'Fee deleted',
                'success',
                true /* persist on page changes */
            )
        );
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};

// create or update fee. relocate is whether to load new page upon save (e.g. when not creating in a modal)
export const updateFee =
    (formData, history, location, relocate, relocateTo, id) =>
    async (dispatch) => {
        try {
            dispatch({
                type: LOADING_FEE,
            });

            const res = id
                ? await api.post(`/admin/fees/${id}`, formData)
                : await api.post('/admin/fees/create', formData);

            let payload = res.data;

            dispatch({
                type: GET_FEE,
                payload,
            });

            dispatch(
                setAlert(
                    'Fee saved',
                    'success',
                    true // persist on page changes
                )
            );

            // if a specific redirect url was given (used for modals that have a unique URL route)
            if (relocateTo) {
                history(relocateTo);
            } else if (!id && relocate) {
                let path = location.pathname.replace(
                    'addfee',
                    'editfee/' + payload._id
                );

                history(path);
            }

            return {
                msg: 'Fee saved.',
                success: true,
            };
        } catch (err) {
            let errmsg = actionErrorHandler(dispatch, err, ADMIN_ERROR);

            return {
                msg: errmsg,
                success: false,
            };
        }
    };

export const getAllFeesForTerm = (id) => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_FEE,
        });
        const res = await api.get(`/admin/allfees/${id}`);
        let payload = res.data;

        dispatch({
            type: FEES_LOADED,
            payload,
        });

        return true;
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
        return false;
    }
};

export const getAllFees = () => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_FEE,
        });
        const res = await api.get(`/admin/allfees`);
        let payload = res.data;

        dispatch({
            type: FEES_LOADED,
            payload,
        });

        return true;
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
        return false;
    }
};

export const clearFee = () => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_FEE });
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};

export const clearFees = () => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_FEES });
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};

export const getGradeLevelById = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_GRADE_LEVEL });
        const res = await api.get(`/admin/gradelevels/${id}`);

        dispatch({
            type: GET_GRADE_LEVEL,
            payload: res.data,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};

export const deleteGradeLevel = (id) => async (dispatch) => {
    try {
        const res = await api.delete(`/admin/gradelevels/${id}`);

        if (res.data.success) {
            dispatch({
                type: DELETE_GRADE_LEVEL,
                payload:
                    res.data.level /* payload has msg, success, and level */,
            });

            dispatch(setAlert('Grade level successfully deleted', 'success'));

            return {
                msg: res.data.msg,
                success: true,
            };
        } else {
            dispatch(setAlert(res.data.msg, 'danger', true));

            return {
                msg: res.data.msg,
                success: false,
            };
        }
    } catch (err) {
        let errmsg = actionErrorHandler(dispatch, err, ADMIN_ERROR);

        return {
            msg: errmsg,
            success: false,
        };
    }
};

// create or update level. relocate is whether to load new page upon save (e.g. when not creating in a modal)
export const updateGradeLevel =
    (formData, history, location, relocate, relocateTo, levelid) =>
    async (dispatch) => {
        try {
            dispatch({
                type: LOADING_GRADE_LEVEL,
            });

            const res = levelid
                ? await api.post(`/admin/gradelevels/${levelid}`, formData)
                : await api.post('/admin/gradelevels/create', formData);

            let level = res.data.level;

            dispatch({
                type: GET_GRADE_LEVEL,
                payload: level,
            });

            dispatch(
                setAlert(
                    'Grade level saved',
                    'success',
                    true // persist on page changes
                )
            );

            // if a specific redirect url was given (used for modals that have a unique URL route)
            if (relocateTo) {
                history(relocateTo);
            } else if (!levelid && relocate) {
                let path = location.pathname.replace(
                    'addgradelevel',
                    'editgradelevel/' + level._id
                );

                history(path);
            }

            return {
                msg: 'Grade level saved.',
                success: true,
            };
        } catch (err) {
            let errmsg = actionErrorHandler(dispatch, err, ADMIN_ERROR);

            return {
                msg: errmsg,
                success: false,
            };
        }
    };

export const getStudentById = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_STUDENT });
        const res = await api.get(`/admin/students/${id}`);

        dispatch({
            type: GET_STUDENT,
            payload: res.data,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};

// create or update student
export const updateStudent =
    (formData, history, location, relocate, relocateTo, studentid) =>
    async (dispatch) => {
        try {
            dispatch({
                type: LOADING_STUDENT,
            });

            const res =
                studentid !== undefined
                    ? await api.post(`/admin/students/${studentid}`, formData)
                    : await api.post('/admin/students/create', formData);

            let student = res.data;

            dispatch({
                type: GET_STUDENT,
                payload: student,
            });

            dispatch(
                setAlert(
                    'Student saved',
                    'success',
                    true // persist on page changes
                )
            );

            // if a specific redirect url was given (used for modals that have a unique URL route)
            if (relocateTo) {
                history(relocateTo);
            } else if (!studentid && relocate) {
                let path = location.pathname.replace(
                    'addstudent',
                    'editstudent/' + student._id
                );

                history(path, {
                    replace: true,
                    isActive: true,
                });
            }

            return {
                msg: 'Student saved.',
                success: true,
            };
        } catch (err) {
            let errmsg = actionErrorHandler(dispatch, err, ADMIN_ERROR);

            return {
                msg: errmsg,
                success: false,
            };
        }
    };

// update student picture
export const uploadStudentPic = (file, studentID) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_STUDENT_PIC });

        // formData stores file
        const res = await upload_api.post(
            `/admin/students/uploadmedia/${studentID}`,
            file
        );

        dispatch({
            type: UPDATE_STUDENT_PIC,
            payload: res.data, // photo obj
        });

        dispatch(
            setAlert(
                'Student photo updated',
                'success',
                true /* persist on page changes */
            )
        );
    } catch (err) {
        actionErrorHandler(dispatch, err, DONE_LOADING_STUDENT_PIC);
    }
};

// update school picture
export const uploadSchoolPic = (file, schoolID) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_SCHOOL_PIC });

        // formData stores file
        const res = await upload_api.post(
            `/admin/schools/uploadmedia/${schoolID}`,
            file
        );

        dispatch({
            type: UPDATE_SCHOOL_PIC,
            payload: res.data, // photo obj
        });

        dispatch(
            setAlert(
                'School photo updated',
                'success',
                true /* persist on page changes */
            )
        );
    } catch (err) {
        actionErrorHandler(dispatch, err, DONE_LOADING_SCHOOL_PIC);
    }
};

// create or update contact. relocate is whether to load new page upon save (e.g. when not creating in a modal)
export const updateContact =
    (formData, history, location, relocate, relocateTo, contactid) =>
    async (dispatch) => {
        try {
            dispatch({
                type: LOADING_CONTACT,
            });

            const res = contactid
                ? await api.post(`/admin/contacts/${contactid}`, formData)
                : await api.post('/admin/contacts/create', formData);

            let contact = res.data;
            dispatch({
                type: GET_CONTACT,
                payload: contact,
            });

            dispatch(
                setAlert(
                    'Contact saved',
                    'success',
                    true // persist on page changes
                )
            );

            // if a specific redirect url was given (used for modals that have a unique URL route)
            if (relocateTo) {
                history(relocateTo);
            } else if (!contactid && relocate) {
                let path = location.pathname.replace(
                    'addcontact',
                    'editcontact/' + contact._id
                );

                // note: redirecting in an action is different from redirecting in a component where Redirect can be used. that's why history is used here
                history(path);
            }

            return {
                msg: 'Contact saved.',
                success: true,
            };
        } catch (err) {
            let errmsg = actionErrorHandler(dispatch, err, ADMIN_ERROR);

            return {
                msg: errmsg,
                success: false,
            };
        }
    };

export const getContactById = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_CONTACT });
        const res = await api.get(`/admin/contacts/${id}`);
        let contact = res.data;

        dispatch({
            type: GET_CONTACT,
            payload: contact,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};

// update contact picture
export const uploadContactPic = (file, contactID) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_CONTACT_PIC });

        // formData stores file
        const res = await upload_api.post(
            `/admin/contacts/uploadmedia/${contactID}`,
            file
        );

        dispatch({
            type: UPDATE_CONTACT_PIC,
            payload: res.data, // photo obj
        });

        dispatch(
            setAlert(
                'Contact photo updated',
                'success',
                true /* persist on page changes */
            )
        );
    } catch (err) {
        actionErrorHandler(dispatch, err, DONE_LOADING_CONTACT_PIC);
    }
};

export const clearContact = () => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_CONTACT });
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};

export const getAllTerms = () => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_TERM,
        });

        const res = await api.get(`/admin/allterms`);

        let terms = res.data;

        dispatch({
            type: TERMS_LOADED,
            payload: terms,
        });

        return true;
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
        return false;
    }
};

export const getTermById = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_TERM });

        const res = await api.get(`/admin/terms/${id}`);

        dispatch({
            type: GET_TERM,
            payload: res.data,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};

export const clearTerm = () => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_TERM });
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};

export const clearStudent = () => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_STUDENT });
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};

export const clearGradeLevel = () => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_GRADE_LEVEL });
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};

// create or update term
export const updateTerm =
    (formData, history, location, relocate, relocateTo, termid) =>
    async (dispatch) => {
        try {
            dispatch({
                type: LOADING_TERM,
            });

            const res =
                termid !== undefined
                    ? await api.post(`/admin/terms/${termid}`, formData)
                    : await api.post('/admin/terms/create', formData);

            let term = res.data;

            dispatch({
                type: GET_TERM,
                payload: term,
            });

            dispatch(
                setAlert(
                    'Term saved',
                    'success',
                    true // persist on page changes
                )
            );

            // if a specific redirect url was given (used for modals that have a unique URL route)
            if (relocateTo) {
                history(relocateTo);
            } else if (!termid && relocate) {
                let path = location.pathname.replace(
                    'addterm',
                    'editterm/' + term._id
                );

                // note: redirecting in an action is different from redirecting in a component where Redirect can be used. that's why history is used here
                history(path);
            }

            return {
                msg: 'Term saved.',
                success: true,
            };
        } catch (err) {
            let errmsg = actionErrorHandler(dispatch, err, ADMIN_ERROR, true);

            return {
                msg: errmsg,
                success: false,
            };
        }
    };

export const updateRegistration =
    (formData /*history, location, relocate, relocateTo */, termid) =>
    async (dispatch) => {
        try {
            dispatch({
                type: LOADING_TERM,
            });

            const res = await api.post(
                `/admin/terms/${termid}/register`,
                formData
            );
            let reg = res.data;

            dispatch({
                type: GET_TERM,
                payload: reg,
            });

            dispatch(
                setAlert(
                    'Term registration saved',
                    'success',
                    true // persist on page changes
                )
            );

            // if a specific redirect url was given (used for modals that have a unique URL route)
            // if (relocateTo) {
            //     history(relocateTo);
            // } else if (!studentid && relocate) {
            //     let path = location.pathname.replace(
            //         'addregistration',
            //         'editregistration/' + reg._id
            //     );

            //     history(path);
            // }

            return {
                msg: 'Registration saved.',
                success: true,
            };
        } catch (err) {
            let errmsg = actionErrorHandler(dispatch, err, ADMIN_ERROR, true);

            return {
                msg: errmsg,
                success: false,
            };
        }
    };

export const getSchoolById = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_SCHOOL });
        const res = await api.get(`/admin/schools/${id}`);

        dispatch({
            type: GET_SCHOOL,
            payload: res.data,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};

// create or update school
export const updateSchool = (formData, schoolid) => async (dispatch) => {
    try {
        dispatch({
            type: LOADING_SCHOOL,
        });

        const res = await api.post(`/admin/schools/${schoolid}`, formData);

        let school = res.data;

        dispatch({
            type: GET_SCHOOL,
            payload: school,
        });

        dispatch(
            setAlert(
                'School saved',
                'success',
                true // persist on page changes
            )
        );
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};

const getDateForURL = (date) => {
    let formattedDate =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0');

    return formattedDate;
};

export const getSchoolAttendanceByDate = (date, termid) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_SCHOOL_ATTENDANCE });
        const res = await api.get(
            `/admin/schoolattendance/${termid}/${getDateForURL(date)}`
        );

        dispatch({
            type: GET_SCHOOL_ATTENDANCE,
            payload: res.data,
        });

        return true;
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
        return false;
    }
};

export const updateSchoolAttendance =
    (formData, date, studentid) => async (dispatch) => {
        try {
            dispatch({ type: LOADING_SCHOOL_ATTENDANCE });

            const res = await api.post(
                `/admin/schoolattendance/${getDateForURL(date)}/${studentid}`,
                formData
            );

            // format: {
            //     msg: 'Attendance successfully updated.',
            //     success: true,
            //     attendance,
            // }

            dispatch({
                type: SCHOOL_ATTENDANCE_LOADED,
                payload: res.data.attendance,
            });

            dispatch(
                setAlert(
                    res.data.msg,
                    'success',
                    true // persist on page changes
                )
            );

            return {
                msg: res.data.msg,
                success: res.data.success,
            };
        } catch (err) {
            let errmsg = actionErrorHandler(dispatch, err, ADMIN_ERROR);

            return {
                msg: errmsg,
                success: false,
            };
        }
    };
