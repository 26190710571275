import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import LeftNavLink from '../../layout/LeftNavLink';
import LeftNavHomeSection from '../LeftNavHomeSection';
import SchoolsWidget from '../SchoolsWidget';
import LeftNavFooter from '../LeftNavFooter';

const baseurl = '/admin';

const SchoolAdminLeftNav = ({ school, params }) => {
    let urlSlug = baseurl + '/' + school.urlslug;
    params = params || {};

    let studentpath = urlSlug + '/students';
    if (studentpath in params) {
        let studentparams = params[studentpath];
        studentpath += studentparams;
    }

    return (
        <Fragment>
            <LeftNavHomeSection baseurl={baseurl} />

            <SchoolsWidget baseurl={baseurl} />
            <ul>
                {LeftNavLink(
                    urlSlug + '/overview',
                    'fas fa-school',
                    'Overview',
                    [
                        { link: urlSlug, exact: true },
                        { link: urlSlug + '/editschool', exact: false },
                        { link: urlSlug + '/addgradelevel' },
                    ]
                )}

                {LeftNavLink(studentpath, 'fas fa-graduation-cap', 'Students', [
                    { link: urlSlug + '/addstudent', exact: false },
                    { link: urlSlug + '/editstudent', exact: false },
                    { link: urlSlug + '/student', exact: false },
                ])}

                {LeftNavLink(
                    urlSlug + '/gradelevels',
                    'fas fa-stairs',
                    'Grade Levels',
                    [
                        {
                            link: urlSlug + '/gradelevels/addgradelevel',
                            exact: true,
                        },
                        {
                            link: urlSlug + '/gradelevels/editgradelevel',
                            exact: false,
                        },
                    ]
                )}

                {LeftNavLink(urlSlug + '/terms', 'fas fa-calendar', 'Terms', [
                    { link: urlSlug + '/terms', exact: false },
                    { link: urlSlug + '/terms/editterm', exact: false },
                ])}

                {LeftNavLink(
                    urlSlug + '/contacts',
                    'fas fa-address-book',
                    'Contacts'
                )}
                {LeftNavLink(
                    urlSlug + '/staff',
                    'fas fa-people-group',
                    'Staff Members'
                )}
                {/* {LeftNavLink(
                    urlSlug + '/messages',
                    'far fa-envelope',
                    'Messages'
                )} */}
                {/* {LeftNavLink(
                    urlSlug + '/billing',
                    'fas fa-file-invoice-dollar',
                    'Billing'
                )} */}
                {/* {LeftNavLink(urlSlug + '/settings', 'fas fa-gear', 'Settings')}
                {LeftNavLink(
                    urlSlug + '/help',
                    'fas fa-circle-question',
                    'Help'
                )} */}
            </ul>

            <LeftNavFooter />
        </Fragment>
    );
};

SchoolAdminLeftNav.propTypes = {
    school: PropTypes.object.isRequired,
};

export default SchoolAdminLeftNav;
