// PRIVATE ROUTE: logged in user (/account)
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import BaseFrame from '../../BaseFrame';
import Spinner from '../../../layout/Spinner';
import PersonalInfo from '../../PersonalInfo';
import {
    updateInfo,
    resendVerificationEmail,
} from '../../../../storedata/actions/auth';
// import { deleteAccount } from '../../actions/profile';
import UploadProfilePic from '../../UploadProfilePic';

const initialState = {
    nameInfo: {
        // firstName: '',
        // middleName: '',
        // lastName: '',
        // namePrefix: 1,
        // nameSuffix: '',
    },
    // currentpassword: '',
    address: {
        // postalCode,line1,line2,country,city,region
    },
    email: '',
    phone: '',
    password: '',
    newpassword1: '',
    newpassword2: '',
};

const UpdateAccount = ({
    updateInfo,
    resendVerificationEmail,
    // deleteAccount,
    auth: { loading, viewingAs, verifying_email },
}) => {
    const [formData, setFormData] = useState(initialState);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [message, updateMessage] = useState({
        msg: 'Email sent! Please check your email for the verification link.',
        success: true,
    });

    useEffect(() => {
        if (!loading && viewingAs?.user) {
            let formData = { ...initialState };
            formData.nameInfo.firstName =
                viewingAs.user.nameInfo.firstName || '';
            formData.nameInfo.middleName =
                viewingAs.user.nameInfo.middleName || '';
            formData.nameInfo.lastName =
                viewingAs.user.nameInfo?.lastName || '';
            formData.nameInfo.namePrefix =
                viewingAs.user.nameInfo?.namePrefix || 1;
            formData.nameInfo.nameSuffix =
                viewingAs.user.nameInfo?.nameSuffix || '';
            formData.email = viewingAs.user.email;
            formData.phone = viewingAs.user.phone || '';
            formData.address.country = viewingAs.user.address?.country || '';
            formData.address.line1 = viewingAs.user.address?.line1 || '';
            formData.address.line2 = viewingAs.user.address?.line2 || '';
            formData.address.city = viewingAs.user.address?.city || '';
            formData.address.region = viewingAs.user.address?.region || '';
            formData.address.postalCode =
                viewingAs.user.address?.postalCode || '';
            // formData.password = viewingAs.user.password || '';
            setFormData(formData);
        }
    }, [loading, viewingAs.user]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of pg on mount
    }, []);

    const onChange = (dataobj) => {
        let nameitems = [
            'namePrefix',
            'nameSuffix',
            'firstName',
            'middleName',
            'lastName',
            'nickname',
        ];

        let addritems = [
            'line1',
            'line2',
            'country',
            'city',
            'region',
            'postalCode',
        ];

        if (nameitems.includes(dataobj.name)) {
            setFormData({
                ...formData,
                nameInfo: {
                    ...formData.nameInfo,
                    [dataobj.name]: dataobj.value,
                },
            });
        } else if (addritems.includes(dataobj.name)) {
            setFormData({
                ...formData,
                address: {
                    ...formData.address,
                    [dataobj.name]: dataobj.value,
                },
            });
        } else setFormData({ ...formData, [dataobj.name]: dataobj.value });
    };

    const onSubmit = async (e) => {
        // @todo async needed here?
        e.preventDefault(); // don't let browser do its default behavior

        updateInfo(formData);
    };

    const resendEmailVerification = (e) => {
        e.preventDefault(); // don't submit form

        resendVerificationEmail(viewingAs.user._id)
            .then(function (result) {
                updateMessage(result);
            })
            .catch(function (err) {
                updateMessage({
                    msg: 'Error verifying email address.',
                    success: false,
                });
            });
        setButtonClicked(true);
    };

    // const deleteAcct = (e) => {
    //     e.preventDefault(); // don't submit form
    //     deleteAccount(formData, history);
    // };

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | My Account</title>
            </Helmet>

            <BaseFrame>
                {loading ? (
                    <div className='containerinnersolid'>
                        <Spinner />
                    </div>
                ) : (
                    <Fragment>
                        <h1 className='mb-2'>My Account</h1>
                        <div className='containerinnersolid'>
                            {!viewingAs.user.emailVerified ? (
                                <Fragment>
                                    <p className='mb-1'>
                                        To update your account, please first
                                        verify your email address:
                                    </p>
                                    <div className='mb-2'>
                                        <button
                                            type='button'
                                            className='btn btn-warning'
                                            onClick={(e) =>
                                                resendEmailVerification(e)
                                            }
                                        >
                                            {buttonClicked ? 'Resend' : 'Send'}{' '}
                                            Email Verification Code
                                        </button>
                                    </div>
                                    {verifying_email ? (
                                        <Spinner />
                                    ) : buttonClicked ? (
                                        <div
                                            className={`inline-notification ${
                                                message?.success
                                                    ? ''
                                                    : 'notification-error'
                                            } mb-0`}
                                        >
                                            <i
                                                className={`${
                                                    message?.success
                                                        ? 'fas fa-check'
                                                        : 'far fa-circle-xmark'
                                                }`}
                                            />
                                            <span className='ml-1'>
                                                {message?.msg}
                                            </span>
                                        </div>
                                    ) : null}
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <UploadProfilePic />
                                    <p className='italicsmsg m0 pt-0'>
                                        * = required field
                                    </p>

                                    <form
                                        className='form bigwarmhug'
                                        onSubmit={onSubmit}
                                    >
                                        <PersonalInfo
                                            onChangeHandler={(data) =>
                                                onChange(data)
                                            }
                                            data={formData}
                                            showMiddleName={true}
                                            showNameSuffix={true}
                                            showChangePassword={true}
                                        />

                                        <div className='admin-form-footer'>
                                            {/* <button
                                        className='btn btn-danger my-1'
                                        onClick={deleteAcct}
                                    >
                                        <i className='fas fa-user-minus' /> Delete
                                        My Account
                                    </button> */}
                                            <input
                                                type='submit'
                                                value='Save'
                                                className='btn btn-primary my-1 mr-0'
                                            />
                                        </div>
                                    </form>
                                </Fragment>
                            )}
                        </div>
                    </Fragment>
                )}
            </BaseFrame>
        </Fragment>
    );
};

UpdateAccount.propTypes = {
    auth: PropTypes.object.isRequired,
    // deleteAccount: PropTypes.func.isRequired,
    updateInfo: PropTypes.func.isRequired,
    resendVerificationEmail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {
    // deleteAccount,
    updateInfo,
    resendVerificationEmail,
})(UpdateAccount);
