import React from 'react';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';

const NotFound = () => {
    return (
        <div className='containermiddle center m-3'>
            <Helmet>
                <title>{APP_NAME} | 404: Page Not Found</title>
            </Helmet>
            <div>
                <h1 className='mb-1'>
                    <i className='fas fa-exclamation-triangle' /> Off the beaten
                    path!
                </h1>
                <h2>Sorry, this page does not exist</h2>
            </div>
        </div>
    );
};

export default NotFound;
