import React, { Fragment } from 'react';
import SuperadminUserWidget from './SuperadminUserWidget';
import LogoTitle from '../../layout/LogoTitle';
import { getUserPic, getUserName } from '../../../utils/helpers';

const SuperadminHeader = ({ viewingAs, user }) => {
    return (
        <Fragment>
            <div className='adminnavbar'>
                <div className='left'>
                    <LogoTitle />
                </div>
                <div className='right'>
                    <div className='namesection'>
                        <img
                            className='round-img-xsml'
                            src={getUserPic(user.photo)}
                            alt='reviewer'
                        />

                        <div className='username'>{getUserName(user)}</div>
                        <div className='badge superadminbadge'>
                            <i className='far fa-sun'></i> Superadmin
                        </div>
                    </div>

                    <SuperadminUserWidget />
                </div>
            </div>
            {/* {user._id !== viewingAs.user._id && (
                <div className='previewnotification'>Previewing account</div>
            )} */}
        </Fragment>
    );
};

// SuperadminHeader.propTypes = {
//     auth: PropTypes.object.isRequired,
// };

// const mapStateToProps = (state) => ({
//     auth: state.auth,
// });

export default SuperadminHeader;
