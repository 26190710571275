import React, { Fragment, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import PropTypes from 'prop-types';
import LandingHeader from './LandingHeader';
import Footer from './Footer';
import PlanTile from './PlanTile';
import adminpic from '../../img/admingraphic.png';
import parentpic from '../../img/parentgraphic.png';

const Landing = ({ auth }) => {
    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    if (!auth.loading && auth.isAuthenticated) return <Navigate to='/' />;
    else
        return (
            <Fragment>
                <Helmet>
                    <title>{APP_NAME} | Home</title>
                </Helmet>

                <div className='landingbg'>
                    <LandingHeader hasButtons={true} />
                    <div className='margin-sides-wrapper'>
                        <section className='landing'>
                            <h1 className='headline'>
                                A simple way to keep track of students and
                                classes
                            </h1>
                            <h3 className='subtitle'>
                                <span className='block'>
                                    Whether you're a school staff member or
                                    parent, there's a place for you at{' '}
                                    {APP_NAME}.
                                </span>
                                <span className='block'>
                                    Track student progress from daycare through
                                    high school in our centralized, easy-to-use
                                    system.
                                </span>
                            </h3>

                            {!auth.loading && !auth.isAuthenticated && (
                                <Link
                                    to='/register'
                                    className='btn btn-primary btn-large'
                                >
                                    Get Started{' '}
                                    <i className='fas fa-arrow-right' />
                                </Link>
                            )}
                        </section>

                        <section className='row-split-evenly even2split mt'>
                            <section className='containerinner containerinnersolid'>
                                <div className='landingtile'>
                                    <div className='row-split-evenly'>
                                        <div className='adminpicbg'>
                                            <img
                                                src={adminpic}
                                                alt='Register your school'
                                            />
                                        </div>
                                        <div>
                                            <h1 className='title'>
                                                For School Staff
                                            </h1>
                                            <h2>Register your school</h2>
                                            <p>
                                                Start managing your day-to-day
                                                administrative tasks, from
                                                enrolling students and tracking
                                                their progress to creating
                                                courses and handling payments.
                                                Add instructors and other staff
                                                to help the team stay on track.
                                            </p>
                                            <div className='landingtilebottom'>
                                                <Link
                                                    to='/register/school'
                                                    className='btn'
                                                >
                                                    <span>
                                                        Register Your School
                                                    </span>{' '}
                                                    <i className='fas fa-arrow-right' />
                                                </Link>

                                                {!auth.loading &&
                                                    !auth.isAuthenticated && (
                                                        <Fragment>
                                                            {' '}
                                                            <span className='link-small'>
                                                                or
                                                            </span>
                                                            <Link
                                                                to='/login'
                                                                className='link-small'
                                                            >
                                                                Sign In
                                                            </Link>
                                                        </Fragment>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='containerinner containerinnersolid'>
                                <div className='landingtile'>
                                    <div className='row-split-evenly'>
                                        <div className='adminpicbg'>
                                            <img
                                                src={parentpic}
                                                alt='Register your school'
                                            />
                                        </div>
                                        <div>
                                            <h1 className='title'>
                                                For Parents
                                            </h1>
                                            <h2>
                                                Track your children’s progress
                                            </h2>
                                            <p>
                                                See what your children are
                                                learning and how they are
                                                developing via course summaries,
                                                grades, and daily reports.
                                                Communicate with their teachers
                                                and school administrators for
                                                the latest updates.
                                            </p>
                                            <div className='landingtilebottom'>
                                                <Link
                                                    to='/register'
                                                    className='btn'
                                                >
                                                    <span>
                                                        Join Your School
                                                    </span>{' '}
                                                    <i className='fas fa-arrow-right' />
                                                </Link>

                                                {!auth.loading &&
                                                    !auth.isAuthenticated && (
                                                        <Fragment>
                                                            {' '}
                                                            <span className='link-small'>
                                                                or
                                                            </span>
                                                            <Link
                                                                to='/login'
                                                                className='link-small'
                                                            >
                                                                Sign In
                                                            </Link>
                                                        </Fragment>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>

                        <section className='containerinner whiteshadowtile mt-3'>
                            <div className='landingtile'>
                                <h1 className='title'>School Pricing Plans</h1>

                                <p className='mb-1'>
                                    Register your school and choose a plan that
                                    is right for you. Parents can access the
                                    portal for free.
                                </p>
                                <div className='plantiles'>
                                    <PlanTile type={1} isSelectable={false} />
                                    <PlanTile type={2} isSelectable={false} />
                                    <PlanTile type={3} isSelectable={false} />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
};

Landing.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(Landing);
