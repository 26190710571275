import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Logo from '../../img/logo.svg';
import { APP_NAME } from '../../utils/constants';

const LogoTitle = ({ isAuthenticated }) => {
    return (
        <Link to={isAuthenticated ? '#' : '/'} className='logotitle'>
            <img src={Logo} alt='logo' className='logo-nav' />
            <div>
                {APP_NAME}
                <sup>SM</sup>
            </div>
        </Link>
    );
};

LogoTitle.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(LogoTitle);
