import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../../storedata/actions/alert';
import Modal from '../../layout/Modal';
import {
    updateFee,
    getFeeById,
    clearFee,
    getAllGradeLevels,
} from '../../../storedata/actions/admin';
import Spinner from '../../layout/Spinner';
import LabelWrapper from '../../layout/inputs/LabelWrapper';
import DatePicker from '../../layout/inputs/DatePicker';
import { getPrettyCost, getPrettyDate } from '../../../utils/helpers';
import Autocomplete from '../../layout/inputs/Autocomplete';

const numreg = /^\d+(\.\d{2})?$/; // /^\d+$/; money display only: 50, 104.50, etc (needs 2 decimals or none)

// Previous location is the page that this modal sits on top of
const AddTermFeeModal = ({
    term,
    feeID,
    auth: { loading },
    admin,
    updateFee,
    getFeeById,
    clearFee,
    getAllGradeLevels,
    setAlert,
    submitCallback,
    closeCallback,
}) => {
    let location = useLocation();
    let history = useNavigate();
    let bodyRef = useRef();
    let dateDivRef = useRef();
    let params = useParams();
    let feeid = params.feeid || feeID;

    const initialState = {
        term: term._id,
        name: '',
        description: '',
        isRequired: true,
        cost: 0,
        billingDate: new Date(),
        dueDate: '',
        student: '',
        gradeLevels: [],
    };

    const [feeData, setFeeData] = useState(initialState);
    const [showDatePicker, setShowDatePicker] = useState(false);

    useEffect(() => {
        if (feeid) {
            getFeeById(feeid);
        }
    }, [getFeeById, feeid]); // @todo8 fee not found

    useEffect(() => {
        if (admin.fee) {
            setFeeData((origData) => {
                return {
                    ...admin.fee,
                    cost: getPrettyCost(admin.fee.cost).replaceAll(',', ''),
                    billingDate: admin.fee.billingDate
                        ? new Date(admin.fee.billingDate)
                        : origData.billingDate,
                };
            });
        }

        if (!admin.grade_levels) {
            getAllGradeLevels();
        }
    }, [admin.fee, admin.grade_levels, getAllGradeLevels]);

    useEffect(() => {
        return function cleanup() {
            // behaves like componentWillUnmount
            clearFee();
        };
    }, [clearFee]);

    const onChange = (e) => {
        let feeitems = ['name', 'description', 'cost'];

        let value = e.target.value;
        const name = e.target.name;

        if (feeitems.includes(name)) {
            setFeeData({ ...feeData, [name]: value });
        }
    };

    const onChangeCost = (e) => {
        let value = e.target.value;

        if (e.target.name !== 'cost') return;

        value = value.replaceAll(',', '').replaceAll('$', '');

        if (value.length > 0 && !numreg.test(value)) {
            setAlert('Please enter a valid cost.', 'danger');
        } else {
            value = parseFloat(value).toFixed(2);
        }

        setFeeData({ ...feeData, cost: value });
    };

    const onChangeLevelAutocomplete = (e) => {
        let value = JSON.parse(e.target.value);

        let gradeLevels = value?.map((lev) => {
            return { name: lev.name, _id: lev._id };
        });

        setFeeData({ ...feeData, gradeLevels });
    };

    // const onChangeStudentAutocomplete = (e) => {
    //     let value = JSON.parse(e.target.value)[0]; // select one max
    //     value = value ? { name: value.name, _id: value._id } : '';

    //     setFeeData({ ...feeData, student: value });
    // };

    const updateRequired = () => {
        setFeeData({ ...feeData, isRequired: !feeData.isRequired });
    };

    const onSubmit = async (e) => {
        e.preventDefault(); // don't let browser do its default behavior

        if (
            !feeData.cost ||
            feeData.cost === '0.00' ||
            !numreg.test(feeData.cost)
        ) {
            setAlert('Please enter a valid cost.', 'danger');
            return;
        }

        updateFee(feeData, history, location, null, null, feeid)
            .then(function (result) {
                if (result.success && submitCallback) {
                    submitCallback(e, feeData);
                }
            })
            .catch(function (err) {});
    };

    let body = (
        <div ref={bodyRef}>
            {loading ||
            (feeid && (admin.loading_fee || !admin.grade_levels)) ? (
                <Spinner size={0} />
            ) : (
                <Fragment>
                    <div className='gridcol'>
                        <LabelWrapper title='Name'>
                            <input
                                type='text'
                                name='name'
                                value={feeData.name || initialState.name}
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                                required
                            />
                        </LabelWrapper>
                        <LabelWrapper title='Cost'>
                            <input
                                type='text'
                                name='cost'
                                value={feeData.cost || initialState.cost}
                                onChange={onChange}
                                onBlur={onChangeCost}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                                required
                            />
                        </LabelWrapper>

                        <div
                            className='single-checkbox-wrapper'
                            onClick={updateRequired}
                        >
                            <input
                                name='isRequired'
                                type='checkbox'
                                value={feeData.isRequired}
                                checked={feeData.isRequired}
                                onChange={updateRequired}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            />
                            <label>Fee is required</label>
                        </div>
                    </div>
                    <div className='gridcol'>
                        <LabelWrapper title='Description'>
                            <input
                                type='text'
                                name='description'
                                value={
                                    feeData.description ||
                                    initialState.description
                                }
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            />
                        </LabelWrapper>
                    </div>
                    <div>
                        <span className='form-header2 mb mt'>
                            Grade Level (optional)
                        </span>
                        <small className='form-text mb-1'>
                            Make this fee specific to particular grade levels.
                            If the fee applies to every registered student,
                            leave this blank.
                        </small>
                        <Autocomplete
                            cssID='glauto'
                            inputName='gradeLevels'
                            selectedData={
                                feeData.gradeLevels || []
                                // ?
                                // [
                                //       feeData.gradeLevel?.name ||
                                //           feeData.gradeLevel,
                                //   ]
                                // : []
                            }
                            placeholder='Select grade levels...'
                            onChangeHandler={onChangeLevelAutocomplete}
                            showAllOptions={true}
                            suggestions={
                                admin.grade_levels
                                // .map((l) => l.name)
                            }
                        />
                    </div>
                    <div>
                        <div className='form-header2 mb-1 mt-2'>
                            Billing Date
                        </div>
                        <span
                            ref={dateDivRef}
                            id='billdatecontrol'
                            className={
                                'datePickerOpener' +
                                (showDatePicker ? ' whitebg' : '')
                            }
                            onClick={() => {
                                let prevState = showDatePicker;

                                // if other picker is open, close it to prevent UI sizing glitches
                                if (!prevState) {
                                    setShowDatePicker(true);
                                }

                                // don't trigger a close w/a click; that happens in datepicker
                            }}
                        >
                            <i className='far fa-calendar'></i>{' '}
                            {feeData.billingDate ? (
                                getPrettyDate(feeData.billingDate)
                            ) : (
                                <span>Select date...</span>
                            )}
                        </span>

                        {showDatePicker && (
                            <DatePicker
                                cssID='dp-startdate'
                                allowSelectNone={false}
                                onChangeHandler={(
                                    e,
                                    selectedDates,
                                    lastClicked,
                                    closepopover /* true if blurred in popover mode */
                                ) => {
                                    if (closepopover && showDatePicker) {
                                        setShowDatePicker(false);
                                        if (!selectedDates) return; // closed w/o selecting dates
                                    }

                                    setFeeData({
                                        ...feeData,
                                        billingDate: selectedDates[0],
                                    });
                                    // also close once selected
                                    setShowDatePicker(false);
                                }}
                                selectedDateObjects={
                                    feeData.billingDate
                                        ? [feeData.billingDate]
                                        : []
                                }
                                selectOneMax={true}
                                size={0}
                                controlRef={dateDivRef}
                                wrapperRef={bodyRef}
                            />
                        )}
                    </div>
                </Fragment>
            )}
        </div>
    );

    let steps = [
        {
            body,
            nextStepOnClick: (e) => {
                onSubmit(e);
            },
            nextStepButtonTitle: 'Save',
            finalStepButtonIcon: 'fa fa-save',
        },
    ];

    return (
        <Modal
            steps={steps}
            hasCloseButton={true}
            closeButtonCallBack={closeCallback}
            showAsOverlay={true}
            modalTitle={(feeID ? 'Edit' : 'Add A') + ' Term Fee'}
            size={1}
        />
    );
};

AddTermFeeModal.propTypes = {
    setAlert: PropTypes.func.isRequired,
    updateFee: PropTypes.func.isRequired,
    clearFee: PropTypes.func.isRequired,
    getFeeById: PropTypes.func.isRequired,
    getAllGradeLevels: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    term: PropTypes.object.isRequired,
    feeID: PropTypes.string,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
});

export default connect(mapStateToProps, {
    setAlert,
    updateFee,
    clearFee,
    getFeeById,
    getAllGradeLevels,
})(AddTermFeeModal);
