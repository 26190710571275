// PRIVATE ROUTE: ADMIN LEVEL
import React, {
    Fragment,
    useEffect,
    useCallback,
    useState,
    useRef,
} from 'react';
import {
    useParams,
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import {
    getAllStudents,
    getAllGradeLevels,
    getAllContacts,
    getSchoolAttendanceByDate,
} from '../../../../storedata/actions/admin';
import { updateSearchParams } from '../../../../storedata/actions/auth';
import {
    getStudentPic,
    getPrettyDate,
    dateIsInTerm,
    getPrettyName,
    URLhelpers,
    storageItemIsTrue,
    toggleFullScreen,
} from '../../../../utils/helpers';
import Spinner from '../../../layout/Spinner';
import Searchbar from '../../../layout/Searchbar';
import DatePicker from '../../../layout/inputs/DatePicker';
import UpdateAttendanceModal from '../UpdateAttendanceModal';
import LabelWrapper from '../../../layout/inputs/LabelWrapper';
import AddTermModal from '../AddTermModal';
import ToggleSwitch from '../../../layout/inputs/ToggleSwitch.tsx';
import genericstudentpic from '../../../../img/generic_students.png';
import genericschoolpic from '../../../../img/generic_school.png';
// import SegmentedButton from '../../../layout/inputs/SegmentedButton';

// todo next: limit date range to days in term

const SchoolAdminSchoolTermAttendance = ({
    auth,
    admin,
    term,
    getAllStudents,
    getAllGradeLevels,
    getAllContacts,
    updateSearchParams,
    getSchoolAttendanceByDate,
}) => {
    let location = useLocation();
    let history = useNavigate();
    let params = useParams();
    let termid = params.termid;
    const [searchParams] = useSearchParams();
    // let ref0 = useRef();
    // let ref1 = useRef();
    // let ref2 = useRef();
    // let ref3 = useRef();
    // let ref4 = useRef();
    let dateDivRef = useRef();

    const [filteredData, setFilteredData] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const [viewedDate, setViewedDate] = useState(null);
    const [viewedLevel, setViewedLevel] = useState('');
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showAttModal, setShowAttModal] = useState(null);
    const [showTermModal, setShowTermModal] = useState(false);
    // const [showSpinner, setShowSpinner] = useState(false);
    // set attendanceAllowed to -1 to start so it can check if set yet when loading pg
    const [attendanceAllowed, setAttendanceAllowed] = useState(-1);
    const [isParentMode, setIsParentMode] = useState(
        storageItemIsTrue('parentMode')
    );

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    useEffect(() => {
        if (!admin.contacts) getAllContacts();
        if (!admin.grade_levels) getAllGradeLevels();
    }, [getAllContacts, admin.contacts, getAllGradeLevels, admin.grade_levels]);

    const updateDate = useCallback(
        (term) => {
            if (term && term.dates.length > 0) {
                let today = new Date();
                let d = dateIsInTerm(today, term)
                    ? today
                    : new Date(term.dates[0]);

                setViewedDate((v) =>
                    dateIsInTerm(new Date(v), term) ? new Date(v) : d
                );

                setViewedLevel(
                    URLhelpers.getURLParam('gl', location.search) || ''
                );
            }
        },
        [location.search]
    );

    // const switchSegment = () => {
    //     setFilteredData(filterData());
    // };

    const onChangeLevel = (e) => {
        let value = e.target.value;
        let gradeLevel = admin.grade_levels?.find((l) => {
            return l._id === value;
        });

        setViewedLevel(gradeLevel?._id || '');
        let searchParam = 'gl';
        let updatedurl;
        if (!value) {
            updatedurl = URLhelpers.deleteURLparam(
                history,
                location,
                searchParam
            );
        } else {
            var o = { [searchParam]: value };
            updatedurl = URLhelpers.setURLparam(history, location.search, o);
        }

        updateSearchParams(location.pathname, updatedurl);
    };

    // checks each set search param in URL and updates students accordingly
    // preassumptions: term.registeredStudents is an array (not null, undefined, or other)
    const filterData = useCallback(() => {
        let studentsToReturn = term?.registeredStudents
            ? [...term.registeredStudents]
            : [];
        let entries = searchParams.entries();

        for (const entry of entries) {
            const [param, value] = entry;

            // search bar
            if (param === 'ss') {
                studentsToReturn = [...studentsToReturn]?.filter((r) => {
                    let nameInfo = r.student.nameInfo;
                    let ssl = value.toLowerCase();

                    let namestr = getPrettyName(nameInfo);
                    let namestr2 = getPrettyName(nameInfo, false);

                    return (
                        namestr.toLowerCase().includes(ssl) ||
                        namestr2.toLowerCase().includes(ssl)
                    );
                });
            } else if (param === 'st') {
                // registration status
                studentsToReturn = [...studentsToReturn]?.filter((r) => {
                    let status = r.student.registrationStatus;
                    return status.toString() === value;
                });
            } else if (param === 'gl') {
                // grade level
                if (value === 'none') {
                    // unassigned
                    studentsToReturn = [...studentsToReturn]?.filter((r) => {
                        return (
                            r.gradeLevel === null || r.gradeLevel === undefined
                        );
                    });
                } else
                    studentsToReturn = [...studentsToReturn]?.filter((r) => {
                        return r.gradeLevel?._id === value;
                    });
            }
        }

        return studentsToReturn;
    }, [term, searchParams]);

    // ! secure login passwords, only. special char etc

    useEffect(() => {
        if (term && admin.grade_levels) {
            setFilteredData(filterData());
            updateDate(term, admin.grade_levels); /// !!!!! fix but where switching term glitches/reloads attendance a few times
        }
    }, [term, admin.grade_levels, filterData, updateDate]);

    useEffect(() => {
        if (!admin.students) getAllStudents();

        if (admin.students?.length > 0 && term) {
            if (term.registeredStudents?.length > 0) {
                // !!!! select current term by default? first one with today's date in term, after sorting terms by start date ascending.
                setAttendanceAllowed(1);
                setInitialized(true);
            } else {
                // no terms, so can't track attendance
                setAttendanceAllowed(0);
                setInitialized(true);
            }
        }
    }, [getAllStudents, admin.students, term]);

    useEffect(() => {
        if (viewedDate) {
            getSchoolAttendanceByDate(viewedDate, termid);
        }
    }, [getSchoolAttendanceByDate, viewedDate, termid]);

    const getHeaderCells = () => {
        return (
            <Fragment>
                <div className='attendance-header-cell'>Status</div>
                <div className='attendance-header-cell'>Name</div>
                <div className='attendance-header-cell'>Drop-Off Time</div>
                <div className='attendance-header-cell'>Dropped Off By</div>
                <div className='attendance-header-cell'>Pick-Up Time</div>
                <div className='attendance-header-cell'>Picked Up By</div>
                <div className='attendance-header-cell'></div>
            </Fragment>
        );
    };

    const getTime = (timeobj) => {
        if (!timeobj) return '—';

        let date = new Date(timeobj);
        let hours = date.getHours();
        let min = date.getMinutes().toString().padStart(2, '0');
        let period = hours >= 12 ? 'pm' : 'am';
        if (hours > 12) hours = Math.abs(date.getHours() - 12);
        else if (hours === 0) hours = 12;

        return hours + ':' + min + ' ' + period;
    };

    const getStudentAttendance = (studentid) => {
        if (!admin?.schoolattendancefordate) return;
        let attendanceForStudent = admin.schoolattendancefordate.find(
            (entry) => {
                return entry.student === studentid; // student not populated, so no ID field; student = ID
            }
        );

        return attendanceForStudent;
    };

    const getPersonName = (nameobj) => {
        let personID = nameobj?.personID;
        return personID
            ? getPrettyName(personID.nameInfo, false)
            : nameobj?.personString || '—';
    };

    const getAttendanceStatus = (studentid) => {
        let s = getStudentAttendance(studentid);

        if (!s || s.status === 0) return { title: 'Absent', class: 'absent' };
        else if (s.status === 1) return { title: 'Present', class: 'present' };
        else if (s.status === 2)
            return { title: 'Picked Up', class: 'pickedup' };
        else return { title: 'Absent', class: 'absent' };
    };

    const attendanceClicked = (student) => {
        setShowAttModal({
            student,
            attendance: getStudentAttendance(student._id) || 0,
            date: viewedDate,
            contacts: admin.contacts,
            currentTerm: term,
        });
    };

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | School Term Attendance</title>
            </Helmet>

            {admin.loading_term ? (
                <div className='containerinnersolid'>
                    <Spinner size={0} />
                </div>
            ) : (
                <Fragment>
                    <div className='split-2-header mb-2'>
                        <h2 className='mb-0'>
                            {filteredData?.length} Student
                            {filteredData?.length !== 1 && 's'}
                        </h2>

                        <div className='gridcol'>
                            {!admin.grade_levels ? null : (
                                <Fragment>
                                    {/* todo next: limit date range to days in term. */}

                                    <div>
                                        <LabelWrapper title='Grade Level'>
                                            <select
                                                name='gradeLevel'
                                                className='mb-0'
                                                value={viewedLevel}
                                                onChange={onChangeLevel}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            >
                                                <option value=''>All</option>
                                                <option value='none'>
                                                    Unassigned
                                                </option>
                                                {admin.grade_levels.map(
                                                    (level, i) => (
                                                        <option
                                                            value={level._id}
                                                            key={i}
                                                        >
                                                            {level.name}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </LabelWrapper>
                                    </div>
                                </Fragment>
                            )}
                            {!term || !viewedDate ? null : (
                                <div ref={dateDivRef}>
                                    <LabelWrapper title='Date'>
                                        <span
                                            className={
                                                'datePickerOpener' +
                                                (showDatePicker
                                                    ? ' whitebg'
                                                    : '')
                                            }
                                            onClick={() => {
                                                setShowDatePicker(
                                                    !showDatePicker
                                                );
                                            }}
                                        >
                                            <i className='far fa-calendar'></i>{' '}
                                            {getPrettyDate(viewedDate)}
                                        </span>
                                    </LabelWrapper>
                                </div>
                            )}

                            <div className='gridcol borderwrapper pr-6'>
                                <span>Parent Mode</span>

                                <ToggleSwitch
                                    checked={isParentMode}
                                    onChange={(v) => {
                                        setIsParentMode(v);

                                        if (v) {
                                            localStorage.setItem(
                                                'parentMode',
                                                location.pathname
                                            );
                                            toggleFullScreen();
                                        } else {
                                            localStorage.removeItem(
                                                'parentMode'
                                            );
                                            if (document.fullscreenElement) {
                                                document?.exitFullscreen();
                                            }
                                        }

                                        window.dispatchEvent(
                                            new Event('storage')
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='searchbar_and_filters'>
                        <Searchbar
                            autoSearch={true}
                            searchParam='ss'
                            searchName='students'
                        />

                        {/* <SegmentedButton
                            name='type'
                            callback={() => switchSegment()}
                            defaultIndex={0}
                            controlRef={ref3}
                            searchParam={'st'}
                            smallSize={true}
                            // 0: absent, 2: school staff, 3: parent, 4: student
                            segments={[
                                {
                                    label: 'All',
                                    value: '',
                                    ref: ref0,
                                },
                                {
                                    label: 'Active',
                                    value: '2',
                                    ref: ref1,
                                },
                                {
                                    label: 'Pending',
                                    value: '1',
                                    ref: ref2,
                                },
                                {
                                    label: 'Inactive',
                                    value: '0',
                                    ref: ref4,
                                },
                            ]}
                        /> */}
                    </div>

                    {showDatePicker && (
                        <DatePicker
                            cssID='dp-startdate'
                            onChangeHandler={(
                                e,
                                selectedDates,
                                lastClicked,
                                closepopover /* true if blurred in popover mode */
                            ) => {
                                if (closepopover && showDatePicker) {
                                    setShowDatePicker(false);
                                    if (!selectedDates) return; // closed w/o selecting dates
                                }
                                setViewedDate(
                                    selectedDates
                                        ? selectedDates[0]
                                        : new Date()
                                );
                            }}
                            selectedDateObjects={[viewedDate]}
                            selectOneMax={true}
                            allowSelectNone={false}
                            size={0}
                            startDate={new Date(term.dates[0])}
                            endDate={
                                new Date(term.dates[term.dates.length - 1])
                            }
                            controlRef={dateDivRef}
                        />
                    )}

                    <div
                        className={`containerinnersolid${
                            !attendanceAllowed ? ' centerVertically' : ''
                        }`}
                    >
                        {!initialized ||
                        // auth.loading ||
                        attendanceAllowed < 0 ||
                        !filteredData ||
                        admin.loading_school ||
                        !term ||
                        !admin.students ? (
                            <div className='centertext mt-3'>
                                <Spinner size={0} />
                            </div>
                        ) : admin.students.length === 0 ? (
                            <div
                                style={{
                                    display: 'grid',
                                    justifyItems: 'center',
                                    gap: '15px',
                                    gridTemplateRows: '150px auto auto auto',
                                }}
                            >
                                <img
                                    src={genericstudentpic}
                                    alt='More data needed'
                                    style={{
                                        height: '150px',
                                        width: 'auto',
                                    }}
                                />
                                <p className='impactText m-0 pt-1'>
                                    Attendance Not Yet Available
                                </p>
                                <p
                                    className='darkcolor'
                                    style={{
                                        maxWidth: '300px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Register one or more students in this term
                                    in order to track attendance
                                </p>
                                <div className='gridcol'>
                                    {admin.students?.length === 0 && (
                                        <Link
                                            className='btn btn-primary'
                                            to={`/admin/${auth.viewingAs.school?.urlslug}/addstudent`}
                                        >
                                            Add Student
                                        </Link>
                                    )}
                                </div>
                            </div>
                        ) : filteredData?.length === 0 ? (
                            term.registeredStudents.length > 0 ? (
                                <div
                                    style={{
                                        display: 'grid',
                                        justifyItems: 'center',
                                        gap: '15px',
                                        gridTemplateRows:
                                            '150px auto auto auto',
                                    }}
                                >
                                    <img
                                        src={genericstudentpic}
                                        alt='No matching students found'
                                        style={{
                                            height: '150px',
                                            width: 'auto',
                                        }}
                                    />
                                    <p className='impactText m-0 pt-1'>
                                        No matching students found
                                    </p>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: 'grid',
                                        justifyItems: 'center',
                                        gap: '15px',
                                        gridTemplateRows:
                                            '150px auto auto auto',
                                    }}
                                >
                                    <img
                                        src={genericstudentpic}
                                        alt='No registered students yet'
                                        style={{
                                            height: '150px',
                                            width: 'auto',
                                        }}
                                    />
                                    <p className='impactText m-0 pt-1'>
                                        No registered students
                                    </p>
                                    <p>
                                        Register students in order to track
                                        attendance.
                                    </p>
                                    <Link
                                        className='btn btn-primary'
                                        to={`/admin/${auth.viewingAs.school?.urlslug}/terms/${termid}/registration`}
                                    >
                                        Register Students
                                    </Link>
                                </div>
                            )
                        ) : !dateIsInTerm(viewedDate, term) ? (
                            <div
                                style={{
                                    display: 'grid',
                                    justifyItems: 'center',
                                    gap: '15px',
                                    gridTemplateRows: '150px auto auto auto',
                                }}
                            >
                                <img
                                    src={genericschoolpic}
                                    alt='School closed'
                                    style={{
                                        height: '150px',
                                        width: 'auto',
                                    }}
                                />
                                <p className='impactText m-0 pt-1'>
                                    School is closed on this day
                                </p>
                                <p
                                    className='darkcolor'
                                    style={{
                                        maxWidth: '300px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Edit the term in order to track attendance
                                    for this day.
                                </p>
                                <div className='gridcol'>
                                    <button
                                        className='btn btn-primary'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowTermModal(true);
                                        }}
                                    >
                                        Edit Term
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className='student-attendance-wrapper'>
                                {getHeaderCells()}

                                {filteredData.map((registration, idx) => (
                                    <Fragment key={'ing-' + idx}>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                attendanceClicked(
                                                    registration.student
                                                );
                                            }}
                                            className='attendance-status'
                                        >
                                            <span
                                                className={`circle ${
                                                    getAttendanceStatus(
                                                        registration.student._id
                                                    ).class
                                                }`}
                                            >
                                                &#x2022;
                                            </span>
                                            <span>
                                                {' '}
                                                {
                                                    getAttendanceStatus(
                                                        registration.student._id
                                                    ).title
                                                }
                                            </span>
                                        </div>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                attendanceClicked(
                                                    registration.student
                                                );
                                            }}
                                            className='student-attendance-pic'
                                        >
                                            <img
                                                className='round-img-sml'
                                                src={getStudentPic(
                                                    registration.student.photo
                                                )}
                                                alt={
                                                    registration.student
                                                        .nameInfo.firstName
                                                }
                                            />
                                            <div>
                                                {/* <Link
                                           to={`/admin/${auth.viewingAs.school?.urlslug}/editstudent/${student._id}`}
                                       > */}
                                                {getPrettyName(
                                                    registration.student
                                                        .nameInfo,
                                                    false
                                                )}
                                                {/* </Link> */}
                                            </div>
                                        </div>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                attendanceClicked(
                                                    registration.student
                                                );
                                            }}
                                        >
                                            {getTime(
                                                getStudentAttendance(
                                                    registration.student._id
                                                )?.dropOffTime
                                            )}
                                        </div>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                attendanceClicked(
                                                    registration.student
                                                );
                                            }}
                                        >
                                            {getPersonName(
                                                getStudentAttendance(
                                                    registration.student._id
                                                )?.dropOffPerson
                                            )}
                                        </div>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                attendanceClicked(
                                                    registration.student
                                                );
                                            }}
                                        >
                                            {getTime(
                                                getStudentAttendance(
                                                    registration.student._id
                                                )?.pickUpTime
                                            )}
                                        </div>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                attendanceClicked(
                                                    registration.student
                                                );
                                            }}
                                        >
                                            {getPersonName(
                                                getStudentAttendance(
                                                    registration.student._id
                                                )?.pickUpPerson
                                            )}
                                        </div>
                                        <div className='student-attendance-cta'>
                                            {
                                                <button
                                                    className='btn btn-small'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        attendanceClicked(
                                                            registration.student
                                                        );
                                                    }}
                                                >
                                                    Edit
                                                </button>
                                            }
                                        </div>
                                    </Fragment>
                                ))}
                            </div>
                        )}
                    </div>
                    {showAttModal && (
                        <UpdateAttendanceModal
                            previousLocation={location.pathname}
                            submitCallback={(e, msg) => {
                                e.preventDefault();
                                setShowAttModal(null);
                            }}
                            closeCallback={(e) => {
                                e.preventDefault();
                                setShowAttModal(null);
                            }}
                            data={showAttModal}
                        />
                    )}
                    {showTermModal && (
                        <AddTermModal
                            previousLocation={location.pathname}
                            submitCallback={(e, formData) => {
                                e.preventDefault();
                                setShowTermModal(false);
                            }}
                            closeCallback={(e) => {
                                e.preventDefault();
                                setShowTermModal(false);
                            }}
                            term={term}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

SchoolAdminSchoolTermAttendance.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    getAllStudents: PropTypes.func.isRequired,
    getAllGradeLevels: PropTypes.func.isRequired,
    getAllContacts: PropTypes.func.isRequired,
    getSchoolAttendanceByDate: PropTypes.func.isRequired,
    updateSearchParams: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
});

export default connect(mapStateToProps, {
    getAllStudents,
    getAllGradeLevels,
    getAllContacts,
    getSchoolAttendanceByDate,
    updateSearchParams,
})(SchoolAdminSchoolTermAttendance);
