import alert from './alert';
import auth from './auth';
import superadmin from './superadmin';
import admin from './admin';
import modal from './modal';

// an object that stores any reducers we create
const rootReducer = {
    alert,
    auth,
    superadmin,
    admin,
    modal,
};
export default rootReducer;
