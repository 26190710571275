import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPrettyCost } from '../../utils/helpers';
import AddTermFeeModal from '../auth/admin/AddTermFeeModal';
import { useLocation } from 'react-router-dom';
import { deleteFee } from '../../storedata/actions/admin';
import DeleteButton from './inputs/DeleteButton';

const FeeRow = ({
    term,
    fee,
    editable = false,
    disabled = false,
    selectable = false,
    selected = false,
    callback,
    deleteFee,
}) => {
    let location = useLocation();
    // false to hide; null to add fee; ID to update fee
    const [showFeeModal, setShowFeeModal] = useState(false);
    // const [width, setWidth] = useState();

    let classes = `feeItem${
        disabled ? ' disabled' : selectable ? ' selectable' : ''
    }${selected ? ' selected' : ''}`;

    return (
        <Fragment>
            <div
                className={classes}
                onClick={() => {
                    if (callback) callback(fee._id);
                }}
            >
                <div>
                    <div>
                        <span className='feeTitle'>
                            {selectable &&
                                (disabled ? (
                                    <i className='far fa-circle-check mr-1'></i>
                                ) : (
                                    <input
                                        name='optionalFees'
                                        type='checkbox'
                                        className='mr-1'
                                        value={selected}
                                        checked={selected}
                                        onChange={() => {
                                            if (callback) callback(fee._id);
                                        }}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    />
                                ))}
                            {fee.name}
                        </span>
                    </div>

                    <div className='cost'>${getPrettyCost(fee.cost)}</div>

                    <div className='gridcolpacked'>
                        {fee.isRequired && (
                            <span className='badge warningbadge border'>
                                Required
                                {fee.gradeLevels?.length > 0 ? ' for:' : ''}
                            </span>
                        )}
                        {fee.gradeLevels?.map((l, i) => (
                            <span key={i} className='badge darkbadge border'>
                                {l.name}
                            </span>
                        ))}
                    </div>

                    <div>
                        {editable && (
                            <div className='gridcolpacked'>
                                <button
                                    className='btn'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowFeeModal(fee._id);
                                    }}
                                >
                                    <i className='fas fa-pen' />
                                </button>
                                <DeleteButton
                                    callback={() => {
                                        console.log('heyyy');
                                        deleteFee(fee._id);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {fee.description ? (
                    <div className='desc'>{fee.description}</div>
                ) : null}
            </div>

            {showFeeModal !== false && (
                <AddTermFeeModal
                    term={term}
                    feeID={showFeeModal}
                    previousLocation={location.pathname}
                    submitCallback={(e, formData) => {
                        e.preventDefault();
                        setShowFeeModal(false);
                    }}
                    closeCallback={(e) => {
                        e.preventDefault();
                        setShowFeeModal(false);
                    }}
                />
            )}
        </Fragment>
    );
};

FeeRow.propTypes = {
    term: PropTypes.object.isRequired,
    fee: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    editable: PropTypes.bool, // whether to show edit button & modal
    selectable: PropTypes.bool, // whether it can be checked off
    selected: PropTypes.bool, // whether it is checked off
    callback: PropTypes.func, // passed to caller to handle selection change
    deleteFee: PropTypes.func.isRequired,
};

export default connect(null, {
    deleteFee,
})(FeeRow);
