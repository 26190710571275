// PRIVATE ROUTE: ADMIN LEVEL
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import { getAllSchoolsForUser } from '../../../../storedata/actions/admin';
import Spinner from '../../../layout/Spinner';
import BaseFrame from '../../BaseFrame';
import SchoolTile from '../SchoolTile';
import freepic from '../../../../img/freeAccess.png';

const SchoolAdminDashboard = ({ getAllSchoolsForUser, auth, admin }) => {
    useEffect(() => {
        // re-fetch schools if changing viewed user
        getAllSchoolsForUser();
    }, [getAllSchoolsForUser, auth.viewingAs]);

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Admin Console</title>
            </Helmet>
            <BaseFrame>
                <h1 className='mb-2'>My Schools</h1>
                {auth.loading || admin.loading_school || !admin.schools ? (
                    <div className='containerinnersolid'>
                        <Spinner size={0} />
                    </div>
                ) : (
                    <div>
                        {admin.schools.map((school, idx) => {
                            return (
                                <SchoolTile
                                    school={school}
                                    key={idx}
                                    editAs={'admin'}
                                />
                            );
                        })}
                    </div>
                )}
                <h1 className='mb-2 mt-3'>Billing</h1>
                <div className='containerinnersolid centervertically'>
                    <div
                        style={{
                            display: 'grid',
                            justifyItems: 'center',
                            gap: 0,
                            gridTemplateRows: '150px auto',
                        }}
                    >
                        <img
                            style={{
                                height: '150px',
                                width: 'auto',
                                borderRadius: '75px',
                                animationName: 'zoom-in-zoom-out',
                                animationDuration: '1.5s',
                                animationTimingFunction: 'ease-in-out',
                                animationIterationCount: 2,
                            }}
                            src={freepic}
                            alt='Free Access'
                        />
                        <p className='impactText mt-3 pb-1'>
                            You have free access to School Trail. Enjoy!
                        </p>
                    </div>
                </div>
            </BaseFrame>
        </Fragment>
    );
};

SchoolAdminDashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    getAllSchoolsForUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
});

export default connect(mapStateToProps, { getAllSchoolsForUser })(
    SchoolAdminDashboard
);
