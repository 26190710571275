import React, { Fragment, useState, useEffect } from 'react';
import Autocomplete from '../layout/inputs/Autocomplete';
import { findCurrentCountryObj, country_list } from '../../utils/helpers';

const initialState = {
    // default values for the formData state
    nameInfo: {
        firstName: '',
        middleName: '',
        lastName: '',
        namePrefix: 1,
        nameSuffix: '',
        nickname: '',
    },
    address: {
        postalCode: '',
        line1: '',
        line2: '',
        country: '',
        city: '',
        region: '',
    },
    email: '',
    phone: '',
    password: '',
    newpassword1: '',
    newpassword2: '',
};

const PersonalInfo = ({
    // params
    onChangeHandler,
    data,
    showMiddleName = false,
    showNameSuffix = false,
    showNamePrefix = true,
    emailRequired = true,
    phoneRequired = true,
    showChangePassword = false,
    showPassword = true,
}) => {
    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    // format: [state, function to update state]
    const [formData, setFormData] = useState(initialState);
    // const [changePassword, setChangePassword] = useState(false);

    // destructure so I don't have to do formData.name, etc...
    // The state will be available everywhere in this file :)
    const {
        nameInfo, //: { firstName, middleName, lastName, namePrefix, nameSuffix, nickname },
        email,
        password,
        newpassword1,
        newpassword2,
        phone,
        address,
    } = formData;

    useEffect(() => {
        if (data) {
            // update form fields with current product
            const data2 = { ...initialState };

            for (const key in data) {
                if (key in data2) data2[key] = data[key] || ''; // in case value is undefined
            }
            setFormData(data2);
        }
    }, [data]);

    const onChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (e.target.name === 'country' || e.target.name === 'region') {
            // value is stringified array; parse into array
            const parsed = JSON.parse(value);
            value = parsed.length > 0 ? parsed[0] : '';
            if (value.name) value = value.name; // in case it is a string vs obj
        }

        onChangeHandler({ name, value });
    };

    return (
        <Fragment>
            {showNamePrefix && (
                <Fragment>
                    <div className='form-header'>Name Prefix*</div>
                    <select
                        name='namePrefix'
                        value={
                            nameInfo.namePrefix ||
                            initialState.nameInfo.namePrefix
                        }
                        onChange={onChange}
                        required
                        onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault();
                        }}
                    >
                        <option value={0}>Mr.</option>
                        <option value={1}>Mrs.</option>
                        <option value={2}>Ms.</option>
                        <option value={3}>Miss</option>
                        <option value={4}>Dr.</option>
                    </select>
                </Fragment>
            )}

            {showMiddleName ? (
                <Fragment>
                    <div className='row-split-evenly grid-gap-20'>
                        <div>
                            <div className='form-header'>First Name*</div>
                            <input
                                type='text'
                                name='firstName'
                                value={
                                    nameInfo.firstName ||
                                    initialState.nameInfo.firstName
                                }
                                onChange={onChange}
                                required
                            />
                        </div>

                        <div>
                            <div className='form-header'>Middle Name</div>
                            <input
                                type='text'
                                name='middleName'
                                value={
                                    nameInfo.middleName ||
                                    initialState.nameInfo.middleName
                                }
                                onChange={onChange}
                            />
                        </div>

                        <div>
                            <div className='form-header'>Last Name*</div>
                            <input
                                type='text'
                                name='lastName'
                                value={
                                    nameInfo.lastName ||
                                    initialState.nameInfo.lastName
                                }
                                onChange={onChange}
                                required
                            />
                        </div>
                    </div>
                </Fragment>
            ) : (
                <div className='row-split-evenly even2split grid-gap-20'>
                    <div>
                        <div className='form-header'>First Name*</div>
                        <input
                            type='text'
                            name='firstName'
                            value={
                                nameInfo.firstName ||
                                initialState.nameInfo.firstName
                            }
                            onChange={onChange}
                            required
                        />
                    </div>

                    <div>
                        <div className='form-header'>Last Name*</div>
                        <input
                            type='text'
                            name='lastName'
                            value={
                                nameInfo.lastName ||
                                initialState.nameInfo.lastName
                            }
                            onChange={onChange}
                            required
                        />
                    </div>
                </div>
            )}

            {showNameSuffix ? (
                <div className='row-split-evenly even2split grid-gap-20'>
                    <div>
                        <div className='form-header'>Name Suffix</div>
                        <select
                            name='nameSuffix'
                            value={
                                nameInfo.nameSuffix ||
                                initialState.nameInfo.nameSuffix
                            }
                            onChange={onChange}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        >
                            <option value=''></option>
                            <option value={3}>Sr.</option>
                            <option value={0}>Jr.</option>
                            <option value={1}>III</option>
                            <option value={2}>IV</option>
                        </select>
                    </div>
                    <div>
                        <div className='form-header'>Nickname</div>
                        <input
                            type='text'
                            name='nickname'
                            value={
                                nameInfo.nickname ||
                                initialState.nameInfo.nickname
                            }
                            onChange={onChange}
                        />
                    </div>
                </div>
            ) : (
                <div>
                    <div className='form-header'>Nickname</div>
                    <input
                        type='text'
                        name='nickname'
                        value={
                            nameInfo.nickname || initialState.nameInfo.nickname
                        }
                        onChange={onChange}
                        required
                    />
                </div>
            )}

            <div className='row-split-evenly even2split grid-gap-20'>
                <div>
                    <div className='form-header'>
                        Email Address{emailRequired && '*'}
                    </div>
                    <input
                        type='email'
                        name='email'
                        value={email || initialState.email}
                        onChange={onChange}
                        required={emailRequired}
                    />
                </div>

                <div>
                    {' '}
                    <div className='form-header'>
                        Phone Number{phoneRequired && '*'}
                    </div>
                    <input
                        type='text'
                        name='phone'
                        value={phone || initialState.phone}
                        onChange={onChange}
                        required={phoneRequired}
                    />
                </div>
            </div>

            <div className='form-header'>Country*</div>

            <Autocomplete
                cssID={'ctry' + Date.now().toString()} // randomize so it doesn't conflict with another in case this component is loaded more than once on same page
                inputName='country'
                selectedData={
                    findCurrentCountryObj(address.country)
                        ? [findCurrentCountryObj(address.country)]
                        : []
                }
                placeholder='Select a country...'
                onChangeHandler={onChange}
                showAllOptions={true}
                suggestions={country_list}
                selectOneMax={true}
            />

            {address.country && (
                <Fragment>
                    <div className='form-header'>Address Line 1*</div>
                    <input
                        type='text'
                        name='line1'
                        value={address.line1 || initialState.address.line1}
                        onChange={onChange}
                        required
                    />

                    <div className='form-header'>Address Line 2</div>
                    <input
                        type='text'
                        name='line2'
                        value={address.line2 || initialState.address.line2}
                        onChange={onChange}
                    />

                    <div className='row-split-evenly even2split grid-gap-20'>
                        <div>
                            <div className='form-header'>City*</div>
                            <input
                                type='text'
                                name='city'
                                value={
                                    address.city || initialState.address.city
                                }
                                onChange={onChange}
                            />
                        </div>
                        <div>
                            <div className='form-header'>
                                {address.country === 'Jamaica'
                                    ? 'Parish*'
                                    : 'State*'}
                            </div>

                            <Autocomplete
                                cssID={'regionac' + Date.now().toString()}
                                inputName='region'
                                selectedData={
                                    address.region ? [address.region] : []
                                }
                                placeholder={
                                    'Select a ' +
                                    (address.country === 'Jamaica'
                                        ? 'parish'
                                        : 'state') +
                                    '...'
                                }
                                onChangeHandler={onChange}
                                showAllOptions={true}
                                suggestions={
                                    findCurrentCountryObj(address.country)
                                        ? findCurrentCountryObj(address.country)
                                              .regions
                                        : []
                                }
                                selectOneMax={true}
                            />
                        </div>
                    </div>

                    {address.country !== 'Jamaica' && (
                        <div>
                            <div className='form-header'>Zip Code*</div>
                            <input
                                type='text'
                                name='postalCode'
                                value={
                                    address.postalCode ||
                                    initialState.address.postalCode
                                }
                                onChange={onChange}
                            />
                        </div>
                    )}
                </Fragment>
            )}

            {showPassword &&
                (showChangePassword ? (
                    <Fragment>
                        <h3 className='mb-1'>Change Password</h3>
                        <div>
                            <div className='form-header'>Current Password</div>
                            <input
                                type='password'
                                value={password || initialState.password}
                                onChange={onChange}
                                name='password'
                            />
                        </div>
                        <div className='row-split-evenly even2split grid-gap-20'>
                            <div>
                                <div className='form-header'>New Password</div>
                                <input
                                    type='password'
                                    name='newpassword1'
                                    value={
                                        newpassword1 ||
                                        initialState.newpassword1
                                    }
                                    onChange={onChange}
                                    minLength='8' // @todo make 8 a constant and use it in the backend api validator too
                                />
                                <small className='form-text mb-1'>
                                    Must be at least 8 characters long
                                </small>
                            </div>
                            <div>
                                <div className='form-header'>
                                    Confirm Password
                                </div>
                                <input
                                    type='password'
                                    name='newpassword2'
                                    value={
                                        newpassword2 ||
                                        initialState.newpassword2
                                    }
                                    onChange={onChange}
                                    minLength='8'
                                />
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className='row-split-evenly even2split grid-gap-20'>
                            <div>
                                <div className='form-header'>Password</div>
                                <input
                                    type='password'
                                    name='newpassword1'
                                    value={
                                        newpassword1 ||
                                        initialState.newpassword1
                                    }
                                    onChange={onChange}
                                    minLength='8' // @todo make 8 a constant and use it in the backend api validator too
                                />
                                <small className='form-text mb-1'>
                                    Must be at least 8 characters long
                                </small>
                            </div>
                            <div>
                                <div className='form-header'>
                                    Confirm Password
                                </div>
                                <input
                                    type='password'
                                    name='newpassword2'
                                    value={
                                        newpassword2 ||
                                        initialState.newpassword2
                                    }
                                    onChange={onChange}
                                    minLength='8'
                                />
                            </div>
                        </div>
                    </Fragment>
                ))}
        </Fragment>
    );
};

export default PersonalInfo;
