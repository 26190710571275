import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { URLhelpers } from '../../utils/helpers';
import { updateSearchParams } from '../../storedata/actions/auth';

// Search bar component
function Searchbar({
    searchParam, // param name used to identify this search query. if not set, then the URL location isn't updated
    searchName, // to identify name field
    callback,
    autoSearch,
    updateSearchParams,
}) {
    let history = useNavigate();
    let location = useLocation();

    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        if (searchParam) {
            let ss = URLhelpers.getURLParam(searchParam, location.search);
            setSearchText(ss);
        }
    }, [location, searchParam]);

    const onChangeHandler = (str) => {
        if (searchParam) {
            var o = { [searchParam]: str };
            let newLocation = URLhelpers.setURLparam(
                history,
                location.search,
                o
            );

            updateSearchParams(location.pathname, newLocation);
        }

        if (callback) callback(str);
    };

    const clearField = () => {
        setSearchText('');
        onChangeHandler('');

        let input = document.querySelector('#searchbar-' + searchName);
        input.focus(); // ! todo make useref
    };

    // updates searchText as user types
    const prepForSearch = (e) => {
        let str = e.target.value;
        setSearchText(str);
        // process the changed text, even if user manually clears field
        // with delete button so text update can happen in caller
        if (autoSearch || str === '') onChangeHandler(str);
    };

    return (
        <div className={`search-wrapper${autoSearch ? ' nogap' : ''}`}>
            <div className='searchbar-wrapper'>
                <div className='search-icon'>
                    <i className='fas fa-search' />
                </div>
                <input
                    type='text'
                    autoComplete='off'
                    className='searchbar'
                    id={'searchbar-' + searchName}
                    onChange={(e) => {
                        prepForSearch(e);
                    }}
                    onKeyPress={(e) => {
                        e.key === 'Enter' &&
                            !autoSearch &&
                            onChangeHandler(searchText);
                    }}
                    value={searchText}
                    name={searchName}
                    // placeholder={'Search ' + searchName}
                />
                {searchText.length > 0 ? (
                    <button
                        type='button'
                        className='btn-icon'
                        onClick={(_e) => {
                            clearField();
                        }}
                    >
                        <i className='far fa-times-circle' />
                    </button>
                ) : (
                    <div></div>
                    // above empty div is so grid column css specs are still valid
                )}
            </div>
            {!autoSearch && (
                <button
                    type='button'
                    className='btn btn-tertiary mr-0'
                    onClick={() => onChangeHandler(searchText)}
                >
                    Search
                </button>
            )}
        </div>
    );
}

Searchbar.propTypes = {
    searchParam: PropTypes.string, // param to identify this search query use. if set, it updates URL bar.
    searchName: PropTypes.string.isRequired, // name used to identify input field
    callback: PropTypes.func,
    autoSearch: PropTypes.bool,
    updateSearchParams: PropTypes.func.isRequired,
};

Searchbar.defaultProps = {
    autoSearch: false,
};

export default connect(null, { updateSearchParams })(Searchbar);
