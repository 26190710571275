// PRIVATE ROUTE: SUPERADMIN LEVEL (/superadmin)
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import BaseFrame from '../../BaseFrame';
import Spinner from '../../../layout/Spinner';

const SuperadminDashboard = ({ auth, superadmin }) => {
    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Superadmin Dashboard</title>
            </Helmet>

            <BaseFrame>
                <h1 className='mb-2'>Dashboard</h1>
                <div className='containerinnersolid'>
                    {auth.loading ? <Spinner /> : <p>SAD Coming soon!</p>}{' '}
                </div>
            </BaseFrame>
        </Fragment>
    );
};

SuperadminDashboard.propTypes = {
    superadmin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    superadmin: state.superadmin,
    auth: state.auth,
});

export default connect(mapStateToProps)(SuperadminDashboard);
