import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import Landing from '../layout/Landing';
import { getRoleBasePath } from '../../utils/helpers';

const BaseRouter = ({ auth: { loading, isAuthenticated, viewingAs } }) => {
    if (loading) {
        return (
            <section className='containerfull'>
                <div className='containermiddle m-3'>
                    <Spinner />
                </div>
            </section>
        );
    } else if (isAuthenticated) {
        return <Navigate to={getRoleBasePath(viewingAs.role)} />;
    } else {
        return <Landing />;
    }
};

BaseRouter.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(BaseRouter);
