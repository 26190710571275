import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux'; // connects component to redux
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import {
    getSchoolById,
    updateSchool,
} from '../../../../storedata/actions/admin';
import { setAlert } from '../../../../storedata/actions/alert';
import PropTypes from 'prop-types';
import Autocomplete from '../../../layout/inputs/Autocomplete';
import {
    findCurrentCountryObj,
    country_list,
    hasAccessToSchool,
} from '../../../../utils/helpers';
import BaseFrame from '../../BaseFrame';
import Spinner from '../../../layout/Spinner';
import UploadSchoolPic from './UploadSchoolPic';

const initialState = {
    initialized: false,
    name: '',
    address: {
        postalCode: '',
        line1: '',
        line2: '',
        country: '',
        city: '',
        region: '',
    },
    phone: '',
};

const SchoolAdminEditSchool = ({
    getSchoolById,
    updateSchool,
    setAlert,
    auth,
    admin: { school, loading_school },
}) => {
    let params = useParams();
    let history = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    const [formData, setFormData] = useState(initialState);
    const { name, address, phone, initialized } = formData;

    useEffect(() => {
        if (params.schoolid) {
            if (hasAccessToSchool(params.schoolid, auth.viewingAs.user.roles)) {
                getSchoolById(params.schoolid);
            } else {
                setAlert(
                    'You are not authorized to view that school.',
                    'danger',
                    true
                );
                history('/base', { replace: true }); // not authorized
                return;
            }
        }
    }, [
        getSchoolById,
        params.schoolid,
        history,
        setAlert,
        auth.viewingAs.user.roles,
    ]); // @todo8 school not found,

    useEffect(() => {
        if (!auth.loading && !loading_school && params.schoolid && school) {
            // update form fields with current student
            const formData = { ...initialState };

            for (const key in school) {
                if (key in formData) formData[key] = school[key];
            }

            formData.initialized = true;
            setFormData(formData);
        }
    }, [auth.loading, loading_school, params.schoolid, school, initialized]);

    const onChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        let addritems = [
            'line1',
            'line2',
            'country',
            'city',
            'region',
            'postalCode',
        ];

        if (addritems.includes(name)) {
            if (name === 'country' || name === 'region') {
                // value is stringified array; parse into array
                const parsed = JSON.parse(value);
                value = parsed.length > 0 ? parsed[0] : '';
                if (value.name) value = value.name;
            }

            setFormData({
                ...formData,
                address: {
                    ...formData.address,
                    [name]: value,
                },
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault(e); // don't let browser do its default behavior

        updateSchool(formData, params.schoolid);
    };

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Update School</title>
            </Helmet>

            <BaseFrame>
                {loading_school || !initialized ? (
                    <div className='containerinnersolid'>
                        <Spinner size={0} />
                    </div>
                ) : (
                    <Fragment>
                        <h1 className='pb-2'>Update School</h1>
                        <div className='containerinnersolid heightfitcontent'>
                            {params.schoolid && initialized && (
                                <UploadSchoolPic />
                            )}
                            <form
                                className='form bigwarmhug'
                                onSubmit={(e) => onSubmit(e)}
                            >
                                <h2 className='mb-1'>School Details</h2>

                                <div className='form-header'>School Name*</div>
                                <input
                                    type='text'
                                    name='name'
                                    value={name}
                                    onChange={onChange}
                                    required
                                />

                                <div className='form-header'>Phone Number*</div>
                                <input
                                    type='text'
                                    name='phone'
                                    value={phone}
                                    onChange={onChange}
                                    required
                                />

                                <div className='form-header'>Country*</div>

                                <Autocomplete
                                    cssID={'ctry' + Date.now().toString()} // randomize so it doesn't conflict with another in case this component is loaded more than once on same page
                                    inputName='country'
                                    selectedData={
                                        findCurrentCountryObj(address.country)
                                            ? [
                                                  findCurrentCountryObj(
                                                      address.country
                                                  ),
                                              ]
                                            : []
                                    }
                                    placeholder='Select a country...'
                                    onChangeHandler={onChange}
                                    showAllOptions={true}
                                    suggestions={country_list}
                                    selectOneMax={true}
                                />

                                {address.country && (
                                    <Fragment>
                                        <div className='form-header'>
                                            Address Line 1*
                                        </div>
                                        <input
                                            type='text'
                                            name='line1'
                                            value={
                                                address.line1 ||
                                                initialState.address.line1
                                            }
                                            onChange={onChange}
                                            required
                                        />

                                        <div className='form-header'>
                                            Address Line 2
                                        </div>
                                        <input
                                            type='text'
                                            name='line2'
                                            value={
                                                address.line2 ||
                                                initialState.address.line2
                                            }
                                            onChange={onChange}
                                        />

                                        <div className='row-split-evenly even2split grid-gap-20'>
                                            <div>
                                                <div className='form-header'>
                                                    City*
                                                </div>
                                                <input
                                                    type='text'
                                                    name='city'
                                                    value={
                                                        address.city ||
                                                        initialState.address
                                                            .city
                                                    }
                                                    onChange={onChange}
                                                />
                                            </div>
                                            <div>
                                                <div className='form-header'>
                                                    {address.country ===
                                                    'Jamaica'
                                                        ? 'Parish*'
                                                        : 'State*'}
                                                </div>

                                                <Autocomplete
                                                    cssID={
                                                        'regionac' +
                                                        Date.now().toString()
                                                    }
                                                    inputName='region'
                                                    selectedData={
                                                        address.region
                                                            ? [address.region]
                                                            : []
                                                    }
                                                    placeholder={
                                                        'Select a ' +
                                                        (address.country ===
                                                        'Jamaica'
                                                            ? 'parish'
                                                            : 'state') +
                                                        '...'
                                                    }
                                                    onChangeHandler={onChange}
                                                    showAllOptions={true}
                                                    suggestions={
                                                        findCurrentCountryObj(
                                                            address.country
                                                        )
                                                            ? findCurrentCountryObj(
                                                                  address.country
                                                              ).regions
                                                            : []
                                                    }
                                                    selectOneMax={true}
                                                />
                                            </div>
                                        </div>

                                        {address.country !== 'Jamaica' && (
                                            <div>
                                                <div className='form-header'>
                                                    Zip Code*
                                                </div>
                                                <input
                                                    type='text'
                                                    name='postalCode'
                                                    value={
                                                        address.postalCode ||
                                                        initialState.address
                                                            .postalCode
                                                    }
                                                    onChange={onChange}
                                                />
                                            </div>
                                        )}
                                    </Fragment>
                                )}
                                <input
                                    type='submit'
                                    value='Save'
                                    className='btn btn-primary my-1 mr-0'
                                />
                            </form>
                        </div>
                    </Fragment>
                )}
            </BaseFrame>
        </Fragment>
    );
};

SchoolAdminEditSchool.propTypes = {
    getSchoolById: PropTypes.func.isRequired,
    updateSchool: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
});

// to use an action, pass it into connect below to allow us to access props.login so we can use it in this component.
// 1st param is state to connect. 2nd param is any actions I want to use
export default connect(mapStateToProps, {
    getSchoolById,
    updateSchool,
    setAlert,
})(SchoolAdminEditSchool);
