import React, { useEffect, Fragment } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getRoleTypes,
    getRoleFromPath,
    getSchools,
    findSchoolFromRoles,
} from '../../utils/helpers';
import { switchView } from '../../storedata/actions/auth';

const SchoolsWidget = ({ auth, baseurl, switchView }) => {
    let location = useLocation();
    let params = useParams();

    useEffect(() => {
        if (!auth.loading && !auth.viewingAs) {
            let role = getRoleFromPath(location.pathname);
            let type = getRoleTypes(auth.viewingAs.user.roles).find((r) => {
                return parseInt(r.type) === parseInt(role);
            });

            let school = params.id
                ? findSchoolFromRoles(params.id, auth.viewingAs.user.roles) // use url to set school
                : type?.institution || null;

            switchView(role, school);
        }
    }, [
        auth.loading,
        auth.viewingAs,
        params.id,
        location.pathname,
        switchView,
    ]);

    const onChangeLink = (id) => {
        let school = getSchools(auth.viewingAs.user.roles).find((school) => {
            return school._id === id;
        });
        switchView(auth.viewingAs.role, school);
    };

    return (
        <div className='schoolswidget'>
            <h3>My Schools</h3>

            <ul>
                {!auth.loading && (
                    <Fragment>
                        {getSchools(auth.viewingAs.user.roles)?.map(
                            (school, i) => (
                                <li
                                    className='school'
                                    value={school.name}
                                    key={i}
                                >
                                    <Link
                                        to={baseurl + '/' + school.urlslug}
                                        onClick={() => {
                                            onChangeLink(school._id);
                                        }}
                                        className={
                                            auth.viewingAs.school !== null &&
                                            school._id ===
                                                auth.viewingAs.school._id
                                                ? 'selected'
                                                : ''
                                        }
                                    >
                                        {!school.isApproved && (
                                            <i
                                                className='alert-color fas fa-triangle-exclamation'
                                                title='School not yet approved'
                                            ></i>
                                        )}
                                        {school.name}
                                    </Link>
                                </li>
                            )
                        )}
                    </Fragment>
                )}
            </ul>
        </div>
    );
};

SchoolsWidget.propTypes = {
    auth: PropTypes.object.isRequired,
    baseurl: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    switchView: PropTypes.func.isRequired,
});

// to use an action, pass it into connect below to allow us to access props.login so we can use it in this component.
// 1st param is state to connect. 2nd param is any actions I want to use
export default connect(mapStateToProps, { switchView })(SchoolsWidget);
