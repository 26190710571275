// Boilerplate store code
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './storedata/reducers'; // the root (base) reducer
import setAuthToken from './utils/setAuthToken';

const initialState = {}; // all initial state will be in the reducer
const sanitimg = '<<LONG_BLOB>>';

const actionSanitizer = (action) => {
    // return action;
    let r =
        // (action.type === 'GET_CONTACT' || action.type === 'GET_STUDENT') &&
        // action.payload
        //     ? {
        //           ...action,
        //           payload: {
        //               ...action.payload,
        //               photo: action.payload.photo
        //                   ? {
        //                         ...action.payload.photo,
        //                         data: sanitimg,
        //                     }
        //                   : null,
        //           },
        //       }
        // :
        (action.type === 'STUDENTS_LOADED' ||
            action.type === 'CONTACTS_LOADED') &&
        action.payload
            ? {
                  ...action,
                  payload:
                      action.payload.length > 0
                          ? sanitimg
                          : //   action.payload.map((s) => {
                            //         if (s.photo) {
                            //             let snew = {
                            //                 ...s,
                            //                 photo: {
                            //                     ...s.photo,
                            //                     data: sanitimg,
                            //                 },
                            //             };
                            //             return snew;
                            //         } else return s;
                            //     })
                            action.payload,
              }
            : action;

    return r;
};

const stateSanitizerForDevtools = (state) => {
    let r = {
        ...state,
        admin: {
            ...state.admin,
            contacts: sanitimg,
            // state?.admin?.contacts
            //     ? state.admin.contacts.map((s) => {
            //           if (s.photo) {
            //               return {
            //                   ...s,
            //                   photo: {
            //                       ...s.photo,
            //                       data: sanitimg,
            //                   },
            //               };
            //           } else return s;
            //       })
            //     : null,
            students: '<<LONG_BLOB>>',
            // state?.admin?.students
            //     ? state.admin.students.map((s) => {
            //           if (s.photo) {
            //               return {
            //                   ...s,
            //                   photo: {
            //                       ...s.photo,
            //                       data: sanitimg,
            //                   },
            //               };
            //           } else return s;
            //       })
            //     : null,
            // student: state?.admin?.student
            //     ? {
            //           ...state.admin.student,
            //           photo: state.admin.student.photo
            //               ? {
            //                     ...state.admin.student.photo,
            //                     data: sanitimg,
            //                 }
            //               : null,
            //       }
            //     : null,
        },
    };

    return r;
};

// comes with the redux browser devtools and thunk middleware already configured
const store = configureStore({
    reducer: rootReducer,
    initialState,
    // Turn off devtools in prod, or pass options in dev
    devTools:
        process.env.NODE_ENV === 'production'
            ? false
            : {
                  actionSanitizer,
                  stateSanitizer: stateSanitizerForDevtools,
              },
});

// set up a store subscription listener
// to store the users token in localStorage

// initialize current state from redux store for subscription comparison
// preventing undefined error
let currentState = store.getState();

// Redux provides us with a `store.subscribe` listener that runs every time a state change occurs.
store.subscribe(() => {
    // keep track of the previous and current state to compare changes
    let previousState = currentState;
    currentState = store.getState();
    // if the token changes set the value in localStorage and axios headers
    if (previousState.auth.token !== currentState.auth.token) {
        const token = currentState.auth.token;
        setAuthToken(token);
    }
});

export default store;
