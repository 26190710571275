import React, { Fragment } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import NotFound from '../layout/NotFound';
import PrivateRoute from './PrivateRoute';
import DefaultRoute from './DefaultRoute';
import UpdateAccount from '../auth/superadmin/pages/UpdateAccount';
import SuperadminDashboard from '../auth/superadmin/pages/SuperadminDashboard';
import SchoolAdminDashboard from '../auth/admin/pages/SchoolAdminDashboard';
import SchoolAdminSchoolOverview from '../auth/admin/pages/SchoolAdminSchoolOverview';
import ParentDashboard from '../auth/parent/pages/ParentDashboard';
import Login from '../auth/Login';
import Register from '../auth/Register';
import Users from '../auth/superadmin/pages/SuperadminUsers';
import Messages from '../auth/superadmin/pages/SuperadminMessages';
import Institutions from '../auth/superadmin/pages/SuperadminInstitutions';
import BaseRouter from '../auth/BaseRouter';
import VerifyEmail from '../auth/VerifyEmail';
import ForgotPassword from '../auth/ForgotPassword';
import ResetPassword from '../auth/ResetPassword';
import About from '../layout/About';
import Terms from '../layout/Terms';
import SchoolAdminSchoolTerm from '../auth/admin/pages/SchoolAdminSchoolTerm';
import SchoolAdminStudents from '../auth/admin/pages/SchoolAdminStudents';
import SchoolAdminStaff from '../auth/admin/pages/SchoolAdminStaff';
import UpdateStudentPage from '../auth/admin/pages/UpdateStudentPage';
import SchoolAdminEditSchool from '../auth/admin/pages/SchoolAdminEditSchool';
import AddContactModal from '../auth/admin/AddContactModal';
import AddTermModal from '../auth/admin/AddTermModal';
import SchoolAdminContacts from '../auth/admin/pages/SchoolAdminContacts';
import SchoolAdminSchoolTerms from '../auth/admin/pages/SchoolAdminSchoolTerms';
import SchoolAdminSchoolGradeLevels from '../auth/admin/pages/SchoolAdminSchoolGradeLevels';
import AddGradeLevelModal from '../auth/admin/AddGradeLevelModal';
import StudentDetails from '../auth/admin/pages/StudentDetails';
import StudentTerms from '../auth/admin/pages/StudentTerms';
import Student from '../auth/admin/pages/Student';

const AppRoutes = () => {
    const location = useLocation();
    const previousLocation = location.state?.previousLocation;

    const RemoveTrailingSlash = ({ ...rest }) => {
        const location = useLocation();

        // If the last character of the url is '/'
        if (location.pathname.match('/.*/$')) {
            return (
                <Navigate
                    replace
                    {...rest}
                    to={{
                        pathname: location.pathname.replace(/\/+$/, ''),
                        search: location.search,
                    }}
                />
            );
        } else return null;
    };

    return (
        <Fragment>
            <RemoveTrailingSlash />
            <Routes location={previousLocation || location}>
                <Route element={<DefaultRoute />}>
                    <Route exact path='/' element={<BaseRouter />} />
                    <Route exact path='/base' element={<BaseRouter />} />
                    <Route exact path='/register' element={<Register />} />
                    <Route
                        exact
                        path='/register/school'
                        element={<Register newschool={true} />}
                    />
                    <Route exact path='/register' element={<Register />} />
                    <Route exact path='/login' element={<Login />} />
                    <Route
                        exact
                        path='/verifyemail'
                        element={<VerifyEmail />}
                    />
                    <Route
                        exact
                        path='/forgotpassword'
                        element={<ForgotPassword />}
                    />
                    <Route
                        exact
                        path='/resetpassword'
                        element={<ResetPassword />}
                    />

                    {/*<Route exact path='/profile/:id' element={<Profile />} />*/}
                    <Route exact path='/about' element={<About />} />
                    <Route exact path='/terms' element={<Terms />} />

                    <Route path='*' element={<NotFound />} />
                </Route>

                <Route element={<PrivateRoute />}>
                    <Route
                        exact
                        path='/admin/account'
                        element={<UpdateAccount />}
                    />
                    <Route
                        exact
                        path='/parent/account'
                        element={<UpdateAccount />}
                    />
                    <Route exact path='/parent' element={<ParentDashboard />} />
                    <Route
                        exact
                        path='/parent/dashboard'
                        element={<ParentDashboard />}
                    />
                </Route>

                <Route element={<PrivateRoute adminPage />}>
                    <Route
                        exact
                        path='/admin'
                        element={<SchoolAdminDashboard />}
                    />
                    <Route
                        exact
                        path='/admin/dashboard'
                        element={<SchoolAdminDashboard />}
                    />

                    <Route
                        exact
                        path='/admin/:id/gradelevels'
                        element={<SchoolAdminSchoolGradeLevels />}
                    />

                    <Route
                        exact
                        path='/admin/:id/terms'
                        element={<SchoolAdminSchoolTerms />}
                    />

                    <Route
                        exact
                        path='/admin/:id/terms/:termid'
                        element={<SchoolAdminSchoolTerm />}
                    />

                    <Route
                        exact
                        path='/admin/:id/terms/:termid/registration'
                        element={<SchoolAdminSchoolTerm />}
                    />

                    <Route
                        exact
                        path='/admin/:id/terms/:termid/courses'
                        element={<SchoolAdminSchoolTerm />}
                    />

                    <Route
                        exact
                        path='/admin/:id/terms/:termid/attendance'
                        element={<SchoolAdminSchoolTerm />}
                    />

                    <Route
                        exact
                        path='/admin/:id/student/:studentid'
                        element={<Student />}
                    />

                    <Route
                        exact
                        path='/admin/:id/student/:studentid/summary'
                        element={<Student />}
                    />

                    <Route
                        exact
                        path='/admin/:id/student/:studentid/terms'
                        element={<Student />}
                    />

                    <Route
                        exact
                        path='/admin/:id'
                        element={<SchoolAdminSchoolOverview />}
                    />
                    <Route
                        exact
                        path='/admin/:id/overview'
                        element={<SchoolAdminSchoolOverview />}
                    />
                    <Route
                        exact
                        path='/admin/:id/editschool/:schoolid'
                        element={<SchoolAdminEditSchool />}
                    />
                    <Route
                        path='/admin/:id/students'
                        element={<SchoolAdminStudents />}
                    />
                    <Route
                        exact
                        path='/admin/:id/addstudent'
                        element={<UpdateStudentPage />}
                    />
                    <Route
                        // exact
                        path='/admin/:id/editstudent/:studentid'
                        element={<UpdateStudentPage />}
                    />
                    <Route
                        // exact
                        path='/admin/:id/student/:studentid'
                        element={<StudentDetails />}
                    />

                    <Route
                        // exact
                        path='/admin/:id/student/:studentid/summary'
                        element={<StudentDetails />}
                    />

                    <Route
                        // exact
                        path='/admin/:id/student/:studentid/terms'
                        element={<StudentTerms />}
                    />

                    <Route
                        exact
                        path='/admin/:id/staff'
                        element={<SchoolAdminStaff />}
                    />
                    <Route
                        exact
                        path='/admin/:id/contacts'
                        element={<SchoolAdminContacts />}
                    />

                    {/* The following route paths are a duplicate of a 
                        modal route so that the data loads behind properly */}

                    <Route
                        path='/admin/:id/contacts/addcontact'
                        element={<SchoolAdminContacts />}
                    />

                    <Route
                        path='/admin/:id/contacts/editcontact/:contactid'
                        element={<SchoolAdminContacts />}
                    />

                    <Route
                        path='/admin/:id/overview/addterm'
                        element={<SchoolAdminSchoolOverview />}
                    />

                    <Route
                        path='/admin/:id/addterm'
                        element={<SchoolAdminSchoolOverview />}
                    />

                    <Route
                        path='/admin/:id/terms/addterm'
                        element={<SchoolAdminSchoolTerms />}
                    />

                    {/* <Route
                        path='/admin/:id/terms/editterm/:termid'
                        element={<SchoolAdminSchoolTerms />}
                    /> */}

                    <Route
                        path='/admin/:id/overview/addgradelevel'
                        element={<SchoolAdminSchoolOverview />}
                    />

                    <Route
                        path='/admin/:id/addgradelevel'
                        element={<SchoolAdminSchoolOverview />}
                    />

                    <Route
                        path='/admin/:id/gradelevels/addgradelevel'
                        element={<SchoolAdminSchoolGradeLevels />}
                    />

                    <Route
                        path='/admin/:id/gradelevels/editgradelevel/:gradelevelid'
                        element={<SchoolAdminSchoolGradeLevels />}
                    />
                </Route>

                <Route element={<PrivateRoute superAdminPage />}>
                    {/* superadmin */}
                    <Route
                        exact
                        path='/superadmin'
                        element={<SuperadminDashboard />}
                    />
                    <Route
                        exact
                        path='/superadmin/dashboard'
                        element={<SuperadminDashboard />}
                    />
                    <Route
                        exact
                        path='/superadmin/account'
                        element={<UpdateAccount />}
                    />
                    <Route exact path='/superadmin/users' element={<Users />} />
                    <Route
                        exact
                        path='/superadmin/messages'
                        element={<Messages />}
                    />

                    <Route
                        exact
                        path='/superadmin/schools'
                        element={<Institutions />}
                    />

                    <Route
                        exact
                        path='/superadmin/:id'
                        element={<SchoolAdminSchoolOverview />}
                    />
                    <Route
                        exact
                        path='/superadmin/:id/overview'
                        element={<SchoolAdminSchoolOverview />}
                    />

                    <Route
                        exact
                        path='/superadmin/:id/editschool/:schoolid'
                        element={<SchoolAdminEditSchool />}
                    />
                </Route>
            </Routes>

            <Routes>
                {/************************* MODAL ROUTES *************************
                 * Modals used here get their own unique URL.
                 * modals appear on top of other components, so need to include in
                 * this private route section to ensure user is authenticated,
                 * checking for previousLocation ensures that a fresh load of one of
                 * the below route paths won't load the modal, since we won't know
                 * where it came from and thus won't know what to show below.
                 * 
                 * Example of how to open the component on a page that wants to do so:
                 * <Link
                        className='btn my-1'
                        to='addcontact'
                        state={{
                            previousLocation: location,
                        }}
                    >
                        Add Contact...
                    </Link> 
                */}
                <Route element={<PrivateRoute adminPage showSpinner={false} />}>
                    <Route
                        exact
                        path='/admin/:id/contacts/addcontact'
                        element={
                            <AddContactModal
                                previousLocation={
                                    previousLocation ||
                                    parseLocation(location, 'addcontact')
                                }
                            />
                        }
                    />

                    <Route
                        path='/admin/:id/contacts/editcontact/:contactid'
                        element={
                            <AddContactModal
                                previousLocation={
                                    previousLocation ||
                                    parseLocation(location, 'editcontact')
                                }
                            />
                        }
                    />

                    <Route
                        exact
                        path='/admin/:id/overview/addterm'
                        element={
                            <AddTermModal
                                previousLocation={
                                    previousLocation ||
                                    parseLocation(location, 'addterm')
                                }
                            />
                        }
                    />

                    <Route
                        exact
                        path='/admin/:id/addterm'
                        element={
                            <AddTermModal
                                previousLocation={
                                    previousLocation ||
                                    parseLocation(location, 'addterm')
                                }
                            />
                        }
                    />

                    <Route
                        exact
                        path='/admin/:id/terms/addterm'
                        element={
                            <AddTermModal
                                previousLocation={
                                    previousLocation ||
                                    parseLocation(location, 'addterm')
                                }
                            />
                        }
                    />

                    <Route
                        path='/admin/:id/gradelevels/editgradelevel/:gradelevelid'
                        element={
                            <AddGradeLevelModal
                                previousLocation={
                                    previousLocation ||
                                    parseLocation(location, 'editgradelevel')
                                }
                            />
                        }
                    />

                    <Route
                        path='/admin/:id/gradelevels/addgradelevel'
                        element={
                            <AddGradeLevelModal
                                previousLocation={
                                    previousLocation ||
                                    parseLocation(location, 'addgradelevel')
                                }
                            />
                        }
                    />

                    <Route
                        path='/admin/:id/overview/addgradelevel'
                        element={
                            <AddGradeLevelModal
                                previousLocation={
                                    previousLocation ||
                                    parseLocation(location, 'addgradelevel')
                                }
                            />
                        }
                    />

                    <Route
                        path='/admin/:id/addgradelevel'
                        element={
                            <AddGradeLevelModal
                                previousLocation={
                                    previousLocation ||
                                    parseLocation(location, 'addgradelevel')
                                }
                            />
                        }
                    />
                </Route>
            </Routes>
        </Fragment>
    );
};

const parseLocation = (location, rep) => {
    let p = location.pathname;
    let params = location.search;
    // remove everything that includes and comes after 'rep', but keep params
    return p.substring(0, p.indexOf(rep)) + params;
};

export default AppRoutes;
