// PRIVATE ROUTE: ADMIN LEVEL
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../layout/Spinner';
import BaseFrame from '../../BaseFrame';
import {
    getTermById,
    getAllTerms,
    clearTerm,
} from '../../../../storedata/actions/admin';
import SchoolHeader from '../../SchoolHeader';
import BackButton from '../../../layout/inputs/BackButton';
import AdminTermNav from '../../AdminTermNav';
import SchoolAdminSchoolTermCalendar from './SchoolAdminSchoolTermCalendar';
import SchoolAdminSchoolTermRegistration from './SchoolAdminSchoolTermRegistration';
import SchoolAdminSchoolTermCourses from './SchoolAdminSchoolTermCourses';
import SchoolAdminSchoolTermAttendance from './SchoolAdminSchoolTermAttendance';
import LabelWrapper from '../../../layout/inputs/LabelWrapper';
import AddTermModal from '../AddTermModal';
import { storageItemIsTrue } from '../../../../utils/helpers';

const SchoolAdminSchoolTerm = ({
    auth,
    admin: { term, terms },
    getTermById,
    getAllTerms,
    clearTerm,
}) => {
    let location = useLocation();
    let history = useNavigate();
    let params = useParams();
    let termid = params.termid;
    const [showTermModal, setShowTermModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    useEffect(() => {
        if (termid) {
            getTermById(termid);
        }
    }, [getTermById, termid]); // @todo8 term not found

    useEffect(() => {
        if (!terms) {
            getAllTerms();
        }
    }, [terms, getAllTerms]);

    useEffect(() => {
        return function cleanup() {
            // behaves like componentWillUnmount
            clearTerm();
        };
    }, [clearTerm]);

    // LOCAL STORAGE HANDLING
    const [storage, setStorage] = useState(null);
    const handleStorage = () => {
        setStorage(storageItemIsTrue('parentMode'));
    };

    useEffect(() => {
        setStorage(storageItemIsTrue('parentMode'));
        window.addEventListener('storage', () => handleStorage());

        return () => {
            // behaves like componentWillUnmount
            window.removeEventListener('storage', handleStorage);
        };
    }, []);

    return (
        <Fragment>
            <BaseFrame>
                <SchoolHeader school={auth.viewingAs?.school} />

                <Fragment>
                    {storage ? (
                        <h1 className='mb-1'>Attendance</h1>
                    ) : (
                        <>
                            <div className='split-2-header mb-2'>
                                <div className='gridcol2packed'>
                                    <BackButton
                                        linkTo={`/admin/${auth.viewingAs.school?.urlslug}/terms/`}
                                    />
                                    {!term ? (
                                        <div className='borderwrapper'>
                                            <Spinner size={0} />
                                        </div>
                                    ) : (
                                        <LabelWrapper title='Term'>
                                            <select
                                                name='currter'
                                                className='mb-0'
                                                value={termid}
                                                onChange={(e) => {
                                                    let t = terms?.find(
                                                        (t) =>
                                                            t._id ===
                                                            e.target.value
                                                    );
                                                    if (t) {
                                                        let newURL =
                                                            location.pathname.replace(
                                                                termid,
                                                                t._id
                                                            );
                                                        history(newURL);
                                                    }
                                                }}
                                                required
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            >
                                                {terms?.map((term, idx) => {
                                                    return (
                                                        <option
                                                            value={term._id}
                                                            key={idx}
                                                        >
                                                            {term.name}{' '}
                                                            {term.schoolYear}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </LabelWrapper>
                                    )}
                                </div>

                                <button
                                    className='btn btn-primary'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowTermModal(true);
                                    }}
                                >
                                    Edit
                                </button>
                            </div>
                            <AdminTermNav
                                schoolSlug={auth.viewingAs.school?.urlslug}
                                termid={termid}
                                params={auth.searchParams}
                            />
                        </>
                    )}

                    {!term ? (
                        <div className='containerinnersolid'>
                            <Spinner size={0} />
                        </div>
                    ) : location.pathname ===
                      `/admin/${auth.viewingAs.school?.urlslug}/terms/${termid}` ? (
                        <SchoolAdminSchoolTermCalendar />
                    ) : location.pathname ===
                      `/admin/${auth.viewingAs.school?.urlslug}/terms/${termid}/courses` ? (
                        <SchoolAdminSchoolTermCourses term={term} />
                    ) : location.pathname ===
                      `/admin/${auth.viewingAs.school?.urlslug}/terms/${termid}/attendance` ? (
                        <SchoolAdminSchoolTermAttendance term={term} />
                    ) : (
                        <SchoolAdminSchoolTermRegistration term={term} />
                    )}

                    {term && showTermModal && (
                        <AddTermModal
                            previousLocation={location.pathname}
                            submitCallback={(e, formData) => {
                                e.preventDefault();
                                setShowTermModal(false);
                            }}
                            closeCallback={(e) => {
                                e.preventDefault();
                                setShowTermModal(false);
                            }}
                            term={term}
                        />
                    )}
                </Fragment>
            </BaseFrame>
        </Fragment>
    );
};

SchoolAdminSchoolTerm.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    getTermById: PropTypes.func.isRequired,
    getAllTerms: PropTypes.func.isRequired,
    clearTerm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
});

export default connect(mapStateToProps, {
    getTermById,
    getAllTerms,
    clearTerm,
})(SchoolAdminSchoolTerm);
