import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import SegmentedButton from './inputs/SegmentedButton';

const prices = {
    // starter: { monthly: 0, yearly: 0 },
    standard: { monthly: 7, yearly: 7 * 12, discounted: 0.85 },
    premium: { monthly: 19, yearly: 19 * 12, discounted: 0.85 },
};

// types: 1 = starter, 2 = standard, 3 = premium
const PlanTile = ({ type = 1, isSelected = false, isSelectable = false }) => {
    const [currentSegment, setCurrentSegment] = useState('monthly');

    if (!type || type < 1 || type > 3) type = 1;

    let title = '';
    let features = '';
    let price = null;

    switch (type) {
        case 2:
            title = 'Standard';

            price = (
                <Fragment>
                    {currentSegment === 'yearly' ? (
                        <Fragment>
                            <div className='red'>
                                <s>${prices.standard.yearly}</s>
                            </div>
                            <h1>
                                $
                                {Math.floor(
                                    prices.standard.yearly *
                                        prices.standard.discounted
                                )}
                            </h1>
                            <div className='unit'>
                                USD
                                <br />/ year
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h1>${Math.floor(prices.standard.monthly)}</h1>
                            <div className='unit'>
                                USD
                                <br />/ month
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            );
            features = (
                <Fragment>
                    <p className='featuretitle'>
                        includes starter features plus:
                    </p>
                    <p>
                        <i className='fas fa-check text-warm' /> Billing system
                    </p>
                    <p>
                        <i className='fas fa-check text-warm' /> Parent access
                    </p>
                    <p>
                        <i className='fas fa-check text-warm' /> Course
                        scheduling
                    </p>
                    <p>
                        <i className='fas fa-check text-warm' /> Manage up to
                        500 students
                    </p>
                </Fragment>
            );
            break;

        case 3:
            title = 'Premium';
            price = (
                <Fragment>
                    {currentSegment === 'yearly' ? (
                        <Fragment>
                            <div className='red'>
                                <s>${prices.premium.yearly}</s>
                            </div>
                            <h1>
                                $
                                {Math.floor(
                                    prices.premium.yearly *
                                        prices.premium.discounted
                                )}
                            </h1>
                            <div className='unit'>
                                USD
                                <br />/ year
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h1>${Math.floor(prices.premium.monthly)}</h1>
                            <div className='unit'>
                                USD
                                <br />/ month
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            );
            features = (
                <Fragment>
                    <p className='featuretitle'>
                        includes standard features plus:
                    </p>
                    <p>
                        <i className='fas fa-check text-warm' /> Report cards
                    </p>
                    <p>
                        <i className='fas fa-check text-warm' /> Student access
                    </p>
                    <p>
                        <i className='fas fa-check text-warm' /> Unlimited
                        support
                    </p>
                    <p>
                        <i className='fas fa-check text-warm' /> Unlimited
                        students
                    </p>
                </Fragment>
            );
            break;

        case 1:
        default:
            title = 'Starter';
            price = (
                <h1>
                    <em>Free</em>
                </h1>
            );
            features = (
                <Fragment>
                    <p className='featuretitle'>Features included:</p>
                    <p>
                        <i className='fas fa-check text-warm' /> Student
                        registration
                    </p>
                    <p>
                        <i className='fas fa-check text-warm' /> Student pick-up
                        &amp; drop-off system
                    </p>
                    <p>
                        <i className='fas fa-check text-warm' /> Progress
                        reports
                    </p>
                    <p>
                        <i className='fas fa-check text-warm' /> Manage up to
                        100 students
                    </p>
                </Fragment>
            );
            break;
    }

    const switchSegment = (e) => {
        setCurrentSegment(e);
    };

    const SegmentedButtonWithRef = () => {
        return (
            <SegmentedButton
                name={'billingperiod-' + title}
                callback={(val) => switchSegment(val)}
                controlRef={useRef()}
                segments={[
                    {
                        label: 'Monthly',
                        value: 'monthly',
                        ref: useRef(),
                    },
                    {
                        label: 'Yearly',
                        value: 'yearly',
                        ref: useRef(),
                    },
                ]}
            />
        );
    };

    return (
        <div className='plantile'>
            <h1>{title}</h1>
            {type === 1 ? (
                <div className='promobox'>Special promotion</div>
            ) : (
                SegmentedButtonWithRef()
            )}
            <div className='pricebox'>{price}</div>
            <hr />
            <div className='features'>{features}</div>
        </div>
    );
};

PlanTile.propTypes = {
    isSelected: PropTypes.bool,
    isSelectable: PropTypes.bool.isRequired,
};

export default PlanTile;
