// PRIVATE ROUTE: ADMIN LEVEL
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import Spinner from '../../../layout/Spinner';
import BaseFrame from '../../BaseFrame';

const ParentDashboard = ({ auth }) => {
    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Admin Console</title>
            </Helmet>
            <BaseFrame>
                <div className='containerinnersolid'>
                    {auth.loading ? <Spinner /> : <p>Coming soon!</p>}{' '}
                </div>
            </BaseFrame>
        </Fragment>
    );
};

ParentDashboard.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(ParentDashboard);
