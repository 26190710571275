import React, { useEffect, useState, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux'; // connects component to redux
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { verifyEmail } from '../../storedata/actions/auth';
import Spinner from '../layout/Spinner';
import LandingHeader from '../layout/LandingHeader';
import Footer from '../layout/Footer';
import PropTypes from 'prop-types';

// create function component
const VerifyEmail = ({ verifyEmail, auth: { loading, verifying_email } }) => {
    let location = useLocation();
    const [message, updateMessage] = useState({
        msg: 'Email successfully verified!',
        success: true,
    });

    useEffect(() => {
        if (!loading) {
            let queryparams = location.search;
            const params = new URLSearchParams(queryparams);
            let u = params.get('u');
            let c = params.get('c');

            verifyEmail(u, c)
                .then(function (result) {
                    updateMessage(result);
                })
                .catch(function (err) {
                    updateMessage({
                        msg: 'Error verifying email address.',
                        success: false,
                    });
                });
        }
    }, [loading, location.search, verifyEmail]);

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Verify Email Address</title>
            </Helmet>
            <div className='landingbg'>
                <LandingHeader hasButtons={true} />
                <div className='margin-sides-wrapper centerbox'>
                    <div className='modalwrapper width500 p-3'>
                        <h1 className='mb-2'>Verify Email Address</h1>
                        {loading || verifying_email ? (
                            <Spinner />
                        ) : (
                            <div
                                className={`inline-notification ${
                                    message?.success ? '' : 'notification-error'
                                } mb-0`}
                            >
                                <i
                                    className={`${
                                        message?.success
                                            ? 'fas fa-check'
                                            : 'far fa-circle-xmark'
                                    }`}
                                />
                                <span className='ml-1'>{message?.msg}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer hastopline />
        </Fragment>
    );
};

VerifyEmail.propTypes = {
    verifyEmail: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { verifyEmail })(VerifyEmail);
