import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../../storedata/actions/alert';
import Modal from '../../layout/Modal';
import { updateSchoolAttendance } from '../../../storedata/actions/admin';
import SegmentedButton from '../../layout/inputs/SegmentedButton';
import {
    getStudentPic,
    getPrettyDate,
    getPrettyName,
} from '../../../utils/helpers';
import Autocomplete from '../../layout/inputs/Autocomplete';
import Spinner from '../../layout/Spinner';

const initialState = {
    initialized: false,
    status: 0,
    pickUpTime: null,
    dropOffTime: null,
    pickUpPerson: null, // type: {personID, personString}
    dropOffPerson: null, // type: {personID, personString}
    notes: '',
};

const getMinutes = () => {
    let mins = Array.from({ length: 60 }, (value, index) => index);
    return mins.map((min) => {
        return min.toString().padStart(2, '0');
    });
};

// Previous location is the page that this modal sits on top of
const UpdateAttendanceModal = ({
    data, // has student, attendance obj, date

    updateSchoolAttendance,
    submitCallback,
    closeCallback,
    setAlert,
}) => {
    let ref0 = useRef();
    let ref1 = useRef();
    let ref2 = useRef();
    let ref3 = useRef();

    const [formData, setFormData] = useState(initialState);
    const [showDropOffData, setShowDropOffData] = useState(true);
    const [showPickUpData, setShowPickUpData] = useState(true);

    let { student, attendance, date, currentTerm } = data;

    useEffect(() => {
        let now = new Date();
        let newDate = new Date(date);
        newDate.setHours(now.getHours(), now.getMinutes());
        let statusToShow =
            attendance?.status === 2 ? 2 : ((attendance?.status || 0) + 1) % 3;
        const formData = {
            ...initialState,
            status: statusToShow,
            dropOffPerson:
                attendance?.dropOffPerson || initialState.dropOffPerson,
            dropOffTime: attendance?.dropOffTime || newDate,
            pickUpPerson: attendance?.pickUpPerson || initialState.pickUpPerson,
            pickUpTime: attendance?.pickUpTime || newDate,
            initialized: true,
            term: currentTerm._id,
        };

        setShowDropOffData(statusToShow > 0);
        setShowPickUpData(statusToShow === 2);
        setFormData(formData);
    }, [attendance, date, currentTerm._id]);

    let {
        notes,
        status,
        pickUpTime,
        dropOffTime,
        pickUpPerson,
        dropOffPerson,
        initialized,
    } = formData;

    const formatSuggestionsForAutocomplete2 = (contacts) => {
        const data = [...contacts].map((g) => {
            return {
                name: getPrettyName(g.contact.nameInfo, false),
                _id: g.contact._id,
            };
        });
        return data;
    };

    // this one acts on the form data, which has elements
    // that look like {personID: obj, personString: str}
    const formatDataForAutocomplete = (contacts) => {
        const data = [...contacts].map((g) => {
            if (!g) return [];
            let name = g.name
                ? g.name // it has .name if it was set from autocomplete component
                : g.personID // else it was set on pg load: = populated contact obj
                ? getPrettyName(g.personID?.nameInfo, false)
                : g.personString || ''; // or it's a custom string

            return {
                name,
                _id: g._id,
            };
        });

        return data;
    };

    const parseTime = (time) => {
        // use set time or the current date
        let date = time ? new Date(time) : new Date();
        let minutes = date.getMinutes().toString().padStart(2, '0');
        let hours = date.getHours();
        let militaryhours = hours;
        let period = hours >= 12 ? 'PM' : 'AM';

        if (hours > 12) hours = Math.abs(date.getHours() - 12);
        else if (hours === 0) hours = 12;
        hours = hours.toString().padStart(2, '0');

        return { hours, militaryhours, minutes, period };
    };

    const onChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'dropOffPerson' || name === 'pickUpPerson') {
            let parsed = JSON.parse(value);
            parsed = parsed.length > 0 ? parsed[0] : null;
            let name = parsed?.name;
            // can be either name field of obj, OR custom entered string
            value = name
                ? { personID: parsed._id, name, personString: '' }
                : { personID: null, name: parsed, personString: parsed || '' };
        } else if (name === 'dropOffHour' || name === 'pickUpHour') {
            let currentlySetTime = parseTime(
                name === 'dropOffHour' ? dropOffTime : pickUpTime
            );
            name = name === 'dropOffHour' ? 'dropOffTime' : 'pickUpTime';

            let hr = parseInt(value);
            let newdate = new Date(date);
            if (hr === 12 && currentlySetTime.period === 'AM') hr = 0;
            else if (hr < 12 && currentlySetTime.period === 'PM') hr = hr + 12;

            newdate.setHours(hr, currentlySetTime.minutes);
            value = newdate;
        } else if (name === 'dropOffPeriod' || name === 'pickUpPeriod') {
            let currentlySetTime = parseTime(
                name === 'dropOffPeriod' ? dropOffTime : pickUpTime
            );
            name = name === 'dropOffPeriod' ? 'dropOffTime' : 'pickUpTime';

            let period = value;
            let hr = parseInt(currentlySetTime.hours);
            let newdate = new Date(date);
            if (hr === 12 && period === 'AM') hr = 0;
            else if (hr < 12 && period === 'PM') hr = hr + 12;

            newdate.setHours(hr, currentlySetTime.minutes);
            value = newdate;
        } else if (name === 'dropOffMinute' || name === 'pickUpMinute') {
            let currentlySetTime = parseTime(
                name === 'dropOffMinute' ? dropOffTime : pickUpTime
            );
            name = name === 'dropOffMinute' ? 'dropOffTime' : 'pickUpTime';

            let mins = parseInt(value);
            let newdate = new Date(date);
            newdate.setHours(currentlySetTime.militaryhours, mins);
            value = newdate;
        }

        setFormData({ ...formData, [name]: value });
    };

    const switchSegment = (value) => {
        value = parseInt(value);
        setFormData({
            ...formData,
            status: value,
        });

        setShowDropOffData(value > 0);
        setShowPickUpData(value === 2);
    };

    const onSubmit = async (e) => {
        e.preventDefault(); // don't let browser do its default behavior

        if (formData.status === 0) {
            // clear drop off and pick up data if it exists
            setFormData({
                ...formData,
                dropOffPerson: null,
                pickUpPerson: null,
                dropOffTime: null,
                pickUpTime: null,
            });
        }

        if (
            formData.status > 0 &&
            (!formData.dropOffPerson ||
                (!formData.dropOffPerson.personID &&
                    formData.dropOffPerson.personString?.length === 0))
        ) {
            setAlert('Please enter a drop-off person.', 'danger');
            return;
        }

        if (
            formData.status === 2 &&
            (!formData.pickUpPerson ||
                (!formData.pickUpPerson.personID &&
                    formData.pickUpPerson.personString?.length === 0))
        ) {
            setAlert('Please enter a pick-up person.', 'danger');
            return;
        }

        if (
            formData.status === 2 &&
            formData.dropOffTime > formData.pickUpTime
        ) {
            setAlert('The pick-up time must be after drop-off time.', 'danger');
            return;
        }

        updateSchoolAttendance(formData, date, student._id)
            .then((result) => {
                if (result.success) {
                    submitCallback && submitCallback(e, result.success);
                }
            })
            .catch((err) => {});
    };

    let body = !initialized ? (
        <Spinner size={0} />
    ) : (
        <Fragment>
            <div className='row-split-evenly auto2split mb-1 mt-1'>
                <img
                    className='round-img-reg'
                    src={getStudentPic(student.photo)}
                    alt={student.nameInfo.firstName}
                />
                <div>
                    <h2 className='warm'>
                        {getPrettyName(student.nameInfo, false)}
                    </h2>
                    <p className='attendance-modal-date'>
                        {currentTerm.name} | {getPrettyDate(date)}
                    </p>
                </div>
            </div>

            <div>
                <SegmentedButton
                    name='type'
                    classes='mt mb-2'
                    callback={(val) => switchSegment(val)}
                    defaultIndex={status}
                    controlRef={ref3}
                    // 0: absent, 2: school staff, 3: parent, 4: student
                    segments={[
                        {
                            label: 'Mark Absent',
                            value: '0',
                            ref: ref0,
                        },
                        {
                            label: 'Drop Off',
                            value: '1',
                            ref: ref1,
                        },
                        {
                            label: 'Pick Up',
                            value: '2',
                            ref: ref2,
                        },
                    ]}
                />
            </div>

            {showDropOffData && (
                <Fragment>
                    <div className='form-header'>Drop-off person and time*</div>
                    <div className='time-row-split'>
                        <div>
                            <Autocomplete
                                cssID={'dropoff'}
                                inputName='dropOffPerson'
                                selectedData={formatDataForAutocomplete(
                                    dropOffPerson ? [dropOffPerson] : []
                                )}
                                placeholder='Enter name'
                                onChangeHandler={onChange}
                                showAllOptions={true}
                                suggestions={formatSuggestionsForAutocomplete2(
                                    student.contacts
                                )}
                                selectOneMax={true}
                                allowNotFoundSuggestions={true}
                            />
                        </div>
                        <div>
                            <select
                                name='dropOffHour'
                                value={parseTime(dropOffTime).hours}
                                onChange={onChange}
                                required
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            >
                                <option value={'01'}>01</option>
                                <option value={'02'}>02</option>
                                <option value={'03'}>03</option>
                                <option value={'04'}>04</option>
                                <option value={'05'}>05</option>
                                <option value={'06'}>06</option>
                                <option value={'07'}>07</option>
                                <option value={'08'}>08</option>
                                <option value={'09'}>09</option>
                                <option value={'10'}>10</option>
                                <option value={'11'}>11</option>
                                <option value={'12'}>12</option>
                            </select>
                        </div>

                        <div className='time-colon'>
                            <span>:</span>
                        </div>
                        <div>
                            <select
                                name='dropOffMinute'
                                value={parseTime(dropOffTime).minutes}
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            >
                                {getMinutes().map((min, i) => (
                                    <option value={min} key={i}>
                                        {min}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <select
                            name='dropOffPeriod'
                            value={parseTime(dropOffTime).period}
                            onChange={onChange}
                            required
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        >
                            <option value={'AM'}>AM</option>
                            <option value={'PM'}>PM</option>
                        </select>
                    </div>
                </Fragment>
            )}

            {showPickUpData && (
                <Fragment>
                    <div className='form-header'>Pick-up person and time*</div>
                    <div className='time-row-split'>
                        <div>
                            <Autocomplete
                                cssID={'pickup'}
                                inputName='pickUpPerson'
                                selectedData={formatDataForAutocomplete(
                                    pickUpPerson ? [pickUpPerson] : []
                                )}
                                placeholder='Enter name'
                                onChangeHandler={onChange}
                                showAllOptions={true}
                                suggestions={formatSuggestionsForAutocomplete2(
                                    student.contacts
                                )}
                                selectOneMax={true}
                                allowNotFoundSuggestions={true}
                            />
                        </div>
                        <div>
                            <select
                                name='pickUpHour'
                                value={parseTime(pickUpTime).hours}
                                onChange={onChange}
                                required
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            >
                                <option value={'01'}>01</option>
                                <option value={'02'}>02</option>
                                <option value={'03'}>03</option>
                                <option value={'04'}>04</option>
                                <option value={'05'}>05</option>
                                <option value={'06'}>06</option>
                                <option value={'07'}>07</option>
                                <option value={'08'}>08</option>
                                <option value={'09'}>09</option>
                                <option value={'10'}>10</option>
                                <option value={'11'}>11</option>
                                <option value={'12'}>12</option>
                            </select>
                        </div>
                        <div className='time-colon'>
                            <span>:</span>
                        </div>
                        <div>
                            <select
                                name='pickUpMinute'
                                value={parseTime(pickUpTime).minutes}
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            >
                                {getMinutes().map((min, i) => (
                                    <option value={min} key={i}>
                                        {min}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <select
                            name='pickUpPeriod'
                            value={parseTime(pickUpTime).period}
                            onChange={onChange}
                            required
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        >
                            <option value={'AM'}>AM</option>
                            <option value={'PM'}>PM</option>
                        </select>
                    </div>
                </Fragment>
            )}
            <div>
                <div className='form-header'>Notes</div>
                <textarea
                    name='notes'
                    value={notes || initialState.notes}
                    rows={2}
                    onChange={onChange}
                />
            </div>
        </Fragment>
    );
    let steps = [
        {
            body,
            nextStepOnClick: (e) => {
                onSubmit(e);
            },
            nextStepButtonTitle: 'Save',
            finalStepButtonIcon: 'fa fa-save',
        },
    ];

    return (
        <Modal
            steps={steps}
            hasCloseButton={true}
            closeButtonCallBack={closeCallback}
            showAsOverlay={true}
            modalTitle='Update Attendance'
        />
    );
};

UpdateAttendanceModal.propTypes = {
    setAlert: PropTypes.func.isRequired,
    updateSchoolAttendance: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired, // has student obj and attendance obj
};

export default connect(null, {
    setAlert,
    updateSchoolAttendance,
})(UpdateAttendanceModal);
