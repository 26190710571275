import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const CHECKBOX_STATES = {
    Checked: 'Checked',
    Indeterminate: 'Indeterminate',
    Empty: 'Empty',
};

// Component that supports multi-select checkbox-like toggles.
function Checkbox({ label, value, onChange }) {
    const checkboxRef = useRef();

    useEffect(() => {
        if (value === CHECKBOX_STATES.Checked) {
            checkboxRef.current.checked = true;
            checkboxRef.current.indeterminate = false;
        } else if (value === CHECKBOX_STATES.Empty) {
            checkboxRef.current.checked = false;
            checkboxRef.current.indeterminate = false;
        } else if (value === CHECKBOX_STATES.Indeterminate) {
            checkboxRef.current.checked = false;
            checkboxRef.current.indeterminate = true;
        }
    }, [value]);

    return (
        <div>
            <input
                ref={checkboxRef}
                className='mr-1'
                type='checkbox'
                onChange={() =>
                    onChange(
                        checkboxRef.current.checked,
                        checkboxRef.current.indeterminate
                    )
                }
            />
            <label>{label}</label>
        </div>
    );
}

Checkbox.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired, // enum of 3 states
    onChange: PropTypes.func.isRequired, // function used to update state in caller
};

export default Checkbox;
