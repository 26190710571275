// PRIVATE ROUTE: ADMIN LEVEL
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import Spinner from '../../../layout/Spinner';
import BaseFrame from '../../BaseFrame';
import {
    getAllGradeLevels,
    deleteGradeLevel,
    getSchoolById,
} from '../../../../storedata/actions/admin';
import AddContactModal from '../AddContactModal';
import SchoolHeader from '../../SchoolHeader';
import DeleteButton from '../../../layout/inputs/DeleteButton';

const SchoolAdminDashboard = ({
    auth,
    admin,
    getAllGradeLevels,
    deleteGradeLevel,
    getSchoolById,
}) => {
    let location = useLocation();
    let mounted = useRef(false);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount

        return () => {
            // behaves like componentWillUnmount
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (auth.viewingAs.school && !mounted.current) {
            if (!admin.school) {
                getSchoolById(auth.viewingAs.school._id);
            }
            if (!admin.grade_levels) {
                getAllGradeLevels();
            }
            mounted.current = true;
        }
    }, [
        admin.school,
        admin.grade_levels,
        getAllGradeLevels,
        getSchoolById,
        auth.viewingAs.school,
    ]);

    const [showContactModal, setShowContactModal] = useState(false);

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Admin Console</title>
            </Helmet>
            <BaseFrame>
                {!admin.school ? (
                    <Spinner size={0} />
                ) : (
                    <SchoolHeader school={admin.school} />
                )}

                <div className='split-2-header mb-2'>
                    <h1>Grade Levels</h1>
                    <Link
                        to={`/admin/${auth.viewingAs.school?.urlslug}/gradelevels/addgradelevel`}
                        className='btn btn-primary'
                    >
                        Add Grade Level
                    </Link>
                </div>
                <div className='containerinnersolid'>
                    {admin.grade_levels?.length > 0 ? (
                        <div className='term-boxes'>
                            {admin.grade_levels.map((level, idx) => {
                                return (
                                    <div key={idx}>
                                        <div className='split-2-header'>
                                            <div>
                                                <h2 className='darkercolor'>
                                                    {level.name}
                                                </h2>
                                            </div>
                                            <div className='gridcol'>
                                                <Link
                                                    className='btn'
                                                    to={`/admin/${auth.viewingAs.school?.urlslug}/gradelevels/editgradelevel/${level._id}`}
                                                    state={{
                                                        previousLocation:
                                                            location,
                                                    }}
                                                >
                                                    <i className='fas fa-pen' />
                                                </Link>
                                                <DeleteButton
                                                    callback={() =>
                                                        deleteGradeLevel(
                                                            level._id
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>

                                        {level.description && (
                                            <p className='mt'>
                                                {level.description}
                                            </p>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <h3>None yet.</h3>
                    )}
                </div>
                {showContactModal && (
                    <AddContactModal
                        previousLocation={location.pathname}
                        submitCallback={(e, formData) => {
                            e.preventDefault();
                            setShowContactModal(false);
                        }}
                        closeCallback={(e) => {
                            e.preventDefault();
                            setShowContactModal(false);
                        }}
                    />
                )}
            </BaseFrame>
        </Fragment>
    );
};

SchoolAdminDashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    getAllGradeLevels: PropTypes.func.isRequired,
    getSchoolById: PropTypes.func.isRequired,
    deleteGradeLevel: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
});

export default connect(mapStateToProps, {
    getSchoolById,
    getAllGradeLevels,
    deleteGradeLevel,
})(SchoolAdminDashboard);
