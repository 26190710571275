import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LogoTitle from './LogoTitle';
import { logout } from '../../storedata/actions/auth';

const LandingHeader = ({
    hasButtons,
    auth: { isAuthenticated, user },
    logout,
}) => {
    // useHistory to access history in this component since it isn't a routed component page
    let history = useNavigate();

    // This behaves like componentDidMount
    useEffect(() => {
        window.addEventListener('scroll', () => handleScroll2());
        return function cleanup() {
            // behaves like componentWillUnmount
            window.removeEventListener('scroll', handleScroll2());
        };
    }, []);

    const [showScrollShadow, setshowScrollShadow] = useState(false);

    const handleScroll2 = () => {
        setshowScrollShadow(window.pageYOffset > 0); // 60 is header height
    };

    return (
        <header
            className={showScrollShadow ? 'headerbg' : 'emptyclass'} // emptyclass just to fill it in
        >
            <nav id='landingheader'>
                <LogoTitle />

                {hasButtons && (
                    <div className='landingheader-buttons'>
                        {!isAuthenticated ? (
                            <Fragment>
                                {' '}
                                <Link to='/login' className='btn'>
                                    Sign In
                                </Link>
                                <Link
                                    to='/register'
                                    className='btn btn-primary'
                                >
                                    Register
                                </Link>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Link to='/base' className='btn btn-primary'>
                                    Home
                                </Link>

                                <a
                                    className='btn btn-primary'
                                    onClick={() => logout(user, history)}
                                    href='#!'
                                >
                                    Sign Out
                                </a>
                            </Fragment>
                        )}
                    </div>
                )}
            </nav>
        </header>
    );
};

LandingHeader.propTypes = {
    hasButtons: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { logout })(LandingHeader);
