// PRIVATE PAGE: View My Messages
import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import { getAllInstitutions } from '../../../../storedata/actions/superadmin';
import BaseFrame from '../../BaseFrame';
import Spinner from '../../../layout/Spinner';
import SchoolTile from '../../admin/SchoolTile';

const Institutions = ({ getAllInstitutions, auth, superadmin }) => {
    useEffect(() => {
        if (superadmin.institutions.length === 0) getAllInstitutions();
    }, [getAllInstitutions, superadmin.institutions]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Schools</title>
            </Helmet>
            <BaseFrame>
                {superadmin.loading ? (
                    <Spinner size={0} />
                ) : (
                    <Fragment>
                        <h1 className='mb-2'>
                            {superadmin.institutions.length} School
                            {superadmin.institutions.length !== 1 && 's'}
                        </h1>
                        <div className='school-tile-wrapper'>
                            {superadmin.institutions.map((school, idx) => {
                                return (
                                    <SchoolTile
                                        onMainPage={false}
                                        school={school}
                                        key={idx}
                                        editAs={'superadmin'}
                                    />
                                );
                            })}
                        </div>
                    </Fragment>
                )}
            </BaseFrame>
        </Fragment>
    );
};

Institutions.propTypes = {
    getAllInstitutions: PropTypes.func.isRequired,
    superadmin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    superadmin: state.superadmin,
    auth: state.auth,
});

export default connect(mapStateToProps, { getAllInstitutions })(Institutions);
