// PRIVATE ROUTE: ADMIN LEVEL
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import Spinner from '../../../layout/Spinner';
import BaseFrame from '../../BaseFrame';
import { getAllContacts } from '../../../../storedata/actions/admin';
import ContactTile from '../ContactTile';
import SchoolHeader from '../../SchoolHeader';

const baseurl = '/admin';

const SchoolAdminContacts = ({ auth, admin, getAllContacts }) => {
    let location = useLocation();
    const [urlSlug, setUrlSlug] = useState(null);
    let mounted = useRef(false);

    useEffect(() => {
        if (!auth.loading && !mounted.current) {
            if (!admin.contacts) getAllContacts();
            let school = auth.viewingAs.school;
            setUrlSlug(baseurl + (school ? '/' + school.urlslug : ''));
            mounted.current = true;
        }
    }, [auth.loading, admin.contacts, getAllContacts, auth.viewingAs?.school]);

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Contacts</title>
            </Helmet>
            <BaseFrame>
                <SchoolHeader school={auth.viewingAs?.school} />
                {!admin.contacts || !auth.viewingAs || !mounted.current ? (
                    <div className='containerinnersolid'>
                        <Spinner size={0} />
                    </div>
                ) : (
                    <Fragment>
                        <div className='split-2-header mb-2'>
                            <h1>
                                {admin.contacts.length} Contact
                                {admin.contacts.length !== 1 && 's'}
                            </h1>

                            <Link
                                className='btn btn-primary'
                                to='addcontact'
                                state={{
                                    previousLocation: location,
                                }}
                            >
                                Add Contact
                            </Link>
                        </div>
                        <div className='containerinner'>
                            <div className='contact-tile-wrapper'>
                                {admin.contacts.map((contact, idx) => {
                                    return (
                                        <ContactTile
                                            contact={contact}
                                            urlSlug={urlSlug}
                                            key={idx}
                                            editAs={'admin'}
                                            state={{
                                                previousLocation: location,
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </Fragment>
                )}
            </BaseFrame>
        </Fragment>
    );
};

SchoolAdminContacts.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    getAllContacts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
});

export default connect(mapStateToProps, { getAllContacts })(
    SchoolAdminContacts
);
