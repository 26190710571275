import React from 'react';
import PropTypes from 'prop-types';

// previouscallback: a function in caller that will go to a previous step in the modal
// nextcallback: a function in caller that will go to the next step in the modal
const ModalFooter = ({
    hasPrevStep = false,
    previouscallback = null,
    nextcallback = null,
    nexttitle = 'Next',
    nexticon = 'fa fa-arrow-right',
}) => {
    return (
        <section className={'modalfooter'}>
            <div>
                {hasPrevStep && previouscallback && (
                    <button
                        type='button'
                        className='btn'
                        onClick={() => previouscallback()}
                    >
                        <i className='fa fa-arrow-left' />
                        Back
                    </button>
                )}
            </div>
            <div>
                {nextcallback && (
                    <button
                        type='button'
                        className='btn btn-primary'
                        onClick={(e) => nextcallback(e)}
                    >
                        {nexttitle}
                        <i className={nexticon} />
                    </button>
                )}
            </div>
        </section>
    );
};

ModalFooter.propTypes = {
    hasPrevStep: PropTypes.bool.isRequired,
    previouscallback: PropTypes.func,
    nextcallback: PropTypes.func,
    nexttitle: PropTypes.string,
    nexticon: PropTypes.string,
};

export default ModalFooter;
