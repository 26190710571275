import api from '../../utils/api';
import upload_api from '../../utils/upload_api';
import { setAlert } from './alert';
import { checkAppVersion } from '../../utils/helpers';
import {
    REGISTER_SUCCESS,
    USER_LOADED,
    FRESH_USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    REMOVE_ALL_ALERTS,
    VERIFYING_EMAIL,
    DONE_VERIFYING_EMAIL,
    RESETTING_PWD,
    DONE_RESETTING_PWD,
    USER_SWITCH_VIEW,
    UPDATE_PROFILE,
    LOADING_PROFILE_PIC,
    UPDATE_PROFILE_PIC,
    DONE_LOADING_PROFILE_PIC,
    UPDATE_SEARCH_PARAMS,
} from '../actions/types';
import actionErrorHandler from './helpers/actionErrorHandler';
import { datadogRum } from '@datadog/browser-rum';

const removeTrailingSlash = (str = '') => {
    return str.replace(/\/+$/, '');
};

export const updateSearchParams = (page, paramstr) => async (dispatch) => {
    if (!page) return;

    page = removeTrailingSlash(page); // to keep URLs consistent

    let payload = { [page]: paramstr };

    //let regexS = /^\/admin\/[a-z0-9]+\/students[/]*$/g;
    // let regexA = /^\/admin\/[a-z0-9]+\/terms\/[a-z0-9]{24}\/attendance[/]*$/g;

    // // see if I also need to do it for attendance pg
    // if (regexA.test(page)) {
    //     var termidRegex = /\/[a-z0-9]{24}\//;
    //     if (termidRegex.test(page)) {
    //         let newpg = page.replace(termidRegex, '/TERMID/');
    //         payload = { [newpg]: paramstr };
    //     }
    // }

    dispatch({
        type: UPDATE_SEARCH_PARAMS,
        payload,
    });
};

// Load User: on pg refreshes
export const loadUser = () => async (dispatch) => {
    try {
        const res = await api.get('/users/auth');

        dispatch(checkAppVersion(res)); // so user can refresh page if their version is stale
        // localStorage.removeItem('parentMode');
        let user = res.data; // user obj with all fields except password

        dispatch({
            type: USER_LOADED,
            payload: user,
        });
    } catch (err) {
        dispatch({
            type: AUTH_ERROR,
        });
    }
};

// load fresh user: for registration and logins
export const loadFreshUser = () => async (dispatch) => {
    try {
        const res = await api.get('/users/auth');

        dispatch(checkAppVersion(res)); // so user can refresh page if their version is stale

        let user = res.data; // user obj with all fields except password
        let id = user._id;

        // set user for datadog RUM session
        datadogRum.setUser({
            id,
            email: user.email,
            app: 'School Trail',
        });

        dispatch({
            type: FRESH_USER_LOADED,
            payload: user, // payload is the user
        });
    } catch (err) {
        dispatch({
            type: AUTH_ERROR,
        });
    }
};

// register form data, which is object with structure { name, email, password, password2 }
export const register = (formData) => async (dispatch) => {
    try {
        const res = await api.post('/users/register', formData);
        // Dispatch an action to the reducer. This is the only way to trigger a state change.
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data, // we get back the token
        });

        // send in case errors were on screen when registering
        dispatch({
            type: REMOVE_ALL_ALERTS, // no payload needed.
        });

        dispatch(loadFreshUser()); // registering will go to sign in user so this is just a precaution
    } catch (err) {
        actionErrorHandler(dispatch, err, AUTH_ERROR);
    }
};

// Login User
export const login = (email, password) => async (dispatch) => {
    const body = { email, password };

    try {
        const res = await api.post('/users/login', body);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data, // payload is the token
        });

        dispatch(loadFreshUser());
        return true;
    } catch (err) {
        actionErrorHandler(dispatch, err, AUTH_ERROR);
        return false;
    }
};

export const verifyEmail = (id, hash) => async (dispatch) => {
    const data = { hash };

    if (!id || !hash) {
        dispatch({
            type: DONE_VERIFYING_EMAIL,
            payload: { msg: 'Invalid email verification link.' },
        });
        return {
            msg: 'Invalid email verification link.',
            success: false,
        };
    }

    try {
        dispatch({ type: VERIFYING_EMAIL });

        const res = await api.post(`/users/verifyemail/${id}`, data);

        dispatch({
            type: DONE_VERIFYING_EMAIL,
            payload: res.data, // payload has the msg and success bool
        });

        return res.data;
    } catch (err) {
        let errmsg = actionErrorHandler(
            dispatch,
            err,
            DONE_VERIFYING_EMAIL,
            false
        );

        return {
            msg: errmsg,
            success: false,
        };
    }
};

export const forgotPassword = (email) => async (dispatch) => {
    const data = { email };

    try {
        dispatch({ type: RESETTING_PWD });

        const res = await api.post(`/users/forgotpassword`, data);

        dispatch({
            type: DONE_RESETTING_PWD,
            payload: res.data, // payload has the msg and success bool
        });
        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            // error will be displayed in UI so not setAlert call needed
            let errmsg = actionErrorHandler(
                dispatch,
                err,
                DONE_RESETTING_PWD,
                false
            );

            return {
                msg: errmsg,
                success: false,
            };
        }
    }
};

// reset pswd. returns msg + true on success; false on fail
export const resetPassword = (formData, hash, history) => async (dispatch) => {
    let msg = 'Invalid reset password link.';
    if (!hash) {
        dispatch({
            type: DONE_RESETTING_PWD,
            payload: { msg },
        });
        return {
            msg,
            success: false,
        };
    }

    try {
        dispatch({ type: RESETTING_PWD });

        const res = await api.post(`/users/resetpassword/${hash}`, formData);

        dispatch({
            type: DONE_RESETTING_PWD,
            payload: res.data, // payload has the msg and success bool
        });

        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            // error will be displayed in UI so not setAlert call needed
            let errmsg = actionErrorHandler(
                dispatch,
                err,
                DONE_RESETTING_PWD,
                false
            );

            return {
                msg: errmsg,
                success: false,
            };
        }
    }
};

export const resendVerificationEmail =
    (viewingAsUserId) => async (dispatch) => {
        try {
            dispatch({ type: VERIFYING_EMAIL });

            const data = { viewingAsUserId };
            const res = await api.post('/users/resendemailverification', data);
            dispatch({
                type: DONE_VERIFYING_EMAIL,
                payload: res.data, // payload has the result message and success bool
            });

            return res.data;
        } catch (err) {
            const errors = err.response.data.errors;

            if (errors) {
                // error will be displayed in UI so not setAlert call needed
                let errmsg = actionErrorHandler(
                    dispatch,
                    err,
                    DONE_VERIFYING_EMAIL,
                    false
                );

                return {
                    msg: errmsg,
                    success: false,
                };
            }
        }
    };

export const logout = (_user, history) => (dispatch) => {
    dispatch({ type: LOGOUT });

    if (history) {
        history('/');
    }

    dispatch(
        setAlert(
            'Successfully logged out. See you soon!',
            'success',
            true /* persist on page changes */,
            true /* clear all other alerts */
        )
    );

    datadogRum.removeUser();

    localStorage.removeItem('parentMode'); // logging out turns isolated setting off
    localStorage.removeItem('viewingAs'); // logging out removes superadmin viewingAs stuff

    // userLogger.info('User ' + user.email + ' logged out', {
    //     email: `${user.email}`,
    //     user_id: `${user._id}`,
    //     app_location: 'auth_action',
    // });
};

// update user info: name, email, or password
export const updateInfo = (formData) => async (dispatch) => {
    try {
        const res = await api.post('/users/update', formData);
        dispatch(checkAppVersion(res));
        // send in case errors were on screen when registering
        dispatch({
            type: REMOVE_ALL_ALERTS, // no payload needed
        });

        dispatch({
            type: UPDATE_PROFILE,
            payload: res.data, // payload is user object
        });

        dispatch(
            setAlert(
                'Account settings updated',
                'success',
                true /* persist on page changes */
            )
        );
    } catch (err) {
        const errors = err.response.data.errors;

        // show first error
        if (errors) {
            dispatch(setAlert(errors[0].msg, 'danger'));
        }

        // no need to dispatch auth error. just show error above
    }
};

export const switchView = (role, school) => (dispatch) => {
    dispatch({
        type: USER_SWITCH_VIEW,
        payload: { role, school },
    });
};

// update profile picture
export const uploadProfilePic = (formData) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_PROFILE_PIC });

        // formData stores file and viewingAsUserId
        const res = await upload_api.post(`/users/uploadmedia`, formData);

        dispatch({
            // send to profile
            type: UPDATE_PROFILE_PIC,
            payload: res.data, // photo obj
        });

        dispatch(
            setAlert(
                'Profile photo updated',
                'success',
                true /* persist on page changes */
            )
        );
    } catch (err) {
        actionErrorHandler(dispatch, err, DONE_LOADING_PROFILE_PIC);
    }
};
