import { ADD_MODAL, REMOVE_MODAL } from './types';
import uuid from 'uuid-random';

// actions have a type and a payload. Actions dispatch type and payload to the reducer,
// and the reducer updates the state.
// The following will send the payload and type to the reducer.
// addModal: sends an action with modal payload to reducer.
export const addModal = () => (dispatch) => {
    const id = uuid(); // randomly generated ID

    let payload = {
        id,
    };

    // Dispatch an action to the reducer. This is the only way to trigger a state change.
    dispatch({
        type: ADD_MODAL,
        payload,
    });

    return id; // so modal component can keep track of ID and remove modal from state when it is closed
};

// sends action with modal id to reducer for modal removal
export const removeModal = (id) => (dispatch) => {
    dispatch({ type: REMOVE_MODAL, payload: id });
};
