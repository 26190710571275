import React, { useState, Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux'; // connects component to redux
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { forgotPassword } from '../../storedata/actions/auth';
import Spinner from '../layout/Spinner';
import LandingHeader from '../layout/LandingHeader';
import Footer from '../layout/Footer';
import PropTypes from 'prop-types';

// create function component
const ForgotPassword = ({
    forgotPassword,
    auth: { loading, isAuthenticated, resetting_pwd },
}) => {
    const [formData, setFormData] = useState({
        message: null,
        success: false,
        email: '',
        submitted: false,
    });

    const { message, success, email, submitted } = formData;

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = async (e) => {
        e.preventDefault(); // don't let browser do its default behavior
        forgotPassword(email)
            .then(function (result) {
                setFormData({
                    ...formData,
                    message: result.msg,
                    success: result.success,
                    submitted: true,
                });
            })
            .catch(function (err) {
                setFormData({
                    ...formData,
                    message: 'Error verifying email address.',
                    success: false,
                    submitted: true,
                });
            });
    };

    if (isAuthenticated) {
        return <Navigate to='/base' />;
    } else
        return (
            <Fragment>
                <Helmet>
                    <title>{APP_NAME} | Forgot Password</title>
                </Helmet>
                <div className='landingbg'>
                    <LandingHeader hasButtons={true} />
                    <div className='margin-sides-wrapper centerbox'>
                        <div className='modalwrapper width500 p-3'>
                            <h1 className='mb-2'>Reset Your Password</h1>
                            <p>
                                Enter your email address and we'll send you a
                                link to reset your password.
                            </p>
                            {!message?.success && (
                                <form className='form' onSubmit={onSubmit}>
                                    <input
                                        type='text'
                                        placeholder='Enter account email address'
                                        name='email'
                                        value={email}
                                        onChange={onChange}
                                        required
                                    />
                                    {loading || resetting_pwd ? (
                                        <Spinner size={1} />
                                    ) : submitted ? (
                                        <div
                                            className={`inline-notification ${
                                                success
                                                    ? ''
                                                    : 'notification-error'
                                            } mb-2 mt`}
                                        >
                                            <i
                                                className={`${
                                                    success
                                                        ? 'fas fa-check'
                                                        : 'far fa-circle-xmark'
                                                }`}
                                            />
                                            <span className='ml-1'>
                                                {message}
                                            </span>
                                        </div>
                                    ) : null}
                                    <input
                                        type='submit'
                                        className='btn btn-primary mr-0'
                                        value={
                                            submitted && success
                                                ? 'Resend Link'
                                                : 'Send Link'
                                        }
                                    />
                                </form>
                            )}
                        </div>
                    </div>
                </div>
                <Footer hastopline />
            </Fragment>
        );
};

ForgotPassword.propTypes = {
    forgotPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);
