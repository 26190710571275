import { CLIENT_VERSION } from './constants';
import api from './api';
import upload_api from './upload_api';
import { setAlert } from '../storedata/actions/alert';
import defaultProfilePic from '../img/defaultProfilePic.png';
import defaultStudentPic from '../img/defaultStudentPic.png';
import defaultStaffPic from '../img/defaultStaffPic.png';
import defaultSchoolPic from '../img/defaultSchoolPic.png';

export const truncateString = (string, limit) => {
    if (string.length > limit) {
        return string.substring(0, limit) + '...';
    } else {
        return string;
    }
};

export const toggleFullScreen = (element) => {
    if (!element) element = document.documentElement;

    if (element?.requestFullScreen) {
        element?.requestFullScreen();
    } else if (element?.webkitRequestFullScreen) {
        element?.webkitRequestFullScreen();
    } else if (element?.mozRequestFullScreen) {
        element?.mozRequestFullScreen();
    } else if (element?.msRequestFullscreen) {
        element?.msRequestFullscreen();
    } else if (element?.webkitEnterFullscreen) {
        element?.webkitEnterFullscreen(); //for iphone this code worked
    }
};

// returns validated url search params object
const checkURLParams = (searchParams, validkeys) => {
    for (const [key, value] of searchParams) {
        if (!validkeys.includes(key) || !value || value.length === 0) {
            searchParams.delete(key);
        }
    }

    return searchParams;
};

// parses the url to get the filter params
const deleteURLparam = (history, location, key, invalid = false) => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(key)) {
        searchParams.delete(key);

        // if page num exists in url, reset it to 1: !!! todo add back for pagination?
        // let pgkey = 'pg';
        // let value = 1;
        // if (searchParams.has(pgkey)) {
        //     searchParams.set(pgkey, value);
        // }
        // else {
        //     searchParams.append(pgkey, value);
        // }

        let url = '?' + searchParams.toString();

        history(url, {
            replace: invalid, // only replace URL if param was invalid and is being deleted because of that
            isActive: true,
        });

        return url;
    }
};

const setURLparam = (history, ls, kvobj /*, thunk = null*/) => {
    const searchParams = new URLSearchParams(ls);

    for (var key in kvobj) {
        let value = kvobj[key];
        if (searchParams.has(key)) {
            // only set it if it has a valid value
            if (value) {
                searchParams.set(key, value);
            } else {
                searchParams.delete(key);
            }
        } else if (value) {
            // only add if valid
            searchParams.append(key, value);
        }
    }

    let url = '?' + searchParams.toString();

    if (history) {
        history(url);
    }
    return url;
};

// returns search string from url where, for ex., the key is 'ss' and the val is the searched query
// note: I handle deletions in case the user enters an invalid/empty param
const getURLParam = (param, queryparam = '') => {
    let str = '';
    if (!queryparam) return str;

    const searchParams = new URLSearchParams(queryparam);
    let val = searchParams.get(param); // e.g. ss=str
    if (val && val.length) {
        str = val;
    }

    return str;
};

const getPageNumParam = (history, location) => {
    let param = getURLParam('pg', location);
    let val = !param ? null : parseInt(param);
    if (!val) {
        val = 1;
        deleteURLparam(history, location, 'pg');
    }
    return val;
};

const dataIsFiltered = (query_str) => {
    let regex = /&?pg=[0-9]/g;

    // strip out page number (based on regex) if present
    let str = query_str ? query_str.replace(regex, '') : '';
    str = str.replace('?', '');
    return str.length > 0;
};

// helper object that stores all URL parsing functions for use in products, ingredients, etc. pages
export const URLhelpers = {
    deleteURLparam,
    setURLparam,
    getURLParam,
    checkURLParams,
    getPageNumParam,
    dataIsFiltered,
};

export const toggleStorage = (item) => {
    let mode = localStorage.getItem(item);
    localStorage.setItem(
        item,
        mode ? !JSON.parse(mode) /* browsers store strings */ : true
    );
};

export const storageItemIsTrue = (item) => {
    let mode = localStorage.getItem(item);
    if (mode) {
        if (mode === 'true' || mode === 'false') {
            return JSON.parse(mode);
        } else return mode;
    } else return false;
};

// used to check client version against server-stored app version. currently, this is only checked on data fetches for some requests
export const checkAppVersion = (res) => (dispatch) => {
    const server_version = res.headers['x-server-version'];

    if (server_version && CLIENT_VERSION !== server_version) {
        // !== instead of less than since version numbers are wonky when comparing. the point is, they should be the same; if they aren't, client browser needs to be refreshed.
        dispatch(
            setAlert(
                'Our website has been updated! Refresh the page to get the latest version.',
                'primary',
                true,
                true, // remove all others in case this is called again
                false,
                false
            )
        );
        console.log('Site updated from', CLIENT_VERSION, 'to', server_version);
    }
};

// set header for user id being viewed
export const setViewingAsUserIDheader = (id) => {
    api.defaults.headers.common['x-viewing-as-user-id'] = id;
    upload_api.defaults.headers.common['x-viewing-as-user-id'] = id; // TODO not sure if it needs to be on this api too
};

export const setViewingAsSchoolIDheader = (id) => {
    api.defaults.headers.common['x-viewing-as-school-id'] = id;
    upload_api.defaults.headers.common['x-viewing-as-school-id'] = id; // TODO not sure if it needs to be on this api too
};

export const parseTotalTime = (timeminutes, returnAsString = false) => {
    // returns object with the minutes and hours split out for UI display,
    // or the prettified string if only need to display the string
    let timeobj = {
        minutes: timeminutes % 60,
        hours: Math.floor(timeminutes / 60),
    };

    if (returnAsString) {
        let hours = timeobj.hours ? timeobj.hours + ' hour' : '';
        let hoursPlural = timeobj.hours > 1 ? 's' : '';

        let middle = timeobj.hours && timeobj.minutes ? ', ' : '';

        let minutesPlural = timeobj.minutes > 1 ? 's' : '';
        let minutes = timeobj.minutes ? timeobj.minutes + ' minute' : '';

        return hours + hoursPlural + middle + minutes + minutesPlural;
    } else return timeobj;
};

const ImgTypeEnum = { PROFILE: 1, STUDENT: 2, STAFF: 3, SCHOOL: 4, CONTACT: 5 };
Object.freeze(ImgTypeEnum); // don't allow new options to be added

export const ImgTypes = () => {
    return ImgTypeEnum;
};

export const getUserPic = (photoobj) => {
    return getImageData(photoobj, ImgTypeEnum.PROFILE);
};

export const getStudentPic = (photoobj) => {
    return getImageData(photoobj, ImgTypeEnum.STUDENT);
};

export const getSchoolPic = (photoobj) => {
    return getImageData(photoobj, ImgTypeEnum.SCHOOL);
};

export const getContactPic = (photoobj) => {
    return getImageData(photoobj, ImgTypeEnum.CONTACT);
};

const getImageData = (photoobj, imgType) => {
    if (!photoobj) {
        return getDefaultImg(imgType);
    }
    let buffer = photoobj.data;
    let type = photoobj.type;
    const b64 = buffer?.toString('base64');

    let src = 'data:' + type + ';base64,' + b64;
    return src || getDefaultImg(imgType);
};

const getDefaultImg = (imgType) => {
    switch (imgType) {
        case ImgTypeEnum.PROFILE:
            return defaultProfilePic;
        case ImgTypeEnum.STUDENT:
            return defaultStudentPic;
        case ImgTypeEnum.STAFF:
            return defaultStaffPic;
        case ImgTypeEnum.SCHOOL:
            return defaultSchoolPic;
        case ImgTypeEnum.CONTACT:
        default:
            return defaultProfilePic;
    }
};

const parseSuffix = (suffix) => {
    switch (parseInt(suffix)) {
        case 0:
            return 'Jr.';
        case 1:
            return 'III';
        case 2:
            return 'IV';
        case 3:
            return 'Sr.';
        default:
            return '';
    }
};

export const parseContactRelationship = (relationship) => {
    switch (parseInt(relationship)) {
        case 0:
            return 'Legal Guardian';
        case 1:
            return 'Mother';
        case 2:
            return 'Father';
        case 3:
            return 'Aunt';
        case 4:
            return 'Uncle';
        case 5:
            return 'Grandmother';
        case 6:
            return 'Grandfather';
        case 7:
            return 'Caregiver';
        case 8:
            return 'Stepmother';
        case 9:
            return 'Stepfather';
        case 10:
        default:
            return 'Other';
    }
};

export const parseContactRelationshipType = (type) => {
    switch (parseInt(type)) {
        case 0:
            return 'Parent/Legal Guardian';
        case 1:
            return 'Emergency Contact';
        case 2:
        default:
            return 'Authorized Pick-up Person';
    }
};

export const getPrettyName = (nameInfo, showMiddleName = true) => {
    if (!nameInfo) return ' ';

    let name =
        nameInfo.firstName +
        ' ' +
        (showMiddleName && nameInfo.middleName
            ? nameInfo.middleName + ' '
            : '') +
        nameInfo.lastName +
        (nameInfo.nameSuffix ? ' ' + parseSuffix(nameInfo.nameSuffix) : '');

    return name;
};

// 0: superadmin 1: school admin, 2: school staff, 3: parent, 4: student

const roles = {
    0: { name: 'Superadmin', basepath: '/superadmin' },
    1: { name: 'Administrator', basepath: '/admin' },
    2: { name: 'Staff', basepath: '/staff' },
    3: { name: 'Parent', basepath: '/parent' },
    4: { name: 'Student', basepath: '/student' },
};

export const getRoleName = (roleType) => {
    return roles[roleType]?.name;
};

export const getRoleBasePath = (roleType) => {
    if (roleType === undefined || roleType < 0 || roleType > 4) return '/';
    return roles[roleType].basepath;
};

export const getRoleFromPath = (path) => {
    // !!!! ensure path is a valid one of the options, otherwise get it from user role and reroute to a valid one they have access to
    if (path.startsWith(getRoleBasePath(0))) return 0;
    else if (path.startsWith(getRoleBasePath(1))) return 1;
    else if (path.startsWith(getRoleBasePath(2))) return 2;
    else if (path.startsWith(getRoleBasePath(3))) return 3;
    else if (path.startsWith(getRoleBasePath(4))) return 4;
};

// !!!!!!! todo filter by approved roles only?
export const getRoleTypes = (roles, getApprovedOnly = false) => {
    let updatedroles = getApprovedOnly
        ? [...roles].filter((role) => {
              return role.isApproved; // skip unapproved roles
          })
        : [...roles];
    let result = updatedroles
        .map(({ type, institution }) => {
            return {
                type,
                name: getRoleName(type),
                path: getRoleBasePath(type),
                institution: institution || null,
            };
        })
        .sort((a, b) => {
            return a.type - b.type;
        });

    /*  [
          {
            institution: "6461ce458fa7a48fb593939b"
            name: "Administrator"
            type: 1
          }
        ]
    */
    return result;
};

// 1: school admin, 2: school staff, 3: parent, 4: student
export const RoleTypeEnum = {
    SUPERADMIN: 0,
    ADMIN: 1,
    STAFF: 2,
    PARENT: 3,
    STUDENT: 4,
};

export const findSchoolFromRoles = (schoolslug, roles) => {
    for (let i = 0; i < roles?.length; i++) {
        let role = roles[i];
        if (role.institution?.urlslug === schoolslug) return role.institution;
    }

    return null; // school not found in the user's roles
};

export const findStrongestRoleFromSchool = (schoolid, roles) => {
    // increasing order to put strongest one first
    let sortedRoles = roles.sort((a, b) => {
        return a.type - b.type;
    });

    for (let i = 0; i < sortedRoles?.length; i++) {
        let role = roles[i];
        if (!role.isApproved) continue;
        if (role.type === RoleTypeEnum.SUPERADMIN) return 0; // superadmin
        if (role.institution?._id === schoolid && role.isApproved)
            return role.type;
    }
    return null; // school not found in the user's roles
};

export const hasAccessToSchool = (schoolid, roles) => {
    for (let i = 0; i < roles?.length; i++) {
        let role = roles[i];
        if (!role.isApproved) continue;
        if (role.type === RoleTypeEnum.SUPERADMIN) return true; // superadmin

        if (role.institution?._id === schoolid) return true;
    }
    return false; // school not found in the user's roles
};

export const getSchools = (roles, getApprovedOnly = false) => {
    let result = roles
        .filter((role) => {
            return role?.institution !== null && getApprovedOnly
                ? role?.isApproved
                : true; // skip null and unapproved roles
        })
        .map(({ institution }) => {
            return institution;
        })
        .sort((a, b) => {
            return a - b;
        });
    // !!!! remove duplicates!

    return result;
};

export const getStrongestRole = (roles) => {
    return getRoleTypes(roles)[0];
};

export const getUserName = (user) => {
    return user?.nameInfo.firstName + ' ' + user.nameInfo.lastName;
};

export const isSchoolAdmin = (roles) => {
    return roles?.filter((r) => r.type === RoleTypeEnum.ADMIN).length > 0;
};

export const isParent = (roles) => {
    return roles?.filter((r) => r.type === RoleTypeEnum.PARENT).length > 0;
};

export const isStaff = (roles) => {
    return roles?.filter((r) => r.type === RoleTypeEnum.STAFF).length > 0;
};

export const isStudent = (roles) => {
    return roles?.filter((r) => r.type === RoleTypeEnum.STUDENT).length > 0;
};

export const country_list = [
    {
        name: 'Jamaica',
        _id: 'jam',
        regions: [
            'Clarendon',
            'Hanover',
            'Kingston/St. Andrew',
            'Manchester',
            'Portland',
            'Saint Ann',
            'Saint Catherine',
            'Saint Elizabeth',
            'Saint James',
            'Saint Mary',
            'Saint Thomas',
            'Trelawny',
            'Westmoreland',
        ],
        regionName: 'Parish',
    },
    {
        name: 'United States of America',
        _id: 'usa',
        regions: [
            'Alabama',
            'Alaska',
            'American Samoa',
            'Arizona',
            'Arkansas',
            'California',
            'Colorado',
            'Connecticut',
            'Delaware',
            'District of Columbia',
            'Federated States of Micronesia',
            'Florida',
            'Georgia',
            'Guam',
            'Hawaii',
            'Idaho',
            'Illinois',
            'Indiana',
            'Iowa',
            'Kansas',
            'Kentucky',
            'Louisiana',
            'Maine',
            'Marshall Islands',
            'Maryland',
            'Massachusetts',
            'Michigan',
            'Minnesota',
            'Mississippi',
            'Missouri',
            'Montana',
            'Nebraska',
            'Nevada',
            'New Hampshire',
            'New Jersey',
            'New Mexico',
            'New York',
            'North Carolina',
            'North Dakota',
            'Northern Mariana Islands',
            'Ohio',
            'Oklahoma',
            'Oregon',
            'Palau',
            'Pennsylvania',
            'Puerto Rico',
            'Rhode Island',
            'South Carolina',
            'South Dakota',
            'Tennessee',
            'Texas',
            'Utah',
            'Vermont',
            'Virgin Island',
            'Virginia',
            'Washington',
            'West Virginia',
            'Wisconsin',
            'Wyoming',
        ],
        regionName: 'State',
        synonyms: ['USA', 'United States'],
    },
];

export const findCurrentCountryObj = (tc) => {
    return country_list.find((c) => c.name === tc);
};

// returns the string name of the month for the given date
// d: the date object
export const getMonthName = (d) => {
    var months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    return months[d.getMonth()];
};

export const getPrettyDate = (date) => {
    date = date ? new Date(date) : new Date();
    return (
        getMonthName(date) + ' ' + date.getDate() + ', ' + date.getFullYear()
    );
};

export const getPrettyCost = (cost) => {
    if (!cost) return '';
    let costStr = cost.toString();
    if (costStr.indexOf('.') > -1) {
        cost = parseFloat(cost).toFixed(2);
    }

    // lookback fails for safari/ipad browsers: \B(?<!\.\d*)(?=(\d{3})+(?!\d))/g
    return cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getNextDate = (date) => {
    let d1 = new Date(date.getTime());
    let d2 = d1.setDate(d1.getDate() + 1);
    return new Date(d2);
};

export const getPrevDate = (date) => {
    let d1 = new Date(date.getTime());
    let d0 = d1.setDate(d1.getDate() - 1);
    return new Date(d0);
};

export const dateIsInTerm = (viewedDate, currentTerm) => {
    if (
        !viewedDate ||
        !currentTerm ||
        !currentTerm.dates ||
        currentTerm.dates.length === 0
    ) {
        return false;
    }

    let datetocheck = new Date(
        viewedDate.getFullYear(),
        viewedDate.getMonth(),
        viewedDate.getDate()
    ).toISOString();

    let i = currentTerm.dates.indexOf(datetocheck);
    return i > -1;
};

export const getDayTypeCountInRange = (startDate, endDate, dayNum) => {
    var total = 0;

    startDate = new Date(startDate.getTime()); // need to do this since accessing date modifies it
    endDate = new Date(endDate.getTime());

    for (var i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
        if (i.getDay() === dayNum) total++;
    }
    return total;
};

export const getNumDayTypeSelected = (selectedDates, dayNum) => {
    var total = 0;
    for (var i = 0; i < selectedDates.length; i++) {
        let date = selectedDates[i];
        if (date.getDay() === dayNum) total++;
    }
    return total;
};

const mapDayStrToNum = (days) => {
    return days?.map((d) => {
        switch (d) {
            case 'Sun':
            default:
                return 0;

            case 'Mon':
                return 1;

            case 'Tue':
                return 2;

            case 'Wed':
                return 3;

            case 'Thu':
                return 4;

            case 'Fri':
                return 5;

            case 'Sat':
                return 6;
        }
    });
};

// selectedDates: the currently select dates to preserve if they don't overlap w/the days to exclude
// weekdays: the days to exclude
// startDate/endDate: the boundaries of the range of dates to include
export const getDatesInRange = (
    selectedDates,
    startDate,
    endDate,
    days = [],
    origdays = [] // used to differentiate btwn user-deselected dates and checkbox excluded dates
) => {
    if (!startDate || !endDate || !selectedDates) {
        return [];
    }

    const datetouse = new Date(startDate.getTime());
    let dates = [];
    let daysmapper = mapDayStrToNum(days);
    let origdaysmapper = mapDayStrToNum(origdays);

    for (
        var date = datetouse;
        date <= endDate;
        date.setDate(date.getDate() + 1)
    ) {
        let newdate = new Date(date);

        if (
            selectedDates.length === 0 ||
            selectedDates.find((d) => d.getTime() === newdate.getTime()) ||
            newdate > selectedDates[selectedDates.length - 1] ||
            newdate < selectedDates[0] ||
            origdaysmapper?.includes(newdate.getDay())
        ) {
            dates.push(newdate);
        }
    }

    if (daysmapper.length > 0) {
        dates = [...dates].filter((dayobj) => {
            return !daysmapper.includes(dayobj.getDay());
        });
    }

    return dates;
};

// suggestions should name a name param which is used to search for and display items in the
// autocomplete component. the id gets sent to API, but since field to update is an ID field, the name gets stripped and only the id gets saved :)
export const formatSuggestionsForAutocomplete = (contacts) => {
    const data = [...contacts]
        .filter((g) => !g.hasOwnProperty('contact'))
        .map((g) => {
            return {
                name:
                    g.name ||
                    g.nameInfo?.firstName + ' ' + g.nameInfo?.lastName,
                _id: g._id,
            };
        });
    return data;
};
