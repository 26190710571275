import React, { useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../layout/Modal';
import { updateStudent } from '../../../storedata/actions/admin';
import Spinner from '../../layout/Spinner';
import StudentForm from './pages/StudentForm';

// Previous location is the page that this modal sits on top of
const AddStudentModal = ({
    updateStudent,
    auth: { loading },
    previousLocation, // set when modal opens via app route (has unique URL),
    submitCallback,
    closeCallback,
    studentID, // optional; if set, then get student info from here (for when I don't use url to open edit modal)
}) => {
    let params = useParams();
    let id = params.studentid || studentID;
    let history = useNavigate();
    let location = useLocation();
    let bodyRef = useRef();

    const [formData, setFormData] = useState(null);

    const onSubmit = async (e) => {
        e.preventDefault(); // don't let browser do its default behavior

        updateStudent(
            formData,
            history,
            location,
            true, // relocate
            previousLocation, // relocateTo
            id || undefined
        )
            .then(function (result) {
                if (result.success) {
                    submitCallback && submitCallback(e, formData);
                }
            })
            .catch(function (err) {});
    };

    let body = (
        <div ref={bodyRef}>
            {loading ? (
                <Spinner size={0} />
            ) : (
                <StudentForm
                    studentID={id}
                    showAsModal={true}
                    onChangeHandler={(formData) => {
                        setFormData(formData);
                    }}
                />
            )}
        </div>
    );

    let steps = [
        {
            body,
            nextStepOnClick: (e) => {
                onSubmit(e);
            },
            nextStepButtonTitle: 'Save',
            finalStepButtonIcon: 'fa fa-save',
        },
    ];

    return (
        <Modal
            steps={steps}
            hasCloseButton={true}
            closeButtonCallBack={closeCallback}
            showAsOverlay={true}
            modalTitle={(id ? 'Edit' : 'Add A') + ' Student'}
            size={1}
        />
    );
};

AddStudentModal.propTypes = {
    updateStudent: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {
    updateStudent,
})(AddStudentModal);
