import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Alert from './components/layout/Alert';
import Spinner from './components/layout/Spinner';
import AppRoutes from './components/routing/AppRoutes';

// Datadog RUM
import ruminit from './rum';

// Redux
import { Provider } from 'react-redux'; // combines react and redux
import store from './store';
import { loadUser } from './storedata/actions/auth';
import setAuthToken from './utils/setAuthToken';

import './css/App.css';

const App = () => {
    const [userChecked, setUserChecked] = useState(false);
    // const [sz, setSz] = useState(window.screen.height);
    // console.log('s');

    // handle android tablet browser bug where full screen mode + an open modal doesn't scroll modal
    // to an input when soft keyboard is open
    let setView = useCallback(() => {
        setTimeout(function () {
            let vh = Boolean(window.visualViewport)
                ? window.visualViewport.height
                : window.screen.height;

            const newState = window.screen.height > vh;

            let situation =
                newState &&
                Boolean(window.visualViewport) &&
                Boolean(document.fullscreenElement);

            if (document.querySelector('.modalbodyforoverlay')) {
                if (situation) {
                    // let h = `${window.visualViewport.height}px`;

                    // since modal doesn't scroll when focusing on field that
                    // opens soft keyboard on android (an annoying android bug),
                    // I need to manually reset modal body height to fit in view:

                    document.querySelector(
                        '.modalbodyforoverlay'
                    ).style.height = `${Math.round(
                        window.visualViewport.height * 0.9 - 100
                    )}px`;
                    // var viewport = document.querySelector('meta[name=viewport]');
                    // viewport.setAttribute(
                    //     'content',
                    //     'initial-scale=1, width=device-width, height=' + vh
                    // );
                } else {
                    document.querySelector(
                        '.modalbodyforoverlay'
                    ).style.height = 'auto';
                }
            }
            // setSz(
            //     // 'window.screen.height  ' +
            //     //     window.screen.height +
            //     //     ' document.body.style.height ' +
            //     //     document.body.style.height +
            //     // ' window.innerHeight ' +
            //     //     window.innerHeight +
            //     // ' document.body.clientHeight ' +
            //     //     document.body.clientHeight +
            //     //     ' ' +
            //     (newState ? ' open' : ' close') +
            //         ' window.visualViewport.height ' +
            //         window.visualViewport.height +
            //         ' situation ' +
            //         situation +
            //         ' h ' +
            //         h
            // );
        });
    }, []);

    // when the state updates, useEffect will keep running in a constant loop, unless
    // you add the [] specified at the end (2nd arg) so that it runs only once when app is mounted
    // This behaves like componentDidMount
    useEffect(() => {
        setAuthToken(localStorage.token);
        store.dispatch(loadUser());
        ruminit();
        setUserChecked(true); // used to ensure user token is set if there's a logged in user.

        window.addEventListener('resize', setView);

        return () => {
            // behaves like componentWillUnmount
            window.removeEventListener('resize', setView());
        };
    }, [setView]);

    return (
        <Provider store={store}>
            <BrowserRouter>
                {userChecked ? (
                    <Fragment>
                        {/* <span>{sz}</span> */}
                        <Alert />
                        <section className='containerfull'>
                            <AppRoutes />
                        </section>
                    </Fragment>
                ) : (
                    <Spinner size={0} />
                )}
            </BrowserRouter>
        </Provider>
    );
};

export default App;
