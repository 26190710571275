import React, { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../../storedata/actions/alert';
import { groupBy } from 'lodash';
import {
    getAllGradeLevels,
    updateRegistration,
    getAllFeesForTerm,
} from '../../../storedata/actions/admin';
import Spinner from '../../layout/Spinner';
import ToggleSwitch from '../../layout/inputs/ToggleSwitch.tsx';
import SegmentedButton from '../../layout/inputs/SegmentedButton';
import Modal from '../../layout/Modal';
import FeeRow from '../../layout/FeeRow';

const initialState = {
    studentList: [],
    gradeLevel: '',
    optionalFees: [],
    isRegistered: true,
    term: null,
};

const UpdateRegistrationModal = ({
    term,
    students,
    auth: { loading },
    admin,
    updateRegistration,
    getAllGradeLevels,
    getAllFeesForTerm,
    setAlert,
    submitCallback,
    closeCallback,
}) => {
    let bodyRef = useRef();
    let ref1 = useRef();
    let ref2 = useRef();
    let ref3 = useRef();

    const [showGradeLevel, setShowGradeLevel] = useState(false);
    const [registrations, setRegistrations] = useState(initialState);
    const [groupedFees, setGroupedFees] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    let { studentList, gradeLevel, optionalFees, isRegistered } = registrations;

    useEffect(() => {
        if (!admin.grade_levels) {
            getAllGradeLevels();
        }

        if (term) {
            if (!admin.fees) {
                getAllFeesForTerm(term._id);
            } else {
                setGroupedFees(groupBy(admin.fees, 'isRequired'));
            }
        }
    }, [
        admin.grade_levels,
        getAllGradeLevels,
        admin.fees,
        getAllFeesForTerm,
        term,
    ]);

    useEffect(() => {
        if (term && students?.length > 0) {
            // let student = students[0];
            // console.log(term.registeredStudents.filter(reg => reg.student._id ===));

            // map students to update with their registrations, if any:
            let studentsUpdates = structuredClone(students).map((s) => {
                let registrationFound = term.registeredStudents.find(
                    (reg) => reg.student._id === s._id
                );
                if (registrationFound) {
                    // found registration for this student
                    s.termRegistration = structuredClone(registrationFound);
                }
                return s;
            });
            // console.log(studentsUpdates);

            let firstGradeLevelFound =
                studentsUpdates[0].termRegistration?.gradeLevel;
            const sameGradeLevel = studentsUpdates.every(
                (s) =>
                    s.termRegistration?.gradeLevel?._id ===
                    firstGradeLevelFound?._id
            );

            let firstIsRegisteredFound = Boolean(
                studentsUpdates[0].termRegistration
            );
            let sameIsRegistered = null;
            if (
                studentsUpdates.every(
                    (s) =>
                        Boolean(s.termRegistration) === firstIsRegisteredFound
                )
            ) {
                sameIsRegistered = true;
            }

            if (sameGradeLevel) {
                setShowGradeLevel(true);
            }

            setRegistrations((origData) => {
                return {
                    ...origData,
                    gradeLevel: sameGradeLevel
                        ? firstGradeLevelFound
                        : origData.gradeLevel,
                    // optionalFees: [],
                    isRegistered:
                        sameIsRegistered === null
                            ? true
                            : firstIsRegisteredFound,
                    studentList: students,
                    term: term._id,
                };
            });
        }
    }, [term, students, studentList.length]);

    const onChangeLevel = (e) => {
        let gradeLevel = admin.grade_levels?.find((l) => {
            return l._id === e.target.value;
        });

        console.log(gradeLevel);

        setRegistrations({
            ...registrations,
            gradeLevel,
            optionalFees: [],
        }); // clear out fees on change
    };

    const onToggleLevel = (value) => {
        setShowGradeLevel(value);
    };

    const switchSegment = (value) => {
        setRegistrations({
            ...registrations,
            isRegistered: value ? true : false,
        });
    };

    const changeFeeSelection = (feeID) => {
        let fee = groupedFees.false?.find((f) => {
            return f._id === feeID;
        });

        if (!fee) return; // todo handle error

        let updatedFees = [...optionalFees];

        let idx = updatedFees.findIndex((f) => f._id === feeID);
        if (idx >= 0) {
            // fee selected; unselect it
            updatedFees.splice(idx, 1); // remove unselected entry
        } else {
            // select fee
            updatedFees = [...updatedFees, { ...fee }];
        }

        setRegistrations({ ...registrations, optionalFees: [...updatedFees] });
    };

    const filterLevels = (f) => {
        // show all fees
        // if (!showGradeLevel && f.isRequired) {
        //     return true;
        // } else
        if (
            // show fees that contain the set level
            gradeLevel &&
            f.gradeLevels?.find((l) => l && l.name === gradeLevel.name)
        ) {
            return true;
        } else return !f.gradeLevels || f.gradeLevels.length === 0;
    };

    const onSubmit = async (e) => {
        e.preventDefault(); // don't let browser do its default behavior
        setShowSpinner(true);

        let rgCopy = { ...registrations };
        rgCopy.studentList = rgCopy.studentList.map((s) => s._id);
        if (isRegistered && !showGradeLevel) {
            delete rgCopy.gradeLevel;
        }

        if (!isRegistered) {
            rgCopy.optionalFees = [];
            rgCopy.gradeLevel = '';
        }

        if (rgCopy.gradeLevel?._id) {
            rgCopy.gradeLevel = rgCopy.gradeLevel._id;
        }

        updateRegistration(
            rgCopy,
            /*, history, location, null, null */ term._id
        )
            .then(function (result) {
                if (result.success && submitCallback) {
                    submitCallback(e, rgCopy);
                }
            })
            .catch(function (err) {
                setShowSpinner(false);
            });
    };

    let body = (
        <div ref={bodyRef}>
            {loading || showSpinner || !admin.grade_levels || !groupedFees ? (
                <Spinner size={0} />
            ) : (
                <Fragment>
                    <h3 className='gridcolpacked mb-2'>
                        Update registration for the selected term and students:
                        <div className='badge neutralbadge'>
                            {term.name} {term.schoolYear}
                        </div>
                        <div className='badge neutralbadge'>
                            {studentList.length === 1
                                ? studentList[0].nameInfo.firstName +
                                  ' ' +
                                  studentList[0].nameInfo.lastName
                                : studentList.length + ' students selected'}
                        </div>
                    </h3>
                    <div className='form-header mt-2'>Registration Status</div>
                    <SegmentedButton
                        name='type'
                        classes='mt mb-2 block'
                        callback={(val) => switchSegment(val)}
                        defaultIndex={isRegistered ? 1 : 0}
                        controlRef={ref1}
                        // 1: school admin, 2: school staff, 3: parent, 4: student
                        segments={[
                            {
                                label: 'Unregistered',
                                value: '',
                                ref: ref2,
                            },
                            {
                                label: 'Registered',
                                value: '1',
                                ref: ref3,
                            },
                        ]}
                    />

                    {isRegistered && (
                        <Fragment>
                            <div className='gridcol'>
                                <div>
                                    <div className='form-header'>
                                        Grade Level
                                    </div>
                                    <div className='gridcolpacked pb-1'>
                                        {admin.grade_levels &&
                                        admin.grade_levels.length > 0 ? (
                                            <Fragment>
                                                <div className='gridcol warmwrapper'>
                                                    <span>
                                                        Update
                                                        {studentList.length ===
                                                        1
                                                            ? ' student grade level'
                                                            : ' grade level for selected students'}
                                                    </span>

                                                    <ToggleSwitch
                                                        checked={showGradeLevel}
                                                        onChange={(v) => {
                                                            onToggleLevel(v);
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    {showGradeLevel && (
                                                        <select
                                                            name='gradeLevel'
                                                            className='mb-0'
                                                            value={
                                                                gradeLevel
                                                                    ? gradeLevel._id
                                                                    : ''
                                                            }
                                                            onChange={
                                                                onChangeLevel
                                                            }
                                                            onKeyPress={(e) => {
                                                                e.key ===
                                                                    'Enter' &&
                                                                    e.preventDefault();
                                                            }}
                                                        >
                                                            <option value=''>
                                                                Unassigned
                                                            </option>
                                                            {admin.grade_levels?.map(
                                                                (level, i) => (
                                                                    <option
                                                                        value={
                                                                            level._id
                                                                        }
                                                                        key={i}
                                                                    >
                                                                        {
                                                                            level.name
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    )}
                                                </div>
                                            </Fragment>
                                        ) : (
                                            <span>
                                                No grade levels created yet.
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <h2 className='mb-1 mt-1'>Review Term Fees</h2>
                            <div className='form-header'> Required Fees</div>
                            <small className='form-text mb-1'>
                                Required fees are automatically added to each
                                registered student. Certain fees are only
                                required for certain grade levels.
                            </small>
                            {groupedFees?.true?.length > 0 ? (
                                <div className='feeWrapper'>
                                    {groupedFees.true
                                        .filter((f) => filterLevels(f))
                                        .map((fee, idx) => (
                                            <FeeRow
                                                term={term}
                                                fee={fee}
                                                selectable
                                                disabled
                                                key={idx}
                                            />
                                        ))}
                                </div>
                            ) : (
                                <Fragment>
                                    {/* <button
                                    className='btn btn-primary'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowFeeModal(null);
                                    }}
                                >
                                    <i className='fas fa-plus' /> Add Fee
                                </button> */}
                                    None found.
                                </Fragment>
                            )}
                            <div className='form-header mb mt-2'>
                                Optional Fees
                            </div>
                            {groupedFees?.false?.length > 0 ? (
                                <div className='feeWrapper'>
                                    {groupedFees.false
                                        .filter((f) => filterLevels(f))
                                        .map((fee, idx) => (
                                            <FeeRow
                                                term={term}
                                                fee={fee}
                                                selectable
                                                selected={
                                                    optionalFees.find(
                                                        (f) => f._id === fee._id
                                                    )
                                                        ? true
                                                        : false
                                                }
                                                key={idx}
                                                callback={() =>
                                                    changeFeeSelection(fee._id)
                                                }
                                            />
                                        ))}
                                </div>
                            ) : (
                                <Fragment>
                                    {/* <button
                                    className='btn btn-primary'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowFeeModal(null);
                                    }}
                                >
                                    <i className='fas fa-plus' /> Add Fee
                                </button> */}
                                    None found.
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </Fragment>
            )}
        </div>
    );

    let steps = [
        {
            body,
            nextStepOnClick: (e) => {
                onSubmit(e);
            },
            nextStepButtonTitle: 'Save',
            finalStepButtonIcon: 'fa fa-save',
        },
    ];

    return (
        <Modal
            steps={steps}
            hasCloseButton={true}
            closeButtonCallBack={closeCallback}
            showAsOverlay={true}
            modalTitle='Update Registration'
            size={2}
        />
    );
};

UpdateRegistrationModal.propTypes = {
    setAlert: PropTypes.func.isRequired,
    updateRegistration: PropTypes.func.isRequired,
    getAllGradeLevels: PropTypes.func.isRequired,
    getAllFeesForTerm: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    term: PropTypes.object.isRequired,
    students: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
});

export default connect(mapStateToProps, {
    setAlert,
    updateRegistration,
    getAllGradeLevels,
    getAllFeesForTerm,
})(UpdateRegistrationModal);
