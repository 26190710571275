import React from 'react';
import NavbarLink from './NavbarLink';

const LeftNavLink = (path, icon, title, relatedpaths = []) => {
    return (
        <li>
            <NavbarLink path={path} relatedpaths={relatedpaths}>
                <i className={icon}></i> {title}
            </NavbarLink>
        </li>
    );
};

export default LeftNavLink;
