import React, { useEffect, Fragment } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; // so we can interact with our auth state in the auth reducer so we can check authentication
import Spinner from '../layout/Spinner';
import SuperadminHeader from '../auth/superadmin/SuperadminHeader';
import { removeAllNonStickyAlerts } from '../../storedata/actions/alert';
import { isSchoolAdmin, findSchoolFromRoles } from '../../utils/helpers';

// Protects route: certain pages are only for logged in users, and others are only for admins.
const PrivateRoute = ({
    auth: { isAuthenticated, isSuperadmin, viewingAs, loading, user },
    adminPage = false, // boolean. pass this prop in if it is a page for only admins.
    superAdminPage = false, // pass this prop in if it is a page for only super admins.
    removeAllNonStickyAlerts,
    showSpinner = true, // sometimes I load more than one private route on the pg. if so, don't show spinner again
}) => {
    let params = useParams();
    // trigger on component mount
    useEffect(() => {
        removeAllNonStickyAlerts();
    }, [removeAllNonStickyAlerts]);

    const isValidAdmin = (roles) => {
        let valid = isSchoolAdmin(roles) || isSuperadmin;

        if (params.id) {
            // school in url; ensure user has access to school passed in route
            let school = findSchoolFromRoles(params.id, roles); // use url to set school
            if (!school) {
                valid = false;
            }
        }

        return valid;
    };

    let componentToRender = () => {
        if (loading) {
            // this is important since we need to check auth state, so need it to be loaded first
            if (showSpinner) {
                return (
                    <div className='containermiddle m-3'>
                        <Spinner size={1} />
                    </div>
                );
            } else {
                return null;
            }
        } else {
            return (
                <Fragment>
                    {isAuthenticated && isSuperadmin && (
                        <SuperadminHeader user={user} viewingAs={viewingAs} />
                    )}
                    {
                        !isAuthenticated || !viewingAs ? (
                            <Navigate to='/login' />
                        ) : adminPage ? (
                            (isSuperadmin &&
                                viewingAs.school) /* in case I switch to url for a particular user/school but am currently on superadmin pg which wouldn't have school set! */ ||
                            isValidAdmin(viewingAs.user.roles) ? (
                                <Outlet />
                            ) : (
                                <Navigate to='/base' /> // !!! add other user roles to this pg
                            )
                        ) : superAdminPage ? (
                            isSuperadmin ? (
                                <Outlet />
                            ) : (
                                <Navigate to='/base' />
                            )
                        ) : (
                            <Outlet />
                        ) // non-admin private page
                    }
                </Fragment>
            );
        }
    };

    return componentToRender();
};

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired,
    removeAllNonStickyAlerts: PropTypes.func.isRequired,
    showSpinner: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { removeAllNonStickyAlerts })(
    PrivateRoute
);
