import React from 'react';
import spinner from './spinner.gif';

const Spinner = ({ size = 2 }) => {
    let pixels = 60;
    if (size === 1) pixels = 30;
    if (size === 0) pixels = 20;

    return (
        <img
            src={spinner}
            style={{
                width: `${pixels}px`,
                margin: `${size === 0 ? 0 : pixels / 2}px auto`,
                display: 'block',
            }}
            alt='Loading...'
        />
    );
};

export default Spinner;
