import React, { Fragment, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux'; // connects component to redux
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { login } from '../../storedata/actions/auth';
import PropTypes from 'prop-types';
import LandingHeader from '../layout/LandingHeader';
import Footer from '../layout/Footer';
import Modal from '../layout/Modal';
import Spinner from '../layout/Spinner';

// create function component
const Login = ({ login, auth }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [loading, setLoading] = useState(false);

    // destructure so I don't have to do formData.name, etc...
    // The state will be available everywhere in this file :)
    const { email, password } = formData;

    const onChange = (e) => {
        // [e.target.name] generalizes the function so it can apply to any field
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    let steps = [
        {
            body: loading ? (
                <Spinner size={0} />
            ) : (
                <Fragment>
                    <input
                        type='text'
                        placeholder='Email Address'
                        name='email'
                        value={email}
                        onChange={onChange}
                        required
                    />

                    <input
                        type='password'
                        placeholder='Password'
                        value={password}
                        onChange={onChange}
                        name='password'
                        required
                    />
                </Fragment>
            ),
            nextStepOnClick: (e) => {
                onSubmit(e);
            },
            nextStepButtonTitle: 'Sign In',
            finalStepButtonIcon: 'fa fa-arrow-right',
        },
    ];

    const onSubmit = async (e) => {
        e.preventDefault(); // don't let browser do its default behavior
        setLoading(true);
        login(email, password)
            .then(function (result) {
                if (!result) setLoading(false);
            })
            .catch(function (err) {
                setLoading(false);
            });
    };

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Log In</title>
            </Helmet>
            {!auth.loading && auth.isAuthenticated && <Navigate to='/base' />}
            <div className='landingbg'>
                <LandingHeader hasButtons={false} />
                <div className='margin-sides-wrapper centerbox'>
                    <Modal
                        steps={steps}
                        hasCloseButton={false}
                        modalTitle='Log In'
                    />
                </div>
            </div>
            <Footer hastopline />
        </Fragment>
    );
};

Login.propTypes = {
    login: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

// to use an action, pass it into connect below to allow us to access props.login so we can use it in this component.
// 1st param is state to connect (not needed here). 2nd param is any actions I want to use
export default connect(mapStateToProps, { login })(Login);
