import React, { Fragment, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const DeleteButton = ({ callback }) => {
    let deleteRef = useRef();
    let textRef = useRef();

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    useEffect(() => {
        if (!deleteRef.current || !textRef.current) {
            return;
        }

        const { style } = deleteRef.current;
        const { offsetWidth } = textRef.current;

        style.setProperty(
            'width',
            `${offsetWidth + 33 /* button padding + border */}px`
        );
        if (showDeleteConfirmation) {
            style.setProperty('padding', '5px 15px');
        }
    }, [showDeleteConfirmation]);

    return (
        <button
            ref={deleteRef}
            className='btn btn-alert-border'
            onClick={(e) => {
                e.preventDefault();
                !showDeleteConfirmation && setShowDeleteConfirmation(true);
            }}
        >
            <div ref={textRef} style={{ width: 'fit-content' }}>
                {showDeleteConfirmation ? (
                    <>
                        <span
                            style={{
                                verticalAlign: 'top',
                                lineHeight: '16px',
                            }}
                        >
                            Are you sure?
                        </span>
                        <i
                            className='ml-1 fas fa-circle-check'
                            style={{ fontSize: '16px' }}
                            onClick={() => {
                                callback();
                                setShowDeleteConfirmation(false);
                            }}
                        />
                        <i
                            className='ml-1 fas fa-circle-xmark'
                            style={{ fontSize: '16px' }}
                            onClick={() => setShowDeleteConfirmation(false)}
                        />
                    </>
                ) : (
                    <i className='fas fa-trash' />
                )}
            </div>
        </button>
    );
};

DeleteButton.propTypes = {
    callback: PropTypes.func.isRequired,
};

export default DeleteButton;
