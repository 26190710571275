import api from './api';
import upload_api from './upload_api';
import { isExpired, decodeToken } from 'react-jwt';

// handles both the setting of the token in local storage and in axios headers.
// used in App.js to always check for user token on app load (page load)
// we want to send a token with EVERY request so we don't have to choose which requests to send it with.
// if there is a token - store it in local storage and set the headers.
// if there is no token - token is null - remove it from storage and delete the headers
const setAuthToken = (token) => {
    if (token && !isExpired(token) && typeof decodeToken(token) === 'object') {
        api.defaults.headers.common['x-auth-token'] = token;
        upload_api.defaults.headers.common['x-auth-token'] = token;
        localStorage.setItem('token', token);
    } else {
        delete api.defaults.headers.common['x-viewing-as-user-id'];
        delete upload_api.defaults.headers.common['x-viewing-as-user-id'];
        delete api.defaults.headers.common['x-viewing-as-school-id'];
        delete upload_api.defaults.headers.common['x-viewing-as-school-id'];
        delete api.defaults.headers.common['x-auth-token'];
        delete upload_api.defaults.headers.common['x-auth-token'];
        localStorage.removeItem('token');
    }
};

export default setAuthToken;
