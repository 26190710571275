// PRIVATE ROUTE: logged in user (/account)
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../layout/Spinner';
import PersonalInfo from '../../PersonalInfo';
import {
    getStudentById,
    getAllContacts,
    clearStudent,
} from '../../../../storedata/actions/admin';
import UploadStudentPic from './UploadStudentPic';
import Autocomplete from '../../../layout/inputs/Autocomplete';
import {
    formatSuggestionsForAutocomplete,
    getPrettyName,
} from '../../../../utils/helpers';
import AddContactModal from '../AddContactModal';

const initialState = {
    nameInfo: '',
    address: '',
    email: '',
    phone: '',
    birthday: Date.now(),
    sex: 0,
    photo: '',
    contacts: [
        {
            contact: null,
            relationship: 1,
            type: 0,
        },
    ], // {contact, relationship, type}
    registrationStatus: 0,
    hasGraduated: false,
    staffNotes: '',
};

const yearOptions = () => {
    let currentYear = new Date().getFullYear();
    let start = currentYear;
    let end = 2000;
    let options = [];

    for (var y = start; y >= end; y--) {
        options.push(
            <option key={y} value={y}>
                {y}
            </option>
        );
    }

    return options;
};

const dayOptions = () => {
    let start = 1;
    let end = 31;
    let options = [
        // <option key={0} value={0}>
        //     Select date
        // </option>,
    ];

    for (var d = start; d <= end; d++) {
        options.push(
            <option key={d} value={d}>
                {d}
            </option>
        );
    }

    return options;
};

const StudentForm = ({
    showAsModal,
    onChangeHandler,
    studentID,
    getStudentById,
    getAllContacts,
    clearStudent,
    auth: { loading },
    admin: { student, loading_student, contacts, loading_contact },
}) => {
    let params = useParams();
    studentID = params.studentid || studentID;
    let location = useLocation();
    let mounted = useRef(false); // used to ensure that submitting form that has error won't reset form

    const [formData, setFormData] = useState(initialState);
    const [showContactModal, setShowContactModal] = useState(false);
    // const [savingData, setSavingData] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    useEffect(() => {
        if (!loading_contact && !contacts) {
            getAllContacts();
        }
    }, [getAllContacts, contacts, loading_contact]);

    useEffect(() => {
        if (!mounted.current && studentID) {
            getStudentById(studentID);
        }
    }, [getStudentById, studentID]); // @todo8 student not found,

    useEffect(() => {
        return () => {
            // behaves like componentWillUnmount
            clearStudent();
        };
    }, [clearStudent]);

    useEffect(() => {
        if (!mounted.current) {
            if (!studentID) {
                const formData = {
                    ...initialState,
                    contactsfd: [...initialState.contacts],
                };

                setFormData(formData);
                onChangeHandler(formData);
                mounted.current = true;
            } else if (student) {
                // update form fields with current student
                setFormData((formData) => {
                    const formData2 = {
                        ...formData,
                        contacts:
                            student.contacts?.length > 0
                                ? student.contacts
                                : JSON.parse(
                                      JSON.stringify(initialState.contacts)
                                  ),
                    };

                    for (const key in student) {
                        if (key in formData && key !== 'contacts') {
                            formData2[key] = student[key];
                        }
                    }

                    let newFormData = { ...formData2 };
                    mounted.current = true;
                    onChangeHandler(newFormData);
                    return newFormData;
                });
            }
        }
    }, [loading, loading_student, studentID, student, onChangeHandler]);

    let {
        birthday,
        sex,
        registrationStatus,
        hasGraduated,
        staffNotes,
        contacts: contactsfd,
    } = formData;

    // this one acts on the form data, which has elements
    // that look like {contact: obj, relationship: num, type: num}
    const formatDataForAutocomplete = (contacts) => {
        const data = [...contacts].map((g) => {
            if (!g.contact) return [];
            let name =
                g.contact.name || // it has .name if it was set from autocomplete component
                getPrettyName(g.contact.nameInfo, false); // else it was set on pg load: = populated contact obj

            return {
                name,
                _id: g._id,
            };
        });
        return data;
    };

    const onChangeBirthday = (e) => {
        e.stopPropagation();
        let name = e.target.name;
        let value = e.target.value;

        let newbirthday = birthday ? new Date(birthday) : new Date();

        if (name === 'bdayyr') {
            newbirthday.setFullYear(value);
        } else if (name === 'bdaymo') {
            newbirthday.setMonth(value);
        } else if (name === 'bdayday') {
            newbirthday.setDate(value);
        }

        let newFormData = { ...formData, birthday: newbirthday };
        setFormData(newFormData);
        onChangeHandler(newFormData);
    };

    const addContactRelationship = (e) => {
        e.preventDefault(); // don't submit form

        let entryCopy = { ...formData }; // copy current ...

        entryCopy.contacts = [...formData.contacts]; // since orig nested array is a ref and not deep copied above
        entryCopy.contacts.push({
            contact: null,
            relationship: 1,
            type: 0,
        });

        let newFormData = {
            ...formData,
            contacts: entryCopy.contacts,
        };
        setFormData(newFormData);
        onChangeHandler(newFormData);
    };

    // idx passed in for changing contact data and other formData fields
    const onChange2 = (e, idx) => {
        e.preventDefault(); // don't submit form
        e.stopPropagation();

        let name = e.target.name;
        let value = e.target.value;

        let newFormData;
        if (
            name === 'contactOf_relationship' ||
            name === 'contactOf_contact' ||
            name === 'contactOf_type'
        ) {
            let entryCopy = { ...formData }; // doesn't deep copy nested objects,
            // so I need to do this mapping to copy properly.
            // also note the spread operator on contacts since that is a ref to orig form data array
            entryCopy.contacts = [...entryCopy.contacts].map((l) =>
                Object.assign({}, l)
            );

            if (name === 'contactOf_relationship') {
                entryCopy.contacts[idx].relationship = parseInt(value);
            } else if (name === 'contactOf_contact') {
                const parsed = JSON.parse(e.target.value);
                entryCopy.contacts[idx].contact =
                    parsed.length > 0 ? parsed[0] : null;
            } else if (name === 'contactOf_type') {
                entryCopy.contacts[idx].type = parseInt(value);
            }

            newFormData = { ...formData, contacts: entryCopy.contacts };
        } else {
            newFormData = { ...formData, [name]: value };
        }

        setFormData(newFormData);
        onChangeHandler(newFormData);
    };

    const onChange = (dataobj) => {
        let value = dataobj.value;

        let nameitems = [
            'nameSuffix',
            'firstName',
            'middleName',
            'lastName',
            'nickname',
        ];

        let addritems = [
            'line1',
            'line2',
            'country',
            'city',
            'region',
            'postalCode',
        ];

        let newFormData;
        if (nameitems.includes(dataobj.name)) {
            newFormData = {
                ...formData,
                nameInfo: {
                    ...formData.nameInfo,
                    [dataobj.name]: value,
                },
            };
        } else if (addritems.includes(dataobj.name)) {
            newFormData = {
                ...formData,
                address: {
                    ...formData.address,
                    [dataobj.name]: value,
                },
            };
        } else {
            newFormData = { ...formData, [dataobj.name]: value };
        }

        setFormData(newFormData);
        onChangeHandler(newFormData);
    };

    return (
        <Fragment>
            {loading ||
            loading_contact ||
            !contacts ||
            !mounted.current ||
            (studentID && !student) ? (
                // savingData ? (
                //     <Modal
                //         steps={[
                //             {
                //                 body: (
                //                     <Fragment>
                //                         <Spinner size={0} />
                //                     </Fragment>
                //                 ),
                //             },
                //         ]}
                //         showAsOverlay={true}
                //         modalTitle='Loading...'
                //     />
                // ) : (
                //     <Spinner size={0} />
                // )

                <Spinner size={0} />
            ) : (
                <div
                    className={
                        'form bigwarmhug' +
                        (showAsModal && showContactModal ? ' displaynone' : '')
                    }
                >
                    {studentID && mounted.current && <UploadStudentPic />}
                    <p className='italicsmsg m0 pt-0'>* = required field</p>

                    <PersonalInfo
                        onChangeHandler={(data) => onChange(data)}
                        data={formData}
                        showMiddleName={true}
                        showNameSuffix={true}
                        showNamePrefix={false}
                        emailRequired={false}
                        phoneRequired={false}
                        showPassword={false}
                    />

                    <h3 className='pb-1'>Birthday</h3>
                    <div className='row-split-evenly grid-gap-20'>
                        <div>
                            <div className='form-header'>Month*</div>
                            <select
                                name='bdaymo'
                                value={
                                    birthday ? new Date(birthday).getMonth() : 0
                                }
                                onChange={onChangeBirthday}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            >
                                {/* <option value={-1}>
                                                    Select month
                                                </option> */}
                                <option value={0}>January</option>
                                <option value={1}>February</option>
                                <option value={2}>March</option>
                                <option value={3}>April</option>
                                <option value={4}>May</option>
                                <option value={5}>June</option>
                                <option value={6}>July</option>
                                <option value={7}>August</option>
                                <option value={8}>September</option>
                                <option value={9}>October</option>
                                <option value={10}>November</option>
                                <option value={11}>December</option>
                            </select>
                        </div>

                        <div>
                            <div className='form-header'>Day*</div>
                            <select
                                name='bdayday'
                                value={
                                    birthday ? new Date(birthday).getDate() : 0
                                }
                                onChange={onChangeBirthday}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            >
                                {dayOptions()}
                            </select>
                        </div>

                        <div>
                            <div className='form-header'>Year*</div>
                            <select
                                name='bdayyr'
                                value={
                                    birthday
                                        ? new Date(birthday).getFullYear()
                                        : new Date().getFullYear()
                                }
                                onChange={onChangeBirthday}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            >
                                {yearOptions()}
                            </select>
                        </div>
                    </div>

                    <div>
                        <div className='form-header'>Sex*</div>
                        <select
                            name='sex'
                            value={sex}
                            onChange={onChange2}
                            required
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        >
                            <option value={0}>Male</option>
                            <option value={1}>Female</option>
                        </select>
                    </div>

                    <div>
                        <div className='form-header'>Registration Status*</div>
                        <select
                            name='registrationStatus'
                            value={registrationStatus}
                            onChange={onChange2}
                            required
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        >
                            <option value={0}>Inactive</option>
                            <option value={1}>Pending</option>
                            <option value={2}>Active</option>
                        </select>
                    </div>

                    <div className='mb-1'>
                        <div className='form-header'>
                            <input
                                type='checkbox'
                                name='hasGraduated'
                                value={hasGraduated}
                                checked={hasGraduated}
                                onChange={() => {
                                    let newFormData = {
                                        ...formData,
                                        hasGraduated: !hasGraduated,
                                    };
                                    setFormData(newFormData);
                                    onChangeHandler(newFormData);
                                }}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            />{' '}
                            Student graduated
                        </div>
                    </div>

                    <div>
                        <div className='form-header'>Private Notes</div>
                        <textarea
                            name='staffNotes'
                            value={staffNotes || initialState.staffNotes}
                            rows={2}
                            onChange={onChange2}
                        />
                    </div>

                    <div>
                        <div className='even2split-spacebtwn mt-2'>
                            <h2 className='aligncenter'>Contacts</h2>

                            <button
                                className='btn my-1'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowContactModal(true);
                                }}
                            >
                                <i className='fas fa-plus' /> Add Contact...
                            </button>
                        </div>
                        <small className='mb-2'>
                            Add a guardian, emergency contact, or authorized
                            pick-up person.
                        </small>
                        {contactsfd?.length > 0 ? (
                            <div>
                                {!loading &&
                                    contactsfd?.map((contact, idx) => (
                                        <div key={idx}>
                                            <div className='evennsplit grid-gap-20'>
                                                <div>
                                                    <div className='form-header'>
                                                        Contact*
                                                    </div>

                                                    <Autocomplete
                                                        cssID={
                                                            'contacts-ac' + idx
                                                        }
                                                        inputName='contactOf_contact'
                                                        selectedData={formatDataForAutocomplete(
                                                            [contact]
                                                        )}
                                                        placeholder='Select a contact...'
                                                        onChangeHandler={(e) =>
                                                            onChange2(e, idx)
                                                        }
                                                        showAllOptions={true}
                                                        suggestions={formatSuggestionsForAutocomplete(
                                                            contacts
                                                        )}
                                                        selectOneMax={true}
                                                        addOptionHandler={(
                                                            e
                                                        ) => {
                                                            e.preventDefault(); // important
                                                            setShowContactModal(
                                                                true
                                                            );
                                                        }}
                                                        // !! enable below when delete
                                                        // row is added since this is a
                                                        // hack to delete empty rows
                                                        // required={
                                                        //     true
                                                        // }
                                                    />
                                                </div>

                                                <div>
                                                    <div className='form-header'>
                                                        Relationship*
                                                    </div>
                                                    <select
                                                        name='contactOf_relationship'
                                                        value={
                                                            contactsfd[idx]
                                                                .relationship
                                                        }
                                                        onChange={(e) => {
                                                            onChange2(e, idx);
                                                        }}
                                                        required
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' &&
                                                                e.preventDefault();
                                                        }}
                                                    >
                                                        <option value={0}>
                                                            Legal Guardian
                                                        </option>
                                                        <option value={1}>
                                                            Mother
                                                        </option>
                                                        <option value={2}>
                                                            Father
                                                        </option>
                                                        <option value={3}>
                                                            Aunt
                                                        </option>
                                                        <option value={4}>
                                                            Uncle
                                                        </option>
                                                        <option value={5}>
                                                            Grandmother
                                                        </option>
                                                        <option value={6}>
                                                            Grandfather
                                                        </option>
                                                        <option value={7}>
                                                            Caregiver
                                                        </option>
                                                        <option value={8}>
                                                            Stepmother
                                                        </option>
                                                        <option value={9}>
                                                            Stepfather
                                                        </option>
                                                        <option value={10}>
                                                            Other
                                                        </option>
                                                    </select>
                                                </div>

                                                <div>
                                                    <div className='form-header'>
                                                        Type*
                                                    </div>
                                                    <select
                                                        name='contactOf_type'
                                                        value={
                                                            contactsfd[idx].type
                                                        }
                                                        onChange={(e) => {
                                                            onChange2(e, idx);
                                                        }}
                                                        required
                                                        onKeyPress={(e) => {
                                                            e.key === 'Enter' &&
                                                                e.preventDefault();
                                                        }}
                                                    >
                                                        <option value={0}>
                                                            Parent or Legal
                                                            Guardian
                                                        </option>
                                                        <option value={1}>
                                                            Emergency Contact
                                                        </option>
                                                        <option value={2}>
                                                            Authorized Drop
                                                            Off/Pick Up Person
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    ))}
                                <div className='mb-1'>
                                    <button
                                        className='btn'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            addContactRelationship(e);
                                        }}
                                    >
                                        Add Another Relationship
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className='mt-1'>
                                <p>
                                    No contacts yet. Create one in order to add
                                    one for this student.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {showContactModal && (
                <AddContactModal
                    previousLocation={location.pathname}
                    submitCallback={(e) => {
                        e.preventDefault();
                        setShowContactModal(false);
                    }}
                    closeCallback={(e) => {
                        e.preventDefault();
                        setShowContactModal(false);
                    }}
                />
            )}
        </Fragment>
    );
};

StudentForm.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    getStudentById: PropTypes.func.isRequired,
    getAllContacts: PropTypes.func.isRequired,
    clearStudent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
});

export default connect(mapStateToProps, {
    getStudentById,
    getAllContacts,
    clearStudent,
})(StudentForm);
