import PropTypes from 'prop-types';
// import { getSchoolPic } from '../../utils/helpers';

const SchoolHeader = ({ school }) => {
    return (
        <div id='school-header-wrapper'>
            {/* <img
                className='round-img-sml'
                src={getSchoolPic(school.logo)}
                alt={school.name}
            /> */}
            <span>{school?.name}</span>
        </div>
    );
};

SchoolHeader.propTypes = {
    school: PropTypes.object.isRequired,
};

export default SchoolHeader;
