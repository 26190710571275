// PRIVATE ROUTE: ADMIN LEVEL
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getContactPic, getPrettyName } from '../../../utils/helpers';

const ContactTile = ({
    contact,
    urlslug,
    editAs,
    state,
    onMainPage = false,
}) => {
    return (
        <div className='contact-tile'>
            <div className='contact-tile-header'>
                {onMainPage ? (
                    <h1 className='warm'>
                        {getPrettyName(contact.nameInfo, false)}
                    </h1>
                ) : (
                    <h2 className='warm'>
                        {getPrettyName(contact.nameInfo, false)}
                    </h2>
                )}
                <div className='gridcol'>
                    <Link
                        className='btn my-1'
                        to={`editcontact/${contact._id}`}
                        state={state}
                    >
                        Edit
                    </Link>
                </div>
            </div>
            <div className='contact-tile-body'>
                <div>
                    <img
                        className='round-img-avg'
                        src={getContactPic(contact.photo)}
                        alt={contact.nameInfo.firstName}
                    />
                </div>
                <div>
                    <p>
                        <small>Address</small>
                    </p>
                    <p>
                        {contact.address.line1 && (
                            <span>{contact.address.line1}, </span>
                        )}
                        {contact.address.line2 && (
                            <span> {contact.address.line2}, </span>
                        )}
                        {contact.address.city && (
                            <span>
                                {contact.address.city},{' '}
                                {contact.address.region && (
                                    <span>{contact.address.region}</span>
                                )}
                            </span>
                        )}

                        {contact.address.postalCode && (
                            <span> {contact.address.postalCode}</span>
                        )}
                        {contact.address.country && (
                            <span>, {contact.address.country}</span>
                        )}
                    </p>
                </div>
                <div>
                    {contact.phone && (
                        <div>
                            {' '}
                            <p>
                                <small>Phone</small>
                            </p>
                            <p>{contact.phone}</p>
                        </div>
                    )}
                    <p>
                        <small>Added On</small>
                    </p>
                    {contact.createdAt && (
                        <p>
                            {new Date(contact.createdAt).getMonth() + 1}/
                            {new Date(contact.createdAt).getDate()}/
                            {new Date(contact.createdAt).getFullYear()}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};
ContactTile.propTypes = {
    admin: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
});

export default connect(mapStateToProps)(ContactTile);
