// PRIVATE ROUTE: ADMIN LEVEL
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import Spinner from '../../../layout/Spinner';
import BaseFrame from '../../BaseFrame';
import {
    getAllStaff,
    getAllStudents,
    getAllTerms,
    getAllContacts,
    getSchoolById,
    getAllGradeLevels,
} from '../../../../storedata/actions/admin';
import AddContactModal from '../AddContactModal';
import SchoolTile from '../SchoolTile';
import SchoolHeader from '../../SchoolHeader';
import AddStudentModal from '../AddStudentModal';

const SchoolAdminDashboard = ({
    auth,
    admin,
    getAllStaff,
    getAllStudents,
    getAllTerms,
    getAllContacts,
    getAllGradeLevels,
    getSchoolById,
}) => {
    let location = useLocation();
    let mounted = useRef(false);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount

        return () => {
            // behaves like componentWillUnmount
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (auth.viewingAs.school && !mounted.current) {
            if (!admin.school) {
                getSchoolById(auth.viewingAs.school._id);
            }
            if (!admin.students) {
                getAllStudents();
            }
            if (!admin.staffmembers) {
                getAllStaff();
            }
            if (!admin.terms) {
                getAllTerms();
            }
            if (!admin.contacts) {
                getAllContacts();
            }
            if (!admin.grade_levels) {
                getAllGradeLevels();
            }
            mounted.current = true;
        }
    }, [
        admin.students,
        admin.staffmembers,
        admin.contacts,
        admin.school,
        admin.terms,
        admin.grade_levels,
        getAllStaff,
        getAllStudents,
        getAllTerms,
        getAllContacts,
        getAllGradeLevels,
        getSchoolById,
        auth.viewingAs.school,
    ]);

    const [showContactModal, setShowContactModal] = useState(false);
    const [showStudentModal, setShowStudentModal] = useState(false);

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Admin Console</title>
            </Helmet>
            <BaseFrame>
                {!admin.school ? (
                    <Spinner size={0} />
                ) : (
                    <SchoolHeader school={admin.school} />
                )}

                <h1 className='mb-2'>Overview</h1>
                {admin.loading_school || !admin.school ? (
                    <div className='containerinnersolid mb-3'>
                        <Spinner size={0} />
                    </div>
                ) : (
                    <SchoolTile
                        school={admin.school}
                        editAs={'admin'}
                        onMainPage={true}
                    />
                )}

                <div className='containerinnersolid heightfitcontent'>
                    <h1 className='warm mb-1'>Summary</h1>
                    {
                        //!mounted.current ||
                        // admin.loading_staff ||
                        !admin.staffmembers ||
                        // admin.loading_student ||
                        !admin.students ||
                        // admin.loading_term ||
                        !admin.terms ||
                        // admin.loading_contact ||
                        !admin.contacts ||
                        // admin.loading_grade_level ||
                        !admin.grade_levels ? (
                            <Spinner size={0} />
                        ) : (
                            <Fragment>
                                <div className='overview-boxes'>
                                    <div>
                                        <p>
                                            <Link
                                                className='toplink'
                                                to={`/admin/${auth.viewingAs.school?.urlslug}/students`}
                                            >
                                                {admin.students?.length}
                                            </Link>
                                        </p>
                                        <p>
                                            Student
                                            {admin.students?.length !== 1 &&
                                                's'}{' '}
                                            <button
                                                className='btn btn-small inlinegrid'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowStudentModal(true);
                                                }}
                                            >
                                                <i className='fas fa-plus' />{' '}
                                                Add
                                            </button>
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            <Link
                                                className='toplink'
                                                to={`/admin/${auth.viewingAs.school?.urlslug}/gradelevels`}
                                            >
                                                {admin.grade_levels.length}
                                            </Link>
                                        </p>
                                        <p>
                                            Grade Level
                                            {admin.grade_levels.length !== 1 &&
                                                's'}{' '}
                                            <Link
                                                to={`/admin/${auth.viewingAs.school?.urlslug}/addgradelevel`}
                                                className='btn btn-small inlinegrid'
                                            >
                                                <i className='fas fa-plus' />{' '}
                                                Add
                                            </Link>
                                        </p>
                                    </div>

                                    <div>
                                        <p>
                                            <Link
                                                className='toplink'
                                                to={`/admin/${auth.viewingAs.school?.urlslug}/terms`}
                                            >
                                                {admin.terms?.length}
                                            </Link>
                                        </p>
                                        <p>
                                            Term
                                            {admin.terms.length !== 1 &&
                                                's'}{' '}
                                            <Link
                                                className='btn btn-small inlinegrid'
                                                to={`/admin/${auth.viewingAs.school?.urlslug}/addterm`}
                                                state={{
                                                    previousLocation: location,
                                                }}
                                            >
                                                <i className='fas fa-plus' />{' '}
                                                Add
                                            </Link>
                                        </p>
                                    </div>

                                    <div>
                                        <p>
                                            <Link
                                                className='toplink'
                                                to={`/admin/${auth.viewingAs.school?.urlslug}/contacts`}
                                            >
                                                {admin.contacts.length}
                                            </Link>
                                        </p>
                                        <p>
                                            Contact
                                            {admin.contacts.length !== 1 &&
                                                's'}{' '}
                                            <button
                                                className='btn btn-small inlinegrid'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowContactModal(true);
                                                }}
                                            >
                                                <i className='fas fa-plus' />{' '}
                                                Add
                                            </button>
                                        </p>
                                    </div>

                                    <div>
                                        <p>
                                            <Link
                                                className='toplink'
                                                to={`/admin/${auth.viewingAs.school?.urlslug}/staff`}
                                            >
                                                {admin.staffmembers.length}
                                            </Link>
                                        </p>
                                        <p>
                                            Staff Member
                                            {admin.staffmembers.length !== 1 &&
                                                's'}
                                        </p>
                                        {/* <Link
                                    to={`/admin/${auth.viewingAs.school?.urlslug}/addstaff`}
                                    className='btn'
                                >
                                    Add Staff Member
                                </Link> */}
                                    </div>
                                </div>
                            </Fragment>
                        )
                    }
                </div>

                {showContactModal && (
                    <AddContactModal
                        previousLocation={location.pathname}
                        submitCallback={(e, formData) => {
                            e.preventDefault();
                            setShowContactModal(false);
                        }}
                        closeCallback={(e) => {
                            e.preventDefault();
                            setShowContactModal(false);
                        }}
                    />
                )}
                {showStudentModal && (
                    <AddStudentModal
                        previousLocation={location.pathname}
                        submitCallback={(e, formData) => {
                            e.preventDefault();
                            setShowStudentModal(null);
                        }}
                        closeCallback={(e) => {
                            e.preventDefault();
                            setShowStudentModal(null);
                        }}
                    />
                )}
            </BaseFrame>
        </Fragment>
    );
};

SchoolAdminDashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    getAllStaff: PropTypes.func.isRequired,
    getAllStudents: PropTypes.func.isRequired,
    getAllTerms: PropTypes.func.isRequired,
    getAllContacts: PropTypes.func.isRequired,
    getAllGradeLevels: PropTypes.func.isRequired,
    getSchoolById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
});

export default connect(mapStateToProps, {
    getAllStaff,
    getAllStudents,
    getAllTerms,
    getAllContacts,
    getSchoolById,
    getAllGradeLevels,
})(SchoolAdminDashboard);
