// PRIVATE ROUTE: logged in user (/account)
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../layout/Spinner';
import { APP_NAME } from '../../../../utils/constants';
import { Helmet } from 'react-helmet-async';
import {
    getStudentPic,
    getPrettyName,
    parseContactRelationship,
    parseContactRelationshipType,
} from '../../../../utils/helpers';

const StudentDetails = ({ student, auth: { loading } }) => {
    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Student Details</title>
            </Helmet>

            {loading ? (
                <div className='containerinnersolid'>
                    <Spinner size={0} />
                </div>
            ) : (
                <Fragment>
                    <div className='student-split-view'>
                        <div className='containerinnersolid'>
                            <div className='student-page-body'>
                                <img
                                    className='round-img-lrg border-radius-5'
                                    src={getStudentPic(student.photo)}
                                    alt={student.nameInfo.firstName}
                                />
                                <div>
                                    <p>
                                        <small>Address</small>
                                    </p>
                                    {student.address.line1 && (
                                        <p>{student.address.line1}</p>
                                    )}
                                    {student.address.line2 && (
                                        <p>{student.address.line2}</p>
                                    )}
                                    {student.address.city && (
                                        <p>
                                            {student.address.city}
                                            {student.address.region && (
                                                <span>
                                                    , {student.address.region}
                                                </span>
                                            )}
                                        </p>
                                    )}

                                    {student.address.postalCode && (
                                        <p>{student.address.postalCode}</p>
                                    )}
                                    {student.address.country && (
                                        <p>{student.address.country}</p>
                                    )}

                                    <p className='py'>
                                        <small>Status</small>
                                    </p>

                                    <div>
                                        {student.registrationStatus === 0 ? (
                                            <div className='badge errorbadge mb-1 mr-1'>
                                                <i className='far fa-circle-xmark'></i>{' '}
                                                Inactive
                                            </div>
                                        ) : student.registrationStatus === 1 ? (
                                            <div className='badge warningbadge mb-1 mr-1'>
                                                <i className='far fa-clock'></i>{' '}
                                                Pending
                                            </div>
                                        ) : (
                                            <div className='badge successbadge mb-1 mr-1'>
                                                <i className='fas fa-check'></i>{' '}
                                                Active
                                            </div>
                                        )}
                                        {student.hasGraduated && (
                                            <div className='badge neutralbadge mb-1 mr-1'>
                                                <i className='fas fa-graduation-cap'></i>{' '}
                                                Graduated
                                            </div>
                                        )}
                                    </div>

                                    {student.phone && (
                                        <>
                                            <p className='pt'>
                                                <small>Phone Number</small>
                                            </p>
                                            <p>{student.phone}</p>
                                        </>
                                    )}

                                    {student.email && (
                                        <div>
                                            <p className='pt'>
                                                <small>Email Address</small>
                                            </p>
                                            <p>{student.email}</p>
                                        </div>
                                    )}

                                    <p className='pt'>
                                        <small>Birthday</small>
                                    </p>
                                    {student.birthday && (
                                        <p>
                                            {new Date(
                                                student.birthday
                                            ).getMonth() + 1}
                                            /
                                            {new Date(
                                                student.birthday
                                            ).getDate()}
                                            /
                                            {new Date(
                                                student.birthday
                                            ).getFullYear()}
                                        </p>
                                    )}
                                    <p className='mt'>
                                        <small>Sex</small>
                                    </p>
                                    {student.sex !== null && (
                                        <p>
                                            {student.sex === 1
                                                ? 'Female'
                                                : 'Male'}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='containerinnersolid'>
                            <h2>Contacts</h2>

                            {student.contacts?.length > 0 ? (
                                <table
                                    cellSpacing={0}
                                    className='student-contact-table'
                                >
                                    <tbody>
                                        <tr>
                                            <th className='student-contact-cell'>
                                                Name
                                            </th>
                                            <th>Relationship</th>
                                            <th>Role</th>
                                        </tr>

                                        {student.contacts.map(
                                            (contact, idx) => (
                                                <tr key={idx}>
                                                    <td>
                                                        {getPrettyName(
                                                            contact.contact
                                                                ?.nameInfo
                                                        )}
                                                    </td>
                                                    <td>
                                                        {parseContactRelationship(
                                                            contact.relationship
                                                        )}
                                                    </td>
                                                    <td>
                                                        {' '}
                                                        {parseContactRelationshipType(
                                                            contact.type
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            ) : (
                                <div className='mt-1'>
                                    <p>No contacts yet.</p>
                                </div>
                            )}
                            <h2 className='mb mt-3'>Administrator Notes</h2>
                            <div>{student.staffNotes || <>None yet.</>}</div>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

StudentDetails.propTypes = {
    auth: PropTypes.object.isRequired,
    student: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(StudentDetails);
