// Handles uploading different image types (regimen pic, product pic, etc.) @todo16 need to also support uploading additional pics for product, regimen steps, etc.
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uploadProfilePic } from '../../storedata/actions/auth';
import {
    uploadStudentPic,
    uploadSchoolPic,
    uploadContactPic,
} from '../../storedata/actions/admin';
import { setAlert } from '../../storedata/actions/alert';
import { ImgTypes } from '../../utils/helpers';

const FileUpload = ({
    auth,
    formID,
    id, // passed in for calls that update a particular obj id
    uploadType,
    allowVideo,
    uploadProfilePic,
    uploadStudentPic,
    uploadSchoolPic,
    uploadContactPic,
    setAlert,
}) => {
    const [files, setFiles] = useState('');

    const submitFiles = (e) => {
        e.preventDefault(); // don't let browser do its default behavior

        if (!files || files.length === 0) {
            setAlert('Please select a photo to upload', 'danger');
            return;
        }

        let file = files[0];

        const formData = new FormData();
        formData.append('file', file);

        if (uploadType === ImgTypes().PROFILE) {
            uploadProfilePic(formData);
        } else if (uploadType === ImgTypes().STUDENT) {
            uploadStudentPic(formData, id);
        } else if (uploadType === ImgTypes().SCHOOL) {
            uploadSchoolPic(formData, id);
        } else if (uploadType === ImgTypes().CONTACT) {
            uploadContactPic(formData, id);
        } else {
            // error
            setAlert(
                'Server error: Invalid upload type specified. Please contact us to resolve.',
                'danger'
            );
            return;
        }

        //document.querySelector('#uploadform-' + formID).reset(); // for form, but no longer using form
    };

    const getAllowedFileTypes = () => {
        let imgs = 'image/jpg,image/png,image/gif,image/svg+xml';
        return allowVideo ? imgs + ',video/mp4' : imgs;
    };

    return (
        <div className='form' id={'uploadform-' + formID}>
            <div className='upload-actions'>
                <input
                    type='file'
                    accept={getAllowedFileTypes()}
                    onChange={(e) => setFiles(e.target.files)}
                />
                <button
                    className='btn btn-primary'
                    onClick={(e) => submitFiles(e)}
                >
                    Upload
                </button>
            </div>
        </div>
    );
};

FileUpload.propTypes = {
    auth: PropTypes.object.isRequired,
    formID: PropTypes.string.isRequired,
    id: PropTypes.string,
    uploadType: PropTypes.number.isRequired,
    allowVideo: PropTypes.bool,
    uploadProfilePic: PropTypes.func.isRequired,
    uploadStudentPic: PropTypes.func.isRequired,
    uploadSchoolPic: PropTypes.func.isRequired,
    uploadContactPic: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {
    allowVideo: false,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {
    uploadProfilePic,
    uploadStudentPic,
    uploadSchoolPic,
    uploadContactPic,
    setAlert,
})(FileUpload);
