// PRIVATE ROUTE: ADMIN LEVEL
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../layout/Spinner';
import { getAllStudents } from '../../../../storedata/actions/admin';
import { getStudentPic, getPrettyName } from '../../../../utils/helpers';
import AddStudentModal from '../AddStudentModal';
import UpdateRegistrationModal from '../UpdateRegistrationModal';

const SchoolAdminSchoolTermRegistrationBox = ({
    admin,
    term,
    getAllStudents,
}) => {
    let location = useLocation();

    const [showSpinner, setShowSpinner] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [showStudentModal, setShowStudentModal] = useState(null);
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    useEffect(() => {
        if (!admin.students) {
            getAllStudents();
        }
    }, [getAllStudents, admin.students]);

    useEffect(() => {
        setShowSpinner(admin.loading_term);
    }, [admin.loading_term, location.pathname]);

    const studentIsRegistered = (term, student) => {
        let reg = term.registeredStudents;
        return reg?.find((s) => s.student?._id === student._id);
    };

    return (
        <Fragment>
            {!showRegistrationModal && (showSpinner || !admin.students) ? (
                <div className='containerinnersolid'>
                    <Spinner size={0} />
                </div>
            ) : (
                <Fragment>
                    <div className='gridrow grid-gap-30'>
                        <div className='containerinnersolid'>
                            <div className='registrationHeader'>
                                <span className='stepNum'>3</span>
                                <h2>Register Students</h2>
                            </div>
                            <p className='mb-2'>
                                Select which students to register for this term.
                                Required fees will automatically be applied to
                                each registered student.
                            </p>

                            {/* <div className='inline-notification notification-warm mb-2'>
                                <span>
                                    Note: Only active and pending students can
                                    be registered. To view and update inactive
                                    students, go to the student roster.
                                </span>
                            </div> */}

                            {term.registeredStudents?.length > 0 ? null : (
                                <div
                                    className={`inline-notification notification-warm mb-2`}
                                >
                                    <span>
                                        No students registered for this term
                                        yet. Register students below.
                                    </span>
                                </div>
                            )}

                            <div className='gridcol even2split-spacebtwn mb-2'>
                                <div>
                                    <h2 className='mb-0'>
                                        <span className='mr-1'>
                                            {admin.students.length} Student
                                            {admin.students.length === 1
                                                ? ''
                                                : 's'}
                                        </span>
                                        <div className='badge neutralbadge'>
                                            {selectedStudents.length} student
                                            {selectedStudents.length === 1
                                                ? ' '
                                                : 's '}
                                            selected
                                        </div>
                                    </h2>
                                </div>
                                <div className='gridcol'>
                                    <button
                                        className='btn'
                                        onClick={(e) => {
                                            setSelectedStudents([
                                                ...admin.students,
                                            ]);
                                        }}
                                        disabled={
                                            selectedStudents.length ===
                                            admin.students.length
                                        }
                                    >
                                        Select All
                                    </button>
                                    <button
                                        className='btn'
                                        onClick={(e) => {
                                            setSelectedStudents([]);
                                        }}
                                        disabled={selectedStudents.length === 0}
                                    >
                                        Unselect All
                                    </button>

                                    <button
                                        className='btn btn-primary'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setShowRegistrationModal(
                                                selectedStudents
                                            );
                                        }}
                                        disabled={selectedStudents.length === 0}
                                    >
                                        Update Registration
                                    </button>

                                    {/* <button
                                        className='btn btn-primary'
                                        onClick={(e) => {
                                            alert('Coming soon!');
                                        }}
                                        disabled={selectedStudents.length === 0}
                                    >
                                        Update Grade Level
                                    </button> */}
                                </div>
                            </div>

                            <div className='student-mini-row-wrapper'>
                                {admin.students
                                    // .filter((s) => s.registrationStatus !== 0)
                                    .map((student, idx) => {
                                        return (
                                            <div
                                                className={
                                                    'student-mini-row' +
                                                    (selectedStudents.find(
                                                        (s) =>
                                                            s._id ===
                                                            student._id
                                                    )
                                                        ? ' row-selected'
                                                        : '')
                                                }
                                                key={idx}
                                                onClick={(e) => {
                                                    const id = student._id;
                                                    if (
                                                        selectedStudents.find(
                                                            (s) => s._id === id
                                                        )
                                                    ) {
                                                        setSelectedStudents(
                                                            selectedStudents.filter(
                                                                (s) =>
                                                                    s._id !== id
                                                            )
                                                        );
                                                    } else {
                                                        setSelectedStudents([
                                                            ...selectedStudents,
                                                            student,
                                                        ]);
                                                    }
                                                }}
                                            >
                                                <div>
                                                    <img
                                                        className='round-img-avg'
                                                        src={getStudentPic(
                                                            student.photo
                                                        )}
                                                        alt={
                                                            student.nameInfo
                                                                .firstName
                                                        }
                                                    />
                                                </div>
                                                <div className='badgebox'>
                                                    {student.registrationStatus ===
                                                    0 ? (
                                                        <div className='badge errorbadge border'>
                                                            <i className='far fa-circle-xmark'></i>{' '}
                                                            Inactive
                                                        </div>
                                                    ) : student.registrationStatus ===
                                                      1 ? (
                                                        <div className='badge warningbadge border'>
                                                            <i className='far fa-clock'></i>{' '}
                                                            Pending
                                                        </div>
                                                    ) : (
                                                        <div className='badge successbadge border'>
                                                            <i className='fas fa-check'></i>{' '}
                                                            Active
                                                        </div>
                                                    )}
                                                </div>
                                                <h3>
                                                    {getPrettyName(
                                                        student.nameInfo,
                                                        false
                                                    )}
                                                </h3>
                                                {studentIsRegistered(
                                                    term,
                                                    student
                                                ) && <span>registered</span>}

                                                <small>
                                                    {studentIsRegistered(
                                                        term,
                                                        student
                                                    ) &&
                                                        (studentIsRegistered(
                                                            term,
                                                            student
                                                        )?.gradeLevel?.name || (
                                                            <div className='danger-color'>
                                                                Unassigned
                                                            </div>
                                                        ))}
                                                </small>

                                                <div className='actions'>
                                                    <button
                                                        className='btn'
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setShowStudentModal(
                                                                student._id
                                                            );
                                                        }}
                                                    >
                                                        <i className='fas fa-pen' />
                                                    </button>
                                                </div>
                                                {selectedStudents.find(
                                                    (s) => s._id === student._id
                                                ) && (
                                                    <div className='check'>
                                                        <i className='far fa-circle-check' />
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}

            {showRegistrationModal !== false && (
                <UpdateRegistrationModal
                    term={term}
                    students={showRegistrationModal}
                    previousLocation={location.pathname}
                    submitCallback={(e, formData) => {
                        e.preventDefault();
                        setShowRegistrationModal(false);
                    }}
                    closeCallback={(e) => {
                        e.preventDefault();
                        setShowRegistrationModal(false);
                    }}
                />
            )}

            {showStudentModal && (
                <AddStudentModal
                    previousLocation={location.pathname}
                    submitCallback={(e, formData) => {
                        e.preventDefault();
                        setShowStudentModal(null);
                    }}
                    closeCallback={(e) => {
                        e.preventDefault();
                        setShowStudentModal(null);
                    }}
                    studentID={showStudentModal}
                />
            )}
        </Fragment>
    );
};

SchoolAdminSchoolTermRegistrationBox.propTypes = {
    admin: PropTypes.object.isRequired,
    getAllStudents: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
});

export default connect(mapStateToProps, {
    getAllStudents,
})(SchoolAdminSchoolTermRegistrationBox);
