// PRIVATE ROUTE: logged in user (/account)
import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../../utils/constants';
import BaseFrame from '../../BaseFrame';
import Spinner from '../../../layout/Spinner';
import { updateStudent } from '../../../../storedata/actions/admin';
import BackButton from '../../../layout/inputs/BackButton';
import StudentForm from './StudentForm';
import SchoolHeader from '../../SchoolHeader';

const UpdateStudentPage = ({ updateStudent, auth: { loading, viewingAs } }) => {
    let params = useParams();
    let id = params?.studentid;
    let history = useNavigate();
    let location = useLocation();

    const [formData, setFormData] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault(); // don't let browser do its default behavior

        updateStudent(
            formData,
            history,
            location,
            true /* relocate */,
            null /* relocateTo */,
            id
        );
        // .then(function (result) {
        //     if (result.success /* && submitCallback*/) {
        //         // submitCallback(e, formData);
        //     }
        // })
        // .catch(function (err) {});
    };

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Update Student</title>
            </Helmet>

            <BaseFrame>
                <SchoolHeader school={viewingAs?.school} />
                <div className='gridcol2packed mb-2'>
                    <BackButton />
                    <h1>{id ? 'Update' : 'Add'} Student</h1>
                </div>
                <div className='containerinnersolid'>
                    {loading ? (
                        <Spinner size={0} />
                    ) : (
                        <Fragment>
                            <StudentForm
                                studentID={id}
                                onChangeHandler={(formData) => {
                                    setFormData(formData);
                                }}
                            />
                            <div className='admin-form-footer'>
                                <button
                                    className='btn btn-primary my-1 mr-0'
                                    onClick={onSubmit}
                                >
                                    Save
                                </button>
                            </div>
                        </Fragment>
                    )}
                </div>
            </BaseFrame>
        </Fragment>
    );
};

UpdateStudentPage.propTypes = {
    auth: PropTypes.object.isRequired,
    updateStudent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {
    updateStudent,
})(UpdateStudentPage);
